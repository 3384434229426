import { Col, Modal } from "react-bootstrap";
import { ButtonOutlinePrimary, ButtonPrimary } from "../../../../shared/component/button/Button";

function ModalInfo({show,onHideModal}) {


    const handleCloseReset = () => {
        
        onHideModal();
    }

    return(
        <Modal
        size={'sm'}
        show={show}
        onHide={()=>handleCloseReset()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        
      >
        <Modal.Header  style={{borderRadius:0}}>
          <Modal.Title id="contained-modal-title-vcenter">
              Info
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="ps-4" style={{borderBottomColor:'white'}}>
           <div style={{fontWeight:'bold'}}>
           Cannot be added as a rater because email data does not exist. Please contact admin for help
           </div>
        </Modal.Body>
      
        <Modal.Footer>
            <ButtonOutlinePrimary label="Close" onClick={onHideModal} />
        </Modal.Footer>
       
      </Modal>
    )
}

export default ModalInfo