import { Modal } from "react-bootstrap";
import { ButtonPrimary } from "../../../../../shared/component/button/Button";
import { useNavigate } from "react-router-dom";
import { CONSTANTS } from "../../../../../common/constants";

function ModalAddContentFrom({ size, show, onHide, title, handleOnClickNew }) {
  const navigate = useNavigate();

  return (
    <Modal
      size={size}
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered

    >
      <Modal.Header closeButton style={{ borderRadius: 0 }}>
        <Modal.Title id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="d-flex justify-content-between">
        <div className="mx-2">
          <ButtonPrimary label="Create New Question" onClick={handleOnClickNew} />
        </div>
        <div className="mx-2">
          <ButtonPrimary label="Add From Question History" onClick={() => navigate(`${CONSTANTS.ROUTER.SELECT_QUESTION_HISTORY}`)} />
        </div>
      </Modal.Body>

      <Modal.Footer className="p-1">
      </Modal.Footer>
    </Modal>
  )
}

export default ModalAddContentFrom;