import { useEffect, useReducer, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import {
  InputOnlyForDropdown,
  InputTextWithLabel,
  InputWithLabelIntro,
} from "../../../shared/component/input/Input";
import { ButtonPrimary } from "../../../shared/component/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { DropdownWithLabel } from "../../../shared/component/dropdown/DropdownInput";
import useRefs from "react-use-refs";
import { UseInstruction } from "./useInstruction";
import { genderIntro } from "./IntroductionData";

function IntroductionModal({ title, onHide, size, show }) {
  const [buNameRef, deptNameRef, levelRef, genderRef] = useRefs();
  const { buData, departmentData, levelData, onUpdateUserInfo,onGetBUData,onGetDepartmentData,onGetLevelData } =
    UseInstruction();
  const [searchBU, setSearchBU] = useState("");
  const [searchDepartment, setSearchDepartment] = useState("");
  const [searchLevel, setSearchLevel] = useState("");
  const [filterBU, setFilteredBU] = useState(buData);
  const [filterDepartment, setFilteredDepartment] = useState(departmentData);
  const [filterLevel, setFilteredLevel] = useState(levelData);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [disableSave, setDisableSave] = useState(true);
  const userReducer = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [userBU, setUserBU] = useState(userReducer.business_unit_name); // ditampung di userBU untuk pilihan akhir
  const [userDepartment, setUserDepartment] = useState(
    userReducer.department_name
  );
  const [userLevel, setUserLevel] = useState(userReducer.level);
  const [userGender, setUserGender] = useState(userReducer.gender);
  const [userDob, setUserDob] = useState(userReducer.dob);
  const [userJoinDate, setUserJoinDate] = useState(userReducer.join_date);
  const [userPosition, setUserPosition] = useState(userReducer.position);
  const [userNoHp, setUserNoHp] = useState(userReducer.no_hp);


  useEffect(() => {
    const isInternal = userReducer.is_internal === 1;
    const allFieldsFilled = [
      userReducer.nik,
      userReducer.name,
      userReducer.email,
      userNoHp,
      !isInternal ? userPosition : true,
      !isInternal ? userBU : true,
      userGender,
      userDob,
    ].every(Boolean);

    // console.log({
    //   nik: userReducer.nik,
    //   name: userReducer.name,
    //   email: userReducer.email,
    //   userNoHp,
    //   userPosition,
    //   userBU,
    //   userGender,
    //   userDob,
    //   userJoinDate: isInternal ? userJoinDate : true,
    //   userLevel: isInternal ? userLevel : true,
    //   userDepartment: isInternal ? userDepartment : true,
    // });

    setDisableSave(!allFieldsFilled);
  }, [
    userGender,
    userDob,
    userNoHp,
    userReducer,
    userBU,
    userPosition
  ]);

  const handleSearchBU = (e) => {
    const term = e.target.value;
    setSearchBU(term);
    if (buData.length != 0) {
      const filtered = buData.filter((option) =>
        option.business_unit_name != null
          ? option.business_unit_name
              .toString()
              .toLowerCase()
              .includes(term.toLowerCase())
          : ""
      );

      setFilteredBU(filtered);
    }
  };

  const handleSearchDepartment = (e) => {
    const term = e.target.value;
    setSearchDepartment(term);
    if (departmentData.length != 0) {
      const filtered = departmentData.filter((option) =>
        option.department_name != null
          ? option.department_name
              .toString()
              .toLowerCase()
              .includes(term.toLowerCase())
          : ""
      );

      setFilteredDepartment(filtered);
    }
  };

  const handleSearchLevel = (e) => {
    const term = e.target.value;
    setSearchLevel(term);
    if (levelData.length != 0) {
      const filtered = levelData.filter((option) =>
        option.level_user != null
          ? option.level_user
              .toString()
              .toLowerCase()
              .includes(term.toLowerCase())
          : ""
      );

      setFilteredLevel(filtered);
    }
  };

  const handleOnSelect = (e) => {
    const { id, value } = e.target;
    console.log(`Changed field: ${id}, Value: ${value}`);

    switch (id) {
      case "business_unit_name":
        setUserBU(value);
        break;
      case "department_name":
        setUserDepartment(value);
        break;
      case "level":
        setUserLevel(value);
        break;
      case "gender":
        setUserGender(value);
        break;
      case "dob":
        setUserDob(value);
        break;
      case "join_date":
        setUserJoinDate(value);
        break;
      case "position":
        setUserPosition(value);
        break;
      case "phone_number":
        setUserNoHp(value);
        break;
      default:
        break;
    }
  };

  const handleOnSave = () => {
    onUpdateUserInfo(
      userReducer.nik,
      userReducer.email,
      userReducer.name,
      userDob,
      userGender,
      userJoinDate,
      userNoHp,
      userLevel,
      userPosition,
      userBU,
      userDepartment,
      userReducer.is_internal,
      userReducer.role
    );
    onHide();
  };

  return (
    <Modal
      show={show}
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="col-12 d-flex">
          <div className="col-6 p-2">
            <div className="col-12 my-4">
              <InputTextWithLabel
                style={{ marginBottom: 2 }}
                label="NIK"
                id={"nik"}
                value={userReducer.nik}
                disabled
              />
            </div>
            <div className="col-12 my-4">
              <InputTextWithLabel
                style={{ marginBottom: 2 }}
                label="Name"
                id={"name"}
                value={userReducer.name}
                disabled
              />
            </div>
            <div className="col-12 my-4">
              <InputTextWithLabel
                style={{ marginBottom: 2 }}
                label="Email"
                id={"email"}
                value={userReducer.email}
                disabled
              />
            </div>
            <div className="col-12 my-4">
              <InputTextWithLabel
                style={{ marginBottom: 2 }}
                label="Phone Number"
                id={"phone_number"}
                required={true}
                value={userReducer.no_hp == null ? userNoHp : userReducer.no_hp}
                handleOnChange={handleOnSelect}
              />
            </div>
            <div className="col-12 my-4">
              <InputTextWithLabel
                style={{ marginBottom: 2 }}
                label="Position"
                id={"position"}
                required={true}
                value={userReducer.position == null ? userPosition : userReducer.position}
                handleOnChange={handleOnSelect}
              />
            </div>
            <div className="col-12 my-4">
              {userReducer.is_internal == 1 ? (
                <DropdownWithLabel
                  id={"business_unit_name"}
                  required={true}
                  innerRef={buNameRef}
                  label={"Business Unit"}
                  default_item={userBU}
                  handleOpenDropdown={onGetBUData}
                  search_bar={
                    <InputOnlyForDropdown
                      type="text"
                      placeholder="Search Business Unit ..."
                      value={searchBU}
                      handleOnChange={handleSearchBU}
                      input_width={"100%"}
                    />
                  }
                  dropdown_item={
                    Array.isArray(filterBU) && filterBU.length != 0 ? (
                      filterBU.map((itm, i) => {
                        return (
                          <Dropdown.Item
                            key={i}
                            onClick={() => setUserBU(itm.business_unit_name)}
                          >
                            {itm.business_unit_name}
                          </Dropdown.Item>
                        );
                      })
                    ) : (
                      <Dropdown.Item>Select</Dropdown.Item>
                    )
                  }
                />
              ) : (
                <InputTextWithLabel
                  style={{ marginBottom: 2 }}
                  label="Business Unit/Company"
                  id={"business_unit_name"}
                  required={true}
                  value={userReducer.business_unit_name == null ? userBU : userReducer.business_unit_name}
                  handleOnChange={handleOnSelect}
                />
              )}
            </div>
          </div>
          <div className="col-6 p-2">
            <div className="col-12 my-4">
              <InputTextWithLabel
                style={{ marginBottom: 2 }}
                label="Type of Participant"
                id={"type_of_participant"}
                value={
                  userReducer.is_internal != null
                    ? userReducer.is_internal == 0
                      ? "External"
                      : "Internal"
                    : null
                } // jangan lupa pass ke API balikin ke 0 dan 1
                disabled
              />
            </div>
            <div className="col-12 my-4">
              <DropdownWithLabel
                id={"gender"}
                innerRef={genderRef}
                label={"Gender"}
                required={true}
                default_item={userReducer.gender == null ? userGender : userReducer.gender}
                dropdown_item={
                  genderIntro.length != 0 ? (
                    genderIntro.map((itm, i) => {
                      return (
                        <Dropdown.Item
                          key={i}
                          onClick={() => setUserGender(itm.value)}
                        >
                          {itm.value}
                        </Dropdown.Item>
                      );
                    })
                  ) : (
                    <Dropdown.Item>Select</Dropdown.Item>
                  )
                }
              />
            </div>
            <div className="col-12 my-4">
              <InputWithLabelIntro
                id={"dob"}
                label={"Date of Birth"}
                type={"date"}
                required={true}
                value={userReducer.dob == null ? userDob : userReducer.dob}
                handleOnChange={handleOnSelect}
              />
            </div>
            <div className="col-12 my-4">
              {userReducer.is_internal == 1 ? (
                <InputWithLabelIntro
                  id={"join_date"}
                  label={"Join Date"}
                  type={"date"}
                  value={userReducer.join_date == null ? userJoinDate : userReducer.join_date}
                  handleOnChange={handleOnSelect}
                />
              ) : (
                <div></div>
              )}
            </div>
            <div className="col-12 my-4">
              {userReducer.is_internal == 1 ? (
                <DropdownWithLabel
                  id={"level"}
                  required={true}
                  innerRef={levelRef}
                  label={"Level"}
                  handleOpenDropdown={onGetLevelData}
                  default_item={userReducer.level == null ? userLevel : userReducer.level}
                  search_bar={
                    <InputOnlyForDropdown
                      type="text"
                      placeholder="Search Level ..."
                      value={searchLevel}
                      handleOnChange={handleSearchLevel}
                      input_width={"100%"}
                    />
                  }
                  dropdown_item={
                    Array.isArray(filterLevel) && filterLevel.length != 0 ? (
                      filterLevel.map((itm, i) => {
                        return (
                          <Dropdown.Item
                            key={i}
                            onClick={() => setUserLevel(itm.level_user)}
                          >
                            {itm.level_user}
                          </Dropdown.Item>
                        );
                      })
                    ) : (
                      <Dropdown.Item>Select</Dropdown.Item>
                    )
                  }
                />
              ) : (
                <div></div>
              )}
            </div>
            <div className="col-12 my-4">
              {userReducer.is_internal == 1 ? (
                <DropdownWithLabel
                  id={"department_name"}
                  required={true}
                  innerRef={deptNameRef}
                  label={"Department"}
                  handleOpenDropdown={onGetDepartmentData}
                  default_item={userReducer.department_name == null ? userDepartment : userReducer.department_name}
                  search_bar={
                    <InputOnlyForDropdown
                      type="text"
                      placeholder="Search Department ..."
                      value={searchDepartment}
                      handleOnChange={handleSearchDepartment}
                      input_width={"100%"}
                    />
                  }
                  dropdown_item={
                    Array.isArray(filterDepartment) &&
                    filterDepartment.length != 0 ? (
                      filterDepartment.map((itm, i) => {
                        return (
                          <Dropdown.Item
                            key={i}
                            onClick={() =>
                              setUserDepartment(itm.department_name)
                            }
                          >
                            {itm.department_name}
                          </Dropdown.Item>
                        );
                      })
                    ) : (
                      <Dropdown.Item>Select</Dropdown.Item>
                    )
                  }
                />
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <ButtonPrimary
          onClick={handleOnSave}
          label="Save"
          disabled={disableSave}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default IntroductionModal;