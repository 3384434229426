import { useEffect } from "react";
import history from "../../history";

export function useBlocker(blocker, when = true) {
   useEffect(() => {
      if (!when) return;
      const unblock = history.block((tx) => {
         const autoUnblockingTx = {
            ...tx,
            retry() {
               unblock();
               tx.retry();
            }
         };
         blocker(autoUnblockingTx);
      });
      return unblock;
   }, [blocker, when]);
};