/* This service for participant features */

export const MultiRaterSurveyFormService = ({ doPost, doDelete }) => {
    const getDetailSurveyFormData = async (survey_code) => {
       try {
            return await doPost({
                url: '/participant/get_all_survey_question',
                data: {
                    survey_code : survey_code,
                },
            });
       } catch (error) {
          throw error;
       }
    };

    const postManyAnswer = async (data) => {
      try {
         return await doPost({
             url: '/participant/insert_many_answer',
             data: data,
         });
      } catch (error) {
         throw error;
      }
    }


    /* TULIS API GET MULTIRATER LIST UTK PARTICIPANT DIBAWAH INI */
    /* Get survey list yang diassign ke raters tersebut */
    
 
    return {
       getDetailSurveyFormData,
       postManyAnswer
    };
 };
 