import { useDispatch, useSelector } from "react-redux";
import { ButtonPrimary } from "../../shared/component/button/Button";
import { SearchColumn } from "../../shared/component/table_property/SearchColumn";
import { ShowEntries } from "../../shared/component/table_property/ShowEntries";
import usePICDashboard from "./usePICDashboarc";
import LoadingBar from 'react-top-loading-bar';
import { IconButtonDelete, IconButtonDuplicate, IconButtonEdit } from "../../shared/component/button/IconButton";
import Pagination from '../../shared/component/table_property/Paginatination';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import screenAlert from "../../shared/hook/SweetAlert";
import { clearAddMultiraterRedux, clearAddSingleraterRedux, clearUpdateMultiraterRedux, clearUpdateSingleraterRedux } from "../../common/utils";
import DuplicateMultirater from "../cms_multirater/duplicate_multirater/DuplicateMultiraterView";
import DuplicateSinglerater from "../cms_singlerater/duplicate_singlerater/DuplicateSingleRater";
import { CONSTANTS } from "../../common/constants";
import { DropdownIcon } from "../../shared/component/dropdown/DropdownInput";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { invite } from "../cms_multirater/multirater_list/MultiraterListView";
import { Dropdown } from "react-bootstrap";


function PICDashboard() {
    const {
      allSurvey,
      pageNumber,
      setPageNumber,
      searchText,
      setSearchText,
      showItem,
      setShowItem,
      totalItem,
      handleChangeSearchText,
      handleDeleteSurvey,
      handleSearchBar,
      handleShowItem,
      onSendInvitationtoAllRaters,
      progress,
      setProgress
 
    } = usePICDashboard();
    
    const [openShare, setOpenShare] = useState(false);
    const navigate = useNavigate();
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const showAlert = screenAlert();
    const [isDuplicateMultiRater, setIsDuplicateMultiRater] = useState(false);
    const [isDuplicateSingleRater, setIsDuplicateSingleRater] = useState(false);
    const [surveyCodeMulti, setSurveyCodeMulti] = useState('');
    const [surveyCodeSingle, setSurveyCodeSingle] = useState('');
    const currentUrl = window.location.pathname.split('/')[1];


 
    // handler
    const paginate = (pageNumber) => {
       setPageNumber(pageNumber);
    };
 
    const previousPage = () => {
       if (pageNumber != 1) {
          setPageNumber(pageNumber - 1);
 
       }
    };
 
    const nextPage = () => {
       if (pageNumber != Math.ceil(totalItem / showItem)) {
          setPageNumber(pageNumber + 1);
       }
    };
 
    // const handleNavigateAddMultiraterPage = () => {
    //    if (user.role == CONSTANTS.ROLE.ADMIN) {
    //       navigate(`${CONSTANTS.ROUTER.ADMIN_CMS}/${CONSTANTS.ROUTER.ADD_MULTI_RATER_SURVEY}`)
    //    } else {
    //       navigate(`${CONSTANTS.ROUTER.PIC_CMS}/${CONSTANTS.ROUTER.ADD_MULTI_RATER_SURVEY}`)
    //    }
    // }
 
    const onDuplicateMultiRater = async (e, surveyCode) => {
       e.preventDefault();
 
       showAlert.info({
          text: "Are you sure to duplicate?\nThis will replace your progress on adding a multirater survey (if any).",
          buttons: {
             cancel: {
                text: "Cancel",
                className: "bg-white text-dark"
             },
             confirm: {
                text: "Confirm",
                value: true,
                className: "bg-success"
             }
          }
       }).then(value => {
          if (value) {
             clearAddMultiraterRedux(dispatch);
             setIsDuplicateMultiRater(true);
             setSurveyCodeMulti(surveyCode);
          }
       });
    }

    const onDuplicateSingleRater = async (e, surveyCode) => {
      e.preventDefault();
 
      showAlert.info({
         text: "Are you sure to duplicate?\nThis will replace your progress on adding a singlerater survey (if any).",
         buttons: {
            cancel: {
               text: "Cancel",
               className: "bg-white text-dark"
            },
            confirm: {
               text: "Confirm",
               value: true,
               className: "bg-success"
            }
         }
      }).then(value => {
         if (value) {
            clearAddSingleraterRedux(dispatch);
            setIsDuplicateSingleRater(true);
            setSurveyCodeSingle(surveyCode);
         }
      });
   }
 
    //effect
    useEffect(() => {
       clearUpdateMultiraterRedux(dispatch);
      clearUpdateSingleraterRedux(dispatch);

    }, []);
 
    return (
       <>
          <LoadingBar color="#7749F8" progress={progress} onLoaderFinished={() => setProgress(0)} />
 
          <div className="container tw-h-max">
             <div className='my-4 d-flex justify-content-between'>
                <h4>My Projects</h4>
             </div>
 
             <div className='card shadow p-4 border-0' >
                <div className='justify-content-between mb-4 d-flex flex-row'>
                   <div className='col-4 d-flex flex-row align-items-center'>
                      <div>
                         Show
                      </div>
 
                      <ShowEntries onClick_item={handleShowItem} default_item={showItem} />
                      <div className=''>
                         entries
                      </div>
                   </div>
 
                   <div className='col-3 d-flex align-items-center'>
                     
                      <SearchColumn onChange_input={handleChangeSearchText} onClick_btn={handleSearchBar} />
 
                     
                   </div>
 
                </div>
 
                <div className='mt-1 table-responsive'>
                   <table className="table table-striped table-bordered">
                      <thead>
                         <tr>
                            <th rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>No</th>
                            <th rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>Type</th>
                            <th rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>Project Name</th>
                            <th rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>Status</th>
                            <th colSpan={3} style={{ verticalAlign: 'middle', textAlign: 'center' }}>Actions</th>
                            <th rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>Invitation</th>
 
                         </tr>
 
                         <tr>
                            <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>Duplicate</th>
                            <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>Update</th>
                            <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>Delete</th>
                         </tr>
 
                      </thead>
                      <tbody>
                         {Array.isArray(allSurvey) && allSurvey.length != 0 ?
                            allSurvey.map((dt, i) => {
                               return (<tr key={i} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                  {
                                     pageNumber == 1 ? <td>{i + 1}</td> : <td>{(i + 1) + (showItem * (pageNumber - 1))}</td>
                                  }
                                  <td style={{ textAlign: 'start' }}>{dt.type}</td>
                                  <td style={{ textAlign: 'start' }}>{dt.survey_name}</td>
                                  <td>{dt.is_active == 0 ? 'not active' : 'active'}</td>
                                  <td>
                                    {                                 
                                     dt.type == 'multi' ? 
                                     <IconButtonDuplicate onClick={(e) => onDuplicateMultiRater(e, dt.survey_code)} /> : <IconButtonDuplicate onClick={(e) => onDuplicateSingleRater(e, dt.survey_code)} />
                                    }
                                  </td>
                                  <td>
                                  {                                 
                                     dt.type == 'multi' ? 

                                     <IconButtonEdit 
                                     onClick={() => navigate(`${CONSTANTS.ROUTER.UPDATE_MULTIRATER}`, { state: { survey_code: dt.survey_code } })} disabled={dt.created_by == user.email || user.role == 1 ? false : true} /> 
                                     : 
                                    
                                    <IconButtonEdit onClick={() => navigate(`${CONSTANTS.ROUTER.UPDATE_SINGLERATER}`, { state: { survey_code: dt.survey_code } })} disabled={dt.created_by == user.email || user.role == 1 ? false : true} />
                                    }
                                     
                                  </td>
                                  <td>
                                     <IconButtonDelete disabled={dt.created_by == user.email ? false : true} onClick={() => handleDeleteSurvey(dt.type,dt.survey_code)} />
                                  </td>
                                  <td>                                    
                                     <DropdownIcon
                                        disabled={dt.created_by == user.email || user.role == 1 ? false : true}
                                        icon_default={faEnvelope}
                                        size_icon={'xl'}
 
                                        dropdown_item={
                                           invite.map((inv, i) => {
                                              return (
                                                 <Dropdown.Item key={i}>
                                                    <div className="d-flex" onClick={() => onSendInvitationtoAllRaters(dt.type,dt.survey_code)}>
                                                       <div className="me-2">
                                                          {inv.icon}
                                                       </div>
                                                       <div>
                                                          {inv.label}
                                                       </div>
                                                    </div>
                                                 </Dropdown.Item>
                                              )
                                           })
                                        }
 
                                     />
                                  </td>
                                  {/* <td>
                         <IconButtonPrimary icon={faShareNodes} disabled={dt.created_by == user.email || user.role == 1 ? false : true}
                           onClick={() => setOpenShare(true)}
                         />
                       </td> */}
 
 
                               </tr>)
                            }) : <tr>
                               <td colSpan={11} style={{ textAlign: 'center', verticalAlign: 'middle' }}>No data</td>
                            </tr>
                         }
                      </tbody>
 
                   </table>
                </div>
                <Pagination showing={Array.isArray(allSurvey) ? allSurvey.length : 0} totalItem={totalItem} showItem={showItem} previousPage={previousPage} nextPage={nextPage} paginate={paginate} limit={pageNumber} />
 
             </div>
             {/* <ModalSharingSurvey
                show={openShare}
                onHide={() => setOpenShare(false)}
                title={'Sharing Survey'}
 
             /> */}
 
             {isDuplicateMultiRater && <DuplicateMultirater surveyCode={surveyCodeMulti} />}
             {isDuplicateSingleRater && <DuplicateSinglerater surveyCode={surveyCodeSingle} />}

          </div>
       </>
    )

 
 }

export default PICDashboard