import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SearchColumn } from "../table_property/SearchColumn";
import {
  thTableMultiRaterSurvey,
} from "../../../features/question_bank/component/multiratersurvey_constants";
import { faArrowsRotate, faCheck } from "@fortawesome/free-solid-svg-icons";
import { ButtonLink } from "../button/Button";
import { ShowEntries } from "../table_property/ShowEntries";
import Pagination from "../table_property/Paginatination";
import { useNavigate} from "react-router-dom";
import { CONSTANTS } from "../../../common/constants";
import { Footer } from "../../../features/layout/navbar/Footer";
import {  useDispatch, useSelector } from "react-redux";
import { useMultiraterSurveyList } from "../../../features/participant_features/multirater_list/useMultiraterSurveyList";
import { rootAction } from "../../../redux/RootAction";
import { useEffect, useState } from "react";

export const BodyFooter = () => {
  const navigate = useNavigate();
  const rater = useSelector((state)=>state.user);
  const dispatch = useDispatch();
  const {multiRatersList,
    handleInputSearchChange,
    handleSearchData,
    handleshowItem,
    totalItem,
    limit,
    onGetAllMultiRatersList,
    searchText,
  setPageNumber,pageNumber} = useMultiraterSurveyList();

  useEffect(()=>{
    dispatch(rootAction.surveyForm.deleteAllMAndatoryContent())
  },[])
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const roleInSurvey = (dt) => {
    // console.log('roleInSurvey',dt);
    if (dt == CONSTANTS.ROLE_IN_SURVEY_ID.SUPERVISOR_ID || dt == "supervisor" || dt == "Supervisor") {
      return "Supervisor"
    } else if (dt == CONSTANTS.ROLE_IN_SURVEY_ID.SUBORDINATE_ID || dt == "subordinate" || dt == "Subordinate") {
      return "Subordinate"
    } else if (dt == CONSTANTS.ROLE_IN_SURVEY_ID.STAKEHOLDER_ID || dt == "stakeholder" || dt == "Stakeholder") {
      return "Stakeholder"
    } else if (dt == CONSTANTS.ROLE_IN_SURVEY_ID.PEER_ID || dt == "peer" || dt == "Peer") {
      return "Peer"
    } else {
      return dt
    }
  }

  const paginate = (pageNumber) => {
    setPageNumber(pageNumber);
 };

 const previousPage =  () => {
      if (pageNumber != 1) {
        setPageNumber(pageNumber - 1);
        
      }
  };

  const nextPage = () => {
      if (pageNumber != Math.ceil(totalItem/ limit)) {
        setPageNumber(pageNumber + 1);
      }
  };

  const handleSeeRatersData = (field,payload) => {
    dispatch(rootAction.addRater.setDataSeeRaters({
       field: field,
       payload: payload
   }));
  }

  const handleParticipantRaterMRFormData = (field,payload) => {
    dispatch(rootAction.surveyForm.setDataParticipantRaterMRForm({
       field: field,
       payload: payload
   }));
  }

  const navigateToAddSeeRaters = (survey_code,tp_id,tp_nik,tp_email,tp_name, user_email) => {
    handleSeeRatersData('survey_code',survey_code);
    handleSeeRatersData('tp_email',tp_email);
    handleSeeRatersData('tp_name',tp_name);
    handleSeeRatersData('tp_id',tp_id);
    handleSeeRatersData('tp_nik',tp_nik);
    handleSeeRatersData('user_email',user_email);
    navigate(`${CONSTANTS.ROUTER.PARTICIPANT_DASHBOARD}/${CONSTANTS.ROUTER.PARTICIPANT_LIST_RATERS}`);
  }

 
  const navigateToGiveFeedback =  ( 
    s_raters_id,
    s_participant_id,
    survey_code,
    target_nik,
    target_full_name,
    target_email,
    survey_name,
    rater_role,
    rater_nik,
    rater_email,
    rater_bu,
    rater_dept) => {
      dispatch(rootAction.surveyForm.deleteAllManyAnswer());
      handleParticipantRaterMRFormData('s_raters_id',s_raters_id);
      handleParticipantRaterMRFormData('s_participant_id',s_participant_id);
      handleParticipantRaterMRFormData('survey_code',survey_code);
      handleParticipantRaterMRFormData('target_nik',target_nik);
      handleParticipantRaterMRFormData('target_full_name',target_full_name);
      handleParticipantRaterMRFormData('target_email',target_email);
      handleParticipantRaterMRFormData('survey_name',survey_name);
      handleParticipantRaterMRFormData('rater_role',rater_role);
      handleParticipantRaterMRFormData('rater_nik',rater_nik);
      handleParticipantRaterMRFormData('rater_email',rater_email);
      handleParticipantRaterMRFormData('rater_bu',rater_bu);
      handleParticipantRaterMRFormData('rater_dept',rater_dept);

      navigate(
        `${CONSTANTS.ROUTER.PARTICIPANT_DASHBOARD}/${CONSTANTS.ROUTER.PARTICIPANT_MULTIRATER_SURVEY_FORM}`)

  }

  useEffect(() => {
console.log('rater',rater);
  
  }, [rater])


  return windowWidth >= 500 ? (
    <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
      <div className="card" style={{ padding: "3%" }}>
       
        <div className="pb-4 justify-content-between d-flex flex-row">
          <div className="col-4 d-flex flex-row align-items-center">
            <div>Show</div>

            <ShowEntries onClick_item={handleshowItem} default_item={limit} />
            <div className="">entries</div>
          </div>

          <div className="col-3 d-flex align-items-center">
          <div className="d-inline float-end">
            <div className="tooltips">
               <button
                className="me-2 btn btn-light "
                onClick={()=>onGetAllMultiRatersList(limit,pageNumber,searchText)}
              >
                <FontAwesomeIcon icon={faArrowsRotate} />
              </button>
              <span className="tooltiptext">Reload</span>
            </div>
          </div>
           
          <SearchColumn onChange_input={handleInputSearchChange}  onClick_btn={handleSearchData}/>
          
          </div>
        </div>

        <div className="table-responsive table-bordered mb-2">
          <table className="table table-striped table-bordered">
            <thead className="tw-h-16">
              <tr>
                {thTableMultiRaterSurvey.map((th, i) => {
                  return <HeadTable key={i} label={th} />;
                })}
                <th
                  colSpan={2}
                  style={{ textAlign: "center", verticalAlign: "middle" }}
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              { Array.isArray(multiRatersList) && multiRatersList.length != 0 ?
              multiRatersList.map((dt, i) => {
                const role = roleInSurvey(dt.rater_role)
                return (
                  <tr
                    key={i}
                    style={{ textAlign: "center", verticalAlign: "middle" }}
                  >
                    <td>{dt.participant_full_name}</td>
                    <td>{dt.participant_email != undefined ? dt.participant_email.toLowerCase() : dt.participant_email}</td>
                    <td>{dt.survey_name}</td>
                    <td>{role}
                    </td>
                    <td>{ dt.status == "Done" || dt.status == "done"? "Done" : "Not Started" }</td>
                    <td style={{ textAlign: "center" }}>
                      { dt.status == "Done" || dt.status == "done"? (
                        <FontAwesomeIcon icon={faCheck} />
                      ) : (
                        <ButtonLink
                          label={"Give Feedback"}
                          onClick={() => navigateToGiveFeedback(
                            dt.s_raters_id,
                            dt.s_participant_id,
                            dt.survey_code,
                            dt.participant_nik,
                            dt.participant_full_name,
                            dt.participant_email,
                            dt.survey_name,
                            dt.rater_role,
                            rater.nik,
                            rater.email,
                            rater.business_unit_name,
                            rater.department_name
                          )
                          }
                        />
                      )}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {(dt.rater_role == CONSTANTS.ROLE_IN_SURVEY_ID.SUPERVISOR_ID || dt.rater_role == "Supervisor" || dt.rater_role == "supervisor" ) && (dt.participant_availability == 'nomination' || dt.participant_availability == 'Nomination' )? (
                        <ButtonLink
                          label={"See Raters"}
                          onClick={()=>navigateToAddSeeRaters(
                            dt.survey_code,
                            dt.s_participant_id,
                            dt.participant_nik,
                            dt.participant_email,
                            dt.participant_full_name,
                            rater.email,
                          )}
                        />
                      ): <div>-</div> }
                    </td>
                  </tr>
                );
              })
            : <tr>
              <td colSpan={6} style={{ textAlign: "center", verticalAlign: "middle" }}>No data</td>
            </tr>}
            </tbody>
          </table>
        </div>

        <Pagination showing={Array.isArray(multiRatersList) ? multiRatersList.length : 0} totalItem={totalItem} showItem={limit} previousPage={previousPage} nextPage={nextPage} paginate={paginate} limit={pageNumber}/>


        {/* </div> */}
      </div>
      <Footer
        disableNext={true}
        onClickPrev={() =>
          navigate(
            `${CONSTANTS.ROUTER.PARTICIPANT_DASHBOARD}/${CONSTANTS.ROUTER.PARTICIPANT_INTRODUCTION}`
          )
        }
      />
    </div>
  ) : (
    <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
    <div className="card" style={{ padding: "3%" }}>
      {/* <div className="card-body"> */}
      <div className="pb-4 justify-content-between d-flex flex-row">
        <div className="col-4 d-flex flex-row align-items-center">
          <div>Show</div>

          <ShowEntries onClick_item={handleshowItem} default_item={limit} />
          <div className="">entries</div>
        </div>
      </div>

      <div className="pb-1 col-4 d-flex align-items-center">
        <div className="d-inline float-end">
          <div className="tooltips">
             <button
              className="me-2 btn btn-light "
              onClick={()=>onGetAllMultiRatersList(limit,pageNumber,searchText)}
            >
              <FontAwesomeIcon icon={faArrowsRotate} />
            </button>
            <span className="tooltiptext">Reload</span>
          </div>
        </div>
         
        
        
        </div> 
      <div className="pb-4">
        <SearchColumn onChange_input={handleInputSearchChange}  onClick_btn={handleSearchData}/>
        </div>

      <div className="table-responsive table-bordered mb-2">
        <table className="table table-striped table-bordered">
          <thead className="tw-h-16">
            <tr>
              {thTableMultiRaterSurvey.map((th, i) => {
                return <HeadTable key={i} label={th} />;
              })}
              <th
                colSpan={2}
                style={{ textAlign: "center", verticalAlign: "middle" }}
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            { Array.isArray(multiRatersList) && multiRatersList.length != 0 ?
            multiRatersList.map((dt, i) => {
              const role = roleInSurvey(dt.rater_role)
              return (
                <tr
                  key={i}
                  style={{ textAlign: "center", verticalAlign: "middle" }}
                >
                  <td>{dt.participant_full_name}</td>
                  <td>{dt.participant_email != undefined ? dt.participant_email.toLowerCase() : dt.participant_email}</td>
                  <td>{dt.survey_name}</td>
                  <td>{role}
                  </td>
                  <td>{ dt.status == "Done" || dt.status == "done"? "Done" : "Not Started" }</td>
                  <td style={{ textAlign: "center" }}>
                    { dt.status == "Done" || dt.status == "done"? (
                      <FontAwesomeIcon icon={faCheck} />
                    ) : (
                      <ButtonLink
                        label={"Give Feedback"}
                        onClick={() => navigateToGiveFeedback(
                          dt.s_raters_id,
                          dt.s_participant_id,
                          dt.survey_code,
                          dt.participant_nik,
                          dt.participant_full_name,
                          dt.participant_email,
                          dt.survey_name,
                          dt.rater_role,
                          rater.nik,
                          rater.email,
                          dt.business_unit_name,
                          dt.department_name
                        )
                        }
                      />
                    )}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {(dt.rater_role == CONSTANTS.ROLE_IN_SURVEY_ID.SUPERVISOR_ID || dt.rater_role == "Supervisor" || dt.rater_role == "supervisor" ) && (dt.participant_availability == 'nomination' || dt.participant_availability == 'Nomination' )? (
                      <ButtonLink
                        label={"See Raters"}
                        onClick={()=>navigateToAddSeeRaters(
                          dt.survey_code,
                          dt.s_participant_id,
                          dt.participant_nik,
                          dt.participant_email,
                          dt.participant_full_name,
                          rater.email,
                        )}
                      />
                    ): <div>-</div> }
                  </td>
                </tr>
              );
            })
          : <tr>
            <td colSpan={6} style={{ textAlign: "center", verticalAlign: "middle" }}>No data</td>
          </tr>}
          </tbody>
        </table>
      </div>
      {/* </div> */}
    </div>

    <Pagination showing={Array.isArray(multiRatersList) ? multiRatersList.length : 0} totalItem={totalItem} showItem={limit} previousPage={previousPage} nextPage={nextPage} paginate={paginate} limit={pageNumber}/>

    <Footer
      disableNext={true}
      onClickPrev={() =>
        navigate(
          `${CONSTANTS.ROUTER.PARTICIPANT_DASHBOARD}/${CONSTANTS.ROUTER.PARTICIPANT_INTRODUCTION}`
        )
      }
    />
  </div> 
  );
};

export function HeadTable({ label }) {
  return (
    <th style={{ textAlign: "center", verticalAlign: "middle" }}>{label}</th>
  );
}
