import { ButtonGroup, Dropdown } from "react-bootstrap";
import '../button/Button.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function DropdownWithLabel({ id,handleOpenDropdown, label, default_item, style, disabled, label_width, input_width, dropdown_item, search_bar, innerRef, helpText, required = false }) {
    return (
        <div>
            <div className="tw-flex tw-w-full tw-items-center tw-justify-center" style={style}>
                <label className={`${label_width ? label_width : 'tw-w-1/5'} tw-font-medium tw-text-gray-800 pe-4`} htmlFor={id} style={label_width ? { width: label_width } : {}}>{label}{required && <span className="text-danger"> *</span>}</label>

                <Dropdown id={id} className={`${input_width ? input_width : 'tw-w-4/5'}`} ref={innerRef} onClick={handleOpenDropdown}>
                    <Dropdown.Toggle className={`tw-rounded-lg tw-p-1 tw-shadow-sm tw-border tw-border-gray-300 
                           focus:tw-outline-none focus:tw-border-violet-500 focus:tw-ring-1 focus:tw-ring-violet-500`} id="dropdown-basic"
                        style={{ width: '100%', backgroundColor: `${disabled ? 'rgba(0, 0, 0, 0.50)' : 'white'}`, color: 'black', textAlign: 'left', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                        disabled={disabled ? disabled : false}
                    >
                        {`${default_item ? default_item : `Select ${label}`} `}
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                        style={{
                            width: '100%',
                            color: 'black',
                            border: '0.1rem solid rgb(139 92 246)',
                            boxShadow: '0 0.1rem 0.1rem 0  rgb(139 92 246)',
                            backgroundColor: `${disabled ? 'rgba(0, 0, 0, 0.50)' : 'white'}`,
                            maxHeight: '200px',
                            overflow: 'auto'
                        }}
                    >
                        {search_bar}
                        {dropdown_item}
                    </Dropdown.Menu>
                </Dropdown>

            </div>

            {helpText &&
                <div>
                    <label className={`${label_width ? label_width : 'tw-w-1/5'}`} style={label_width ? { width: label_width } : {}}></label>
                    <small className="form-text text-muted">{helpText}</small>
                </div>
            }
        </div>
    )
}


export function ToggleWithLabel({ id, label, scale, checked, handleChange, style, label_width, required = false }) {
    return (
        <div className="tw-flex tw-w-full tw-items-center tw-justify-between" style={style}>
            <label className={`${label_width ? label_width : 'tw-w-1/5'} tw-font-medium tw-text-gray-800`} htmlFor={id} style={label_width ? { width: label_width } : {}}>{label}{required && <span className="text-danger"> *</span>}</label>


            <div className={`form-check form-switch`} style={{ transform: `scale(${scale ? scale : 1.7})` }}  >
                <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id={id}
                    checked={checked}
                    onChange={handleChange}
                />


            </div>

            {/* </div> */}

        </div>
    )
}

export function DropdownOnly({ id, default_item, style, disabled, dropdown_item, search_bar, innerRef ,handleOpenDropdown}) {
    return (

        <Dropdown id={id} style={style} ref={innerRef} onClick={handleOpenDropdown}>
            <Dropdown.Toggle className={`tw-rounded-lg tw-p-1 tw-shadow-sm tw-border tw-border-gray-300 
                           focus:tw-outline-none focus:tw-border-violet-500 focus:tw-ring-1 focus:tw-ring-violet-500`} id="dropdown-basic"
                style={{ width: '100%', backgroundColor: `${disabled ? 'rgba(0, 0, 0, 0.50)' : 'white'}`, color: 'black', textAlign: 'left', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                disabled={disabled ? disabled : false}
            >
                {`${default_item ? default_item : `Select`} `}
            </Dropdown.Toggle>

            <Dropdown.Menu
                style={{
                    width: '100%',
                    color: 'black',
                    border: '0.1rem solid rgb(139 92 246)',
                    boxShadow: '0 0.1rem 0.1rem 0  rgb(139 92 246)',
                    backgroundColor: `${disabled ? 'rgba(0, 0, 0, 0.50)' : 'white'}`,
                    maxHeight: '200px',
                    overflow: 'auto'
                }}
            >
                {search_bar}
                {dropdown_item}
            </Dropdown.Menu>
        </Dropdown>


    )
}



export function DropdownIcon({ icon_default, size_icon, style_dropdown_toggle, style, disabled, dropdown_item }) {
    return (

        <Dropdown style={style} >
            <Dropdown.Toggle id="dropdown-basic"
                disabled={disabled ? disabled : false}
                className="icon-btn-dropdown color-primary-dropdown"
                style={style_dropdown_toggle}

            >

                <div className="d-flex justify-content-center">
                    <FontAwesomeIcon icon={icon_default} size='xl' />
                </div>

            </Dropdown.Toggle>

            <Dropdown.Menu
                style={{

                    color: 'black',
                    border: '0.1rem solid rgb(139 92 246)',
                    boxShadow: '0 0.1rem 0.1rem 0  rgb(139 92 246)',
                    backgroundColor: `${disabled ? 'rgba(0, 0, 0, 0.50)' : 'white'}`
                }}
            >
                {dropdown_item}
            </Dropdown.Menu>
        </Dropdown>


    )
}

export function DropdownButtonOnly({ title, style, disabled, dropdown_item, label }) {
    return (
        <Dropdown>
            <Dropdown.Toggle split id="dropdown-split-basic"
                className={` tw-p-1 tw-shadow-sm tw-border
                            bg-primary btn-new-style gap-3`}
                style={{ width: '100%', backgroundColor: `${disabled ? 'rgba(0, 0, 0, 0.50)' : 'white'}`, color: 'white', textAlign: 'left', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                disabled={disabled ? disabled : false}
            >
                <span className="">{label}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu
                style={{
                    width: '100%',
                    color: 'black',
                    border: '0.1rem solid rgb(139 92 246)',
                    boxShadow: '0 0.1rem 0.1rem 0  rgb(139 92 246)',
                    backgroundColor: `${disabled ? 'rgba(0, 0, 0, 0.50)' : 'white'}`
                }}
            >
                {dropdown_item}
            </Dropdown.Menu>
        </Dropdown>


    )
}
