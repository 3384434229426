import { useEffect, useState } from "react";
import { useDeps } from "../../../shared/context/DependencyContext";
import { dataListRater } from "../../question_bank/component/listrater_constants";
import { useSelector } from "react-redux";

export const useListRater = () => {
  const { participantService } = useDeps();
  const [raterList, setRaterList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalItem, setTotalItem] = useState(0);
  const [limit, setLimit] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [filterRelationship, setFilterRelationship] = useState("");
  const [filterNIK, setFilterNIK] = useState("");
  const [filterName, setFilterName] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const [filterPosition, setFilterPosition] = useState("");
  const [relationshipData, setRelationshipData] = useState([]);
  const [nikData, setnikData] = useState([]);
  const [nameData, setNameData] = useState([]);
  const [emailData, setEmailData] = useState([]);
  const [positionData, setPositionData] = useState([]);
  const dataParticipant = useSelector((state) => state.data_see_raters);
  

  useEffect(() => {
    if (pageNumber == 1) {
      onGetAllRaterList(
        limit,
        pageNumber,
        dataParticipant.tp_id
      );
    }
    setPageNumber(1);
  }, [limit]);

  useEffect(() => {
    onGetAllRaterList(
      limit,
        pageNumber,
        dataParticipant.tp_id
    );
  }, [pageNumber]);

  const handleInputSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleSearchData = () => {
    if (pageNumber == 1) {
      onGetAllRaterList(
        limit,
        pageNumber,
        dataParticipant.tp_id
      );
    } else {
      setPageNumber(1);
    }
  };

  const onGetAllRaterList = async (
    limit,
    page_number,
    target_participant_id,
  ) => {
    try {
      const response =
        await participantService.listRaterService.getAllRaterList(
          limit,
          page_number,
          target_participant_id
        );

      if (response.status == "SUCCESS") {
        setRaterList(response.data.data);
        setTotalItem(response.data.total_item)
      } else {
        console.log(response.status);
      }
    } catch (error) {
      console.log(error);
    } finally {
      // console.log("finally");
    }
  };

  return {
    raterList,
    limit,
    totalItem,
    pageNumber,
    setPageNumber,
  };
};
