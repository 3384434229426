export const InstructionService = ({ doPost, doGet }) => {
  const getInstructionText = async () => {
    try {
      return await doPost({
        url: "/admin/get_intro_id",
        data: {
          introId: "1",
        },
      });
    } catch (e) {
      throw e;
    }
  };


  const getInstructionImage = async (image_path) => {
    try {
      return await doGet({
        url: "/admin/get_intro_image/" + image_path,
      });
    } catch (e) {
      throw e;
    }
  };

  const postInstruction = async (teks) => {
    try {
      return await doPost({
        url: "/admin/create_intro",
        data: {
          intro_text: teks,
        },
      });
    } catch (e) {
      throw e;
    }
  };

  const updateInstruction = async (teks) => {
    try {
      return await doPost({
        url: "/admin/update_intro",
        data: {
          intro_text: teks,
        },
      });
    } catch (e) {
      throw e;
    }
  };

  /* TULIS API GET INSTRUCTION UTK PARTICIPANT DIBAWAH INI */
  const getInstructionTextParticipantUrl = async () => {
    try {
      return await doPost({
        url: "/participant/get_intro_id",
        data: {
          introId: "1",
        },
      });
    } catch (e) {
      throw e;
    }
  };

  const updateUserInfo = async (data) => {
    try {
         return await doPost({
             url: '/participant/update_user_info',
             data: data,
         });
    } catch (error) {
       throw error;
    }
 };
  
  return {
    getInstructionText,
    getInstructionImage,
    postInstruction,
    updateInstruction,
    getInstructionTextParticipantUrl,
    updateUserInfo
   
  };
};
