import AuthService from './AuthService'
import { AdminListService } from './AdminListService'
import { ConfigDataService } from './ConfigDataService'
import { PICListService } from './PICListService'
import { MultiRaterSurveyFormService } from './MultiRaterSurveyFormService'
import { QuestionHistoryService } from './QuestionHistoryService'
import { RatersHistoryService } from './RatersHistoryService'
import { FaqService } from './FaqService'
import { TargetParticipantHistoryService } from './TargetParticipantHistoryService'
import { MultiRaterService } from './MultiRaterService'
import { AddRatersService } from "./participant_service/AddRatersService";
import { InstructionService } from './InstructionService'
import { ContactUsService } from './ContactUsService'
import { SurveyHistoryService } from './SurveyHistory'
import { GeneralDataService } from './GeneralDataService'
import { MultiRatersListService } from './participant_service/MultiRaterListService'
import { ListRaterService } from './participant_service/ListRaterService'
import { SingleRaterService } from './SingleRaterService'
import { UserMasterService } from './UserMasterService'
import { SingleRaterListService } from './participant_service/SingleRaterListService'
import { SingleRaterSurveyFormService } from './SingleRaterSurveyFormService'
import { PICDashboardService } from './PICDashboardService'
import { ReportTemplateService } from './ReportTemplateService'
import { CONSTANTS } from '../common/constants'
import { LoginByLinkService } from './participant_service/LoginByLinkService'
import { SharingSurveyService } from './SharingSurveyService'
import { DashboardListService } from './DashboardListService'

export const ServiceFactory = (apiClient) => {
  return {

    generalService: {
      authService: AuthService(apiClient),
      generalDataService: GeneralDataService(apiClient)
    },

    adminService: {
      adminListService: AdminListService(apiClient), //admin list page
      configDataService: ConfigDataService(apiClient), //config data page
      picListService: PICListService(apiClient), // pic list page
      instructionService: InstructionService(apiClient), // setting instruction & read instruction
      contactUsService: ContactUsService(apiClient), // setting contact us & read contact us
      faqService: FaqService(apiClient), //setting faq & read faq
      surveyHistoryService: SurveyHistoryService(apiClient), // survey history page

      questionHistoryService: QuestionHistoryService(apiClient), //question bank or question history page
      ratersHistoryService: RatersHistoryService(apiClient), // raters history page
      targetParticipantHistoryService: TargetParticipantHistoryService(apiClient), //target participant history page
      multiRaterService: MultiRaterService(apiClient), // CRUD multirater and multirater list
      singleRaterService: SingleRaterService(apiClient), // CRUD singlerater and single rater list
      userMasterService: UserMasterService(apiClient), // user master service
      dashboardListService:DashboardListService(apiClient), //dashboard service

      reportTemplateService: ReportTemplateService(apiClient, CONSTANTS.ROLE.ADMIN),
    },

    picService: {
      configDataService: ConfigDataService(apiClient), //config data page
      questionHistoryService: QuestionHistoryService(apiClient), //question bank or question history page
      ratersHistoryService: RatersHistoryService(apiClient), // raters history page
      targetParticipantHistoryService: TargetParticipantHistoryService(apiClient), //target participant history page
      multiRaterService: MultiRaterService(apiClient), // CRUD multirater and multirater list
      singleRaterService: SingleRaterService(apiClient), // CRUD singlerater and single rater list
      userMasterService: UserMasterService(apiClient), // user master service
      picDashboardService : PICDashboardService(apiClient), // my project list for PIC
      sharingSurveyService : SharingSurveyService(apiClient), // to sharing survey feature, admin available too

      reportTemplateService: ReportTemplateService(apiClient),
    },

    participantService: {
      multiRaterSurveyFormService: MultiRaterSurveyFormService(apiClient), // multirater survey from participant side
      multiRaterListService: MultiRatersListService(apiClient),
      listRaterService: ListRaterService(apiClient),
      addRatersService: AddRatersService(apiClient), // view and add raters in a survey for a target participant
      instructionService: InstructionService(apiClient), // setting instruction & read instruction (participant read only)
      contactUsService: ContactUsService(apiClient), // setting contact us & read contact us (participant read only)
      faqService: FaqService(apiClient), //setting faq & read faq (participant read only)
      singleRaterListService : SingleRaterListService(apiClient),
      singleRaterSurveyFormService : SingleRaterSurveyFormService(apiClient),
      loginByLinkService : LoginByLinkService(apiClient), // auto login & user info
    },

  }
}


