import { useEffect, useState } from "react";
import useAuthomaticLoginByLink from "../../login/useAutomaticLoginByLink";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import screenAlert from "../../../shared/hook/SweetAlert";
import { rootAction } from "../../../redux/RootAction";
import { CONSTANTS } from "../../../common/constants";
import { ButtonOutlinePrimary } from "../../../shared/component/button/Button";
import { WelcomeMultiRaterView } from "../multirater_survey_form/components/WelcomeMultiRaterView";
import FinishFormView from "../multirater_survey_form/components/FinishFormView";
import SurveyContentView from "../multirater_survey_form/components/SurveyContentView";
import { clearParticipantAccountData } from "../../../common/utils";

function MRSurveyFormLoginByLink(params) {
    const [sec, setSec] = useState(SURVEY_PART.WELCOME);
    const [sectionPageIndex,setSectionPageIndex] = useState(0);
    const user = useSelector((state)=>state.user);
    const dispatch = useDispatch();
    const dataParticipantRaterMRForm = useSelector((state)=>state.data_participant_rater_MR_form);
    const manyAnswerReducer = useSelector((state) => state.insertManyAnswer);
    const mandatoryContentList = useSelector((state) => state.mandatoryContentList);
    const navigate = useNavigate();
    const showAlert = screenAlert();
    const surveyDetailData = useSelector((state)=>state.survey_MR_detail_data);


    useEffect(()=> {
    },[surveyDetailData])


    const checkMandatoryQuestion =  () => {
        for (let i = 0; i < manyAnswerReducer.length; i++) {
          for (let j = 0; j < mandatoryContentList.length; j++) {
            if ((manyAnswerReducer[i].content_type == 'scale' || manyAnswerReducer[i].content_type == 'single_choice' || manyAnswerReducer[i].content_type == 'multiple_choice') && manyAnswerReducer[i].section_code == mandatoryContentList[j].section_code && manyAnswerReducer[i].content_code == mandatoryContentList[j].content_code) {
                dispatch(rootAction.surveyForm.deleteMandatoryContent(
                    {
                        section_code: mandatoryContentList[j].section_code,
                        content_code: mandatoryContentList[j].content_code,
                    }
                   
                ))
            } 

            if ( manyAnswerReducer[i].content_type == 'text_input'  
            && manyAnswerReducer[i].section_code == mandatoryContentList[j].section_code 
            && manyAnswerReducer[i].content_code == mandatoryContentList[j].content_code && manyAnswerReducer[i].answer != "" ) {
                dispatch(rootAction.surveyForm.deleteMandatoryContent( {
                    section_code: mandatoryContentList[j].section_code,
                    content_code: mandatoryContentList[j].content_code,
                }))
            } 
            if ( manyAnswerReducer[i].content_type == 'essay'  
                && manyAnswerReducer[i].section_code == mandatoryContentList[j].section_code 
                && manyAnswerReducer[i].content_code == mandatoryContentList[j].content_code && manyAnswerReducer[i].answer.length >= 100) {
                    dispatch(rootAction.surveyForm.deleteMandatoryContent( {
                        section_code: mandatoryContentList[j].section_code,
                        content_code: mandatoryContentList[j].content_code,
                    }))
                }  
            if ( manyAnswerReducer[i].content_type == 'ranking' && `${manyAnswerReducer[i].content_code}-${manyAnswerReducer[i].choice_code}` == mandatoryContentList[j].content_code) {
                
                dispatch(rootAction.surveyForm.deleteMandatoryContent(
                    {section_code: mandatoryContentList[j].section_code,
                    content_code: mandatoryContentList[j].content_code}
                ))
            }  
          }
    }
}
   
    const handleNextPage = () => {
        if (sectionPageIndex < surveyDetailData.section.length - 1) {
            setSectionPageIndex(sectionPageIndex+1);
        } else if ((sectionPageIndex + 1) == surveyDetailData.section.length) {
        checkMandatoryQuestion();
           setSec(SURVEY_PART.FINISH)
        }
    }

    const handlePrevPage = () => {
        if (sectionPageIndex > 0) {
            setSectionPageIndex(sectionPageIndex-1);
        }else if (sectionPageIndex == 0) {
            setSec(SURVEY_PART.WELCOME)
        }
    }

    const handleFromWelcomeToQuestion = () => {
        setSec(SURVEY_PART.QUESTION);
        setSectionPageIndex(0);
    }

    const handleFinishtoQuestion = () => {
        setSec(SURVEY_PART.QUESTION);
        setSectionPageIndex(surveyDetailData.section.length - 1);
    }

    const handleSurveyDetailData = (field,payload) => {
                 dispatch(rootAction.surveyForm.setSurveyField({
                    field: field,
                    payload: payload
                }));
    }
    
    useEffect(()=>{
      
       if (surveyDetailData != undefined) {
        handleSurveyDetailData(CONSTANTS.SURVEY_FORM_REDUX.SURVEY_CODE,surveyDetailData.survey_code);
        handleSurveyDetailData(CONSTANTS.SURVEY_FORM_REDUX.SURVEY_NAME, surveyDetailData.setting.survey_name); 
    }
    
    },[surveyDetailData])

    useEffect(()=>{
      
        if (surveyDetailData != undefined && mandatoryContentList.length == 0 ) {
         handleAddMandatoryQuestionList()
        }
     
     },[surveyDetailData])

    useEffect(()=>{
        if (dataParticipantRaterMRForm.rater_email != null && dataParticipantRaterMRForm.rater_email != undefined && surveyDetailData != undefined) {
                handleSurveyDetailData(CONSTANTS.SURVEY_FORM_REDUX.PARTICIPANT_EMAIL, dataParticipantRaterMRForm.rater_email);
                handleSurveyDetailData(CONSTANTS.SURVEY_FORM_REDUX.PARTICIPANT_NIK, dataParticipantRaterMRForm.rater_nik);
                handleSurveyDetailData(CONSTANTS.SURVEY_FORM_REDUX.PARTICIPANT_DEPT, dataParticipantRaterMRForm.rater_dept);
                handleSurveyDetailData(CONSTANTS.SURVEY_FORM_REDUX.PARTICIPANT_BU, dataParticipantRaterMRForm.rater_bu);
        }
    },[dataParticipantRaterMRForm,surveyDetailData]);

    useEffect(()=>{
        if (dataParticipantRaterMRForm.target_email != null && dataParticipantRaterMRForm.target_email != undefined){
            handleSurveyDetailData(CONSTANTS.SURVEY_FORM_REDUX.TARGET_NIK, dataParticipantRaterMRForm.target_nik);
            handleSurveyDetailData(CONSTANTS.SURVEY_FORM_REDUX.TARGET_EMAIL, dataParticipantRaterMRForm.target_email);
            handleSurveyDetailData(CONSTANTS.SURVEY_FORM_REDUX.PARTICIPANT_ID,dataParticipantRaterMRForm.s_raters_id);
            handleSurveyDetailData(CONSTANTS.SURVEY_FORM_REDUX.TARGET_ID,dataParticipantRaterMRForm.s_participant_id);
        }
    },[dataParticipantRaterMRForm]);

    const backToSurveyList = () => {
        showAlert.warning({
            title: "Warning",
            text: "Your response will be deleted, are you sure you want to exit this page?",
            buttons: {
                cancel: {
                    text: "Cancel",
                    className: "bg-white text-dark"
                },
                confirm: {
                    text: "Yes",
                    value: true
                }
            },
            isDanger: true
        }).then(value => {
            if (value) {
                dispatch(rootAction.surveyForm.clearDataParticipantRaterMRForm())
                dispatch(rootAction.surveyForm.deleteAllManyAnswer());
                dispatch(rootAction.surveyForm.deleteAllMAndatoryContent());
                
                navigate(CONSTANTS.ROUTER.LOGIN_AS_PARTICIPANT, { replace: true });

            }
        });
    }

    const handleAddMandatoryQuestionList = () => {
        for (let i = 0; i < surveyDetailData.section.length; i++) {
            for (let j = 0; j < surveyDetailData.section[i].content.length; j++) {
                if (surveyDetailData.section[i].content[j].is_mandatory == 1 && surveyDetailData.section[i].content[j].content_type != "ranking") {
                
                dispatch(rootAction.surveyForm.addContentMandatory(
                    {
                        section_code: surveyDetailData.section[i].section_code,
                        content_code: surveyDetailData.section[i].content[j].content_code
                    }
                ))
                } else  if (surveyDetailData.section[i].content[j].is_mandatory == 1 && surveyDetailData.section[i].content[j].content_type == "ranking") {
                    for (let k = 0; k < surveyDetailData.section[i].content[j].content_choice.length; k++) {
                        dispatch(rootAction.surveyForm.addContentMandatory(
                            {
                                section_code: surveyDetailData.section[i].section_code,
                                content_code: `${surveyDetailData.section[i].content[j].content_code}-${surveyDetailData.section[i].content[j].content_choice[k].choice_code}`
                            }
                        ))
                        
                    }
                    
                }
            }
        }
      }

        const handleLogout = async () => {
            clearParticipantAccountData(dispatch);
            dispatch(rootAction.user.userLogoutAction());
            navigate(CONSTANTS.ROUTER.LOGIN_AS_PARTICIPANT, { replace: true });
        }

    if (surveyDetailData == null || surveyDetailData == undefined) {
        return(
            <div>
                <div className="mb-4">No Data</div>
            <ButtonOutlinePrimary label="Sign Out" onClick={() => handleLogout()}/>
            </div>
        )
    }else {
        return (
            <div  className="container tw-h-max tw-max-w-[1400px]" >
                 <div style={{margin:0}} >
                            <div>Survey Name : {surveyDetailData.setting.survey_name}</div>
                            <div style={{fontWeight:'bold'}}>Feedback for target participant : {dataParticipantRaterMRForm.target_full_name} </div>
                 </div>
            {
            surveyDetailData.section.map((ch,i)=>{
                return(
                    <div key={i}>
                             {sec == SURVEY_PART.WELCOME && i == sectionPageIndex ?
                    <WelcomeMultiRaterView onClickPrev={()=>backToSurveyList()} preview={false} onClickNext={()=>handleFromWelcomeToQuestion()} pic={user.role == CONSTANTS.ROLE.ADMIN ? '' : surveyDetailData.created_by} welcome_text={surveyDetailData.setting.welcome_text}/> : 
                    sec == SURVEY_PART.FINISH && i==sectionPageIndex ? 
                        <FinishFormView finish_text={surveyDetailData.setting.finish_text} is_auto_login={true}  onClickPrev={()=>handleFinishtoQuestion()} /> : 
                         i==sectionPageIndex ?
                        <SurveyContentView surveyDetailData={surveyDetailData} sectionPageIndex={sectionPageIndex} handleNextPage={()=>handleNextPage()} handlePrevPage={()=>handlePrevPage()}/> : <></>
               }
                    </div>
                )
            })
            }
                
            </div>
        )
    }
   
}


export default MRSurveyFormLoginByLink

const SURVEY_PART = {
    WELCOME : 'welcome',
    QUESTION :'question',
    FINISH : 'finish'
}

