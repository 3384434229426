import { useRef, useState } from 'react';
import { useTransform, initPosistion } from './dropdown/useDropdown';
import './styles/dropdown.css';

const isHiddenMenu = (id) => {
    // 1.8 Comments Custom
    // 1.10 Demography
    // 1.12.*.4 Summary Table
    return ['1.8.2', '1.10.5', '1.12.1.4', '1.12.2.4', '1.12.3.4', '1.12.4.4'].includes(
        id
    );
};

const SectionControlItem = ({ id, sections, onAddSection, children }) => {
    const [display, setDisplay] = useState(false);
    const [position, setPosition] = useState(initPosistion);
    const itemRef = useRef();
    const { handleTransform } = useTransform();

    const handleClick = () => {
        setPosition(handleTransform(itemRef, 95));
        setDisplay(!display);
        onAddSection(id, sections);
    };

    const handleMouseLeave = () => setDisplay(false);

    return (
        <>
            <a
                ref={itemRef}
                className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                onClick={handleClick}
                style={{ cursor: 'pointer' }}
            >
                <small>{children}</small>
            </a>
            {Array.isArray(sections) && sections.length >= 1 && (
                <ul
                    className={`dropdown-menu ${display ? 'd-block' : 'd-none'}`}
                    onMouseLeave={handleMouseLeave}
                    style={{ transform: `translate(${position.x}, ${position.y}px)` }}
                >
                    {sections.map(({ id, text, sections, showDialog }) => (
                        <>
                            {!isHiddenMenu(id) && (
                                <SubMenu
                                    key={id}
                                    id={id}
                                    text={text}
                                    items={sections}
                                    shown={display}
                                    onAddSection={onAddSection}
                                    showDialog={showDialog}
                                />
                            )}
                        </>
                    ))}
                </ul>
            )}
        </>
    );
};

const SubMenu = ({
    id,
    text,
    items,
    onAddSection,
    shown = false,
    showDialog = false
}) => {
    const [display, setDisplay] = useState(false);
    const [position, setPosition] = useState(initPosistion);
    const itemRef = useRef();
    const { handleTransform } = useTransform();

    const handleClick = () => {
        setPosition(handleTransform(itemRef, 40));
        setDisplay(!display);
        onAddSection(id, items, showDialog);
    };

    const handleMouseLeave = () => setDisplay(false);

    return (
        <li className={`${shown ? 'd-block' : 'd-none'}`} style={{ height: '32px' }}>
            <a
                className="dropdown-item"
                ref={itemRef}
                onClick={handleClick}
                style={{ cursor: 'pointer' }}
            >
                <small>
                    {text} {/* {showDialog ? '-dialog' : ''} */}
                </small>
            </a>
            {Array.isArray(items) && items.length && (
                <ul
                    className={`submenu dropdown-menu ${display ? 'd-block' : 'd-none'}`}
                    onMouseLeave={handleMouseLeave}
                    style={{ transform: `translate(${position.x}, ${position.y}px)` }}
                >
                    {items.map((item) => (
                        <>
                            {!isHiddenMenu(item.id) && (
                                <SubMenu
                                    key={item.id}
                                    id={item.id}
                                    text={item.text}
                                    items={item.sections}
                                    shown={display}
                                    onAddSection={onAddSection}
                                    showDialog={item.showDialog}
                                />
                            )}
                        </>
                    ))}
                </ul>
            )}
        </li>
    );
};

export default SectionControlItem;
