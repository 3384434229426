let store;

export const injectStore = (_store) => {
    store = _store
}

export const authHeaderInterceptor = (config) => {
    // Sesuaikan dengan PATH login
    if (config.url !== '/auth/request_otp' || config.url !== '/auth/verify_otp') {
        config.headers.Authorization = `Bearer ${store.getState().user.token}`;
    }
    return config;
}