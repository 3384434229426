import ReportTemplateType from './ReportTemplateType';

//
// Define Action Constant
//
const {
    SET_REPORT_TYPE,
    DISCARD_TEMPLATE,
    UPDATE_TEMPLATE,
    CHANGE_CODE,
    CHANGE_NAME,
    CHANGE_LANGUAGE,
    UPDATE_SECTIONS,
    RESET_TEMPLATE,
    SET_URL_PREV,
    SET_URL_DATA,
    RESET_URL_DATA,
    SET_URLPREVIEW_DATA,
    RESET_URLPREVIEW_DATA
} = ReportTemplateType;

const resetReportTemplate = () => ({
    type: RESET_TEMPLATE
});
const setReportType = (reqData) => ({
    type: SET_REPORT_TYPE,
    data: reqData
});
const discardTemplate = () => ({
    type: DISCARD_TEMPLATE
});
const updateTemplate = (reqData) => ({
    type: UPDATE_TEMPLATE,
    data: reqData
});
const changeCode = (reqData) => ({
    type: CHANGE_CODE,
    data: reqData
});
const changeName = (reqData) => ({
    type: CHANGE_NAME,
    data: reqData
});
const changeLanguage = (reqData) => ({
    type: CHANGE_LANGUAGE,
    data: reqData
});
const updateSections = (reqData) => ({
    type: UPDATE_SECTIONS,
    data: reqData
});
const setUrlPrev = (reqData) => ({
    type: SET_URL_PREV,
    data: reqData
});
const setUrlData = (reqData) => ({
    type: SET_URL_DATA,
    data: reqData
});
const resetUrlData = () => ({
    type: RESET_URL_DATA
});
const setUrlPreviewData = (reqData) => ({
    type: SET_URLPREVIEW_DATA,
    data: reqData
});
const resetUrlPreviewData = () => ({
    type: RESET_URLPREVIEW_DATA
});

export default {
    discardTemplate,
    resetReportTemplate,
    updateTemplate,
    setReportType,
    changeCode,
    changeName,
    changeLanguage,
    updateSections,
    setUrlPrev,
    setUrlData,
    resetUrlData,
    setUrlPreviewData,
    resetUrlPreviewData
};
