import { Dropdown } from "react-bootstrap";
import { DropdownWithLabel } from "../../../../../shared/component/dropdown/DropdownInput";
import { InformationWithLabel } from "../../../../../shared/component/input/Input";
import { IconButtonDelete, IconButtonSave } from "../../../../../shared/component/button/IconButton";

function TabReviewUpload({tab,dataUpload}) {
    const dropdownfilter = ['All','Correct Data','Incorrect Data'];

    return(
        <div>
            {tab == 3 ? 
             <div className="">
             <DropdownWithLabel label={'Filter Data'}
             default_item={'All'}
             dropdown_item={
                 dropdownfilter.map((drop,i)=>{
                     return(
                         <Dropdown.Item key={i}>{drop}</Dropdown.Item>
                     )
                 })
             }
             />
         
            </div> :
            <div></div>
            }
           
            {
                                dataUpload.map((dt,i)=>{
                                    return(
                                        <div className="mb-4 mt-2 border rounded shadow" key={i}>
                                            {tab == 1 || tab == 2 ?
                                                (
                                                <div className="d-flex justify-content-between px-4 py-1 rounded-top" style={{backgroundColor:'#ADB5BD'}}>
                                                    <div  className="d-flex">
                                                        <div className="border rounded-circle bg-white d-flex justify-content-center align-items-center fw-bold me-2" style={{width:'2.5rem',height:'2.5rem',fontSize:'1.2rem'}}>{i+1}</div>
                                                    </div>
                                                    {tab == 1 ?
                                                    <div className="d-flex">  
                                                        <div className="border rounded-circle bg-white d-flex justify-content-center align-items-center" style={{width:'2.5rem',height:'2.5rem'}} ><IconButtonSave /></div>
                                                    </div>
                                                    :
                                                    <div className="d-flex">
                                                        <div className="border rounded-circle bg-white d-flex justify-content-center align-items-center me-2" style={{width:'2.5rem',height:'2.5rem'}}><IconButtonDelete/></div>
                                                        <div className="border rounded-circle bg-white d-flex justify-content-center align-items-center" style={{width:'2.5rem',height:'2.5rem'}} ><IconButtonSave /></div>
                                                    </div>
                                                    }
                                                    
                                                </div>
                                                ):
                                                (
                                                    (dt.name != '' && dt.name != null) && (dt.email != '' && dt.email != null)  && (dt.number != '' && dt.number != null)  ? 
                                                    <div className="d-flex justify-content-between px-4 py-1 rounded-top" style={{backgroundColor:'#ADB5BD'}}>
                                                        <div  className="d-flex">
                                                            <div className="border rounded-circle bg-white d-flex justify-content-center align-items-center fw-bold me-2" style={{width:'2.5rem',height:'2.5rem',fontSize:'1.2rem'}}>{i+1}</div>
                                                            <div className="d-flex justify-content-center align-items-center" style={{fontWeight:'bold',color:'#0A58CA'}}>
                                                                Complete Data
                                                            </div>
                                                        </div>
                                                        <div className="d-flex">
                                                            {/* <div className="border rounded-circle bg-white d-flex justify-content-center align-items-center me-2" style={{width:'2.5rem',height:'2.5rem'}}><IconButtonDelete/></div> */}
                                                            <div className="border rounded-circle bg-white d-flex justify-content-center align-items-center" style={{width:'2.5rem',height:'2.5rem'}} ><IconButtonSave /></div>
                                                        </div>
                                                    </div>
                                                    : 
                                                    <div className="d-flex justify-content-between px-4 py-1 rounded-top" style={{backgroundColor:'#FFC107'}}>
                                                        <div  className="d-flex">
                                                            <div className="border rounded-circle bg-white d-flex justify-content-center align-items-center fw-bold me-2" style={{width:'2.5rem',height:'2.5rem',fontSize:'1.2rem'}}>{i+1}</div>
                                                            <div className="d-flex justify-content-center align-items-center" style={{fontWeight:'bold'}}>
                                                                Incomplete Data
                                                            </div>
                                                        </div>
                                                        <div className="d-flex">
                                                            <div className="border rounded-circle bg-white d-flex justify-content-center align-items-center me-2" style={{width:'2.5rem',height:'2.5rem'}}><IconButtonDelete/></div>
                                                            <div className="border rounded-circle bg-white d-flex justify-content-center align-items-center" style={{width:'2.5rem',height:'2.5rem'}} ><IconButtonSave disabled={true}/></div>
                                                        </div>
                                                    </div>
                                                    
                                                )
                                            }
                                            
                                        
                                            <div className="col-12 py-2 px-3 rounded-bottom">
                                                <div className="my-2 fw-bold">
                                                        Target Participant Identity
                                                </div>
                                                <div className="d-flex justify-content-around">
                                                    <div className="col-5 ">
                                                        <div className="">
                                                            <InformationWithLabel label={'Name'} disabled={true} value={dt.name}  />
                                                        </div>
                                                        <div >
                                                            <InformationWithLabel label={'NIK'} disabled={true} value={dt.nik}  />
                                                        </div>
                                                        <div className="">
                                                            <InformationWithLabel label={'Number'} value={dt.number} disabled={true}  />
                                                        </div>
                                                        <div >
                                                            <InformationWithLabel label={'Email'} value={dt.email}  />
                                                        </div>
                                                    </div>
                                                    <div className="col-5 ">
                                                        <div >
                                                            <InformationWithLabel label={'Position'} disabled={true} value={dt.position}  />
                                                        </div>
                                                        <div >
                                                            <InformationWithLabel label={'Level'} disabled={true} value={dt.level} />
                                                        </div>
                                                        <div >
                                                            <InformationWithLabel label={'Department'} disabled={true} value={dt.department} />
                                                        </div>
                                                        <div >
                                                            <InformationWithLabel label={`Business Unit / \n Company`} disabled={true} value={dt.bu} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
        </div>
    )
}

export default TabReviewUpload;