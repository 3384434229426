import { USER_LOGIN, USER_LOGOUT, USER_REFRESH_TOKEN } from "./UserType";

// define action constant
const userLoginAction = (reqData) => ({
   type: USER_LOGIN,
   data: reqData
});

const userLogoutAction = _ => ({
   type: USER_LOGOUT
});

const userRefreshTokenAction = (reqData) => ({
   type: USER_REFRESH_TOKEN,
   data: reqData
});

export default {
   userLoginAction,
   userLogoutAction,
   userRefreshTokenAction
}