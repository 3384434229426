import { ChevronLast, ChevronFirst } from "lucide-react";
import { useState, useRef } from "react";
import Techconnect from '../../../assets/images/TC-horizontal.png';
import trendyLogo from "../../../assets/images/Trendy-horizontal-color.png";
import { Navbar } from "../../../features/layout/navbar/Navbar";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { rootAction } from "../../../redux/RootAction";

/**
 * This part of code is made using Tailwind.css
 */

export function Sidebar({ children }) {
   // state
   const preference = useSelector((state) => state.preference);
   const dispatch = useDispatch();

   const handleExpandSidebar = () => {
      dispatch(rootAction.preference.setSidebarAction({
         field: "expanded_sidebar",
         payload: !preference.expanded_sidebar
      }))
   }

   return (
      <>
         <Navbar isExpanded={preference.expanded_sidebar} />
         <aside className="tw-h-screen tw-z-50 tw-fixed 2xl:tw-relative">
            <nav className={`tw-h-full tw-flex tw-flex-col tw-bg-white tw-border-r 
                           tw-shadow-sm`}>
               <div className="tw-p-4 tw-pb-2 tw-flex tw-justify-between tw-items-center">
                  <img
                     src={trendyLogo}
                     className={`tw-overflow-hidden tw-transition-all ${preference.expanded_sidebar ? "tw-w-40" : "tw-w-0"
                        }`}
                     alt=""
                  />
                  <button
                     onClick={handleExpandSidebar}
                     className="tw-p-2 tw-rounded-lg hover:tw-bg-gray-200"
                  >
                     {preference.expanded_sidebar ? <ChevronFirst /> : <ChevronLast />}
                  </button>
               </div>

               <ul className="tw-flex-1 tw-flex tw-flex-col tw-px-3 tw-overflow-y-auto tw-mb-0">{children}</ul>

               <div className={`tw-border-t tw-flex tw-p-3 ${preference.expanded_sidebar ? "" : "tw-justify-center"}`}>
                  <div
                     className={`
                     tw-flex tw-justify-between tw-items-center
                     tw-overflow-hidden tw-transition-all ${preference.expanded_sidebar ? "tw-ml-3" : ""}`}
                  >
                     {preference.expanded_sidebar ?
                        <div className="tw-leading-4">
                           <span
                              className={`tw-overflow-hidden tw-transition-all tw-text-xs tw-text-gray-600 ${preference.expanded_sidebar ? "tw-w-full" : "tw-w-0 tw-hidden"
                                 }`}
                           >
                              Copyright © 2023 Techconnect
                           </span>
                        </div> :
                        <div className="tw-leading-4">
                           <span
                              className={`tw-overflow-hidden tw-transition-all tw-text-xs tw-text-gray-600 ${preference.expanded_sidebar ? "tw-w-0 tw-hidden" : "tw-w-full"
                                 }`}
                           >
                              © 2023
                           </span>
                        </div>}
                  </div>
               </div>
            </nav>
         </aside>
      </>
   )
}

export function SidebarItem({ icon, text, path, last, on_click }) {
   // context
   const preference = useSelector((state) => state.preference);

   // state
   const [visible, setVisible] = useState(false);
   const [top, setTop] = useState(0);

   // ref
   const ref = useRef(null);
   const onHover = (e) => {
      var rect = ref.current.getBoundingClientRect()
      setTop(rect.top);
      setVisible(true);
   }

   return (
      <NavLink className={`tw-no-underline ${last ? 'tw-mt-auto tw-mb-1' : ''}`} to={path} onClick={on_click}>
         {({ isActive, isPending, isTransitioning }) => (
            <li
               className={`
            tw-relative tw-flex tw-items-center tw-py-2 tw-px-3 tw-mt-1
            tw-font-medium tw-rounded-md tw-cursor-pointer
            tw-transition-colors tw-group hover:tw-text-violet-500
         ${isActive
                     ? "tw-bg-gradient-to-tr tw-from-indigo-200 tw-to-indigo-100 tw-text-indigo-800"
                     : "hover:tw-bg-indigo-50 tw-text-gray-600"
                  }
            ${preference.expanded_sidebar ? "" : "tw-justify-center tw-h-12"}
         `}
               ref={ref} onMouseEnter={onHover} onMouseLeave={() => setVisible((visible) => false)}
            >
               {icon}
               <span
                  className={`tw-overflow-hidden tw-transition-all tw-text-base ${preference.expanded_sidebar ? "tw-w-48 tw-ml-3" : "tw-w-0 tw-hidden"
                     }`}
               >
                  {text}
               </span>

               {!preference.expanded_sidebar && visible && (
                  <div
                     className={`
                  tw-fixed tw-left-20 tw-rounded-md tw-px-2 tw-py-2 tw-ml-4 tw-z-50
                  tw-bg-indigo-100 tw-text-indigo-800 tw-text-sm
                  tw-invisible tw-opacity-20 tw--translate-x-3
            group-hover:tw-visible group-hover:tw-opacity-100 group-hover:tw-translate-x-0 w-max
            `}
                     style={{ top: visible ? top : 0 }}
                  >
                     {text}
                  </div>
               )}
            </li>
         )}
      </NavLink>
   )
}