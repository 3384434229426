import { useEffect, useState } from "react";
import { useDeps } from "../../../../shared/context/DependencyContext";
import { useSelector } from "react-redux";
import screenToast from "../../../../shared/hook/ScreenToast";
import useViewState from "../../../../shared/hook/UseViewState";
import { CONSTANTS } from "../../../../common/constants";

function useTabMonitoring() {
    const {adminService, picService} = useDeps();
    const [allTP, setAllTP] = useState([]);
    const [showItem, setShowItem] = useState(50);
    const [searchText, setSearchText] = useState('');
    const [totalItem, setTotalItem] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const user = useSelector((state) => state.user);
    const showToast = screenToast();
    const { viewState, setError, setSuccess } = useViewState();
    const [progress, setProgress] = useState(0);

    useEffect(() => {
      if (viewState.isError == true) {
          showToast.error(viewState.errorMsg);
          setError(false, '');
      }

      if (viewState.isSuccess == true) {
          showToast.success(viewState.successMsg);
          setSuccess(false, '');
      }
    }, [viewState]);


    const onGetAllTP = async (survey_code) => {
      setProgress(10);
        try {
          setProgress(30);
            if (user.role == CONSTANTS.ROLE.ADMIN) {
              const response = await adminService.singleRaterService.getDetailSurveySingleraterPICUrl(survey_code);
            setProgress(60);
              
              if (response.status == 'SUCCESS') {
                setAllTP(response.data.participant);
                setTotalItem(response.data.participant.length);
                // console.log("response",response);
              }
            } else {
              const response = await picService.singleRaterService.getDetailSurveySingleraterPICUrl(survey_code);
            setProgress(60);
              
              if (response.status == 'SUCCESS') {
                setAllTP(response.data.participant);
                setTotalItem(response.data.participant.length);
              }
            }

        } catch (error) {
            console.log(error)
        } finally {
            // console.log('finally ')
          setProgress(100);

        }
    }

  const handleshowItem = (item) => {
      setShowItem(item);
  }

  const handleInputSearchChange = (e) => {
      setSearchText(e.target.value);
  }

  const handleSearchData = () => {
      if (pageNumber == 1) {
      } else {
          setPageNumber(1);
      }
  }

    return{
        allTP,
        pageNumber,
        totalItem,
        setPageNumber,
        setShowItem,
        showItem,
        setTotalItem,
        searchText,
        setSearchText,
        handleInputSearchChange,
        handleSearchData,
        handleshowItem,
        onGetAllTP,
        progress,
        setProgress
       
    }
    
}
export default useTabMonitoring;