import React from 'react';


const ParticipantTables = ({ singleRaterSurveys = [], multiRaterSurveys = [] }) => {
  const isEmptySingleRaterSurveys = !Array.isArray(singleRaterSurveys) || singleRaterSurveys.length === 0;
  const isEmptyMultiRaterSurveys = !Array.isArray(multiRaterSurveys)  || multiRaterSurveys.length === 0;
  // console.log("dari Survey Tables",multiRaterSurveys)
  return (
    <div className="survey-tables">
      <div className="survey-table">
        <h2>Top 10 Single Rater Survey by Participant</h2>
        {isEmptySingleRaterSurveys ? (
        <div style={{ textAlign: 'center', marginTop: 50 }}>
          <p>Data tidak tersedia.</p>
        </div>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Nama survey</th>
              <th>Created by dept</th>
              <th>Created by bu</th>
              <th>Jumlah participant</th>
            </tr>
          </thead>
          <tbody>
            {singleRaterSurveys.map((survey, index) => (
              <tr key={index}>
                <td>{survey.nama_survey}</td>
                <td>{survey.created_by_dept}</td>
                <td>{survey.created_by_bu}</td>
                <td>{survey.jumlah_participant}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      </div>
      <div className="survey-table">
        <h2>Top 10 Multi Rater Survey by Participant</h2>
        {isEmptyMultiRaterSurveys ? (
        <div style={{ textAlign: 'center', marginTop: 50 }}>
          <p>Data tidak tersedia.</p>
        </div>
      ) : (
        <table className='tw-self-center'>
          <thead>
            <tr>
              <th>Nama survey</th>
              <th>Created by dept</th>
              <th>Created by bu</th>
              <th>Jumlah participant</th>
            </tr>
          </thead>
          <tbody>
            {multiRaterSurveys.map((survey, index) => (
              <tr key={index}>
              <td>{survey.nama_survey}</td>
                <td>{survey.created_by_dept}</td>
                <td>{survey.created_by_bu}</td>
                <td>{survey.jumlah_participant}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      </div>
    </div>
  );
};

export default ParticipantTables;