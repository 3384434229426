import { Outlet, Route, Routes } from "react-router-dom"
import SelectUserLogin from "../features/login/SelectUserLogin"
import { CONSTANTS } from "../common/constants";
import AdminListView from "../features/admin_list/AdminListView";
import SupportView from "../features/support/SupportView";
import ConfigDataView from "../features/config_data/ConfigDataView";
import PicListView from "../features/pic_list/PicListView";
// import SurveyHistoryView from "../features/survey_history/SurveyHistoryView";
import LoginView from "../features/login/LoginView";
import QuestionBankView from "../features/question_bank/QuestionBankView";
import RatersHistoryView from "../features/raters_history/RatersHistoryView";
import { NotFound } from "../features/errors/NotFound";
import LoadingBar from "react-top-loading-bar";
import { useState } from "react";
import AddMultiRater from "../features/cms_multirater/add_multirater/AddMultiraterView";
import TargetParticipantHistoryView from "../features/target_participant/target_participant_history/TargetParticipantHistoryView";
import MultiraterListView from "../features/cms_multirater/multirater_list/MultiraterListView";
import SelectHistoryTPView from "../features/target_participant/add_target_participant/tp_from_history/SelectHistoryTPView";
import AddTPFromSAPView from "../features/target_participant/add_target_participant/tp_from_SAP/AddTPFromSAPView";
import ReviewDataUploadTPView from "../features/target_participant/add_target_participant/tp_upload_file/ReviewDataUpload";
import UpdateMultiraterView from "../features/cms_multirater/edit_multirater/UpdateMultiraterView";
// import SelectQuestionHistoryView from "../features/cms_multirater/tab/tab_content/question/select_question_history/SelectQuestionHistory";
import ParticipantFAQView from "../features/participant_features/participant_faq/FAQView";
import ContactUsView from "../features/participant_features/participant_contact_us/ContactUsView";
import { PicIsLogged } from "../shared/auth/PicAuth";
import { ParticipantIsLogged } from "../shared/auth/ParticipantAuth";
import { AdminIsLogged } from "../shared/auth/AdminAuth";
import AdminNav from "../features/layout/sidebar/AdminNav";
import ParticipantNav from "../features/layout/sidebar/ParticipantNav";
import { MultiraterSurveyFormList } from "../features/participant_features/multirater_list/MultiraterSurveyListView";
import MultiSurveyFormView from "../features/participant_features/multirater_survey_form/MultiSurveyFormView";
import AddRatersView from "../features/participant_features/participant_add_raters/AddRatersView";
import EditRatersForParticipant from "../features/cms_multirater/tab/tab_participant/tab_participant_components/EditRatersForParticipant";
import IntroductionView from "../features/support/introduction/IntroductionView";
import { IntroductionViewParticipant } from "../features/participant_features/participant_introduction/IntroductionView";
import PreviewMultirater from "../features/cms_multirater/preview_multirater/PreviewMultirater";
import AuthRedirect from "../features/login/AuthRedirect";
import AddTPFromSAPViewUpdate from "../features/target_participant/add_target_participant/tp_from_SAP/AddTPFromSAPViewUpdate";
import EditRatersForParticipantUpdate from "../features/cms_multirater/tab/tab_participant/tab_participant_components/EditRatersForParticipantUpdate";
import { ListRaterView } from "../features/participant_features/participant_list_rater/ListRaterView";
import AddSingleRater from "../features/cms_singlerater/add_singlerater/AddSingleRaterView";
import SingleRaterList from "../features/cms_singlerater/singlerater_list/SingleRaterList";
import QuestionHistory from "../features/question_bank/select_question_history/QuestionHistory";
import QuestionHistoryUpdate from "../features/question_bank/select_question_history/QuestionHistoryUpdate";
import PreviewSinglerater from "../features/cms_singlerater/preview_singlerater/PreviewSR";
import UpdateSingleraterView from "../features/cms_singlerater/edit_singlerater/UpdateSingleraterView";
import { SingleraterSurveyFormList } from "../features/participant_features/participant_single_rater/SingleraterSurveyListView";
import SingleSurveyFormView from "../features/participant_features/participant_single_rater/singlerater_surveyform/SingleSurveyFormView";
import EditParticipant from "../features/cms_singlerater/tab_singlerater/participant/components/EditParticipant";
import EditParticipantUpdate from "../features/cms_singlerater/tab_singlerater/participant/components/EditParticipantUpdate";
import PICDashboard from "../features/dashboard/PICDashboard";
import ReportTemplateListView from "../features/report_template/ReportTemplateListView";
import TemplateSingleRaterForm from "../features/report_template/single_rater/TemplateSingleRaterForm";
import TemplateMultiRaterForm from '../features/report_template/multi_rater_group/TemplateMultiRaterForm';
import TemplateMultiRaterIndividuForm from "../features/report_template/multi_rater_individu/TemplateMultiRaterIndividuForm";
import { ACTIONS } from "../features/report_template/constants";
import TemplatePreviewForm from "../features/report_template/TemplatePreviewForm";
import SelectHistoryViewTPUpdate from "../features/target_participant/add_target_participant/tp_from_history/SelectHistoryTPViewUpdate";
import LoginSurveyByLink from "../features/login/AutomaticLoginByLink";
import SRSurveyFormLoginByLink from "../features/participant_features/survey_login_by_link/SRSurveyFormLoginByLink";
import MRSurveyFormLoginByLink from "../features/participant_features/survey_login_by_link/MRSurveyFormLoginByLink";
import AdminDashboard from "../features/dashboard/AdminDashboard";
import ReportTemplatePreview from "../features/report_template/preview/ReportTemplatePreview";


export const AppRouter = () => {
    const [progress, setProgress] = useState(0);

    return (
        <>
            <LoadingBar color="#7749F8" progress={progress} onLoaderFinished={() => setProgress(0)} />
            <Routes>
                <Route path={'/to-survey/:survey_code/:raters_id/:participant_id/:token'} element={<LoginSurveyByLink />} />
                <Route path={CONSTANTS.ROUTER.LOGIN} element={<SelectUserLogin />} />
                <Route path={CONSTANTS.ROUTER.LOGIN_AS_ADMIN} element={<LoginView route={CONSTANTS.USER.ADMIN} />} />
                <Route path={CONSTANTS.ROUTER.LOGIN_AS_PIC} element={<LoginView route={CONSTANTS.USER.PIC} />} />
                <Route path={CONSTANTS.ROUTER.LOGIN_AS_PARTICIPANT} element={<LoginView route={CONSTANTS.USER.PARTICIPANT} />} />
                <Route path={'/'} element={<AuthRedirect />} />

                {/* Require Participant Role */}
                <Route element={<ParticipantIsLogged />}>
                    <Route path={CONSTANTS.ROUTER.PARTICIPANT_DASHBOARD} element={<ParticipantNav />}>
                        <Route path={CONSTANTS.ROUTER.PARTICIPANT_INTRODUCTION} element={<IntroductionViewParticipant />} />
                        <Route path={CONSTANTS.ROUTER.PARTICIPANT_MULTIRATER_LIST} element={<MultiraterSurveyFormList />} />
                        <Route path={CONSTANTS.ROUTER.PARTICIPANT_SINGLERATER_LIST} element={<SingleraterSurveyFormList />} />
                        <Route path={CONSTANTS.ROUTER.PARTICIPANT_FAQ} element={<ParticipantFAQView />}></Route>
                        <Route path={CONSTANTS.ROUTER.PARTICIPANT_CONTACT_US} element={<ContactUsView />}></Route>
                        <Route path={CONSTANTS.ROUTER.PARTICIPANT_MULTIRATER_SURVEY_FORM} element={<MultiSurveyFormView />}></Route>
                        <Route path={CONSTANTS.ROUTER.PARTICIPANT_ADD_RATERS} element={<AddRatersView />}></Route>
                        <Route path={CONSTANTS.ROUTER.PARTICIPANT_LIST_RATERS} element={<ListRaterView />}></Route>
                        <Route path={CONSTANTS.ROUTER.PARTICIPANT_SINGLERATER_SURVEY_FORM} element={<SingleSurveyFormView />}></Route>
                        <Route path={CONSTANTS.ROUTER.PARTICIPANT_SINGLERATER_SURVEY_FORM_AUTO} element={<SRSurveyFormLoginByLink />}></Route>
                        <Route path={CONSTANTS.ROUTER.PARTICIPANT_MULTIRATER_SURVEY_FORM_AUTO} element={<MRSurveyFormLoginByLink />}></Route>
                        
                        
                        <Route path={'*'} element={<NotFound />} />

                    </Route>

                </Route>

                

                {/* Require PIC Role */}
                <Route element={<PicIsLogged />}>
                    <Route path={CONSTANTS.ROUTER.PIC_CMS} element={<AdminNav />}>
                        <Route path={CONSTANTS.ROUTER.DASHBOARD} element={<><Outlet /></>}>
                            <Route path="" element={<PICDashboard />} />
                            <Route path={CONSTANTS.ROUTER.UPDATE_MULTIRATER} element={<><Outlet /></>}>
                                <Route path="" element={<UpdateMultiraterView />} />
                                <Route path={CONSTANTS.ROUTER.PREVIEW_MULTIRATER} element={<PreviewMultirater />} />
                                <Route path={CONSTANTS.ROUTER.TP_FROM_SAP} element={<AddTPFromSAPViewUpdate />} />
                                <Route path={CONSTANTS.ROUTER.EDIT_RATERS_FOR_PARTICIPANT} element={<EditRatersForParticipantUpdate />} />
                                <Route path={CONSTANTS.ROUTER.SELECT_QUESTION_HISTORY} element={<QuestionHistoryUpdate />} />
                                <Route path={CONSTANTS.ROUTER.TP_FROM_HISTORY} element={<SelectHistoryViewTPUpdate />} />
                            </Route>
                            <Route path={CONSTANTS.ROUTER.UPDATE_SINGLERATER} element={<><Outlet /></>}>
                                <Route path="" element={<UpdateSingleraterView />} />
                                <Route path={CONSTANTS.ROUTER.PREVIEW_SINGLERATER} element={<PreviewSinglerater />} />
                                <Route path={CONSTANTS.ROUTER.EDIT_PARTICIPANT_SINGLE} element={<EditParticipantUpdate />} />
                                <Route path={CONSTANTS.ROUTER.SELECT_QUESTION_HISTORY} element={<QuestionHistoryUpdate />} />
                                <Route path={CONSTANTS.ROUTER.TP_FROM_SAP} element={<AddTPFromSAPViewUpdate />} />
                                <Route path={CONSTANTS.ROUTER.TP_FROM_HISTORY} element={<SelectHistoryViewTPUpdate />} />
                            </Route>
                        </Route>
                        <Route path={CONSTANTS.ROUTER.TARGET_PARTICIPANT_DATA} element={<TargetParticipantHistoryView />} />
                        <Route path={CONSTANTS.ROUTER.QUESTION_BANK} element={<QuestionBankView />} />

                        <Route path={CONSTANTS.ROUTER.ADD_SINGLE_RATER_SURVEY} element={<><Outlet /></>}>
                            <Route path="" element={<AddSingleRater />} />
                            <Route path={CONSTANTS.ROUTER.TP_FROM_SAP} element={<AddTPFromSAPView />} />
                            <Route path={CONSTANTS.ROUTER.EDIT_PARTICIPANT_SINGLE} element={<EditParticipant />} />
                            <Route path={CONSTANTS.ROUTER.SELECT_QUESTION_HISTORY} element={<QuestionHistory />} />
                            <Route path={CONSTANTS.ROUTER.PREVIEW_SINGLERATER} element={<PreviewSinglerater />} />
                            <Route path={CONSTANTS.ROUTER.TP_FROM_HISTORY} element={<SelectHistoryTPView />} />
                          
                        </Route>

                        <Route path={CONSTANTS.ROUTER.SINGLE_RATER_SURVEY_LIST} element={<><Outlet /></>}>
                            <Route path="" element={<SingleRaterList />} />
                            <Route path={CONSTANTS.ROUTER.UPDATE_SINGLERATER} element={<><Outlet /></>}>
                                <Route path="" element={<UpdateSingleraterView />} />
                                <Route path={CONSTANTS.ROUTER.PREVIEW_SINGLERATER} element={<PreviewSinglerater />} />
                                <Route path={CONSTANTS.ROUTER.EDIT_PARTICIPANT_SINGLE} element={<EditParticipantUpdate />} />
                                <Route path={CONSTANTS.ROUTER.SELECT_QUESTION_HISTORY} element={<QuestionHistoryUpdate />} />
                                <Route path={CONSTANTS.ROUTER.TP_FROM_SAP} element={<AddTPFromSAPViewUpdate />} />
                                <Route path={CONSTANTS.ROUTER.TP_FROM_HISTORY} element={<SelectHistoryViewTPUpdate />} />
                            </Route>
                        </Route>

                        <Route path={CONSTANTS.ROUTER.ADD_MULTI_RATER_SURVEY} element={<><Outlet /></>}>
                            <Route path="" element={<AddMultiRater />} />
                            <Route path={CONSTANTS.ROUTER.PREVIEW_MULTIRATER} element={<PreviewMultirater />} />
                            <Route path={CONSTANTS.ROUTER.SELECT_QUESTION_HISTORY} element={<QuestionHistory />} />
                            <Route path={CONSTANTS.ROUTER.TP_FROM_SAP} element={<AddTPFromSAPView />} />
                            <Route path={CONSTANTS.ROUTER.TP_FROM_HISTORY} element={<SelectHistoryTPView />} />
                            <Route path={CONSTANTS.ROUTER.REVIEW_DATA_UPLOAD} element={<ReviewDataUploadTPView />} />
                            <Route path={CONSTANTS.ROUTER.EDIT_RATERS_FOR_PARTICIPANT} element={<EditRatersForParticipant />} />
                        </Route>

                        <Route path={CONSTANTS.ROUTER.MULTI_RATER_SURVEY_LIST} element={<><Outlet /></>}>
                            <Route path="" element={<MultiraterListView />} />
                            <Route path={CONSTANTS.ROUTER.UPDATE_MULTIRATER} element={<><Outlet /></>}>
                                <Route path="" element={<UpdateMultiraterView />} />
                                <Route path={CONSTANTS.ROUTER.PREVIEW_MULTIRATER} element={<PreviewMultirater />} />
                                <Route path={CONSTANTS.ROUTER.TP_FROM_SAP} element={<AddTPFromSAPViewUpdate />} />
                                <Route path={CONSTANTS.ROUTER.EDIT_RATERS_FOR_PARTICIPANT} element={<EditRatersForParticipantUpdate />} />
                                <Route path={CONSTANTS.ROUTER.SELECT_QUESTION_HISTORY} element={<QuestionHistoryUpdate />} />
                                <Route path={CONSTANTS.ROUTER.TP_FROM_HISTORY} element={<SelectHistoryViewTPUpdate />} />
                            </Route>
                        </Route>

                        <Route path={CONSTANTS.ROUTER.REPORT_TEMPLATE} element={<><Outlet /></>}>
                            <Route path={CONSTANTS.ROUTER.ADD_REPORT_SINGLE} element={<TemplateSingleRaterForm action={ACTIONS.CREATE} role={CONSTANTS.ROLE.PIC} />} />
                            <Route path={CONSTANTS.ROUTER.ADD_REPORT_MULTI_GROUP} element={<TemplateMultiRaterForm action={ACTIONS.CREATE} role={CONSTANTS.ROLE.PIC}  />} />
                            <Route path={CONSTANTS.ROUTER.ADD_REPORT_MULTI} element={<TemplateMultiRaterIndividuForm action={ACTIONS.CREATE} role={CONSTANTS.ROLE.PIC} />} />
                            <Route path={CONSTANTS.ROUTER.EDIT_REPORT_SINGLE} element={<TemplateSingleRaterForm action={ACTIONS.UPDATE} role={CONSTANTS.ROLE.PIC} />} />
                            <Route path={CONSTANTS.ROUTER.EDIT_REPORT_MULTI_GROUP} element={<TemplateMultiRaterForm action={ACTIONS.UPDATE} role={CONSTANTS.ROLE.PIC} />} />
                            <Route path={CONSTANTS.ROUTER.EDIT_REPORT_MULTI} element={<TemplateMultiRaterIndividuForm action={ACTIONS.UPDATE} role={CONSTANTS.ROLE.PIC} />} />
                            <Route path={CONSTANTS.ROUTER.PREVIEW_TEMPLATE} element={<TemplatePreviewForm />} />
                            <Route path={CONSTANTS.ROUTER.REPORT_PREVIEW_TEMPLATE} element={<ReportTemplatePreview />} />
                        </Route>

                        <Route path={CONSTANTS.ROUTER.RATERS_HISTORY} element={<RatersHistoryView />} />
                        <Route path={'*'} element={<NotFound />} />
                    </Route>
                </Route>

                {/* Require Admin Role */}
                <Route element={<AdminIsLogged />}>
                    <Route path={CONSTANTS.ROUTER.ADMIN_CMS} element={<AdminNav />}>
                        <Route path={CONSTANTS.ROUTER.DASHBOARD} element={<AdminDashboard />} />
                        <Route path={CONSTANTS.ROUTER.TARGET_PARTICIPANT_DATA} element={<TargetParticipantHistoryView />} />
                        <Route path={CONSTANTS.ROUTER.QUESTION_BANK} element={<QuestionBankView />} />

                        <Route path={CONSTANTS.ROUTER.ADD_MULTI_RATER_SURVEY} element={<><Outlet /></>}>
                            <Route path="" element={<AddMultiRater />} />
                            <Route path={CONSTANTS.ROUTER.PREVIEW_MULTIRATER} element={<PreviewMultirater />} />
                            <Route path={CONSTANTS.ROUTER.SELECT_QUESTION_HISTORY} element={<QuestionHistory />} />
                            <Route path={CONSTANTS.ROUTER.TP_FROM_SAP} element={<AddTPFromSAPView />} />
                            <Route path={CONSTANTS.ROUTER.TP_FROM_HISTORY} element={<SelectHistoryTPView />} />
                            <Route path={CONSTANTS.ROUTER.REVIEW_DATA_UPLOAD} element={<ReviewDataUploadTPView />} />
                            <Route path={CONSTANTS.ROUTER.EDIT_RATERS_FOR_PARTICIPANT} element={<EditRatersForParticipant />} />

                        </Route>

                        <Route path={CONSTANTS.ROUTER.ADD_SINGLE_RATER_SURVEY} element={<><Outlet /></>}>
                            <Route path="" element={<AddSingleRater />} />
                            <Route path={CONSTANTS.ROUTER.TP_FROM_SAP} element={<AddTPFromSAPView />} />
                            <Route path={CONSTANTS.ROUTER.EDIT_PARTICIPANT_SINGLE} element={<EditParticipant />} />
                            <Route path={CONSTANTS.ROUTER.SELECT_QUESTION_HISTORY} element={<QuestionHistory />} />
                            <Route path={CONSTANTS.ROUTER.PREVIEW_SINGLERATER} element={<PreviewSinglerater />} />
                            <Route path={CONSTANTS.ROUTER.TP_FROM_HISTORY} element={<SelectHistoryTPView />} />
                        </Route>

                        <Route path={CONSTANTS.ROUTER.SINGLE_RATER_SURVEY_LIST} element={<><Outlet /></>}>
                            <Route path="" element={<SingleRaterList />} />
                            <Route path={CONSTANTS.ROUTER.UPDATE_SINGLERATER} element={<><Outlet /></>}>
                                <Route path="" element={<UpdateSingleraterView />} />
                                <Route path={CONSTANTS.ROUTER.PREVIEW_SINGLERATER} element={<PreviewSinglerater />} />
                                <Route path={CONSTANTS.ROUTER.TP_FROM_SAP} element={<AddTPFromSAPViewUpdate />} />
                                <Route path={CONSTANTS.ROUTER.EDIT_PARTICIPANT_SINGLE} element={<EditParticipantUpdate />} />
                                <Route path={CONSTANTS.ROUTER.SELECT_QUESTION_HISTORY} element={<QuestionHistoryUpdate />} />
                                <Route path={CONSTANTS.ROUTER.TP_FROM_HISTORY} element={<SelectHistoryViewTPUpdate />} />
                            </Route>
                        </Route>


                        <Route path={CONSTANTS.ROUTER.MULTI_RATER_SURVEY_LIST} element={<><Outlet /></>}>
                            <Route path="" element={<MultiraterListView />} />
                            <Route path={CONSTANTS.ROUTER.UPDATE_MULTIRATER} element={<><Outlet /></>}>
                                <Route path="" element={<UpdateMultiraterView />} />
                                <Route path={CONSTANTS.ROUTER.PREVIEW_MULTIRATER} element={<PreviewMultirater />} />
                                <Route path={CONSTANTS.ROUTER.TP_FROM_SAP} element={<AddTPFromSAPViewUpdate />} />
                                <Route path={CONSTANTS.ROUTER.EDIT_RATERS_FOR_PARTICIPANT} element={<EditRatersForParticipantUpdate />} />
                                <Route path={CONSTANTS.ROUTER.SELECT_QUESTION_HISTORY} element={<QuestionHistoryUpdate />} />
                                <Route path={CONSTANTS.ROUTER.TP_FROM_HISTORY} element={<SelectHistoryViewTPUpdate />} />

                            </Route>
                        </Route>

                        <Route path={CONSTANTS.ROUTER.RATERS_HISTORY} element={<RatersHistoryView />} />
                        <Route path={CONSTANTS.ROUTER.ADMIN_SUPPORT} element={<SupportView />} />
                        <Route path={CONSTANTS.ROUTER.CONFIG_DATA} element={<ConfigDataView />} />
                        <Route path={CONSTANTS.ROUTER.ADMIN_LIST} element={<AdminListView />} />
                        <Route path={CONSTANTS.ROUTER.PIC_LIST} element={<PicListView />} />
                        {/* <Route path={CONSTANTS.ROUTER.SURVEY_HISTORY} element={<SurveyHistoryView />} /> */}
                        <Route path={CONSTANTS.ROUTER.REPORT_TEMPLATE} element={<><Outlet /></>}>
                            <Route path="" element={<ReportTemplateListView />} />
                            <Route path={CONSTANTS.ROUTER.ADD_REPORT_SINGLE} element={<TemplateSingleRaterForm action={ACTIONS.CREATE} role={CONSTANTS.ROLE.ADMIN} />} />
                            <Route path={CONSTANTS.ROUTER.ADD_REPORT_MULTI_GROUP} element={<TemplateMultiRaterForm action={ACTIONS.CREATE} role={CONSTANTS.ROLE.ADMIN} />} />
                            <Route path={CONSTANTS.ROUTER.ADD_REPORT_MULTI} element={<TemplateMultiRaterIndividuForm action={ACTIONS.CREATE} role={CONSTANTS.ROLE.ADMIN} />} />
                            <Route path={CONSTANTS.ROUTER.EDIT_REPORT_SINGLE} element={<TemplateSingleRaterForm action={ACTIONS.UPDATE} role={CONSTANTS.ROLE.ADMIN} />} />
                            <Route path={CONSTANTS.ROUTER.EDIT_REPORT_MULTI_GROUP} element={<TemplateMultiRaterForm action={ACTIONS.UPDATE} role={CONSTANTS.ROLE.ADMIN} />} />
                            <Route path={CONSTANTS.ROUTER.EDIT_REPORT_MULTI} element={<TemplateMultiRaterIndividuForm action={ACTIONS.UPDATE} role={CONSTANTS.ROLE.ADMIN} />} />
                            <Route path={CONSTANTS.ROUTER.PREVIEW_TEMPLATE} element={<TemplatePreviewForm />} />
                            <Route path={CONSTANTS.ROUTER.REPORT_PREVIEW_TEMPLATE} element={<ReportTemplatePreview />} />
                        </Route>

                        <Route path={'*'} element={<NotFound />} />
                    </Route>
                </Route>

                <Route path={'*'} element={<NotFound />} />

            </Routes>
        </>
    )
}
