import { useEffect, useState } from "react";
import { useDeps } from "../../shared/context/DependencyContext";
import { dataQuestion } from "./component/question_constants";
import { useSelector } from "react-redux";
import { CONSTANTS } from "../../common/constants";

function useQuestionBank() {
    const {adminService,picService} = useDeps();
    const [allQuestionHistory, setAllQuestionHistory] = useState([]);
    const [showItem, setShowItem] = useState(10);
    const [searchText, setSearchText] = useState('');
    const [totalItem, setTotalItem] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [progress, setProgress] = useState(0);
    const user = useSelector((state) => state.user);



    useEffect(() => {
        if (pageNumber == 1) {
            onGetSearchQuestionHistory(searchText,showItem,pageNumber)
            // console.log('when page number 1');
        }else{
            setPageNumber(1);
            // console.log('when page number other');
        }
    }, [showItem])

    useEffect(() => {
        onGetSearchQuestionHistory(searchText,showItem,pageNumber)
        // console.log('when page number changed');
    }, [pageNumber])


    const onGetSearchQuestionHistory = async (search_text,limit,page_number) => {
        setProgress(10);
        try {
            setProgress(20);
            if (user.role == CONSTANTS.ROLE.ADMIN) {
                const response = await adminService.questionHistoryService.getWithSearchQuestionHistory(search_text,limit,page_number);
                setProgress(60);
                if (response.status == 'SUCCESS') {
                setAllQuestionHistory(response.data.data);
                //   console.log(response.data);
                setTotalItem(response.data.total_item)
                }
            } else {
                const response = await picService.questionHistoryService.getWithSearchQuestionHistoryPICUrl(search_text,limit,page_number);
                setProgress(60);
                if (response.status == 'SUCCESS') {
                  setAllQuestionHistory(response.data.data);
                //   console.log(response.data);
                  setTotalItem(response.data.total_item)
                }
            }
            
        } catch (error) {
            console.log(error)
        } finally {
            // console.log('finally onGetSearchQuestionHistory')
            setProgress(100);
        }
    }
    
    const handleshowItem = (item) => {
        setShowItem(item);
    }

    const handleInputSearchChange = (e) => {
        setSearchText(e.target.value);
    }

    const handleSearchData = () => {
        if (pageNumber == 1) {
            onGetSearchQuestionHistory(searchText,showItem, pageNumber)
        } else {
            setPageNumber(1);
        }
    }

    return{
       allQuestionHistory,
       totalItem,
       handleshowItem,
       showItem,
       handleInputSearchChange,
       handleSearchData,
       pageNumber,
       setPageNumber,
       setSearchText,
       progress,
       setProgress
    }
}
export default useQuestionBank;