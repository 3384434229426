import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import EChartsReact from 'echarts-for-react';
import { CONTENTS } from '../../constants';
import { randomTypes, createRandom } from '../../utils/createRandom';
import ColorInfo from './ColorInfo';

const {
    MRG_RADAR_GROUP,
    MRG_RADAR_DIMENSION,
    MRG_RADAR_ASPECT,
    MRI_RADAR_GROUP,
    MRI_RADAR_DIMENSION,
    MRI_RADAR_ASPECT
} = CONTENTS;
const { BASE100 } = randomTypes;

const isValid = (cid, suffix) => {
    return (
        [
            MRG_RADAR_GROUP + suffix,
            MRG_RADAR_DIMENSION + suffix,
            MRG_RADAR_ASPECT + suffix
        ].includes(cid) ||
        [
            MRI_RADAR_GROUP + suffix,
            MRI_RADAR_DIMENSION + suffix,
            MRI_RADAR_ASPECT + suffix
        ].includes(cid)
    );
};

const createColor = (theme) => {
    const themeColors = {
        theme1: ['#ca6510', '#ffc107', '#198754', '#0a58ca', '#0a58ca'],
        theme2: ['#C93232', '#E27228', '#143F8F', '#FC4479', '#DDDD16'],
        theme3: ['#4285F4', '#EC594D', '#FBBE0B', '#84CA97', '#FF6F04'],
        theme4: ['#BF8C7B', '#3E5656', '#E79A3C', '#447874', '#D2D479'],
        theme5: ['#5CB6AA', '#2D729D', '#1F3E78', '#EB5600', '#FF99AC']
    };

    return themeColors[theme];
};

const getLegends = (contentId) => {
    let data = ['Self'];

    if (isValid(contentId, '.1')) {
        data = [...data, 'Others', 'All']; //3
    } else if (isValid(contentId, '.2')) {
        data = [...data, 'Supervisor', 'Subordinate', 'Peer', 'All']; //5
    } else if (isValid(contentId, '.3')) {
        data = [...data, 'Supervisor', 'Sub & Peer', 'All']; //4
    } else if (isValid(contentId, '.4')) {
        data = [...data, 'Supervisor', 'Subordinate', 'Peer']; //4
    }

    return data;
};

const createRadar = (contentId) => {
    const column =
        contentId.startsWith(MRG_RADAR_GROUP) || contentId.startsWith(MRI_RADAR_GROUP)
            ? 'Group'
            : contentId.startsWith(MRG_RADAR_DIMENSION) ||
              contentId.startsWith(MRI_RADAR_DIMENSION)
            ? 'Dimension'
            : contentId.startsWith(MRG_RADAR_ASPECT) ||
              contentId.startsWith(MRI_RADAR_ASPECT)
            ? 'Aspect'
            : null;

    return {
        shape: 'circle',
        indicator: [...Array(7).keys()].map((n) => {
            return {
                name: `${column} ${n + 1}`,
                max: 100
            };
        }),
        textStyle: { fontWeight: 'bold' }
    };
};

const createLegends = (legends = []) => {
    return {
        data: legends,
        orient: 'horizontal',
        bottom: 0,
        textStyle: { color: '#777' }
    };
};

const createData = (legends = []) => {
    const data = legends.map((legend) => {
        return {
            value: [...Array(7).keys()].map((n) => createRandom(BASE100, 9, 19)),
            name: legend
        };
    });

    return [{ type: 'radar', data: data }];
};

const RadarChartData = ({ contentId, defaultTheme, showThemePicker, onChange }) => {
    const [theme, setTheme] = useState(defaultTheme || 'theme1');
    const radar = createRadar(contentId);
    const legends = getLegends(contentId);
    const legendProps = createLegends(legends);
    const data = createData(legends);
    const color = createColor(theme);
    const options = {
        grid: { top: 0 },
        radar: radar,
        color: color,
        legend: legendProps,
        series: data
    };

    const handleChangeTheme = (e) => {
        setTheme(e.target.value);
        if (onChange) onChange(e.target.value);
    };

    return (
        <Row className="mx-2">
            <Col>
                <EChartsReact option={options} style={{ width: '400px' }} />
            </Col>
            {showThemePicker && (
                <ColorInfo
                    id={contentId}
                    title={'Line Color'}
                    colorCount={legends.length}
                    colors={color}
                    value={theme}
                    onChange={handleChangeTheme}
                    showLine={true}
                />
            )}
        </Row>
    );
};

export default RadarChartData;
