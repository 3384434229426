import { faRectangleXmark } from "@fortawesome/free-regular-svg-icons"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { IconButtonGeneral } from "./IconButton"



export const ButtonPrimary = ({ label = '', onClick, disabled, type, style }) => {
    return (
        <button
            className="btn btn-primary btn-new-style"
            onClick={disabled ? () => { } : onClick}
            disabled={disabled}
            type={type}
            style={style}
        >
            {label}
        </button>
    )
}

export const ButtonPreview = ({ label = '', onClick, style, disabled }) => {
    return (
        <button
            className="btn btn-info  text-white font-bold btn-new-style"
            onClick={disabled ? () => { } : onClick}
            style={style}
            disabled={disabled}

        >
            {label}
        </button>
    )
}

export const ButtonOutlinePrimary = ({ label = '', onClick, style, disabled }) => {
    return (
        <button
            className="btn btn-outline-primary font-bold btn-new-style"
            onClick={disabled ? () => { } : onClick}
            style={style}
        >
            {label}
        </button>
    )
}

export const ButtonContent = ({ label = '', onClick, style, disabled }) => {
    return (
        <button
            className="btn btn-danger font-bold"
            onClick={disabled ? () => { } : onClick}
            disabled={disabled}
            style={style}

        >
            {label}
        </button>
    )
}

export const ButtonLink = ({ label = '', onClick, style, disabled }) => {
    return (
        <button
            className="btn btn-link"
            onClick={disabled ? () => { } : onClick}
            disabled={disabled}
            style={style}
        >
            {label}
        </button>
    )
}

export const ButtonDisabled = ({ label = '', onClick, style }) => {
    return (
        <button
            className="btn btn-dark disabled"
            onClick={onClick}
            style={style}
        >
            {label}
        </button>
    )
}

export const ButtonAddPrimary = ({ label = '', onClick, disabled }) => {
    return (
        <ButtonPrimary
            label={
                <div className="d-flex justify-content-center align-items-center">
                    <FontAwesomeIcon icon={faPlus} size="xl" />
                    <div style={{ fontSize: '1 rem', marginLeft: '0.3rem' }}>{label}</div>
                </div>
            }
            onClick={disabled ? () => { } : onClick}

        />
    )
}



export const AddAddedButton = ({ onClickAdd, onClickDelete }) => {
    const [check, setCheck] = useState(false);

    const handleAdd = () => {
        setCheck(!check);
        onClickAdd();
    }

    const handleDelete = () => {
        setCheck(!check);
        onClickDelete();
    }

    return (
        check ?

            <div className="d-flex justify-content-center align-items-center">
                <div style={{ color: '#7749F8' }}>Added</div>
                <IconButtonGeneral color_enabled={'#C82333'} icon={faRectangleXmark} size={'lg'}
                    onClick={() => handleDelete()} />
            </div>

            :

            <ButtonPrimary style={{ backgroundColor: '#7749F8' }} onClick={() => handleAdd()} label="Add" />

    )
}
