import { SearchColumn } from "../table_property/SearchColumn";
import {
  thTableListRater,
} from "../../../features/question_bank/component/listrater_constants";
import { useState } from "react";
import { useListRater } from "../../../features/participant_features/participant_list_rater/useListRater";
import Pagination from "../table_property/Paginatination";
import { ShowEntries } from "../table_property/ShowEntries";

export const CardListRater = () => {
  const {
    raterList,
    limit,
    totalItem,
    pageNumber,
    setPageNumber,
  } = useListRater();

  const paginate = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const previousPage = () => {
    if (pageNumber != 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const nextPage = () => {
    if (pageNumber != Math.ceil(totalItem / limit)) {
      setPageNumber(pageNumber + 1);
    }
  };

  return (
    <div className="container">
      <div className="card" style={{ width: "100%", margin: "0" }}>
        <div className="card-body">
          <div className="pb-4 justify-content-between d-flex flex-row">
            <div className="col-4 d-flex flex-row align-items-center">
              <div>Show</div>

              <ShowEntries/>

              <div className="">entries</div>
            </div>

            {/* <div className="col-3 d-flex align-items-center">
              
              <SearchColumn />
              
            </div> */}
          </div>

          <div className="table-responsive table-bordered mb-2">
            <table className="table table-striped table-bordered">
              <thead className="tw-h-18">
                <tr>
                  {thTableListRater.map((th, i) => {
                    return <HeadTable key={i} label={th} />;
                  })}
                </tr>
              </thead>

              <tbody>
                { Array.isArray(raterList) && raterList.length != 0 ?

                raterList.map((dt, i) => {
                  return (
                    <tr
                      key={i}
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                    >
                      <td>{dt.role_name}</td>
                      <td>{dt.nik}</td>
                      <td>{dt.full_name}</td>
                      <td>{dt.email}</td>
                      <td>{dt.position}</td>
                    </tr>
                  );
                })
              :
              <tr  style={{ textAlign: "center", verticalAlign: "middle" }}><td colSpan={5}>No data</td></tr>
              }
              </tbody>
            </table>
          </div>

          <Pagination
            showing={Array.isArray(raterList) ? raterList.length : 0}
            totalItem={totalItem}
            showItem={limit}
            previousPage={previousPage}
            nextPage={nextPage}
            paginate={paginate}
            limit={pageNumber}
          />
        </div>
      </div>
    </div>
  );
};

export function HeadTable({ label }) {
  return (
    <th style={{ textAlign: "center", verticalAlign: "middle" }}>{label}</th>
  );
}
