import MultiRaterAction from "./MultiRater/MultiRaterAction"
import PreferencesAction from "./Preferences/PreferencesAction"
import UserAction from "./User/UserAction"
import SurveyFormAction from "./MultiraterFormPart/MultiraterFormPartAction"
import AddRaterAction from "./AddRater/AddRaterAction"
import UpdateMultiRaterAction from "./UpdateMultiRater/UpdateMultiRaterAction"
import SingleRaterAction from "./SingleRater/SingleRaterAction"
import UpdateSingleRaterAction from "./UpdateSingleRater/UpdateSingleRaterAction"
import SingleSurveyFormAction from "./SingleraterFormPart/SingleraterFormPartAction"
import SpreadSheetAction from "./SpreadSheet/SpreadSheetAction"
import  { setSummary } from "./AdminDashboard/summaryActions";
import {setSurveys} from "./AdminDashboard/surveyActions";
import { setSelectedYear } from "./AdminDashboard/chartActions"
import ReportTemplateAction from "./ReportTemplate/ReportTemplateAction"

export const rootAction = {
   user: UserAction,
   multiRater: MultiRaterAction,
   preference: PreferencesAction,
   surveyForm: SurveyFormAction,
   addRater: AddRaterAction,
   updateMultiRater: UpdateMultiRaterAction,
   singleRater: SingleRaterAction,
   updateSingleRater: UpdateSingleRaterAction,
   singleSurveyForm: SingleSurveyFormAction,
   spreadSheetAction: SpreadSheetAction,
   setSummary: setSummary,
   setSurverys: setSurveys,
   setSelectedYear:setSelectedYear,
   reportTemplate: ReportTemplateAction,
}
