import { Table } from 'react-bootstrap';

const getColumnName = (headers) => {
    const last = headers.length - 1;
    return headers[last];
};

const createColumns = (headers, items) => {
    const otherCols = Object.keys(items);
    return [headers[0], headers[1], ...otherCols];
};

const SummaryTable = ({ contentId, result }) => {
    const columns = createColumns(result.headers, result.items);
    const colTitle = getColumnName(result.headers);
    const calcs = Object.values(result.items);

    return (
        <>
            {result.yosage ? (
                <CreateTableYosage {...result} />
            ) : (
                <CreateTable
                    columns={columns}
                    calcs={calcs}
                    data={result.values}
                    title={colTitle}
                />
            )}
        </>
    );
};

const CreateTable = ({ columns, calcs, data, title }) => {
    return (
        <Table striped bordered hover size="sm" className="fw-light">
            <thead className="text-center">
                <tr>
                    <th rowSpan={3} style={{ verticalAlign: 'top' }}>
                        {columns[0]}
                    </th>
                    <th rowSpan={3} style={{ verticalAlign: 'top', width: '10%' }}>
                        {columns[1]}
                    </th>
                    <th colSpan={columns.length - 2} style={{ verticalAlign: 'top' }}>
                        {title}
                    </th>
                </tr>
                <tr>
                    {Array.isArray(calcs) &&
                        calcs.map((c, i) => <th>{Number(c).toFixed(2)}</th>)}
                </tr>
                <tr>
                    {Array.isArray(columns) &&
                        columns.map((c, i) => <>{i > 1 && <th>{c}</th>}</>)}
                </tr>
            </thead>
            <tbody>
                {Array.isArray(data) &&
                    data.map((row, _) => (
                        <tr>
                            {Array.isArray(row) &&
                                row.map((val, idx) => (
                                    <td className={`${idx > 0 ? 'text-end' : ''}`}>
                                        {idx > 0 ? Number(val).toFixed(2) : val}
                                    </td>
                                ))}
                        </tr>
                    ))}
            </tbody>
        </Table>
    );
};

const CreateTableYosage = ({ headers, items, values }) => {
    const yosItems = Object.values(items.yos);
    const ageItems = Object.values(items.age);

    return (
        <Table striped bordered hover size="sm" className="fw-light">
            <thead className="text-center">
                <tr style={{ verticalAlign: 'top' }}>
                    <th rowSpan={3}>{headers[0]}</th>
                    <th rowSpan={3} style={{ width: '10%' }}>
                        {headers[1]}
                    </th>
                    <th colSpan={yosItems.length}>{headers[2]}</th>
                    <th colSpan={ageItems.length}>{headers[3]}</th>
                </tr>
                <tr>
                    {yosItems && yosItems.map((c, i) => <th>{Number(c).toFixed(2)}</th>)}
                    {ageItems && ageItems.map((c, i) => <th>{Number(c).toFixed(2)}</th>)}
                </tr>
                <tr>
                    {yosItems && Object.keys(items.yos).map((y, i) => <th>{y}</th>)}
                    {ageItems && Object.keys(items.age).map((a, i) => <th>{a}</th>)}
                </tr>
            </thead>
            <tbody>
                {Array.isArray(values) &&
                    values.map((row, _) => (
                        <tr>
                            {Array.isArray(row) &&
                                row.map((val, idx) => (
                                    <td className={`${idx > 0 ? 'text-end' : ''}`}>
                                        {idx > 0 ? Number(val).toFixed(2) : val}
                                    </td>
                                ))}
                        </tr>
                    ))}
            </tbody>
        </Table>
    );
};

export default SummaryTable;
