import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SearchColumn } from "../../../shared/component/table_property/SearchColumn";
import { ShowEntries } from "../../../shared/component/table_property/ShowEntries";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { ButtonOutlinePrimary } from "../../../shared/component/button/Button";
import { useNavigate } from "react-router-dom";
import { CONSTANTS } from "../../../common/constants";
import useFaq from "../../support/faq/useFaq";
import Pagination from "../../../shared/component/table_property/Paginatination";

function FAQView() {
  const navigate = useNavigate();
  const {
    allFaq,
    pageNumber,
    setPageNumber,
    showItem,
    totalItem,
    handleshowItem,
    handleInputSearchChange,
    handleSearchData,

  } = useFaq();

  const paginate = (pageNumber) => {
    setPageNumber(pageNumber);
 };

 const previousPage =  () => {
      if (pageNumber != 1) {
        setPageNumber(pageNumber - 1);
        
      }
  };

  const nextPage = () => {
      if (pageNumber != Math.ceil(totalItem/ showItem)) {
        setPageNumber(pageNumber + 1);
      }
  };

  return (
    <div className="container tw-h-max tw-max-w-[1400px]">
      <div className="my-4">
        <h4>Frequently Ask Question</h4>
      </div>
      <div className="shadow p-4 rounded border">
        <div className="d-flex flex-row justify-content-between">
          <div className="col-2 d-flex flex-row justify-content-center">
            <div className="d-flex align-items-center mr-1">Show</div>
            <ShowEntries onClick_item={handleshowItem} default_item={showItem}/>
            <div className="d-flex align-items-center ml-1">entries</div>
          </div>
          <div className="col-3">
          <SearchColumn onChange_input={handleInputSearchChange}  onClick_btn={handleSearchData}/>
          </div>
        </div>
        <div className="mt-1">
          <table className="table table-striped table-bordered table-sm">
            <thead>
              <tr  style={{ textAlign:'center'}}>
                <th style={{ verticalAlign: "middle" }}>No</th>
                <th style={{ verticalAlign: "middle" }}>Question</th>
                <th style={{ verticalAlign: "middle" }}>Answer</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(allFaq) && allFaq.length != 0 ?
              allFaq.map((dt,i) => {
                return (
                  <tr key={i}  style={{ verticalAlign: "middle" ,textAlign:'center'}}>
                     {
                      pageNumber == 1 ? <td>{i+1}</td> : <td>{(i+1) + (showItem*(pageNumber-1))}</td>
                    }
                    <td>{dt.question}</td>
                    <td>{dt.answer}</td>
                  </tr>
                );
              })
            :
            <tr style={{ verticalAlign: "middle" ,textAlign:'center'}}><td colSpan={3}>No data</td></tr>
            }
            </tbody>
          </table>
        </div>
        <Pagination showing={Array.isArray(allFaq) ? allFaq.length : 0} totalItem={totalItem} showItem={showItem} previousPage={previousPage} nextPage={nextPage} paginate={paginate} limit={pageNumber}/>
        <div className="d-flex justify-content-center align-items-center">
          <div className="me-2">
            <h5>Other Question ?</h5>
          </div>
          <div className="mb-2">
            <ButtonOutlinePrimary
              label={
                <div className="d-flex">
                  <div className="me-2">
                    <FontAwesomeIcon icon={faPhone} />
                  </div>
                  <div className="fw-bold">Contact Us</div>
                </div>
              }
              onClick={() =>
                navigate(
                  `${CONSTANTS.ROUTER.PARTICIPANT_DASHBOARD}/${CONSTANTS.ROUTER.PARTICIPANT_CONTACT_US}`
                )
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQView;
