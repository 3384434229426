import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import EChartsReact from 'echarts-for-react';
import { CONTENTS } from '../../constants';
import { randomTypes, createRandom } from '../../utils/createRandom';
import ColorInfo from './ColorInfo';

const {
    SR_BAR_GROUP,
    SR_BAR_DIMENSION,
    SR_BAR_ASPECT,
    SR_BAR_ITEM,
    MRG_BAR_GROUP,
    MRG_BAR_DIMENSION,
    MRG_BAR_ASPECT,
    MRG_BAR_ITEM,
    MRI_BAR_GROUP,
    MRI_BAR_DIMENSION,
    MRI_BAR_ASPECT,
    MRI_BAR_ITEM
} = CONTENTS;
const { BASE100 } = randomTypes;

const defaultOptions = {
    tooltip: {
        trigger: 'axis',
        axisPointer: { type: 'shadow' }
    },
    legend: {
        orient: 'horizontal',
        bottom: 0
    },
    grid: {
        top: 0,
        left: '3%',
        right: '4%',
        bottom: 30,
        containLabel: true
    },
    xAxis: { type: 'value' }
};

const createColor = (theme) => {
    const themeColors = {
        theme1: ['#2485f4', '#ea4335', '#fbbc04', '#34a853', '#ff6d01'],
        theme2: ['#C1D0D7', '#004561', '#FF6F31', '#2F818E', '#194CAB'],
        theme3: ['#C5D9D7', '#2D729D', '#8594B0', '#EB5600', '#FF99AC'],
        theme4: ['#FF99AC', '#0B6374', '#FC6360', '#5F5FA6', '#8DD8D3'],
        theme5: ['#006391', '#E24A38', '#FDBC34', '#28998B', '#C0DE00']
    };

    return themeColors[theme];
};

const createLegends = (title) => {
    return {
        type: 'category',
        data: [...Array(7).keys()].map((_, idx) => `${title} ${idx + 1}`).reverse()
    };
};

const genRowData = (name) => {
    return {
        name: name,
        type: 'bar',
        stack: 'total',
        label: { show: true },
        emphasis: { focus: 'series' },
        data: [...Array(7).keys()].map((_) => createRandom(BASE100, 2, 4, 10, 0.66)),
        bottom: 10
    };
};

const isSingleR = (cid) => {
    return [SR_BAR_GROUP, SR_BAR_DIMENSION, SR_BAR_ASPECT, SR_BAR_ITEM].includes(cid);
};

const isValid = (cid, suffix) => {
    return (
        [
            SR_BAR_GROUP + suffix,
            SR_BAR_DIMENSION + suffix,
            SR_BAR_ASPECT + suffix,
            SR_BAR_ITEM + suffix
        ].includes(cid) ||
        [
            MRG_BAR_GROUP + suffix,
            MRG_BAR_DIMENSION + suffix,
            MRG_BAR_ASPECT + suffix,
            MRG_BAR_ITEM + suffix
        ].includes(cid) ||
        [
            MRI_BAR_GROUP + suffix,
            MRI_BAR_DIMENSION + suffix,
            MRI_BAR_ASPECT + suffix,
            MRI_BAR_ITEM + suffix
        ].includes(cid)
    );
};

const createData = (contentId) => {
    let data = [];

    if (isSingleR(contentId)) {
        data = [
            genRowData('Label 1'),
            genRowData('Label 2'),
            genRowData('Label 3'),
            genRowData('Label 4')
        ];
    } else {
        data.push(genRowData('Self'));
        if (isValid(contentId, '.1')) {
            data.push(genRowData('Others'));
            data.push(genRowData('All'));
        } else if (isValid(contentId, '.2')) {
            data.push(genRowData('Supervisor'));
            data.push(genRowData('Subordinate'));
            data.push(genRowData('Peer'));
            data.push(genRowData('All'));
        } else if (isValid(contentId, '.3')) {
            data.push(genRowData('Supervisor'));
            data.push(genRowData('Sub & Peer'));
            data.push(genRowData('All'));
        } else if (isValid(contentId, '.4')) {
            data.push(genRowData('Supervisor'));
            data.push(genRowData('Subordinate'));
            data.push(genRowData('Peer'));
        }
    }

    return data;
};

const BarChartData = ({ contentId, defaultTheme, showThemePicker, onChange }) => {
    const [theme, setTheme] = useState(defaultTheme || 'theme1');
    const column =
        contentId.startsWith(SR_BAR_GROUP) ||
        contentId.startsWith(MRG_BAR_GROUP) ||
        contentId.startsWith(MRI_BAR_GROUP)
            ? 'Group'
            : contentId.startsWith(SR_BAR_DIMENSION) ||
              contentId.startsWith(MRG_BAR_DIMENSION) ||
              contentId.startsWith(MRI_BAR_DIMENSION)
            ? 'Dimension'
            : contentId.startsWith(SR_BAR_ASPECT) ||
              contentId.startsWith(MRG_BAR_ASPECT) ||
              contentId.startsWith(MRI_BAR_ASPECT)
            ? 'Aspect'
            : contentId.startsWith(SR_BAR_ITEM) ||
              contentId.startsWith(MRG_BAR_ITEM) ||
              contentId.startsWith(MRI_BAR_ITEM)
            ? 'Item'
            : null;
    const legends = createLegends(column);
    const data = createData(contentId);
    const color = createColor(theme);
    const options = { ...defaultOptions, color: color, yAxis: legends, series: data };

    const handleChangeTheme = (e) => {
        setTheme(e.target.value);
        if (onChange) onChange(e.target.value);
    };

    return (
        <Row className="mx-2">
            <Col>
                <EChartsReact option={options} style={{ width: '400px' }} />
            </Col>
            {showThemePicker && (
                <ColorInfo
                    id={contentId}
                    title={'Bar Color'}
                    colorCount={data.length}
                    colors={color}
                    value={theme}
                    onChange={handleChangeTheme}
                />
            )}
        </Row>
    );
};

export default BarChartData;
