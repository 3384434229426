import { CONTENT_SECTIONS as CONTROLS } from '../constants';

export const initSectionReduxState = {
    id: 0,
    tpl_code: '',
    section_code: '',
    section_id: '',
    index: 0,
    title: '',
    content: '',
    theme: '',
    group_value: '',
    dim_value: '',
    aspect_value: '',
    higher_point: 0,
    lower_point: 0
};

export const createSection = (controlId, contentId, text) => {
    const hasTheme = [
        CONTROLS.BAR_CHART_DATA,
        CONTROLS.RADAR_CHART_DATA,
        CONTROLS.DATA_TABLE,
        CONTROLS.DEMOGRAPHY_DATA
    ].includes(controlId);

    return {
        id: Math.random().toString(36).substring(2, 7),
        controlId: controlId,
        contentId: contentId,
        title: text,
        content: `New ${text}`,
        theme: hasTheme ? 'theme1' : ''
    };
};

export const getControlId = (code) => {
    return code.split('.')[1];
};

export const mapToSection = (el) => {
    const controlId = getControlId(el.section_code);
    return {
        id: el.section_id,
        controlId: controlId,
        contentId: el.section_code,
        title: null,
        content: el.content,
        theme: el.theme
    };
};

export const mapSectionToRedux = (el, index) => ({
    ...initSectionReduxState,
    index: index,
    section_code: el.contentId,
    section_id: el.id,
    title: el.title,
    content: el.content,
    theme: el.theme
});
