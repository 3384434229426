import { useSelector } from "react-redux";
import { redirect, useNavigate } from "react-router";
import { CONSTANTS } from "../../common/constants";
import { useEffect } from "react";

export default function AuthRedirect() {
   const userReducer = useSelector((state) => state.user);
   const navigate = useNavigate();

   useEffect(() => {
      if (userReducer.role == 1) {
         navigate(`${CONSTANTS.ROUTER.ADMIN_CMS}/${CONSTANTS.ROUTER.DASHBOARD}`);
      } else if (userReducer.role == 2) {
         navigate(`${CONSTANTS.ROUTER.PIC_CMS}/${CONSTANTS.ROUTER.DASHBOARD}`);
      } else if (userReducer.role == 3) {
         navigate(`${CONSTANTS.ROUTER.PARTICIPANT_DASHBOARD}/${CONSTANTS.ROUTER.PARTICIPANT_INTRODUCTION}`);
      } else {
         navigate(CONSTANTS.ROUTER.LOGIN);
      }
   }, [userReducer]);
}