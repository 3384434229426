import Lottie from "lottie-react";
import notFoundAnimation from "../../assets/json/not-found.json";

/**
 * This part of code is made using Tailwind.css
 */

export function NotFound() {
   return (
      <div className='tw-flex tw-w-full tw-max-w-[1400px] tw-h-full tw-justify-center tw-items-center tw-flex-col'>
         <Lottie animationData={notFoundAnimation} style={{ width: '300px' }} />
         <div className='tw-font-medium tw-text-xl tw-pb-10'>Sorry, we can't get your request</div>
      </div>
   )
}