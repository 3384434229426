import { useEffect, useRef, useState } from "react";
import { useDeps } from "../../shared/context/DependencyContext";
import swal from "sweetalert";
import screenToast from "../../shared/hook/ScreenToast";
import useViewState from "../../shared/hook/UseViewState";
import screenAlert from "../../shared/hook/SweetAlert";

function usePICList() {
    const { adminService } = useDeps();
    const [allPIC, setAllPIC] = useState([]);
    const [isloading, setIsloading] = useState(false);
    const [modalAdd, setModalAdd] = useState(false);
    const [userNik, setUserNik] = useState('');
    const [userName, setUserName] = useState('');
    const [userNumber, setUserNumber] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userDep, setUserDep] = useState('');
    const [userBU, setUserBU] = useState('');
    const [nikFound, setNikFound] = useState(false);
    const [showItem, setShowItem] = useState(10);
    const [changeData, setChangeData] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [totalItem, setTotalItem] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const showToast = screenToast();
    const { viewState, setError, setSuccess } = useViewState();
    const [progress, setProgress] = useState(0);
    const showAlert = screenAlert();


    useEffect(() => {
        if (viewState.isError == true) {
            showToast.error(viewState.errorMsg);
            setError(false, '');
        }

        if (viewState.isSuccess == true) {
            showToast.success(viewState.successMsg);
            setSuccess(false, '');
        }
    }, [viewState]);

    useEffect(() => {
        if (pageNumber == 1) {
            onSearchData(showItem, pageNumber, searchText);
        }
        setPageNumber(1);

    }, [showItem, changeData])

    useEffect(() => {
        onSearchData(showItem, pageNumber, searchText);
    }, [pageNumber])

    const onGetAllPIC = async (limit, page_number) => {
       
        try {
            setIsloading(true);
            const response = await adminService.picListService.getAllPIC(limit, page_number);

            if (response.status == 'SUCCESS') {
                setAllPIC(response.data.data);
                setTotalItem(response.data.totalItem)
            }
        } catch (error) {
            console.log(error)
        }finally{
            setIsloading(false);
        }

    }

    const onDeletePIC = async (nik, name, email, number) => {
        setIsloading(true);
        try {
            const response = await adminService.picListService.deletePIC(nik, name, email, number);

            if (response.status == 'SUCCESS') {
                setChangeData(!changeData);
                setSuccess(true,'Deleted Successfully')
            }else {
                setError(true,response.status)
            }
        } catch (error) {
            console.log(error)
            setError(true,'Error System')
        } finally {
            setIsloading(false);
        }
    }

    const onCheckNIK = async () => {
        setUserName('');
        setUserEmail('');
        setUserNumber('');
        setUserDep('');
        setUserBU('');
        setIsloading(true);
        try {
            const response = await adminService.picListService.checkNIK(userNik);
            if (response.status == 'SUCCESS') {
                if (response.data == 'NO DATA') {
                    setError(true,'Nik not found')
                }else {
                    setNikFound(true);
                    setUserName(response.data.full_name);
                    setUserEmail(response.data.email);
                    setUserNumber(response.data.phone_number);
                    setUserDep(response.data.department_name);
                    setUserBU(response.data.business_unit_name);
                }
            } else {
                setError(true,response.status)
                setNikFound(false);
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsloading(false);
        }
    }

    const onPostPIC = async (nik, name, email, number) => {
        setIsloading(true);
        try {
            const response = await adminService.picListService.postPIC(nik, name, email, number);

            if (response.status == 'SUCCESS') {
                setChangeData(!changeData);
                setSuccess(true,'Saved Successfully')
            }else {
                setError(true,response.status)
            }
        } catch (error) {
            console.log(error)
            setError(true,'Error System')
        } finally {
            setIsloading(false);
        }
    }

    const onSearchData = async (limit, page_number, search) => {
        setIsloading(true);
        setProgress(10);

        try {
            setProgress(30);
            const response = await adminService.picListService.searchData(limit, page_number, search);
            setProgress(60);
            if (response.status == 'SUCCESS') {
                setAllPIC(response.data.data);
                setTotalItem(response.data.totalItem)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsloading(false);
            setProgress(100);
        }
    }

    const handleChangeUserNIK = (e) => {
        setUserNik(e.target.value);
        setNikFound(false);
    }

    const handleChangeUserEmail = (e) => {
        setUserEmail(e.target.value);
    }

    const handleChangeUserNumber = (e) => {
        setUserNumber(e.target.value);
    }

    const handleHideModal = () => {
        setUserNik('');
        setUserName('');
        setUserEmail('');
        setUserNumber('');
        setUserDep('');
        setUserBU('');
        setModalAdd(false);
        setNikFound(false);
    }

    const handleSavePIC = async () => {
        await onPostPIC(userNik, userName, userEmail, userNumber);
        setUserNik('');
        setUserName('');
        setUserEmail('');
        setUserNumber('');
        setUserDep('');
        setUserBU('');
        setModalAdd(false);
        setNikFound(false);
    }

    const handleshowItem = (item) => {
        setShowItem(item);
    }

    const handleInputSearchChange = (e) => {
        setSearchText(e.target.value);
    }

    const handleDeletePIC = (nik, name, email, number) => {
        showAlert.warning({
            text: "Are you sure to delete this?",
            buttons: {
                cancel: {
                    text: "Cancel",
                    className: ""
                },
                confirm: {
                    text: "Yes",
                    value: true,
                    className: "bg-success"
                }
            }
        }).then(value => {
            if (value) {
                onDeletePIC(nik, name, email, number);
            }
        });
        
    }



    return {
        onGetAllPIC,
        allPIC,
        isloading,
        onDeletePIC,
        handleChangeUserNIK,
        onCheckNIK,
        userNik,
        userName,
        userEmail,
        userNumber,
        userDep,
        userBU,
        handleChangeUserEmail,
        handleChangeUserNumber,
        modalAdd,
        setModalAdd,
        handleHideModal,
        nikFound,
        setNikFound,
        handleSavePIC,
        handleshowItem,
        showItem,
        onSearchData,
        handleInputSearchChange,
        searchText,
        handleDeletePIC,
        totalItem,
        pageNumber,
        setPageNumber,
        progress,
        setProgress
        
    }

}

export default usePICList;