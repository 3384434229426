import { Table } from "react-bootstrap";

const DemographyData = ({contentId, result}) => {
    return (
        <div className="fw-light text-center col-md-5 mx-auto">
            <Table striped bordered hover  size="sm">
                <thead>
                    <tr>
                        <th>{result.headers[0]}</th>
                        <th>{result.headers[1]}</th>
                    </tr>
                </thead>
                <tbody>
                    {result.values.map((val, i) => (
                        <tr>
                            <td>{val[0]}</td>
                            <td>{val[1]}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}

export default DemographyData;
