import { Table } from 'react-bootstrap';
import { CONTENTS } from '../../constants';

const {
    MRI_TABLE_ITEM,
    MRI_TABLE_HIGHEST,
    MRI_TABLE_LOWEST,
    MRI_TABLE_BLINDSPOT,
    MRI_TABLE_HIDDENSTR,
    MRI_TABLE_ACTSTR,
    MRI_TABLE_ACTWEAK,
    MRG_TABLE_ITEM,
    MRG_TABLE_HIGHEST,
    MRG_TABLE_LOWEST,
    MRG_TABLE_BLINDSPOT,
    MRG_TABLE_HIDDENSTR,
    MRG_TABLE_ACTSTR,
    MRG_TABLE_ACTWEAK
} = CONTENTS;

const DataTable = ({ contentId, result }) => {
    const props = { contentId:contentId, columns:result.headers, data:result.values };

    return (
        <>
            {(contentId.startsWith(MRI_TABLE_ITEM) ||
                contentId.startsWith(MRG_TABLE_ITEM)) && (
                <ItemScore {...props} />
            )}
            {(contentId.startsWith(MRI_TABLE_HIGHEST) ||
                contentId.startsWith(MRG_TABLE_HIGHEST)) && (
                <HighestLowestScore {...props} />
            )}
            {(contentId.startsWith(MRI_TABLE_LOWEST) ||
                contentId.startsWith(MRG_TABLE_LOWEST)) && (
                <HighestLowestScore {...props} />
            )}
            {(contentId.startsWith(MRI_TABLE_BLINDSPOT) ||
                contentId.startsWith(MRG_TABLE_BLINDSPOT)) && (
                <BlindSpotScore {...props} />
            )}
            {(contentId.startsWith(MRI_TABLE_HIDDENSTR) ||
                contentId.startsWith(MRG_TABLE_HIDDENSTR)) && (
                <HiddenStrengthScore {...props} />
            )}
            {(contentId.startsWith(MRI_TABLE_ACTSTR) ||
                contentId.startsWith(MRG_TABLE_ACTSTR)) && (
                <ActionForStrength {...props} />
            )}
            {(contentId.startsWith(MRI_TABLE_ACTWEAK) ||
                contentId.startsWith(MRG_TABLE_ACTWEAK)) && (
                <ActionForWeakness {...props} />
            )}
        </>
    );
};

const ItemScore = ({ columns, data }) => {
    return <CreateTable column={columns} data={data} />;
};

const HighestLowestScore = ({ columns, data }) => {
    return <CreateTable column={columns} data={data} />;
};

const BlindSpotScore = ({ columns, data }) => {
    return <CreateTable column={columns} data={data} />;
};

const HiddenStrengthScore = ({ columns, data }) => {
    return <CreateTable column={columns} data={data} />;
};

const ActionForStrength = ({ columns, data }) => {
    return <CreateTable column={columns} data={data} />;
};

const ActionForWeakness = ({ columns, data }) => {
    return <CreateTable column={columns} data={data} />;
};

const CreateTable = ({ column, data }) => {
    return (
        <Table striped bordered hover size="sm" className="fw-light">
            <thead>
                <tr style={{ verticalAlign: 'top' }}>
                    {Array.isArray(column) && column.map((c) => <th>{c}</th>)}
                </tr>
            </thead>
            <tbody>
                {Array.isArray(data) &&
                    data.map((row, rowId) => (
                        <tr>
                            {Array.isArray(row) &&
                                row.map((col, colId) => <td>{data[rowId][colId]}</td>)}
                        </tr>
                    ))}
            </tbody>
        </Table>
    );
};

export default DataTable;
