import { Spinner } from 'react-bootstrap';

export const DivRow = ({ className, children }) => {
    return (
        <div className={`d-flex justify-content-start mb-3 ${className ?? ''}`}>
            {children}
        </div>
    );
};

export const BtnMini = ({ label, onClick }) => {
    return (
        <button className="btn btn-sm btn-primary" onClick={onClick}>
            {label}
        </button>
    );
};

export const DownloadSpinner = ({ percentage }) => (
    <>
        &nbsp;
        <Spinner animation="border" size="sm" />
        <span> {percentage} %</span>
    </>
);