import { ADD_SCALE_CHOICE_SR, CLEAR_SCALE_CHOICE_SR, CLEAR_VIEW_CONTENT_UPDATE_SR, DELETE_CONTENT_CHOICE_SR, DELETE_CONTENT_SR, DELETE_OWNERSHIP_SR, DELETE_PARTICIPANT_SR, DELETE_SCALE_CHOICE_SR, DELETE_SECTION_SR, EDIT_CONTENT_CHOICE_SR, EDIT_CONTENT_SR, EDIT_PARTICIPANT_SR, EDIT_SCALE_CHOICE_SR, EDIT_SECTION_SR, LOAD_PARTICIPANT_SR, LOAD_SCALE_CHOICE_SR, LOAD_SCORE_SR, LOAD_SECTION_CONTENT_SR, LOAD_SETTING_SR, LOAD_SINGLE_RATER, OWNERSHIP_LIST_SR, RESET_CONTENT_VIEW_SR, RESET_IS_EDITING_SR, RESET_OWNERSHIP_SR, RESET_PARTICIPANT_SR, RESET_SCALE_CHOICE_SR, RESET_SCORE_SR, RESET_SECTION_SR, RESET_SETTING_LABEL_DATA_SR, RESET_SETTING_SR, RESET_SINGLE_RATER, RESET_VALID_INPUT, RESET_VIEW_EDIT_PARTICIPANT_KEY_EMAIL_SR, RESET_VIEW_EDIT_PARTICIPANT_SR, RESET_VIEW_TAB_SR, SET_CONTENT_BULK_SR, SET_CONTENT_CHOICE_SCALE_SR, SET_CONTENT_CHOICE_SR, SET_CONTENT_SR, SET_IS_EDITING_SR, SET_PARTICIPANT_SR, SET_SCALE_CHOICE_SR, SET_SCORE_SR, SET_SECTION_SR, SET_SETTING_LABEL_DATA_SR, SET_SETTING_SR, SET_SINGLE_RATER, SET_VALID_CONTENT, SET_VALID_PARTICIPANT, SET_VALID_SETTING, SET_VIEW_CONTENT_UPDATE_SR, SET_VIEW_EDIT_PARTICIPANT_KEY_EMAIL_SR, SET_VIEW_EDIT_PARTICIPANT_SR, SET_VIEW_SR } from "./UpdateSingleRaterType";

// initial state
const initViewTabState = 1;

const initViewEditParticipantState = '';

const initSingleRaterState = {};

const initOwnership =[];

const initSettingState = {
   survey_name: '',
   description_setting: '',
   anonimity: 0,
   language: '',
   finish_text: '',
   invitation_text: '',
   reminder_text: '',
   reminder_interval_in_days: 0,
   goal_id: 0,
   business_unit_code: '',
   department_code: '',
   pagination_content: '',
   welcome_text: '',
   start_date: '',
   end_date: '',
   participant_availability: '',
   is_auto_login:0
}

const initSettingLabelDataState = {
   goal_name:'',
   department_name:'',
   business_unit_name:''
}

const initParticipantState = [];

const initSectionState = [];

const initContentViewState = {
   section_id: 0,
   content_id: 0
}

const initScaleChoiceState = []

const initScoreState = {
   supervisor_weight: 0,
   subordinate_weight: 0,
   peer_weight: 0,
   stakeholder_weight: 0,
   self_weight: 0,

}

const initIsEditingState = false;

const initIsValid = {
   setting: true,
   content: true,
   participant: true
}

// define reducer action
export function updateViewSingleRaterReducer(state = initViewTabState, action) {
   switch (action.type) {
      case SET_VIEW_SR:
         return state = action.data;
      case RESET_VIEW_TAB_SR:
         return state = 1;
      default:
         return state;
   }
}

export function updateSingleRaterReducer(state = initSingleRaterState, action) {
   switch (action.type) {
      case LOAD_SINGLE_RATER:
         return Object.assign({}, state, action.data);
      case SET_SINGLE_RATER:
         return {
            ...state,
            [action.data.field]: action.data.payload
         }
      case RESET_SINGLE_RATER:
         return state = {}
      default:
         return state;
   }
}

export function updateSettingSingleRaterReducer(state = initSettingState, action) {
   switch (action.type) {
      case LOAD_SETTING_SR:
         return Object.assign({}, state, action.data);
      case SET_SETTING_SR:
         return {
            ...state,
            [action.data.field]: action.data.payload
         }
      case RESET_SETTING_SR:
         return state = {
            survey_name: '',
            description_setting: '',
            anonimity: 0,
            language: '',
            finish_text: '',
            invitation_text: '',
            reminder_text: '',
            reminder_interval_in_days: 0,
            goal_id: 0,
            business_unit_code: '',
            department_code: '',
            pagination_content: '',
            welcome_text: '',
            start_date: '',
            end_date: '',
            participant_availability: '',
            is_auto_login:0
         }
      default:
         return state;
   }
}

export function updateSectionSingleRaterReducer(state = initSectionState, action) {
   switch (action.type) {
      case LOAD_SECTION_CONTENT_SR:
         return [...action.data];
      case SET_SECTION_SR:
         return [...state, action.data];
      case EDIT_SECTION_SR:
         return [...state].map((el) => {
            if (el.section_id == action.data.key) {
               return {
                  ...el,
                  [action.data.field]: action.data.payload
               }
            } else {
               return el;
            }
         });
      case DELETE_SECTION_SR:
         return state.filter((el) => el.section_id != action.data);
      case SET_CONTENT_SR:
         return [...state].map((el) => {
            if (el.section_id == action.data.key) {
               return {
                  ...el,
                  content: [...el.content, action.data.payload]
               };
            } else {
               return el;
            }
         });
      case SET_CONTENT_BULK_SR:
         return [...state].map((el) => {
            if (el.section_id == action.data.key) {
               return {
                  ...el,
                  content: action.data.payload
               };
            } else {
               return el;
            }
         });
      case EDIT_CONTENT_SR:
         return [...state].map((el) => {
            if (el.section_id == action.data.section_key) {
               return {
                  ...el,
                  content: [...el.content].map((val) => {
                     if (val.content_id == action.data.content_key) {
                        return {
                           ...val,
                           [action.data.field]: action.data.payload
                        }
                     } else {
                        return val;
                     }
                  })
               }
            } else {
               return el;
            }
         });
      case DELETE_CONTENT_SR:
         return [...state].map((el) => {
            if (el.section_id == action.data.section_key) {
               return {
                  ...el,
                  content: el.content.filter((el) => el.content_id != action.data.content_key).map((val, i) => {
                     if (val.content_id != action.data.content_key) {
                        return {
                           ...val,
                           content_id: i + 1,
                           content_name: 'Question ' + (i + 1)
                        }
                     }
                  })
               }
            } else {
               return el;
            }
         });
      case SET_CONTENT_CHOICE_SR:
         return [...state].map((el) => {
            if (el.section_id == action.data.section_key) {
               return {
                  ...el,
                  content: [...el.content].map((val) => {
                     if (val.content_id == action.data.content_key) {
                        return {
                           ...val,
                           content_choice: [...val.content_choice, action.data.payload]
                        }
                     } else {
                        return val;
                     }
                  })
               }
            } else {
               return el;
            }
         });
      case SET_CONTENT_CHOICE_SCALE_SR:
         return [...state].map((el) => {
            if (el.section_id == action.data.section_key) {
               return {
                  ...el,
                  content: [...el.content].map((val) => {
                     if (val.content_id == action.data.content_key) {
                        return {
                           ...val,
                           content_choice: action.data.payload
                        }
                     } else {
                        return val;
                     }
                  })
               }
            } else {
               return el;
            }
         });
      case EDIT_CONTENT_CHOICE_SR:
         return [...state].map((el) => {
            if (el.section_id == action.data.section_key) {
               return {
                  ...el,
                  content: [...el.content].map((val) => {
                     if (val.content_id == action.data.content_key) {
                        return {
                           ...val,
                           content_choice: [...val.content_choice].map((data) => {
                              if (data.choice_id == action.data.choice_key) {
                                 return {
                                    ...data,
                                    [action.data.field]: action.data.payload
                                 }
                              } else {
                                 return data;
                              }
                           })
                        }
                     } else {
                        return val;
                     }
                  })
               }
            } else {
               return el;
            }
         })
      case DELETE_CONTENT_CHOICE_SR:
         return [...state].map((el) => {
            if (el.section_id == action.data.section_key) {
               return {
                  ...el,
                  content: [...el.content].map((val) => {
                     if (val.content_id == action.data.content_key) {
                        return {
                           ...val,
                           content_choice: val.content_choice.filter((data) => data.choice_id != action.data.choice_key)
                        }
                     } else {
                        return val;
                     }
                  })
               }
            } else {
               return el;
            }
         });
      case RESET_SECTION_SR:
         return state = [];
      default:
         return state;
   }
}

export function updateViewContentSingleRaterReducer(state = initContentViewState, action) {
   switch (action.type) {
      case SET_VIEW_CONTENT_UPDATE_SR:
         return Object.assign({}, state, action.data);
      case CLEAR_VIEW_CONTENT_UPDATE_SR:
         return Object.assign({}, state, {
            section_id: 0,
            content_id: 0
         });
      case RESET_CONTENT_VIEW_SR:
         return state = {
            section_id: 0,
            content_id: 0
         }
      default:
         return state;
   }
}

export function updateScaleChoiceSingleRaterReducer(state = initScaleChoiceState, action) {
   switch (action.type) {
      case LOAD_SCALE_CHOICE_SR:
         return [...action.data];
      case SET_SCALE_CHOICE_SR:
         return [...state, action.data];
      case ADD_SCALE_CHOICE_SR:
         return [...state].map((el) => {
            if (el.section_id == action.data.key) {
               return {
                  ...el,
                  content_choice: [...el.content_choice, action.data.payload]
               }
            } else {
               return el;
            }
         })
      case EDIT_SCALE_CHOICE_SR:
         return [...state].map((el) => {
            if (el.section_id == action.data.section_key) {
               return {
                  ...el,
                  content_choice: [...el.content_choice].map((val) => {
                     if (val.scale_id == action.data.scale_key) {
                        return {
                           ...val,
                           [action.data.field]: action.data.payload
                        }
                     } else {
                        return val;
                     }
                  })
               }
            } else {
               return el;
            }
         });
      case DELETE_SCALE_CHOICE_SR:
         return [...state].map((el) => {
            if (el.section_id == action.data.section_key) {
               let newScale = {
                  ...el,
                  content_choice: el.content_choice.filter((val) => val.scale_id != action.data.scale_key)
               }

               return {
                  ...newScale,
                  content_choice: [...newScale.content_choice].map((val, i) => {
                     return {
                        ...val,
                        choice_score: i + 1
                     }
                  })
               };
            } else {
               return el;
            }
         })
      case CLEAR_SCALE_CHOICE_SR:
         return state.filter((el) => el.section_id != action.data);
      case RESET_SCALE_CHOICE_SR:
         return state = [];
      default:
         return state;
   }
}

export function updateScoreSingleRaterReducer(state = initScoreState, action) {
   switch (action.type) {
      case LOAD_SCORE_SR:
         return Object.assign({}, state, action.data);
      case SET_SCORE_SR:
         return {
            ...state,
            [action.data.field]: action.data.payload
         }
      case RESET_SCORE_SR:
         return state = {
            supervisor_weight: 0,
            subordinate_weight: 0,
            peer_weight: 0,
            stakeholder_weight: 0,
            self_weight: 0,

         }
      default:
         return state;
   }
}

export function updateParticipantSingleRaterReducer(state = initParticipantState, action) {
   switch (action.type) {
      case LOAD_PARTICIPANT_SR:
         return [...action.data];
      case SET_PARTICIPANT_SR:
         return action.data;
      case EDIT_PARTICIPANT_SR:
         return [...state].map((el) => {
            if (el.nik == action.data.nik) {
               return action.data;
            } else {
               return el;
            }
         });
      case DELETE_PARTICIPANT_SR:
         return state.filter((el) => el.nik != action.data);
      case RESET_PARTICIPANT_SR:
         return state = [];
      default:
         return state;
   }
}

export function updateViewEditParticipantReducerSR(state = initViewEditParticipantState, action) {
   switch (action.type) {
      case SET_VIEW_EDIT_PARTICIPANT_SR:
         return action.data;
      case RESET_VIEW_EDIT_PARTICIPANT_SR:
         return state = '';
      default:
         return state;
   }
}

export function updateIsEditingReducerSR(state = initIsEditingState, action) {
   switch (action.type) {
      case SET_IS_EDITING_SR:
         return state = action.data;
      case RESET_IS_EDITING_SR:
         return state = false;
      default:
         return state;
   }
}

export function updateIsValidSingleRaterReducer(state = initIsValid, action) {
   switch (action.type) {
      case SET_VALID_SETTING:
         return state = {
            ...state,
            setting: action.data
         };
      case SET_VALID_CONTENT:
         return state = {
            ...state,
            content: action.data
         };
      case SET_VALID_PARTICIPANT:
         return state = {
            ...state,
            participant: action.data
         };
      case RESET_VALID_INPUT:
         return state = {
            setting: true,
            content: true,
            participant: true
         };
      default:
         return state;
   }
}

export function ownershipSRReducer(state = initOwnership, action) {
   switch (action.type) {
      case OWNERSHIP_LIST_SR:
         return action.data;
      case DELETE_OWNERSHIP_SR:
         return state.filter((el) => el.email != action.data);
      case RESET_OWNERSHIP_SR:
         return initOwnership;
      default:
         return state;
   }
}


export function updateViewEditParticipantReducerSRByEmail(state = initViewEditParticipantState, action) {
   switch (action.type) {
      case SET_VIEW_EDIT_PARTICIPANT_KEY_EMAIL_SR:
         return action.data;
      case RESET_VIEW_EDIT_PARTICIPANT_KEY_EMAIL_SR:
         return state = '';
      default:
         return state;
   }
}

export function updateLabelDataSettingSingleRaterReducer(state = initSettingLabelDataState, action) {
   switch (action.type) {
      case SET_SETTING_LABEL_DATA_SR:
         return {
            ...state,
            [action.data.field]: action.data.payload
         }
      case RESET_SETTING_LABEL_DATA_SR:
         return state = {
            goal_name:'',
            department_name:'',
            business_unit_name:''
         }
      default:
         return state;
   }
}