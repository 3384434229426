import Lottie from "lottie-react";
import loginLogo from "../../assets/images/logo_horizontal_tc.png";
import loginAnimation from "../../assets/json/login.json";
import { useNavigate } from "react-router-dom";
import { CONSTANTS } from "../../common/constants";
import { useEffect, useState } from "react";
import trendyLogo from "../../assets/images/Trendy-horizontal-color.png";

function SelectUserLogin() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const userName = [
    CONSTANTS.USER.PARTICIPANT,
    CONSTANTS.USER.PIC,
    CONSTANTS.USER.ADMIN,
  ];
  const navigate = useNavigate();

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Membersihkan listener ketika komponen di-unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleLoginAs = (e) => {
    if (e === CONSTANTS.USER.ADMIN) {
      navigate(CONSTANTS.ROUTER.LOGIN_AS_ADMIN);
    } else if (e === CONSTANTS.USER.PIC) {
      navigate(CONSTANTS.ROUTER.LOGIN_AS_PIC);
    } else {
      navigate(CONSTANTS.ROUTER.LOGIN_AS_PARTICIPANT);
    }
  };

  return windowWidth >= 500 && windowHeight >= 500 ? (
    <div className="w-100 d-flex align-items-center ">
      <div className="h-100 col-md-8 d-flex justify-content-around align-items-center flex-column tw-py-[5rem]">
        <div
          className="text-center d-flex flex-column"
          style={{ color: "#7749F8", fontWeight: "bolder" }}
        >
          <div className="d-flex justify-content-center">
          <img src={trendyLogo} alt="trendy" width="470" height="470" />
          </div>
        </div>
        <Lottie
          animationData={loginAnimation}
          className="tw-h-[20rem] tw-drop-shadow-lg"
        />
        <div className="d-flex justify-content-center">
          <img src={loginLogo} alt="techconnect" />
        </div>
      </div>
      <div className="h-100 col-md-4 d-flex align-items-center flex-row tw-bg-slate-50 tw-rounded-l-3xl">
        <div
          className="w-100 d-flex align-items-center tw-p-5 justify-content-center"
          style={{ paddingRight: 0, paddingLeft: 0, minHeight: "70vh" }}
        >
          <div className="card align-items-center p-4 w-75 tw-border-none tw-h-96 tw-bg-slate-50">
            <div className="mb-4 col-12 text-center">
              <h5 style={{ color: "#7749F8", fontWeight: "bold", margin: 0 }}>
                Sign In As
              </h5>
            </div>
            <form className="w-100">
              {userName.map((usr, i) => {
                return (
                  <button
                    className="btn btn-primary mb-4 mt-4 col-12 tw-h-[3rem] tw-font-semibold"
                    key={i}
                    onClick={() => handleLoginAs(usr)}
                  >
                    {usr}
                  </button>
                );
              })}
            </form>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="w-100  align-items-center" style={{overflow:"auto"}}>
      <div className="h-2  d-flex justify-content-around align-items-center flex-column tw-py-[5rem]">
        <div
          className="text-center d-flex flex-column"
          style={{ color: "#7749F8", fontWeight: "bolder" }}
        >
       <div className="d-flex justify-content-center">
          <img src={trendyLogo} alt="trendy" width="270" height="270" />
          </div>
        </div>
        <Lottie
          animationData={loginAnimation}
          className="tw-h-[8rem] tw-drop-shadow-lg"
        />
        <div className="d-flex justify-content-center">
          <img src={loginLogo} alt="techconnect" />
        </div>
      </div>
      <div className="h-30 col-md-4 d-flex align-items-center flex-row bg-white tw-rounded-l-3xl">
        <div
          className="w-100 d-flex align-items-center tw-p-100 justify-content-center"
          style={{ paddingRight: 0, paddingLeft: 0, minHeight: "5vh" }}
        >
          <div className="card align-items-center p-max w-75 tw-border-none tw-h-96">
            <div className="mb-4 col-12 text-center">
              <h5 style={{ color: "#7749F8",  margin: 0 }}>
                Sign In As
              </h5>
            </div>
            <form className="w-100">
              {userName.map((usr, i) => {
                return (
                  <button
                    className="btn btn-primary mb-4 mt-4 col-12 tw-h-[3rem] tw-font-semibold"
                    key={i}
                    onClick={() => handleLoginAs(usr)}
                  >
                    {usr}
                  </button>
                );
              })}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectUserLogin;
