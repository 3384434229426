import { ACTIONS, REPORT_TYPE } from '../constants';
import useFormReportTemplate from '../useFormReportTemplate';
import AddReportTemplateView from '../AddReportTemplateView';
import UpdateReportTemplateView from '../UpdateReportTemplateView';
import ModalCommentsCustom from '../components/modals/ModalCommentsCustom';

const TemplateSingleRaterForm = ({ action, role }) => {
    const { showCommentDialog, setShowCommentDialog } = useFormReportTemplate();
    const handleCommentDialog = () => {};
    const viewProps = {
        role: role,
        formType: REPORT_TYPE.SINGLE,
        pageTitle: action + ' Single-Rater Report Template',
        pageDescription: 'Description'
    };

    return (
        <>
            {action === ACTIONS.CREATE ? (
                <AddReportTemplateView {...viewProps} />
            ) : action === ACTIONS.UPDATE ? (
                <UpdateReportTemplateView {...viewProps} />
            ) : null}

            <ModalCommentsCustom
                show={showCommentDialog}
                onHide={() => {
                    setShowCommentDialog(false);
                }}
                onSave={handleCommentDialog}
            />
        </>
    );
};

export default TemplateSingleRaterForm;
