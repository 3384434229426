export const SurveyHistoryService = ({  doPost }) => {
    

    const getWithSearchSurveyHistory = async (search_text,limit,page_number) => {
        try {
            return await doPost({
                url: '',
                data: {
                        search: search_text,
                        limit: limit,
                        page_number: page_number
                  },                  
            });
        } catch (e) {
            throw e;
        }
    };


    return {
        getWithSearchSurveyHistory
    };
};