import { CONTENTS } from "../../constants";

const CommentData = ({ contentId, title }) => (
    <>
        {contentId === CONTENTS.SR_COMMENTS_ALL && <AllData />}
        {contentId === CONTENTS.MRI_COMMENTS_QUESTION && <TextQuestion />}
        {contentId === CONTENTS.MRI_COMMENTS_CHOICE && <NoAnswerChoice />}
    </>
)

const AllData = () => (
    <div className="px-2">
        {[...Array(3).keys()].map(at => (
            <div className="mb-1 border-bottom ps-2">
                Comment Data Comment Data Comment Data Comment Data
            </div>
        ))}
    </div>
)

const TextQuestion = () => (
    <div className="px-2">
        <div className="fw-bold">Item Pertanyaan: Input Text Question ?</div>
        {[...Array(3).keys()].map(at => (
            <div className="mb-1 border-bottom ps-2">
                Penjelasan Penjelasan Penjelasan Penjelasan Penjelasan {at+1}
            </div>
        ))}
    </div>
)

const NoAnswerChoice = () => (
    <div className="px-2">
        <div className="fw-bold">Item Pertanyaan: No Answer Choice ?</div>
        <div className="mb-1 border-bottom ps-2">
            Tidak Menjawab Tidak Menjawab Tidak Menjawab Tidak Menjawab
        </div>
    </div>
)

export default CommentData;
