import { useState } from "react";
import { Modal, ModalFooter } from "react-bootstrap";
import {  ButtonPrimary } from "../button/Button";
import { InputOnly } from "../input/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faChevronLeft } from "@fortawesome/free-solid-svg-icons";

function FilterModal({show,onHideModal,title,size,filterField}) {
  const [page,setPage] = useState(0)
  const [filterBy, setFilterBy] = useState({})

  const handleFilterBy = (e) => {
    setPage(1);
    setFilterBy(e);
  }

  const handleCloseReset = () => {
    setPage(0);
    onHideModal();
  }

    return(
        <Modal
          size={size}
          show={show}
          onHide={()=>handleCloseReset()}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          
        >
          <Modal.Header closeButton style={{borderRadius:0}}>
            <Modal.Title id="contained-modal-title-vcenter">
                {title} 
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className="p-0" style={{borderBottomColor:'white'}}>
            <div className="card m-0 p-0" style={{borderRadius:0, borderBottomColor:'white'}}>
              {page === 0 ?
              (
                filterField.map((f,i)=>{
                  return(
                
                  <button 
                    className="btn btn-light" 
                    style={{borderRadius:0, textAlign:'left'}} 
                    key={i}
                    onClick={()=>handleFilterBy(f)}>
                  <ul className="list-group list-group-flush" >
                        {f.field_name}
                  </ul>
                  </button>
              
                  )
                })
              )
              :
              (
               <div>
                  <div className="card-header row align-items-center pr-0 pl-1">
                      <div className="col-2" style={{marginRight:0, paddingRight:0}}>
                        <button className="btn btn-light" onClick={()=>setPage(0)}>
                          <FontAwesomeIcon icon={faChevronLeft}/>
                        </button>
                      </div>
                      <div className="col-10" style={{textAlign:'left',margin:0,padding:0}}>
                        {filterBy.field_name}
                      </div>
                      
                  </div>
                  {
                    filterBy.field_type === 'text' ?
                    (
                      <div className="p-2">
                          <InputOnly type={'text'} input_width={'100vw'} style={{marginBottom:8,marginTop:4}} placeholder={`Search ${filterBy.field_name}`}/>
                          <div className="d-flex justify-content-end">
                              <ButtonPrimary label="Search"/>
                          </div>
                         
                      </div>
                    ):
                    (
                      filterBy.field_type === 'date' ? (
                        <div className="p-2">
                          <InputOnly type={'date'} input_width={'100vw'} style={{marginBottom:8,marginTop:4}} placeholder={`Search ${filterBy.field_name}`}/>
                          <div className="d-flex justify-content-end">
                              <ButtonPrimary label="Search"/>
                          </div>
                        </div>
                      ):
                      (
                       <div className="p-2">
                         {filterBy.field_category.map((cat,i)=>{
                          return(
                            <div className="form-check ms-3 m-2" key={i}>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                                id="flexCheckDefault"
                              />
                              <label className="form-check-label" htmlFor="flexCheckDefault">
                                {cat}
                              </label>
                            </div>
                            
                          )
                        })}
                          <div className="d-flex justify-content-end"  style={{marginTop:16,marginBottom:2}}>
                              <ButtonPrimary  label="Search"/>
                          </div>
                          
                       </div>
                      )
                    )
                  }
               </div>
              )
              }
            </div>
          </Modal.Body>
        
          <ModalFooter className="p-1">
          </ModalFooter>
        </Modal>
        )
    
}

export default FilterModal;


