import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const labelMapping = {
  'multi_rater': 'multi rater',
  'single_rater': 'single rater',
  'multiRater': 'multi rater',
  'singleRater': 'single rater'
};

const BarChartSurvey = ({ data, dataKeyX, dataKeysY, onBarClick }) => (
  <ResponsiveContainer width="100%" height={300}>
    <BarChart
      data={data}
      margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      onClick={(e) => {
        try {
          if (e && e.activeLabel) {
            if (typeof onBarClick === 'function') {
              onBarClick(e.activeLabel);
            } else {
              console.error('onBarClick is not a function or not provided');
            }
          }
        } catch (error) {
          console.error('Error handling bar click:', error);
        }
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey={dataKeyX} />
      <YAxis />
      <Tooltip />
      <Legend />
      {dataKeysY.map((key, index) => (
        <Bar
          key={index}
          dataKey={key}
          name={labelMapping[key] || key}  // Apply the label mapping here
          stackId="a"
          fill={index % 2 === 0 ? "#8884d8" : "#82ca9d"}
        />
      ))}
    </BarChart>
  </ResponsiveContainer>
);

export default BarChartSurvey;
