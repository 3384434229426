import { useEffect, useState } from "react";
import { useDeps } from "../../../../../shared/context/DependencyContext";
import screenToast from "../../../../../shared/hook/ScreenToast";

function useImportFile() {
   const { generalService } = useDeps();
   const [niks, setNiks] = useState([]);
    const [alt, setAlt] = useState([]);
   const showToast = screenToast();

   useEffect(() => {
      getAllNiks();
      getAllUsersMgmtAlt();
   }, []);

  

   const getAllNiks = async () => {
    try {
        const response = await generalService.generalDataService.getAllUsersMgmt();
        if (response.status === 'SUCCESS') {
            setNiks(response.data);
            // console.log(response.data)
        }
    } catch (error) {
        console.error('Error fetching all niks:', error);
    }
};

const getAllUsersMgmtAlt = async () => {
 try {
     const response = await generalService.generalDataService.getAllUsersMgmtAlt();
     if (response.status === 'SUCCESS') {
         setAlt(response.data);
        //  console.log(response.data)
     }
 } catch (error) {
     console.error('Error fetching target Users Alt:', error);
 }
};

   return {
    niks,
    alt,
   }
}

export default useImportFile;