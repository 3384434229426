import { useState } from "react";
import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useDeps } from "../../../../shared/context/DependencyContext";
import { rootAction } from "../../../../redux/RootAction";
import { clearParticipantAccountData } from "../../../../common/utils";
import { CONSTANTS } from "../../../../common/constants";
import screenToast from "../../../../shared/hook/ScreenToast";
import useViewState from "../../../../shared/hook/UseViewState";
import screenAlert from "../../../../shared/hook/SweetAlert";

function useSingleRaterForm() {
  const { participantService } = useDeps();
  const [surveyDetailDataSR, setSurveyDetailDataSR] = useState();
  const accountData = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { viewState, setError, setSuccess } = useViewState();
  const showToast = screenToast();
  const showAlert = screenAlert();


  useEffect(() => {
    if (viewState.isError == true) {
        showToast.error(viewState.errorMsg);
        setError(false, '');
    }

    if (viewState.isSuccess == true) {
        showToast.success(viewState.successMsg);
        setSuccess(false, '');
    }
}, [viewState]);


  const onGetDetailSurveyDataSR = async (survey_code) => {
    try {
      const response =
        await participantService.singleRaterSurveyFormService.getDetailSurveyFormDataSingleRater(
          survey_code
        );

      if (response.status == "SUCCESS") {
        setSurveyDetailDataSR(response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      // console.log('final onGetDetailSurveyDataSR')
    }
  };

  const handleLogout = async () => {
    clearParticipantAccountData(dispatch);
    dispatch(rootAction.user.userLogoutAction());
    navigate(CONSTANTS.ROUTER.LOGIN_AS_PARTICIPANT, { replace: true });

    }

  const handleSubmitManyAnswerSR = async (data,is_auto_login) => {
    // console.log('data answer many',JSON.stringify(data));
    // console.log('data answer many data', data);
    try {
      const response =
        await participantService.singleRaterSurveyFormService.postManyAnswerSingleRater(
          data
        );

      if (response.status == "SUCCESS") {
        // hapus redux dr many answer
        dispatch(rootAction.singleSurveyForm.deleteAllManyAnswerSR());
        dispatch(rootAction.singleSurveyForm.clearDataParticipantRaterSRForm());
        if (is_auto_login) {
          handleSuccessAndLogout()
                setSuccess(true, 'Submitted Successfully')

          } else {
                handleSuccessAndBack()
                setSuccess(true, 'Submitted Successfully')

        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      // console.log('final onGetDetailSurveyDataSR')
    }
  };

  const handleSuccessAndBack = () => {
    showAlert.success({
      title: "Success",
      text: "Your survey has been successfully submitted!",
      buttons: {
          confirm: {
              text: "Back To Survey List",
              className: "bg-success",
              value: true
          }
      }
      }).then(value => {
          if (value) {
            navigate(-1)
          }
      });
}


        const handleSuccessAndLogout = () => {
        showAlert.success({
          title: "Success",
          text: "Your survey has been successfully submitted!",
          buttons: {
              confirm: {
                  text: "Exit",
                  className: "bg-success",
                  value: true
              }
          }
          }).then(value => {
              if (value) {
                handleLogout();
              }
          });
        }

  return {
    surveyDetailDataSR,
    handleSubmitManyAnswerSR,
    onGetDetailSurveyDataSR,
  }
}

export default useSingleRaterForm;
