import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { ButtonPreview } from "../../../../shared/component/button/Button";
import { DropdownIcon } from "../../../../shared/component/dropdown/DropdownInput";
import FilterModal from "../../../../shared/component/modal/FilterModal";
import { SearchColumn } from "../../../../shared/component/table_property/SearchColumn";
import { ShowEntries } from "../../../../shared/component/table_property/ShowEntries";
import { Dropdown } from "react-bootstrap";
import { invite } from "../../multirater_list/MultiraterListView";
import useTabMonitoring from "./useTabMonitoring";
import Pagination from "../../../../shared/component/table_property/Paginatination";
import { useEffect } from "react";
import { CONSTANTS } from "../../../../common/constants";
import LoadingBar from "react-top-loading-bar";

function TabMonitoringView({survey_code}) {
    const {
        allTP,
        pageNumber,
        totalItem,
        setPageNumber,
        setShowItem,
        showItem,
        setTotalItem,
        searchText,
        setSearchText,
        handleInputSearchChange,
        handleSearchData,
        handleshowItem,
        completion,
        onGetAllTP,
        progress,
      setProgress
        
    } = useTabMonitoring();

    useEffect(()=>{
        if (survey_code != undefined || survey_code != null) {
            onGetAllTP(survey_code)
        }
    },[survey_code])

    const paginate = (pageNumber) => {
        setPageNumber(pageNumber);
     };
    
     const previousPage =  () => {
          if (pageNumber != 1) {
            setPageNumber(pageNumber - 1);
            
          }
      };
    
      const nextPage = () => {
          if (pageNumber != Math.ceil(totalItem/ showItem)) {
            setPageNumber(pageNumber + 1);
          }
      };

      
    

    return (
      <>
       <LoadingBar color="#7749F8" progress={progress} onLoaderFinished={() => setProgress(0)} />

            <div>
                {/* <h6 className="mb-4">Monitoring Project :</h6>
                
                <ButtonPreview label="Nominations"/> */}
                
                <h6 className="my-4">Monitoring Individu :</h6>

                <div className="">
                        <div className='row justify-content-between mb-2'>
                            <div className='col-4 d-flex flex-row align-items-center'>
                                <div>
                                    Show
                                </div>

                                <ShowEntries onClick_item={handleshowItem} default_item={showItem}/>

                                <div className=''>
                                    entries
                                </div>
                            </div>

                            <div className='col-3 d-flex align-items-center'>
                            <SearchColumn onChange_input={handleInputSearchChange} onClick_btn={handleSearchData} />
                            </div>
                        </div>


                        <div className='table-responsive mt-1 '>
                            <table className="table  table-bordered table-sm">
                                <thead>
                                    <tr>
                                        <th rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }} >No</th>
                                        <th rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>Target Participant <br></br> Name & Email</th>
                                     

                                        <th colSpan={3} style={{ verticalAlign: 'middle', textAlign: 'center' }}>Raters</th>
                                        <th rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>Completion</th>
                                    </tr>

                                    <tr>
                                        <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>Relationship</th>
                                        <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>Name & Email</th>
                                        <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>Status</th>
                                    
                                    </tr>

                                </thead>
                                
                                {   Array.isArray(allTP) && allTP.length != 0 ?
                                    allTP.map((dt, i) => {
                                        const persen = completion(dt.raters);
                                        return (
                                            <tbody key={i}>
                                                <tr style={{  verticalAlign: 'middle' }} >
                                                    <td rowSpan={dt.raters.length != 0 ? dt.raters.length : 1} style={{  verticalAlign: 'middle' }}>{i + 1}
                                                    </td>
                                                    <td rowSpan={dt.raters.length != 0 ? dt.raters.length : 1} style={{ verticalAlign: 'middle' }}>
                                                        <span className="tw-font-medium">{dt.full_name}</span>
                                                        <br></br>
                                                        {dt.email ? dt.email.toLowerCase() : "-"}
                                                    </td>
                                                  
                                                    {
                                                        dt.raters[0].role_in_survey_id != undefined? 
                                                        (
                                                            <td style={{ textAlign: 'center' }}>
                                                            {
                                                            dt.raters[0].role_in_survey_id == 1 ?
                                                            'Self' : dt.raters[0].role_in_survey_id == 2 ?
                                                                'Supervisor' : dt.raters[0].role_in_survey_id == 3 ?
                                                                    'Subordinate' : dt.raters[0].role_in_survey_id == 4 ?
                                                                        'Peer' : dt.raters[0].role_in_survey_id == 5 ?
                                                                            'Stakeholder' : 'Other'}
                                                            </td>
                                                        ) : <td>-</td>
                                                    }
                                                
                                                    <td>
                                                        <span className="tw-font-medium">{dt.raters[0].full_name}</span>
                                                        <br></br>
                                                        {dt.raters[0].email ? dt.raters[0].email.toLowerCase() : "-"}
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        {dt.raters[0].status == 'done' || dt.raters[0].status == 'Done' || dt.raters[0].status == 'DONE' ? dt.raters[0].status : '-'}
                                                    </td>
                                                    <td rowSpan={dt.raters.length != 0 ? dt.raters.length : 1} style={{ textAlign: 'center' }}>
                                                        {persen} %
                                                    </td>

                                                    {/* <td >
                                                            <DropdownIcon
                
                                                            icon_default={faEnvelope}
                                                            size_icon={'xl'}
                                                        
                                                            dropdown_item= {
                                                                invite.map((inv,i)=>{
                                                                    return(
                                                                        <Dropdown.Item key={i}>
                                                                            <div className="d-flex">
                                                                                <div className="me-2">
                                                                                {inv.icon}
                                                                                </div>
                                                                                <div>
                                                                                    {inv.label}
                                                                                </div>
                                                                            </div>
                                                                        </Dropdown.Item>
                                                                    )
                                                                })
                                                            }

                                                            />
                                                    </td> */}

                                                    
                                                </tr>


                                                { Array.isArray(dt.raters) && dt.raters.length != 0 &&
                                                    dt.raters.map((rat, j) => {
                                                        if (j > 0) {
                                                            return (
                                                                <tr key={j} style={{ verticalAlign: 'middle' }}>
                                                                    {
                                                                        rat.role_in_survey_id != undefined? 
                                                                        (
                                                                            <td style={{ textAlign: 'center' }}>
                                                                            {
                                                                            rat.role_in_survey_id == 1 ?
                                                                            'Self' :  rat.role_in_survey_id == 2 ?
                                                                                'Supervisor' :  rat.role_in_survey_id == 3 ?
                                                                                    'Subordinate' :  rat.role_in_survey_id == 4 ?
                                                                                        'Peer' :  rat.role_in_survey_id == 5 ?
                                                                                            'Stakeholder' : 'Other'}
                                                                            </td>
                                                                        ) : <td>-</td>
                                                                    }
                                                                    <td>
                                                                        <span className="tw-font-medium">{rat.full_name}</span>
                                                                        <br></br>
                                                                        {rat.email ? rat.email.toLowerCase() : "-"}
                                                                    </td>
                                                                    <td style={{ textAlign: 'center' }}>
                                                                        
                                                                        {rat.status == 'done' || rat.status == 'Done' ||rat.status == 'DONE' ? rat.status : '-'}
                                                                    </td>

                                                                </tr>
                                                            )
                                                        }
                                                    })
                                                }
                                            </tbody>)
                                        
                                    }) :
                                    <tbody>
                                        <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                            <td colSpan={7}>No data</td>
                                        </tr>
                                    </tbody>
                                    }
                            

                            </table>
                        </div>
                        <Pagination showing={Array.isArray(allTP) ? allTP.length : 0} totalItem={totalItem ? totalItem : 0} showItem={showItem} previousPage={previousPage} nextPage={nextPage} paginate={paginate} limit={pageNumber}/>

                    </div>

            </div>
      </>
    )
}

export default TabMonitoringView;


