import { Outlet } from 'react-router-dom';
import { NavbarParticipant } from '../navbar/NavbarParticipant';

/**
 * This part of code is made using Tailwind.css
 */

export default function ParticipantNav(props) {
   return (
      <>
         <NavbarParticipant />
         <div className='tw-flex tw-w-full tw-flex-col'>
            <div className={`tw-p-4 tw-py-4 tw-pt-[5rem] tw-flex tw-w-full 
                           tw-justify-center tw-h-full tw-overflow-auto tw-bg-slate-50`}>
               <Outlet />
            </div>
         </div>
      </>
   )
}