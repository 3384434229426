export const PICDashboardService = ({  doPost }) => {

    const getProjectListData = async (search,created_by,survey_type, limit, page_number) => {
        try {
           return await doPost({
              url: '/pic/get_list_survey_by_user',
              data: {
                 search: search,
                 created_by: created_by,
                 survey_type: survey_type,
                 limit: limit,
                 page_number: page_number
              }
           });
        } catch (err) {
           throw err;
        }
     }

     return {
        getProjectListData
     }
};