import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const Top5Chart = ({ data, title, yAxisKey }) => {
  const isEmptyData = !Array.isArray(data) || data.length === 0;

  // Fungsi untuk memformat label yang ada di Legend dan Tooltip
  const formatLabel = (label) => {
    if (label === 'not_started') return 'not started';
    return label;
  };

  return (
    <div style={{ width: '100%', height: 400 }}>
      <h3>{title}</h3>
      {isEmptyData ? (
        <div style={{ textAlign: 'center', marginTop: 50 }}>
          <p>Data tidak tersedia.</p>
        </div>
      ) : (
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            layout="vertical"
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" />
            <YAxis
              type="category"
              dataKey={yAxisKey}
              tickFormatter={(value) => value || "No Data"} // Menangani null values
              tick={{ fontSize: 11 }}
            />
            <Tooltip formatter={(value, name) => [value, formatLabel(name)]} />
            <Legend formatter={formatLabel} />
            <Bar dataKey="done" fill="#82ca9d" name="Done" />
            <Bar dataKey="not_started" fill="#8884d8" name="not started" />
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default Top5Chart;
