import { useDispatch, useSelector } from "react-redux";
import { ButtonOutlinePrimary, ButtonPrimary } from "../../../../shared/component/button/Button";
import SectionCard from "../../../../shared/component/card/SectionCard";
import { Footer, FooterPreview } from "../../../layout/navbar/Footer";
import useMultiRaterForm from "../useMultiRaterForm";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { rootAction } from "../../../../redux/RootAction";
import screenAlert from "../../../../shared/hook/SweetAlert";

function FinishFormView({onClickPrev, preview=false,finish_text,is_auto_login=false}) {
    const manyAnswerReducer = useSelector((state) => state.insertManyAnswer);
    const mandatoryContentList = useSelector((state) => state.mandatoryContentList);
    const {handleSubmitManyAnswer} = useMultiRaterForm();
    const navigate = useNavigate();
    // const dispatch = useDispatch();
    const showAlert = screenAlert();

    // useEffect(()=>{
    //     console.log('mandatory list finish form', mandatoryContentList);
    // })

    const handleSubmit = () => {
        showAlert.warning({
            title: "Warning",
            text: "Are you sure to submit this survey?",
            buttons: {
                cancel: {
                    text: "Cancel",
                    className: "bg-white text-dark"
                },
                confirm: {
                    text: "Submit",
                    className: "bg-success",
                    value: true
                }
            },
            isDanger: true
        }).then(value => {
            if (value) {
                // console.log('SUBMIT MANY ANSWER', JSON.stringify(manyAnswerReducer));
                handleSubmitManyAnswer(manyAnswerReducer,is_auto_login)
            }
        });
       }

    


    if (preview) {
        return (
            <div>
                <div className="tw-p-4 tw-py-4 tw-pb-[5rem]  tw-w-full 
                                tw-justify-center tw-h-full tw-overflow-auto tw-bg-slate-50">
                    <SectionCard
                        titleCard={`FINISH`}
                        descCard={finish_text}
                    />
                    <div className="mt-4 d-flex justify-content-center" >
                        <ButtonOutlinePrimary label="Back" onClick={()=>navigate(-1)}/>
                    </div>
                   
                </div>
                <FooterPreview
                    disablePrev={false} disableNext={true} onClickPrev={onClickPrev}
                />
            </div>
        )
    } else {
        return (
            <>
                <div className="tw-p-4 tw-py-4 tw-pb-[5rem]  tw-w-full 
                                tw-justify-center tw-h-full tw-overflow-auto tw-bg-slate-50">
                    <SectionCard
                        titleCard={'FINISH'}
                        descCard={finish_text}
                    />
    
                    <div className="mt-4 ">
                    {
                    Array.isArray(mandatoryContentList) && mandatoryContentList.length != 0 ? 
                    <div className="d-flex  justify-content-center mb-4" style={{color:'red'}}>
                      Your answers are not complete.  
                     <div className="mx-2" style={{fontWeight:'bold'}}>
                     Please check again, make sure all the mandatory questions have been answered.
                     </div>
                    </div> 
                    : <div></div>
                  }
                    
                    <div className="d-flex  justify-content-center">
                    <ButtonPrimary disabled={Array.isArray(mandatoryContentList) && mandatoryContentList.length != 0 ? true : false} label="Submit and Finish" onClick={()=>handleSubmit()}/>
                    </div>
    
                    
                    </div>
                   
                    
                </div>
    
                
    
                <Footer
                    disablePrev={false} disableNext={true} onClickPrev={onClickPrev}
                />
            </>
        )
    }

    
}

export default FinishFormView;