import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Container } from 'react-bootstrap';
import LoadingBar from 'react-top-loading-bar';
import { rootAction } from '../../redux/RootAction';
import { clearReportTemplateRedux } from '../../common/utils';
import { CONSTANTS } from '../../common/constants';
import useRouting from './utils/useRouting';
import { useDeps } from '../../shared/context/DependencyContext';
import useFormReportTemplate from './useFormReportTemplate';
import { alertDiscard, alertSave } from './utils/alertInfo';
import screenAlert from '../../shared/hook/SweetAlert';
import screenToast from '../../shared/hook/ScreenToast';
import { ButtonOutlinePrimary } from '../../shared/component/button/Button';
import RowUnderLine from './components/RowUnderline';
import {
    FormPageHeader,
    FormPageInput,
    FormPageBorder,
    FormPageContent
} from './FormPageTemplate';

const AddReportTemplateView = ({
    role,
    formType,
    pageTitle,
    pageDescription,
    children
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { adminService } = useDeps();
    const user = useSelector((state) => state.user);
    const reportTemplate = useSelector((state) => state.reportTemplate);
    const reportUrl = useSelector((state) => state.reportTemplateUrl);
    const [progress, setProgress] = useState(0);
    const showAlert = screenAlert();
    const showToast = screenToast();
    const { urlPreviewTemplate, urlFromState } = useRouting(role);

    const {
        toggleDnd,
        formSections,
        setFormSections,
        handleSectionUpdate,
        handleSectionChangeTheme,
        handleSectionRemove,
        handleSectionDragEnd,
        handleAddFormElement,
        handleToggleOrderEdit
    } = useFormReportTemplate();

    // Event handler
    const handleNavigateBack = (e) => {
        const [url, state] = urlFromState(reportUrl);
        navigate(url, state);
    };

    const handleDiscardTemplate = (e) => {
        e.preventDefault();

        showAlert.info(alertDiscard).then((value) => {
            if (value) {
                dispatch(rootAction.reportTemplate.discardTemplate());
            }
        });
    };

    const handlePreview = (e) => {
        const [url, state] = urlPreviewTemplate();
        navigate(url, { state: state });
    };

    const handleSaveTemplate = async (e) => {
        e.preventDefault();

        showAlert.info(alertSave).then((value) => {
            if (!value) {
                return;
            }

            (async () => {
                setProgress(10);
                try {
                    // user.role == CONSTANTS.ROLE.ADMIN
                    const response = await adminService.reportTemplateService.saveData({
                        ...reportTemplate,
                        created_by: user.email,
                        user_role: reportUrl.survey_code ? '2' : '1'
                    });
                    setProgress(60);
                    if (!response) {
                        showToast.error('Something went wrong. Please try again later.');
                        return;
                    }

                    if (response.status === CONSTANTS.API.SUCCESS) {
                        clearReportTemplateRedux(dispatch);
                        setFormSections([]);
                        showToast.success(response.status);

                        if (
                            [CONSTANTS.ROLE.ADMIN, CONSTANTS.ROLE.PIC].includes(user.role)
                        ) {
                            let urlData = {};
                            if (reportUrl.group_option === 1) {
                                urlData = { group_option: 1, group_code: response.data };
                            } else if (reportUrl.individu_option === 1) {
                                urlData = {
                                    individu_option: 1,
                                    individu_code: response.data
                                };
                            }

                            const stateProps = {
                                ...urlData,
                                __prev: reportUrl.__prev,
                                __state: reportUrl.__state
                            };

                            dispatch(rootAction.reportTemplate.setUrlData(stateProps));
                            const [url, state] = urlFromState(stateProps);

                            navigate(url, state);
                        }
                    } else {
                        showToast.error(response.status);
                    }
                } catch (err) {
                    console.log(err);
                    showToast.error('Something went wrong. Please try again later.');
                } finally {
                    setProgress(100);
                }
            })();
        });
    };

    return (
        <Container className="tw-h-max">
            <LoadingBar
                color="#7749F8"
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
            />

            <FormPageHeader
                title={pageTitle}
                description={pageDescription}
                sections={formSections}
                btnToogle={toggleDnd}
                onBackClick={handleNavigateBack}
                onToggleClick={handleToggleOrderEdit}
                onPreview={handlePreview}
                onSaveClick={handleSaveTemplate}
            >
                <div className="me-2">
                    <ButtonOutlinePrimary
                        label={'Discard'}
                        onClick={handleDiscardTemplate}
                    />
                </div>
            </FormPageHeader>

            <FormPageBorder>
                <FormPageInput
                    formName={reportTemplate.name}
                    formLang={reportTemplate.lang}
                />

                <RowUnderLine />

                {children}

                <FormPageContent
                    toggleDnd={toggleDnd}
                    formSections={formSections}
                    formType={formType}
                    onAddSection={handleAddFormElement}
                    onUpdateSection={handleSectionUpdate}
                    onUpdateTheme={handleSectionChangeTheme}
                    onDeleteSection={handleSectionRemove}
                    onDragEnd={handleSectionDragEnd}
                />
            </FormPageBorder>
        </Container>
    );
};

export default AddReportTemplateView;
