import { faFilter, faMagnifyingGlass, faSort } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Dropdown } from "react-bootstrap"
import { useState } from "react"
import FilterModal from "../../shared/component/modal/FilterModal"
import { data_Raters, filTer_Raters, thField_Raters } from "./component/raters_constants"
import { SearchInputTable } from "../../shared/component/input/Input"
import { SearchColumn } from "../../shared/component/table_property/SearchColumn"
import { ShowEntries } from "../../shared/component/table_property/ShowEntries"
import useRatersHistory from "./useRatersHistory"
import Pagination from "../../shared/component/table_property/Paginatination"
import LoadingBar from "react-top-loading-bar"

function RatersHistoryView() {
  // const [openFilter, setOpenFilter] = useState(false);
  const {
    allRatersHistory,
    totalItem,
    handleshowItem,
    showItem,
    handleInputSearchChange,
    handleSearchData,
    pageNumber,
    setPageNumber,
    setSearchText,
    progress,
    setProgress
  } = useRatersHistory();

  const paginate = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const previousPage = () => {
    if (pageNumber != 1) {
      setPageNumber(pageNumber - 1);

    }
  };

  const nextPage = () => {
    if (pageNumber != Math.ceil(totalItem / showItem)) {
      setPageNumber(pageNumber + 1);
    }
  };

  return (
    <>
    <LoadingBar color="#7749F8" progress={progress} onLoaderFinished={() => setProgress(0)} />

<div className="container tw-h-max tw-max-w-[1400px]">
  <div className='m-4 mt-2 d-flex'><h4>Raters History</h4></div>

  <div className='shadow p-4 rounded-3 bg-white'>
    <div className='row justify-content-between'>
      <div className='col-4 d-flex flex-row align-items-center'>
        <div>
          Show
        </div>

        <ShowEntries onClick_item={handleshowItem} default_item={showItem} />

        <div className=''>
          entries
        </div>
      </div>

      <div className='col-3 d-flex align-items-center'>
        {/* <button className="me-2 btn btn-light" onClick={() => setOpenFilter(true)}>
          <FontAwesomeIcon icon={faFilter} />
        </button> */}
        <SearchColumn onChange_input={handleInputSearchChange} onClick_btn={handleSearchData} />
        {/* <FilterModal
          size={'sm'}
          show={openFilter}
          onHideModal={() => setOpenFilter(false)}
          filterField={filTer_Raters}
          title={'Filter By'}
        /> */}
      </div>

    </div>

    <div className='mt-1 table-responsive'>
      <table className="table table-striped table-bordered table-sm">
        <thead>
          <tr>
            {thField_Raters.map((th, i) => {
              return (
                <HeadTable key={i} label={th} />
              )
            })}

          </tr>

        </thead>
        <tbody>
          {Array.isArray(allRatersHistory) &&
            allRatersHistory.length != 0 ?
            allRatersHistory.map((dt, i) => {
              return (<tr key={i} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                {
                  pageNumber == 1 ? <td style={{ textAlign: 'center' }}>{i + 1}</td> : <td style={{ textAlign: 'center' }}>{(i + 1) + (showItem * (pageNumber - 1))}</td>
                }
                <td>{dt.survey_code}</td>
                <td>{dt.type}</td>
                <td>{dt.survey_name}</td>
                <td>{dt.nik}</td>
                <td>{dt.full_name}</td>
                <td>{dt.email != undefined && typeof dt.email == 'string' ? dt.email.toLowerCase() : dt.email}</td>
                <td>{dt.no_hp}</td>
                <td>{dt.business_unit_name}</td>
                <td>{dt.position}</td>
                {/* <td>{dt.level}</td> */}
                <td>{dt.status}</td>
              </tr>)
            }) :
            <tr>
              <td style={{ textAlign: 'center' }} colSpan={11}>No data</td>
            </tr>
          }
        </tbody>
      </table>
    </div>
    <Pagination showing={Array.isArray(allRatersHistory) ? allRatersHistory.length : 0} totalItem={totalItem ? totalItem : 0} showItem={showItem} previousPage={previousPage} nextPage={nextPage} paginate={paginate} limit={pageNumber} />

  </div>

</div>
    </>
  )
}

export default RatersHistoryView;


export function HeadTable({ label }) {
  return (
    <th style={{ textAlign: 'center', verticalAlign: 'middle' }}>
      {label}
    </th>
  )
}