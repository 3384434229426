import { useEffect, useState } from "react";
import { useDeps } from "../../../shared/context/DependencyContext";
import { useSelector } from "react-redux";
import { CONSTANTS } from "../../../common/constants";

function useDuplicateMultirater({ survey_code }) {
   const { adminService, picService } = useDeps();
   const [multiRater, setMultiRater] = useState({});
   const [setting, setSetting] = useState({});
   const [sectionContent, setSectionContent] = useState([]);
   const [scaleChoice, setScaleChoice] = useState([]);
   const [scoreWeight, setScoreWeight] = useState([]);
   const [participant, setParticipant] = useState([]);
   const [error, setError] = useState(false);
   const user = useSelector((state) => state.user);
   const [response, setResponse] = useState();

   useEffect(() => {
      onGetMultiRaterDetail(survey_code);
   }, [survey_code])


   

   /* Multi Rater Data */
   const onGetMultiRaterDetail = async (survey_code) => {
      
      try {
         const response =
            user.role == CONSTANTS.ROLE.ADMIN ? await adminService.multiRaterService.getDetailSurveyMultirater(survey_code) :
               (user.role == CONSTANTS.ROLE.PIC ? await picService.multiRaterService.getDetailSurveyMultiraterPICUrl(survey_code) : null);

         if (response && response.status == 'SUCCESS') {
            if (response.data != 'NO DATA') {
               // Set multi rater from response
               console.log('Duplicate onGetMultiRaterDetail', response);
               setResponse(response);
               setMultiRater({
                  survey_code: '',
                  type: response.data.type,
                  is_deleted: response.data.is_deleted,
                  is_active: response.data.is_active,
                  created_by: user.email,
                  updated_by: ''
               });

               // Set setting from response
               let setting = response.data.setting;
               if (setting.start_date && setting.end_date) {
                  setting.start_date = setting.start_date.split("T")[0];
                  setting.end_date = setting.end_date.split("T")[0];
               } else {
                  setting.start_date = '';
                  setting.end_date = '';
               }
               setSetting(response.data.setting);

               // Set section and scale choice from response
               let section = response.data.section;
               let scaleChoice = [];
               let scaleContent = [];
               section.forEach((sec, i) => {
                  sec.section_id = i + 1;
                  sec.section_code = '';
                  let isUsingScale = sec.using_scale == 1 ? true : false;

                  if (sec.content.length > 0) {
                     sec.content.forEach((con, j) => {
                        con.content_id = j + 1;
                        con.content_code = '';

                        if (con.content_choice.length > 0) {
                           scaleContent = isUsingScale ? { section_id: sec.section_id, content_choice: [] } : null;

                           if (isUsingScale && scaleContent) {
                              let newContentChoice = con.content_choice;
                              newContentChoice.forEach((newCho, k) => {
                                 newCho.scale_id = k + 1;
                                 newCho.choice_code = '';
                                 newCho.choice_score = k + 1;
                                 scaleContent.content_choice.push(newCho);
                              });

                              con.content_choice = [];
                           } else {
                              con.content_choice.forEach((cho, k) => {
                                 cho.choice_id = k + 1;
                                 cho.choice_code = '';
                              });
                           }


                        }
                     });

                     if (scaleContent) scaleChoice.push(scaleContent);
                  }
               });
               setSectionContent(section);
               setScaleChoice(scaleChoice);

               // Set score weight from response
               setScoreWeight(response.data.score_weight);
            } else {
               setError(true);
            }
         }
      } catch (error) {
         console.log(error)
         setError(true);
      }
   }

   return {
      multiRater,
      setting,
      sectionContent,
      scaleChoice,
      scoreWeight,
      participant,
      error,
      response,
      setResponse
   }
}

export default useDuplicateMultirater;