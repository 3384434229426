import { Table } from 'react-bootstrap';
import data from './displayData.json';
import { CONTENTS } from '../../constants';

const {
    SR_DATA_LIST,
    SR_DATA_LIST_GD,
    SR_DATA_LIST_GDA,
    SR_DATA_LIST_GDAI,
    SR_DATA_SCALE,
    SR_DATA_PARTCP,
    MRG_DATA_LIST,
    MRG_DATA_SCALE,
    MRG_DATA_PARTCP_ALL,
    MRG_DATA_PARTCP_NUM,
    MRG_DATA_LIST_GD,
    MRG_DATA_LIST_GDA,
    MRG_DATA_LIST_GDAI,
    MRI_DATA_LIST,
    MRI_DATA_SCALE,
    MRI_DATA_PARTCP_ALL,
    MRI_DATA_PARTCP_NUM,
    MRI_DATA_LIST_GD,
    MRI_DATA_LIST_GDA,
    MRI_DATA_LIST_GDAI
} = CONTENTS;

const DisplayData = ({ contentId, title, ordered = false }) => (
    <>
        {contentId.startsWith(MRI_DATA_LIST) ||
        contentId.startsWith(SR_DATA_LIST) ||
        contentId.startsWith(MRG_DATA_LIST) ? (
            <DisplayList
                contentId={contentId}
                title={title}
                ordered={ordered}
            />
        ) : [MRI_DATA_SCALE, SR_DATA_SCALE, MRG_DATA_SCALE].includes(
              contentId
          ) ? (
            <DisplayScale />
        ) : [MRI_DATA_PARTCP_ALL, SR_DATA_PARTCP, MRG_DATA_PARTCP_ALL].includes(
              contentId
          ) ? (
            <DisplayParticipant contentId={contentId} />
        ) : [MRI_DATA_PARTCP_NUM, MRG_DATA_PARTCP_NUM].includes(contentId) ? (
            <DisplayNumParticipant />
        ) : null}
    </>
);

const DisplayScale = () => {
    return (
        <div className="fw-light text-center col-md-5 mx-auto">
            <Table striped bordered hover size="sm" className="fw-light">
                <tbody>
                    {[1, 2, 3, 4].map((sc) => (
                        <tr>
                            <td>{sc}</td>
                            <td>Scale {sc}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

const DisplayParticipant = ({ contentId }) => {
    const start = new Date('2024-01-01');
    const end = new Date('2024-06-01');

    return (
        <div className="fw-light text-center col-md-5 mx-auto">
            <Table striped bordered hover size="sm" className="fw-light">
                <thead>
                    <tr>
                        <th>
                            {contentId === MRG_DATA_PARTCP_ALL
                                ? 'Name Target Participant'
                                : 'Raters Name'}
                        </th>
                        <th>
                            {contentId === MRG_DATA_PARTCP_ALL
                                ? 'Date of Completed Review'
                                : 'Date of Review'}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {[1, 2, 3, 4, 5, 6, 7].map((sc) => (
                        <tr>
                            <td>Rater no {sc}</td>
                            <td>
                                {new Date(
                                    start.getTime() +
                                        Math.random() *
                                            (end.getTime() - start.getTime())
                                ).toDateString()}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

const DisplayNumParticipant = () => {
    const list = [
        ['Self', 1],
        ['Subordinate', 2],
        ['Peer', 5],
        ['Supervisor', 1],
        ['Total', 9]
    ];

    return (
        <div className="fw-light text-center col-md-6 mx-auto">
            <Table striped bordered hover size="sm" className="fw-light">
                <thead>
                    <tr>
                        <th>Rater Type</th>
                        <th>Nominated</th>
                        <th>Responded</th>
                        <th>Response Rate</th>
                    </tr>
                </thead>
                <tbody>
                    {Array.from(list).map((rt) => (
                        <tr>
                            <td>{rt[0]}</td>
                            <td>{rt[1]}</td>
                            <td>{rt[1]}</td>
                            <td>100%</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

const DisplayList = ({ contentId, title, ordered }) => {
    /*const genGroup = Array.from([1,2,3],  (id) => `Group ${id}`)
    const genDim = Array.from([1,2,3,1,2,3], (id) => `Dimension ${id}.${id%2?1:2}`)
    const genAspect = Array.from([1,2,1,2,1,2], (id, idx) => `Aspect ${idx+1}.${id%2?1:2}`)
    const genItem = Array.from(Array(5).keys(), (_, idx) => `Item ${idx+1}`)
    const genLvl5 = Array.from(genGroup).map((g, idx) => {
        return [g, ...Array.from([1,2], (id) => `Dimension ${idx+1}.${id}`)]
    })
    const genLvl6 = Array.from(genLvl5).map(gd => {
        return [...gd, genAspect[0], genAspect[1]]
    })
    const genLvl7 = Array.from(genLvl6).map(gda => [...gda, genItem[0], genItem[1]])

    const data = {
        '1.11.1.1': genGroup,
        '2.4.1.1': genGroup,
        '3.4.1.1': genGroup,
        '1.11.1.2': genDim,
        '2.4.1.2': genDim,
        '3.4.1.2': genDim,
        '1.11.1.3': genAspect,
        '2.4.1.3': genAspect,
        '3.4.1.3': genAspect,
        '1.11.1.4': genItem,
        '2.4.1.4': genItem,
        '3.4.1.4': genItem,
        '1.11.1.5': genLvl5, // G - D
        '2.4.1.5': genLvl5, // G - D
        '3.4.1.5': genLvl5, // G - D
        '1.11.1.6': genLvl6, // G - D - A
        '2.4.1.6': genLvl6, // G - D - A
        '3.4.1.6': genLvl6, // G - D - A
        '1.11.1.7': genLvl7, // G - D - A - I
        '2.4.1.7': genLvl7, // G - D - A - I
        '3.4.1.7': genLvl7, // G - D - A - I
    }*/

    const isMultiLevel = [
        MRI_DATA_LIST_GD,
        MRI_DATA_LIST_GDA,
        MRI_DATA_LIST_GDAI,
        MRG_DATA_LIST_GD,
        MRG_DATA_LIST_GDA,
        MRG_DATA_LIST_GDAI,
        SR_DATA_LIST_GD,
        SR_DATA_LIST_GDA,
        SR_DATA_LIST_GDAI
    ].includes(contentId);

    const isLevel6 = [
        MRI_DATA_LIST_GDA,
        MRI_DATA_LIST_GDAI,
        MRG_DATA_LIST_GDA,
        MRG_DATA_LIST_GDAI,
        SR_DATA_LIST_GDA,
        SR_DATA_LIST_GDAI
    ].includes(contentId);

    const isLevel7 = [
        MRI_DATA_LIST_GDAI,
        MRG_DATA_LIST_GDAI,
        SR_DATA_LIST_GDAI
    ].includes(contentId);

    const list = data[contentId];

    const style = ordered
        ? {
              fontSize: '13px',
              listStyle: 'decimal inside'
          }
        : { fontSize: '13px' };

    return (
        <div className="px-3 py-1">
            {title && <h6>{title}</h6>}
            <ul className="list-unstyled mb-0" style={style}>
                {isMultiLevel
                    ? list.map((item) => (
                          <li>
                              {item[0]}
                              <List>
                                  {Array.from([1, 2]).map((idx) => (
                                      <li>
                                          {item[idx]}
                                          {isLevel6 ? (
                                              <List>
                                                  <li>
                                                      {item[idx + 2]}
                                                      {isLevel7 ? (
                                                          <List>
                                                              <li>
                                                                  {
                                                                      item[
                                                                          idx +
                                                                              4
                                                                      ]
                                                                  }
                                                              </li>
                                                              <li>
                                                                  {
                                                                      item[
                                                                          idx +
                                                                              5
                                                                      ]
                                                                  }
                                                              </li>
                                                          </List>
                                                      ) : null}
                                                  </li>
                                              </List>
                                          ) : null}
                                      </li>
                                  ))}
                              </List>
                          </li>
                      ))
                    : list.map((item) => <li>{item}</li>)}
                <li>...</li>
            </ul>
        </div>
    );
};

const List = ({ children }) => (
    <ul className="list-unstyled ms-4">{children}</ul>
);

export default DisplayData;
