import { rootAction } from "../redux/RootAction";

export const getImagePath = (image_path) => {
  return (
    process.env.REACT_APP_BASE_URL + "/admin/get_intro_image/" + image_path
  );
};

export const clearAddMultiraterRedux = (dispatch) => {
  dispatch(rootAction.multiRater.resetContentView());
  dispatch(rootAction.multiRater.resetMultiRater());
  dispatch(rootAction.multiRater.resetParticipant());
  dispatch(rootAction.multiRater.resetScaleChoice());
  dispatch(rootAction.multiRater.resetScore());
  dispatch(rootAction.multiRater.resetSection());
  dispatch(rootAction.multiRater.resetSetting());
  dispatch(rootAction.multiRater.resetViewEditParticipant());
  dispatch(rootAction.multiRater.resetViewTab());
  dispatch(rootAction.multiRater.resetSettingLabelDataMR());
}

export const createTimestamp = () => {
  let newDate = new Date()
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  let hour = newDate.getHours();
  let minute = newDate.getMinutes();

  return `${year}${month}${date}${hour}${minute}`
}

export const clearUpdateMultiraterRedux = (dispatch) => {
  dispatch(rootAction.updateMultiRater.resetViewTab());
  dispatch(rootAction.updateMultiRater.resetMultiRater());
  dispatch(rootAction.updateMultiRater.resetSetting());
  dispatch(rootAction.updateMultiRater.resetContentView());
  dispatch(rootAction.updateMultiRater.resetSection());
  dispatch(rootAction.updateMultiRater.resetScore());
  dispatch(rootAction.updateMultiRater.resetParticipant());
  dispatch(rootAction.updateMultiRater.resetScaleChoice());
  dispatch(rootAction.updateMultiRater.resetViewEditParticipant());
  dispatch(rootAction.updateMultiRater.resetIsEditingAction());
  dispatch(rootAction.updateMultiRater.resetSettingLabelDataMR());

}

export const clearAddSingleraterRedux = (dispatch) => {
  dispatch(rootAction.singleRater.resetContentViewSR());
  dispatch(rootAction.singleRater.resetSingleRater());
  dispatch(rootAction.singleRater.resetParticipantSR());
  dispatch(rootAction.singleRater.resetScaleChoiceSR());
  dispatch(rootAction.singleRater.resetScoreSR());
  dispatch(rootAction.singleRater.resetSectionSR());
  dispatch(rootAction.singleRater.resetSettingSR());
  dispatch(rootAction.singleRater.resetViewEditParticipantSR());
  dispatch(rootAction.singleRater.resetViewTabSR());
  dispatch(rootAction.singleRater.resetSettingLabelDataSR());

}

export const clearUpdateSingleraterRedux = (dispatch) => {
  dispatch(rootAction.updateSingleRater.resetViewTabSR());
  dispatch(rootAction.updateSingleRater.resetSingleRater());
  dispatch(rootAction.updateSingleRater.resetSettingSR());
  dispatch(rootAction.updateSingleRater.resetContentViewSR());
  dispatch(rootAction.updateSingleRater.resetSectionSR());
  dispatch(rootAction.updateSingleRater.resetScoreSR());
  dispatch(rootAction.updateSingleRater.resetParticipantSR());
  dispatch(rootAction.updateSingleRater.resetScaleChoiceSR());
  dispatch(rootAction.updateSingleRater.resetViewEditParticipantSR());
  dispatch(rootAction.updateSingleRater.resetIsEditingActionSR());
  dispatch(rootAction.updateSingleRater.resetSettingLabelDataSR());

  
}

export const clearParticipantAccountData = (dispatch) => {
  dispatch(rootAction.surveyForm.clearDataParticipantRaterMRForm());
  dispatch(rootAction.surveyForm.clearSurveyField());
  dispatch(rootAction.surveyForm.deleteAllManyAnswer());
  dispatch(rootAction.surveyForm.deleteManyAnswer());
  dispatch(rootAction.singleSurveyForm.clearDataParticipantRaterSRForm());
  dispatch(rootAction.singleSurveyForm.clearSurveyFieldSR());
  dispatch(rootAction.singleSurveyForm.deleteAllManyAnswerSR());
  dispatch(rootAction.singleSurveyForm.deleteManyAnswerSR());
  dispatch(rootAction.surveyForm.deleteMRDetailData());
}

export const clearReportTemplateRedux = (dispatch) => {
  dispatch(rootAction.reportTemplate.resetReportTemplate());
}

export const clearReportUrlRedux = (dispatch) => {
  dispatch(rootAction.reportTemplate.resetUrlData());
}
