export const AdminListService = ({  doPost }) => {
    const getAllAdmin = async (limit,page_number) => {
        try {
            return await doPost({
                url: '/admin/get_all_admin',
                data: {
                    limit: limit,
                    page_number: page_number
                },
            });
        } catch (e) {
            throw e;
        }
    };

    const postAdmin = async (nik,name,email,number) => {
        try {
            return await doPost({
                url: '/admin/assign_user_admin',
                data: {
                    nik: nik,
                    name: name,
                    email: email,
                    number: number,
                },
            });
        } catch (e) {
            throw e;
        }
    };

    const checkNIK = async (nik) => {
        try {
            return await doPost({
                url: `/admin/check_user_master_admin`,
                data: {
                    user_nik: nik,
                },
            });
        } catch (e) {
            throw e;
        }
    }

    const deleteAdmin = async (nik,name,email,number) => {
        try {
            return await doPost({
                url: `/admin/delete_user_admin`,
                data: {
                    nik: nik,
                    name: name,
                    email: email,
                    number: number,
                },
            });
        } catch (e) {
            throw e;
        }
    };

    const searchData = async (limit,page_number,search) => {
        try {
            return await doPost({
                url: `/admin/search_admin`,
                data:{
                    limit: limit,
                    page_number: page_number,
                    search_bar: search
                  },
            });
        } catch (e) {
            throw e;
        }
    };

    return {
       getAllAdmin,
       postAdmin,
       deleteAdmin,
       checkNIK,
       searchData
    };
};
