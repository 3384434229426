import { IconButtonDelete } from "../../../../shared/component/button/IconButton";
import { useSelector } from "react-redux";
import usePICProject from "./usePICProject";

function TabPICProjectView() {
  const ownershipList = useSelector((state) => state.ownership_list_mr);
  const userLogin = useSelector((state) => state.user);
  const multiRater = useSelector((state) => state.update_multi_rater);
  const {handleDeletePicProject} = usePICProject();


    return(
        <div className="my-2">
          <div className='mt-1 table-responsive'>
            <table className="table table-striped table-bordered table-sm">
              <thead>
                <tr style={{verticalAlign:'middle',textAlign:'center'}}>
                  <th  >No</th>
                  <th >PIC NIK</th>
                  <th >PIC Name</th>
                  <th  > PIC Email</th>
                  <th  > PIC Number</th>
                  <th  >PIC Role</th>
                  <th >Delete</th>
                </tr>
                
              </thead>
              <tbody>
                  {
                      ownershipList.map((dt,i) => {
                        return (<tr key={i} style={{textAlign:'center', verticalAlign:'middle'}}>
                                <td>{i+1}</td>
                                <td>{dt.nik}</td>
                                <td>{dt.name}</td>
                                <td>{dt.email}</td>
                                <td>{dt.no_hp}</td>
                                <td>{dt.ownership_role}</td>
                                <td><IconButtonDelete onClick={()=>handleDeletePicProject(multiRater.survey_code,dt.email,userLogin.email)}
                                  disabled={userLogin.email == multiRater.created_by ? false : true}
                                  /></td>
                               
                              </tr>)
                      })
                    }
              </tbody>
            </table>
          </div>

      
        </div>
    )
}

export default TabPICProjectView;