import { ButtonOutlinePrimary, ButtonPrimary } from "../../../../shared/component/button/Button";
import { useState } from "react";
import TabReviewUpload from "./tab_upload/TabReviewUpload";

function ReviewDataUploadTPView() {
    const [saveAllDisabled, setSaveAllDisabled] = useState(true)
    const [tab, setTab] = useState(1);

    const onChangeTab = (ev) => {
        setTab(ev.target.value);
    };
    
    return(
        <div className="container tw-h-max tw-max-w-[1400px]">
            <div className='col-12 m-4  d-flex justify-content-between pe-4'>
              <h4>Review Data Upload</h4>
              <div className="d-flex">
                    <div className="mx-2">
                        <ButtonOutlinePrimary label="Back" />
                    </div>
                    <div>
                        <ButtonPrimary label="Save All" 
                        disabled={saveAllDisabled}
                        />
                    </div>
                </div>
            </div>
            <div className="fst-italic mb-4">
                Make sure all data is filled in correctly so it can be saved
            </div>

           
            <div className='card shadow' style={{borderLeft:0,borderBottom:0,borderRight:0,minHeight:'70vh'}}>

                <div className="card-header d-flex p-1">
                    <div
                        className="btn-group"
                        role="group"
                        aria-label="Basic radio toggle button group"
                        
                        >
                        <input
                            type="radio"
                            className="btn-check"
                            name="btn-radio"
                            id="btn-same-sap"
                            autoComplete="off"
                            value={1}
                            defaultChecked
                            onChange={onChangeTab}
                            

                        />
                        <label className="btn btn-outline-primary" htmlFor="btn-same-sap" style={{width:'15vw',borderRadius:'0.5rem 0 0 0.5rem'}}>
                          Data Same as SAP
                        </label>
                        <input
                            type="radio"
                            className="btn-check"
                            name="btn-radio"
                            id="btn-non-same-sap"
                            autoComplete="off"
                            value={2}
                            onChange={onChangeTab}
                        />
                        <label className="btn btn-outline-primary" htmlFor="btn-non-same-sap" style={{width:'15vw',borderRadius:0}}>
                        Data is not the same as SAP 
                        </label>
                        <input
                            type="radio"
                            className="btn-check"
                            name="btn-radio"
                            id="btn-not-in-sap"
                            autoComplete="off"
                            value={3}
                            onChange={onChangeTab}
                        />
                        <label className="btn btn-outline-primary" htmlFor="btn-not-in-sap" style={{width:'15vw',borderRadius:'0 0.5rem 0.5rem 0'}}>
                        Not Found in SAP
                        </label>
                    </div>
                </div>

                <div className="card-body p-4">
                <TabReviewUpload tab={tab} dataUpload={dataTP}/>
                </div>
            </div>
        </div>
    )
}

export default ReviewDataUploadTPView;

const dataTP = [
    {
        name:'',
        nik:'',
        email:'',
        department:'',
        bu:''
    }
]

