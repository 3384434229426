import { Table } from "react-bootstrap";
import { CONTENTS } from "../../constants";

const DemographyData = ({contentId, title}) => {
    const { SR_DEMO_LIST_GENDER } = CONTENTS;

    const columns = {
        '1.10.1': ['< 25 year old', '25 - 29 year old', 'dst'],
        '1.10.2': ['< 5 years', '6 - 10 years', '> 11 years'],
        '1.10.3': ['Dept 1', 'Dept 2', 'Dept 3'],
        '1.10.4': ['Unit 1', 'Unit 2', 'Unit 3'],
        '1.10.5': ['Company 1', 'Company 2', 'Company 3'],
        '1.10.6': ['Laki-Laki', 'Perempuan'],
        '1.10.7': ['Level 1', 'Level 2', 'Level 3'],
    }

    const values = [
        parseInt(Math.random().toString().substring(2,4)),
        parseInt(Math.random().toString().substring(2,4)),
        parseInt(Math.random().toString().substring(2,4)),
    ]
    const total = values[0] + values[1] + (contentId === SR_DEMO_LIST_GENDER ? 0 : values[2])

    return (
        <div className="fw-light text-center col-md-5 mx-auto">
            <Table striped bordered hover  size="sm">
                <thead>
                    <tr>
                        <th>{title}</th>
                        <th>#Respondents</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{columns[contentId][0]}</td>
                        <td>{values[0]}</td>
                    </tr>
                    <tr>
                        <td>{columns[contentId][1]}</td>
                        <td>{values[1]}</td>
                    </tr>
                    {columns[contentId][2] && <tr>
                        <td>{columns[contentId][2]}</td>
                        <td>{values[2]}</td>
                    </tr>}
                </tbody>
                <tfoot>
                    <tr>
                        <th>Grand Total</th>
                        <th>{total}</th>
                    </tr>
                </tfoot>
            </Table>
        </div>
    )
}

export default DemographyData;
