import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const CustomBarChart = ({ title, data,yAxisKey }) => {
  const isEmptyData = !Array.isArray(data) || data.length === 0;    
  // console.log("dari top 5 chart",data)
  return(
  <div style={{ width: '100%', height: 400  }}>
      <h3 >{title}</h3>
      {isEmptyData ? (
        <div style={{ textAlign: 'center', marginTop: 50 }}>
          <p>Data tidak tersedia.</p>
        </div>
      ) : (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={data}
          layout="vertical"
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" />
          <YAxis dataKey={yAxisKey} type="category" tick={{ fontSize: 14 }} />
          <Tooltip />
          <Legend />
          <Bar dataKey="single_rater" name="single rater" stackId="a" fill="#82ca9d" />
          <Bar dataKey="multi_rater" name = "multi rater"  stackId="a"  fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    )}
    </div>
  );
};

export default CustomBarChart;
