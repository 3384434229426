import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import LoadingBar from 'react-top-loading-bar';
import useReportTemplateList from './useReportTemplateList';
import usePagination from './utils/usePagination';
import { rootAction } from '../../redux/RootAction';
import { CONSTANTS } from '../../common/constants';
import { ShowEntries } from '../../shared/component/table_property/ShowEntries';
import { SearchColumn } from '../../shared/component/table_property/SearchColumn';
import { ButtonPrimary } from '../../shared/component/button/Button';
import {
    IconButtonDelete,
    IconButtonEdit
} from '../../shared/component/button/IconButton';
import Pagination from '../../shared/component/table_property/Paginatination';
import screenAlert from '../../shared/hook/SweetAlert';
import { REPORT_TYPE } from './constants';
import ModalAddReport from './components/modals/ModalAddReport';
import useRouting from './utils/useRouting';
import { alertDelete } from './utils/alertInfo';

function ReportTemplateListView() {
    const { showItem, pageNumber, handleShowItem, getIndexNo, paginationProps } =
        usePagination();
    const {
        user,
        searchText,
        progress,
        setProgress,
        allReports,
        totalItem,
        showModal,
        setShowModal,
        changeData,
        handleSearchBar,
        handleChangeSearchText,
        handleNextPage,
        onGetReportTemplateList,
        onDeleteReportTemplate
    } = useReportTemplateList();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const showAlert = screenAlert();
    const {
        pathname,
        urlAddTemplateSR,
        urlAddTemplateMRG,
        urlAddTemplateMRI,
        urlEditTemplateSR,
        urlEditTemplateMRG,
        urlEditTemplateMRI
    } = useRouting(CONSTANTS.ROLE.ADMIN);

    // Handlers
    const handleNavigateNext = (reportType) => {
        if (user.role !== CONSTANTS.ROLE.ADMIN) return;

        dispatch(
            rootAction.reportTemplate.updateTemplate({
                type: reportType,
                code: '',
                name: '',
                lang: ''
            })
        );
        dispatch(rootAction.reportTemplate.setUrlData({ __prev: pathname }));
        if (reportType === REPORT_TYPE.SINGLE) {
            navigate(urlAddTemplateSR());
        } else if (reportType === REPORT_TYPE.MULTI_GROUP) {
            navigate(urlAddTemplateMRG());
        } else if (reportType === REPORT_TYPE.MULTI_INDIVIDU) {
            navigate(urlAddTemplateMRI());
        }
    };
    const handleAddReport = () => {
        setShowModal(true);
    };
    const handleEditReport = (type, code) => {
        if (user.role !== CONSTANTS.ROLE.ADMIN) return;

        const stateProp = { state: { code: code } };

        dispatch(
            rootAction.reportTemplate.setUrlData({
                __prev: pathname,
                __state: stateProp
            })
        );
        if (type === REPORT_TYPE.SINGLE) {
            navigate(urlEditTemplateSR(), stateProp);
        } else if (type === REPORT_TYPE.MULTI_GROUP) {
            navigate(urlEditTemplateMRG(), stateProp);
        } else if (type === REPORT_TYPE.MULTI_INDIVIDU) {
            navigate(urlEditTemplateMRI(), stateProp);
        }
    };
    const handleDeleteReport = (tpl_code) => {
        showAlert.warning(alertDelete).then((value) => {
            if (value) {
                onDeleteReportTemplate(tpl_code);
            }
        });
    };

    // Effect
    useEffect(() => {
        onGetReportTemplateList(searchText, showItem, pageNumber);
    }, [showItem, pageNumber, changeData]);

    return (
        <>
            <LoadingBar
                color="#7749F8"
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
            />

            <div className="container tw-h-max">
                <div className="my-4 d-flex justify-content-between">
                    <h4>Report Template List</h4>
                </div>
                <div className="my-4 d-flex justify-content-between align-items-center">
                    <span className="fst-italic">List all the report templates.</span>
                    <div className="d-flex justify-content-end">
                        <ButtonPrimary label={'Add'} onClick={handleAddReport} />
                    </div>
                </div>

                <div className="card shadow p-4 border-0">
                    <div className="justify-content-between mb-4 d-flex flex-row">
                        <div className="col-4 d-flex flex-row align-items-center">
                            <div>Show</div>
                            <ShowEntries
                                onClick_item={handleShowItem}
                                default_item={showItem}
                            />
                            <div>entries</div>
                        </div>

                        <div className="col-3 d-flex align-items-center">
                            <SearchColumn
                                onChange_input={handleChangeSearchText}
                                onClick_btn={handleSearchBar}
                            />
                        </div>
                    </div>

                    <div className="mt-1 table-responsive">
                        <table className="table table-striped table-bordered">
                            <thead>
                                <tr className="text-center align-middle">
                                    <th>No</th>
                                    <th>Report Type</th>
                                    <th>Report Name</th>
                                    <th colSpan={2}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(allReports) && allReports.length !== 0 ? (
                                    allReports.map((dt, i) => {
                                        return (
                                            <tr
                                                key={i}
                                                className="text-center align-middle"
                                            >
                                                <td>
                                                    {getIndexNo(i, pageNumber, showItem)}
                                                </td>
                                                <td className="text-start">
                                                    {dt.tpl_type}
                                                </td>
                                                <td className="text-start">{dt.name}</td>
                                                {/* <td>
                                                    <IconButtonPreview
                                                        onClick={() =>
                                                            handlePreview(
                                                                dt.tpl_code
                                                            )
                                                        }
                                                    />
                                                </td> */}
                                                <td>
                                                    <IconButtonEdit
                                                        onClick={() =>
                                                            handleEditReport(
                                                                dt.tpl_type,
                                                                dt.tpl_code
                                                            )
                                                        }
                                                        disabled={false}
                                                    />
                                                </td>
                                                <td>
                                                    <IconButtonDelete
                                                        onClick={() =>
                                                            handleDeleteReport(
                                                                dt.tpl_code
                                                            )
                                                        }
                                                        disabled={false}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td
                                            className="text-center align-middle"
                                            colSpan={4}
                                        >
                                            No data
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                    <Pagination
                        {...paginationProps}
                        totalItem={totalItem}
                        nextPage={handleNextPage}
                        showing={Array.isArray(allReports) ? allReports.length : 0}
                    />
                </div>
            </div>

            <ModalAddReport
                show={showModal}
                onHide={() => {
                    setShowModal(false);
                }}
                onSave={handleNavigateNext}
            />
        </>
    );
}

export default ReportTemplateListView;
