import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CONSTANTS } from '../../common/constants';
import { useDeps } from '../../shared/context/DependencyContext';
import usePagination from './utils/usePagination';
import useViewState from '../../shared/hook/UseViewState';
import screenToast from '../../shared/hook/ScreenToast';

const useReportTemplateList = () => {
    const { adminService } = useDeps();
    const user = useSelector((state) => state.user);
    const { showItem, pageNumber, setPageNumber } = usePagination();
    const { viewState, setError, setSuccess } = useViewState();
    const showToast = screenToast();

    // React states
    const [progress, setProgress] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [allReports, setAllReports] = useState([]);
    const [totalItem, setTotalItem] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [changeData, setChangeData] = useState(false);

    // Handlers
    const handleSearchBar = () => {
        onGetReportTemplateList(searchText, showItem, pageNumber);
    };
    const handleChangeSearchText = (e) => {
        setSearchText(e.target.value);
    };
    const handleNextPage = () => {
        if (pageNumber !== Math.ceil(totalItem / showItem)) {
            setPageNumber(pageNumber + 1);
        }
    };

    // Data Handlers
    const onGetReportTemplateList = async (search, limit, page_number) => {
        setProgress(10);
        try {
            setProgress(30);
            if (user.role === CONSTANTS.ROLE.ADMIN) {
                const response =
                    await adminService.reportTemplateService.getListData(
                        search,
                        limit,
                        page_number
                    );
                setProgress(60);

                if (response.status === 'SUCCESS') {
                    setAllReports(response.data.data);
                    setTotalItem(response.data.total_item);
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setProgress(100);
        }
    };
    const onDeleteReportTemplate = async (tpl_code) => {
        try {
            if (user.role === CONSTANTS.ROLE.ADMIN) {
                const response =
                    await adminService.reportTemplateService.deleteData({
                        tpl_code: tpl_code
                    });

                if (response.status === 'SUCCESS') {
                    setChangeData(!changeData);
                    setSuccess(true, 'Deleted Successfully');
                } else {
                    setError(true, response.status);
                }
            }
        } catch (error) {
            console.log(error);
            setError(true, 'Error System');
        }
    };

    // Side Effects
    useEffect(() => {
        if (viewState.isError === true) {
            showToast.error(viewState.errorMsg);
            setError(false, '');
        }

        if (viewState.isSuccess === true) {
            showToast.success(viewState.successMsg);
            setSuccess(false, '');
        }
    }, [viewState]);

    return {
        user,
        progress,
        setProgress,
        searchText,
        setSearchText,
        allReports,
        setAllReports,
        totalItem,
        setTotalItem,
        showModal,
        setShowModal,
        changeData,
        setChangeData,
        handleSearchBar,
        handleChangeSearchText,
        handleNextPage,
        onGetReportTemplateList,
        onDeleteReportTemplate
    };
};

export default useReportTemplateList;
