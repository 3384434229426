import { useEffect, useState } from "react";
import { useDeps } from "../../../shared/context/DependencyContext";
import { data } from "autoprefixer";
import { useDispatch, useSelector } from "react-redux";
import { rootAction } from "../../../redux/RootAction";
import useViewState from "../../../shared/hook/UseViewState";
import screenToast from "../../../shared/hook/ScreenToast";

export const UseInstruction = () => {
  const { participantService, generalService } = useDeps();
  const [instructionDataText, setInstructionDataText] = useState("");
  const [buData, setBuData] = useState([
    { business_unit_name: "OTHER", business_unit_code: "11111111" },
  ]);
  const [departmentData, setDepartmentData] = useState([
    { department_name: "OTHER", department_code: "11111111" },
  ]);
  const [levelData, setLevelData] = useState([{ level_user: "OTHER" }]);
  const dispatch = useDispatch();
  const { viewState, setError, setSuccess } = useViewState();
  const showToast = screenToast();
  const userReducer = useSelector((state) => state.user);
  // const [instructionDataImage, setInstructionDataImage] = useState("");


  useEffect(() => {
    onGetInstructionData();
  }, []);

  useEffect(() => {
    onGetLevelData();
  }, []);

  // useEffect(() => {
  //   onUpdateUserInfo();
  // }, []);

  useEffect(() => {
    if (viewState.isError == true) {
      showToast.error(viewState.errorMsg);
      setError(false, "");
    }

    if (viewState.isSuccess == true) {
      showToast.success(viewState.successMsg);
      setSuccess(false, "");
    }
  }, [viewState]);

  const onGetBUData = async () => {
    try {
      const response =
        await generalService.generalDataService.getBusinessUnitData();

      if (response.status == "SUCCESS") {
        setBuData([...buData, ...response.data]);
      } else {
        setBuData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onGetDepartmentData = async () => {
    try {
      const response =
        await generalService.generalDataService.getDepartmentData();

      if (response.status == "SUCCESS") {
        setDepartmentData([...departmentData, ...response.data]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onGetLevelData = async () => {
    try {
      const response = await generalService.generalDataService.getLevelData();

      if (response.status == "SUCCESS") {
        setLevelData([...levelData, ...response.data]);
      } else {
        setLevelData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onGetInstructionData = async () => {
    // console.log("onGetInstruction");
    try {
      const response =
        await participantService.instructionService.getInstructionTextParticipantUrl();

      if (response.status == "SUCCESS") {
        setInstructionDataText(response.data.intro_text);
        // setInstructionDataImage(response.data.intro_image_link);
        // console.log(response.data);
      } else {
        console.log(`STATUS => ${response.status}`);
      }
      // console.log(response);
    } catch (error) {
      console.log(error);
    } finally {
      // console.log("finally");
    }
  };

  const onUpdateUserInfo = async (
    nik,
    email,
    full_name,
    dob,
    gender,
    join_date,
    no_hp,
    level,
    position,
    business_unit_name,
    department_name,
    is_internal,
    role
  ) => {
 
    try {
      const response =
        await participantService.instructionService.updateUserInfo({
          nik: nik,
          email: email,
          full_name: full_name,
          dob: dob,
          gender: gender,
          join_date: join_date,
          no_hp: no_hp,
          level: level,
          position: position,
          business_unit_name: business_unit_name,
          department_name: department_name,
          is_internal: is_internal,
          role: role,
        });

      if (response.status == "SUCCESS") {
        setSuccess(true,'Saved Successfully');
        dispatch(
          rootAction.user.userLoginAction({
            token: userReducer.token,
            email: email,
            name: full_name,
            role: role,
            nik:nik,           
            dob: dob,
            gender: gender,
            join_date: join_date,
            no_hp: no_hp,
            level: level,
            position: position,
            business_unit_name: business_unit_name,
            department_name: department_name,
            is_internal: is_internal,
          })
        );
      } 
    } catch (error) {
      console.log(error);
    }
  };

  return {
    instructionDataText,
    onGetInstructionData,
    onUpdateUserInfo,
    buData,
    departmentData,
    levelData,
    onGetBUData,
    onGetDepartmentData,
    onGetLevelData
  };
};
