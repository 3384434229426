import { useState } from "react";
import { useEffect } from "react";
import useSingleRaterForm from "./useSingleRaterForm";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { WelcomeSingleRaterView } from "./components/WelcomeSingleRaterView";
import screenAlert from "../../../../shared/hook/SweetAlert";
import { ButtonOutlinePrimary } from "../../../../shared/component/button/Button";
import { CONSTANTS } from "../../../../common/constants";
import { rootAction } from "../../../../redux/RootAction";
import SingleRaterFinishFormView from "./components/SingleRaterFinishFormView";
import SingleSurveyContentView from "./components/SingleRaterSurveyContentView";

function SingleSurveyFormView() {
  const [sec, setSec] = useState(SURVEY_PART.WELCOME);
  const [sectionPageIndex,setSectionPageIndex] = useState(0);
  const {surveyDetailDataSR,onGetDetailSurveyDataSR} = useSingleRaterForm();
  const user = useSelector((state)=>state.user);
  const dispatch = useDispatch();
  const dataParticipantRaterSRForm = useSelector((state)=>state.dataParticipantRaterSRForm);
  const manyAnswerReducer = useSelector((state) => state.insertManyAnswerSR);
  const mandatoryContentList = useSelector((state) => state.mandatoryContentListSR);
  const navigate = useNavigate();
  const showAlert = screenAlert();

  useEffect(()=> {
      onGetDetailSurveyDataSR(dataParticipantRaterSRForm.survey_code);
  },[])

  const checkMandatoryQuestion =  () => {
    for (let i = 0; i < manyAnswerReducer.length; i++) {
      for (let j = 0; j < mandatoryContentList.length; j++) {
        if ((manyAnswerReducer[i].content_type == 'scale' || manyAnswerReducer[i].content_type == 'single_choice' || manyAnswerReducer[i].content_type == 'multiple_choice') && manyAnswerReducer[i].section_code == mandatoryContentList[j].section_code && manyAnswerReducer[i].content_code == mandatoryContentList[j].content_code) {
            // console.log('MANDATORY SCALE', manyAnswerReducer[i], mandatoryContentList[j]);
            dispatch(rootAction.singleSurveyForm.deleteMandatoryContentSR(
                {
                    section_code: mandatoryContentList[j].section_code,
                    content_code: mandatoryContentList[j].content_code,
                }
               
            ))
        } 

        if ( manyAnswerReducer[i].content_type == 'text_input'  
        && manyAnswerReducer[i].section_code == mandatoryContentList[j].section_code 
        && manyAnswerReducer[i].content_code == mandatoryContentList[j].content_code && manyAnswerReducer[i].answer != "" ) {
            // console.log('ANSWER TEXT manyAnswerReducer[i].answer', manyAnswerReducer[i].answer );
            dispatch(rootAction.singleSurveyForm.deleteMandatoryContentSR( {
                section_code: mandatoryContentList[j].section_code,
                content_code: mandatoryContentList[j].content_code,
            }))
        } 
        if ( manyAnswerReducer[i].content_type == 'essay'  
          && manyAnswerReducer[i].section_code == mandatoryContentList[j].section_code 
          && manyAnswerReducer[i].content_code == mandatoryContentList[j].content_code && manyAnswerReducer[i].answer.length >= 100 ) {
              // console.log('ANSWER TEXT manyAnswerReducer[i].answer', manyAnswerReducer[i].answer );
              dispatch(rootAction.singleSurveyForm.deleteMandatoryContentSR( {
                  section_code: mandatoryContentList[j].section_code,
                  content_code: mandatoryContentList[j].content_code,
              }))
          }  
        if ( manyAnswerReducer[i].content_type == 'ranking' && `${manyAnswerReducer[i].content_code}-${manyAnswerReducer[i].choice_code}` == mandatoryContentList[j].content_code) {
                
          dispatch(rootAction.singleSurveyForm.deleteMandatoryContentSR(
              {section_code: mandatoryContentList[j].section_code,
              content_code: mandatoryContentList[j].content_code}
          ))
      }  
      }
}
}

  const handleNextPage = () => {
      if (sectionPageIndex < surveyDetailDataSR.section.length - 1) {
          setSectionPageIndex(sectionPageIndex+1);
      } else if ((sectionPageIndex + 1) == surveyDetailDataSR.section.length) {
        checkMandatoryQuestion();
         setSec(SURVEY_PART.FINISH)
      }
  }

  const handlePrevPage = () => {
      if (sectionPageIndex > 0) {
          setSectionPageIndex(sectionPageIndex-1);
      }else if (sectionPageIndex == 0) {
          setSec(SURVEY_PART.WELCOME)
      }
  }

  const handleFromWelcomeToQuestion = () => {
      setSec(SURVEY_PART.QUESTION);
      setSectionPageIndex(0);

  }

  const handleFinishtoQuestion = () => {
      setSec(SURVEY_PART.QUESTION);
      setSectionPageIndex(surveyDetailDataSR.section.length - 1);
  }

  const handleSurveyDetailData = (field,payload) => {
               dispatch(rootAction.singleSurveyForm.setSurveyFieldSR({
                  field: field,
                  payload: payload
              }));
  }

  useEffect(()=>{
      
    if (surveyDetailDataSR != undefined && mandatoryContentList.length == 0 ) {
     handleAddMandatoryQuestionList()
    }
 
 },[surveyDetailDataSR])

 
  
  useEffect(()=>{
     if (surveyDetailDataSR != undefined) {
      handleSurveyDetailData(CONSTANTS.SURVEY_FORM_REDUX.SURVEY_CODE,surveyDetailDataSR.survey_code);
      handleSurveyDetailData(CONSTANTS.SURVEY_FORM_REDUX.SURVEY_NAME, surveyDetailDataSR.setting.survey_name); 
     }
  
  },[surveyDetailDataSR])

  useEffect(()=>{
      if (dataParticipantRaterSRForm.rater_email != null && dataParticipantRaterSRForm.rater_email != undefined && surveyDetailDataSR != undefined) {
              handleSurveyDetailData(CONSTANTS.SURVEY_FORM_REDUX.PARTICIPANT_EMAIL, dataParticipantRaterSRForm.rater_email);
              handleSurveyDetailData(CONSTANTS.SURVEY_FORM_REDUX.PARTICIPANT_NIK, dataParticipantRaterSRForm.rater_nik);
              handleSurveyDetailData(CONSTANTS.SURVEY_FORM_REDUX.PARTICIPANT_DEPT, dataParticipantRaterSRForm.rater_dept);
              handleSurveyDetailData(CONSTANTS.SURVEY_FORM_REDUX.PARTICIPANT_BU, dataParticipantRaterSRForm.rater_bu);
      }
  },[dataParticipantRaterSRForm,surveyDetailDataSR]);

  useEffect(()=>{
      if (dataParticipantRaterSRForm.target_email != null && dataParticipantRaterSRForm.target_email != undefined){
          handleSurveyDetailData(CONSTANTS.SURVEY_FORM_REDUX.TARGET_NIK, dataParticipantRaterSRForm.target_nik);
          handleSurveyDetailData(CONSTANTS.SURVEY_FORM_REDUX.TARGET_EMAIL, dataParticipantRaterSRForm.target_email);
          handleSurveyDetailData(CONSTANTS.SURVEY_FORM_REDUX.PARTICIPANT_ID,dataParticipantRaterSRForm.s_raters_id);
          handleSurveyDetailData(CONSTANTS.SURVEY_FORM_REDUX.TARGET_ID,dataParticipantRaterSRForm.s_participant_id);
      }
  },[dataParticipantRaterSRForm]);

  const backToSurveyList = () => {
      showAlert.warning({
          title: "Warning",
          text: "Your response will be deleted, are you sure you want to exit this page?",
          buttons: {
              cancel: {
                  text: "Cancel",
                  className: "bg-white text-dark"
              },
              confirm: {
                  text: "Yes",
                  value: true
              }
          },
          isDanger: true
      }).then(value => {
          if (value) {
              dispatch(rootAction.singleSurveyForm.clearDataParticipantRaterSRForm())
              dispatch(rootAction.singleSurveyForm.deleteAllManyAnswerSR());
              
              navigate(-1)

          }
      });
  }

  const handleAddMandatoryQuestionList = () => {
    for (let i = 0; i < surveyDetailDataSR.section.length; i++) {
        for (let j = 0; j < surveyDetailDataSR.section[i].content.length; j++) {
            if (surveyDetailDataSR.section[i].content[j].is_mandatory == 1 && surveyDetailDataSR.section[i].content[j].content_type != "ranking") {
            
            dispatch(rootAction.singleSurveyForm.addContentMandatorySR(
                {
                    section_code: surveyDetailDataSR.section[i].section_code,
                    content_code: surveyDetailDataSR.section[i].content[j].content_code
                }
            ))
            }else  if (surveyDetailDataSR.section[i].content[j].is_mandatory == 1 && surveyDetailDataSR.section[i].content[j].content_type == "ranking") {
              for (let k = 0; k < surveyDetailDataSR.section[i].content[j].content_choice.length; k++) {
                  dispatch(rootAction.singleSurveyForm.addContentMandatorySR(
                      {
                          section_code: surveyDetailDataSR.section[i].section_code,
                          content_code: `${surveyDetailDataSR.section[i].content[j].content_code}-${surveyDetailDataSR.section[i].content[j].content_choice[k].choice_code}`
                      }
                  ))
                  
              }
              
          }
        }
    }
  }


  if (surveyDetailDataSR == null || surveyDetailDataSR == undefined) {
    return (
      <div>
        <div className="mb-4">No Data</div>
        <ButtonOutlinePrimary label="Back" onClick={() => navigate(-1)} />
      </div>
    );
  } else {
    return (
      <div className="container tw-h-max tw-max-w-[1400px]">
        <div>Survey Name : {surveyDetailDataSR.setting.survey_name}</div>
        {surveyDetailDataSR.section.map((ch, i) => {
          return (
            <div key={i}>
              {sec == SURVEY_PART.WELCOME && i == sectionPageIndex ? (
                <WelcomeSingleRaterView
                  onClickPrev={() => backToSurveyList()}
                  preview={false}
                  onClickNext={() => handleFromWelcomeToQuestion()}
                  pic={
                    user.role == CONSTANTS.ROLE.ADMIN
                      ? ""
                      : surveyDetailDataSR.created_by
                  }
                  welcome_text={surveyDetailDataSR.setting.welcome_text}
                />
              ) : sec == SURVEY_PART.FINISH && i == sectionPageIndex ? (
                <SingleRaterFinishFormView
                  finish_text={surveyDetailDataSR.setting.finish_text}
                  onClickPrev={() => handleFinishtoQuestion()}
                />
              ) : i == sectionPageIndex ? (
                <SingleSurveyContentView
                  surveyDetailData={surveyDetailDataSR}
                  sectionPageIndex={sectionPageIndex}
                  handleNextPage={() => handleNextPage()}
                  handlePrevPage={() => handlePrevPage()}
                />
              ) : (
                <></>
              )}
            </div>
          );
        })}
      </div>
    );
  }
}

export default SingleSurveyFormView;

const SURVEY_PART = {
  WELCOME: "welcome",
  QUESTION: "question",
  FINISH: "finish",
};
