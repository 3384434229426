import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { REPORT_TYPE, SectionOptions } from '../constants';
import SectionControlItem from './SectionControlItem';

const SectionControl = ({ controlId, onAddSection }) => {
    const SectionTypeOptions =
        controlId === REPORT_TYPE.SINGLE
            ? SectionOptions.SR
            : controlId === REPORT_TYPE.MULTI_GROUP
            ? SectionOptions.MR_G
            : SectionOptions.MR_I;

    return (
        <div className="list-group">
            {SectionTypeOptions.sections.map(({ id, text, sections }) => (
                <SectionControlItem
                    key={id}
                    id={id}
                    sections={sections}
                    onAddSection={onAddSection}
                >
                    <FontAwesomeIcon icon={faCirclePlus} /> {text}
                </SectionControlItem>
            ))}
        </div>
    );
};

export default SectionControl;
