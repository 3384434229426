import { CONTENTS } from '../../constants';

const CommentData = ({ contentId, result }) => (
    <>
        {contentId === CONTENTS.SR_COMMENTS_ALL && <AllData values={result.values} />}
        {contentId === CONTENTS.MRI_COMMENTS_QUESTION && (
            <TextQuestion values={result.values || []} />
        )}
        {contentId === CONTENTS.MRI_COMMENTS_CHOICE && <NoAnswerChoice />}
    </>
);

const AllData = ({ values }) => (
    <div className="px-2">
        {values.map((val) => (
            <div className="mb-1 border-bottom ps-2">{val}</div>
        ))}
    </div>
);

const TextQuestion = ({ values }) => (
    <div className="px-2">
        {values.map((q) => (
            <>
                <div className="fw-bold">{q.question}</div>
                {q.answers.map((answer) => (
                    <div className="mb-1 border-bottom ps-2">{answer}</div>
                ))}
            </>
        ))}
    </div>
);

const NoAnswerChoice = () => (
    <div className="px-2">
        <div className="fw-bold">Item Pertanyaan: No Answer Choice ?</div>
        <div className="mb-1 border-bottom ps-2">
            Tidak Menjawab Tidak Menjawab Tidak Menjawab Tidak Menjawab
        </div>
    </div>
);

export default CommentData;
