import { useEffect, useState } from "react";
import { useDeps } from "../../shared/context/DependencyContext";

function useAdminDashboard() {
  const { adminService } = useDeps();
  const [dataMetrics, setDataMetrics] = useState({});
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);
  const [dataDepartments, setDataDepartments] = useState([]);
  const [dataBusinessUnits, setDataBusinessUnits] = useState([]);
  const [dataSingleRaterSurveys, setSingleRaterSurveys] = useState([]);
  const [dataMultiRaterSurveys, setMultiRaterSurveys] = useState([]);
  const [dataTop5ActiveParticipantByDepartment, setdataTop5ActiveParticipantByDepartment] = useState([]);
  const [dataTop5ActiveParticipantByBusinessUnit, setdataTop5ActiveParticipantByBusinessUnit] = useState([]);
  const [dataTop10SingleRaterByParticipant, setDataTop10SingleRaterByParticipant] = useState([]);
  const [dataTop10MultiRaterByParticipant, setDataTop10MultiRaterByParticipant] = useState([]);
  const [dataYearly, setDataYearly] = useState([]);
  const [dataMonthly, setDataMonthly] = useState([]);
  const [dataYearlyStatusSingle, setDataYearlyStatusSingle] = useState([]);
  const [dataMonthlyStatusSingle, setDataMonthlyStatusSingle] = useState([]);
  const [dataYearlyStatusMulti, setDataYearlyStatusMulti] = useState([]);
  const [dataMonthlyStatusMulti, setDataMonthlyStatusMulti] = useState([]);
  const [serverTime, setServerTime] = useState(''); 
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Batch 1 - Initial Metrics and Top Data
  const fetchBatch1 = async () => {
    const [
      responseMetrics,
      response1,
      response2
    ] = await Promise.all([
      adminService.dashboardListService.getDashboardMetrics(),
      adminService.dashboardListService.top_goals(),
      adminService.dashboardListService.top_groups(),
    ]);

    // Process Batch 1 Responses
    if (responseMetrics.status === 'SUCCESS') setDataMetrics(responseMetrics.data);
    if (response1.status === 'SUCCESS') setData1(response1.data);
    if (response2.status === 'SUCCESS') setData2(response2.data);
  };

  // Batch 2 - Top Dimensions, Aspects, and Departments/Business Units
  const fetchBatch2 = async () => {
    const [
      response3,
      response4,
      responseDepartments,
      responseBusinessUnits
    ] = await Promise.all([
      adminService.dashboardListService.top_dimensions(),
      adminService.dashboardListService.top_aspects(),
      adminService.dashboardListService.top_10_pic_by_department(),
      adminService.dashboardListService.top_10_pic_by_business_unit(),
    ]);

    // Process Batch 2 Responses
    if (response3.status === 'SUCCESS') setData3(response3.data);
    if (response4.status === 'SUCCESS') setData4(response4.data);
    if (responseDepartments.status === 'SUCCESS') setDataDepartments(responseDepartments.data);
    if (responseBusinessUnits.status === 'SUCCESS') setDataBusinessUnits(responseBusinessUnits.data);
  };

  // Batch 3 - Active Surveys and Top Participants
  const fetchBatch3 = async () => {
    const [
      responseSingleSurveys,
      responseMultiSurveys,
      responseTop5Department,
      responseTop5BusinessUnit,
      responseTop10SingleParticipant,
      responseTop10MultiParticipant,
    ] = await Promise.all([
      adminService.dashboardListService.active_single_rater_surveys(),
      adminService.dashboardListService.active_multi_rater_surveys(),
      adminService.dashboardListService.top_5_active_participant_by_department(),
      adminService.dashboardListService.top_5_active_participant_by_business_unit(),
      adminService.dashboardListService.top_10_single_rater_by_participant(),
      adminService.dashboardListService.top_10_multi_rater_by_participant(),
    ]);

    // Process Batch 3 Responses
    if (responseSingleSurveys.status === 'SUCCESS') setSingleRaterSurveys(responseSingleSurveys.data);
    if (responseMultiSurveys.status === 'SUCCESS') setMultiRaterSurveys(responseMultiSurveys.data);
    if (responseTop5Department.status === 'SUCCESS') setdataTop5ActiveParticipantByDepartment(responseTop5Department.data);
    if (responseTop5BusinessUnit.status === 'SUCCESS') setdataTop5ActiveParticipantByBusinessUnit(responseTop5BusinessUnit.data);
    if (responseTop10SingleParticipant.status === 'SUCCESS') setDataTop10SingleRaterByParticipant(responseTop10SingleParticipant.data);
    if (responseTop10MultiParticipant.status === 'SUCCESS') setDataTop10MultiRaterByParticipant(responseTop10MultiParticipant.data);
  };

  // Batch 4 - Yearly, Monthly Data, and Server Time
  const fetchBatch4 = async () => {
    const [
      responseDataYearly,
      responseDataMonthlySingle,
      responseDataMonthlyMulti,
      responseYearlyStatusSingle,
      responseMonthlyStatusSingle,
      responseYearlyStatusMulti,
      responseMonthlyStatusMulti,
      responseServerTime
    ] = await Promise.all([
      adminService.dashboardListService.yearly_survey_data(),
      adminService.dashboardListService.monthly_single_raters(),
      adminService.dashboardListService.monthly_multi_raters(),
      adminService.dashboardListService.yearly_status_single_data(),
      adminService.dashboardListService.monthly_status_single_data(),
      adminService.dashboardListService.yearly_status_multi_data(),
      adminService.dashboardListService.monthly_status_multi_data(),
      adminService.dashboardListService.server_time(),
    ]);

    // Process Batch 4 Responses
    if (responseDataYearly.status === 'SUCCESS') setDataYearly(responseDataYearly.data);

    // Monthly Data Handling
    if (responseDataMonthlySingle.status === 'SUCCESS' && responseDataMonthlyMulti.status === 'SUCCESS') {
      const monthlyData = {};

      responseDataMonthlySingle.data.forEach(item => {
        const key = `${item.year}-${item.month}`;
        if (!monthlyData[key]) {
          monthlyData[key] = { month: item.month, year: item.year, singleRater: 0, multiRater: 0 };
        }
        monthlyData[key].singleRater += item.single_rater;
      });

      responseDataMonthlyMulti.data.forEach(item => {
        const key = `${item.year}-${item.month}`;
        if (!monthlyData[key]) {
          monthlyData[key] = { month: item.month, year: item.year, singleRater: 0, multiRater: 0 };
        }
        monthlyData[key].multiRater += item.multi_rater;
      });

      setDataMonthly(Object.values(monthlyData));
    }

    // Yearly and Monthly Status Data
    if (responseYearlyStatusSingle.status === 'SUCCESS') setDataYearlyStatusSingle(responseYearlyStatusSingle.data);
    if (responseMonthlyStatusSingle.status === 'SUCCESS') setDataMonthlyStatusSingle(responseMonthlyStatusSingle.data);
    if (responseYearlyStatusMulti.status === 'SUCCESS') setDataYearlyStatusMulti(responseYearlyStatusMulti.data);
    if (responseMonthlyStatusMulti.status === 'SUCCESS') setDataMonthlyStatusMulti(responseMonthlyStatusMulti.data);

    // Server time
    if (responseServerTime) setServerTime(responseServerTime.server_time);
  };

  // Fetch dashboard data in four batches
  const getDashboardData = async () => {
    try {
      setLoading(true);
      await fetchBatch1(); // Fetch batch 1
      await fetchBatch2(); // Fetch batch 2
      await fetchBatch3(); // Fetch batch 3
      await fetchBatch4(); // Fetch batch 4
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      setError('Error fetching dashboard data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  return {
    dataMetrics,
    data1,
    data2,
    data3,
    data4,
    dataDepartments,
    dataBusinessUnits,
    dataSingleRaterSurveys,
    dataMultiRaterSurveys,
    dataTop5ActiveParticipantByDepartment,
    dataTop5ActiveParticipantByBusinessUnit,
    dataTop10SingleRaterByParticipant,
    dataTop10MultiRaterByParticipant,
    dataYearlyStatusSingle,
    dataMonthlyStatusSingle,
    dataYearlyStatusMulti,
    dataMonthlyStatusMulti,
    dataYearly,
    dataMonthly,
    serverTime,
    loading,
    error
  };
}

export default useAdminDashboard;
