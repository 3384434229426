const P = ({ className, children }) => {
    return <p className={`${className} mt-3`}>{children.replace(/(<([^>]+)>)/ig, '')}</p>;
}

export const Chapter = ({ children }) => {
    return <P className="h2 border-bottom border-dark">{children}</P>;
};

export const SubChapter = ({ children }) => {
    return <P className="h4">{children}</P>;
};

export const Text = ({ children }) => {
    return <P>{children}</P>;
};
