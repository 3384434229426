import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { arrayMove } from '@dnd-kit/sortable';
import { rootAction } from '../../redux/RootAction';
import { CONTENTS, SectionOptions } from './constants';
import { createSection } from './utils/useSection';

const useFormReportTemplate = () => {
    const dispatch = useDispatch();
    const [formSections, setFormSections] = useState([]);
    const [toggleDnd, setToggleDnd] = useState(false);
    const [showCommentDialog, setShowCommentDialog] = useState(false);

    const getSectionOptions = (id) => {
        return id === '1'
            ? SectionOptions.SR
            : id === '2'
            ? SectionOptions.MR_G
            : id === '3'
            ? SectionOptions.MR_I
            : {};
    };

    // Event Handlers
    // See: https://github.com/ckeditor/ckeditor5-react/issues/270
    const handleSectionUpdate = (id, data) => {
        const changedId = formSections.findIndex((item) => item.id === id);
        const currentSection = formSections[changedId];

        if (currentSection?.content !== data) {
            setFormSections(
                formSections.map((section, index) => {
                    if (changedId === index) {
                        return { ...section, content: data };
                    }
                    return section;
                })
            );
        }
    };
    const handleSectionChangeTheme = (id, theme) => {
        const changedId = formSections.findIndex((item) => item.id === id);

        setFormSections(
            formSections.map((section, index) => {
                if (changedId === index) {
                    return { ...section, theme: theme };
                }
                return section;
            })
        );
    };
    const handleSectionRemove = (id, event) => {
        setFormSections((sections) => {
            const removeId = sections.findIndex((item) => item.id === id);
            return sections.filter((_, index) => index !== removeId);
        });
    };
    const handleSectionDragEnd = (e) => {
        const { active, over } = e;
        if (over === null || over === undefined) {
            return;
        }

        if (active.id !== over.id) {
            setFormSections((sections) => {
                const activeId = sections.findIndex((item) => item.id === active.id);
                const overId = sections.findIndex((item) => item.id === over.id);
                return arrayMove(sections, activeId, overId);
            });
        }
    };
    const handleAddFormElement = (itemId, sections, showDialog) => {
        const parts = itemId.split('.');
        const Options = getSectionOptions(parts[0]);

        if (Array.isArray(sections) && sections.length > 0) {
            // console.log(sections)
        } else {
            /*if (showDialog) {
                if (itemId === CONTENTS.SR_COMMENTS_CUSTOM) {
                    setShowCommentDialog(true);
                }
            } else*/ if (Array.isArray(parts)) {
                let text = '';
                const idx2 = Options.sections.findIndex(
                    (section) => section.id === `${parts[0]}.${parts[1]}`
                );
                const sectionLv2 = Options.sections[idx2];

                switch (parts.length) {
                    case 5:
                        text =
                            sectionLv2.sections[parts[2] - 1].sections[parts[3] - 1]
                                .sections[parts[4] - 1].text;
                        break;
                    case 4:
                        text =
                            sectionLv2.sections[parts[2] - 1].sections[parts[3] - 1].text;
                        break;
                    case 3:
                        text = sectionLv2.sections[parts[2] - 1].text;
                        break;
                    case 2:
                        text = sectionLv2.text;
                        break;
                    default:
                        text = '';
                        break;
                }

                const newSection = createSection(parts[1], itemId, text);
                setFormSections([...formSections, newSection]);
            }
        }
    };
    const handleToggleOrderEdit = () => setToggleDnd(!toggleDnd);

    // Side Effects
    useEffect(() => {
        dispatch(rootAction.reportTemplate.updateSections(formSections));
    }, [formSections]);

    return {
        formSections,
        setFormSections,
        toggleDnd,
        setToggleDnd,
        showCommentDialog,
        setShowCommentDialog,
        handleSectionUpdate,
        handleSectionChangeTheme,
        handleSectionRemove,
        handleSectionDragEnd,
        handleAddFormElement,
        handleToggleOrderEdit
    };
};

export default useFormReportTemplate;
