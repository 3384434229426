import { useDispatch } from 'react-redux';
import { Col, Form, Row } from 'react-bootstrap';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { rootAction } from '../../redux/RootAction';
import { itemLanguage } from '../cms_multirater/tab/tab_settings/TabSettingData';
import debounce from './utils/debounce';
import {
    ButtonOutlinePrimary,
    ButtonPrimary
} from '../../shared/component/button/Button';
import SectionControl from './components/SectionControl';
import SectionContent from './components/SectionContent';
import FormInputGroup from './components/FormInputGroup';

export const FormPageHeader = ({
    title,
    description,
    sections,
    btnToogle,
    onBackClick,
    onToggleClick,
    onPreview,
    onSaveClick,
    children
}) => (
    <>
        <div className="my-4 d-flex justify-content-between">
            <h4>{title}</h4>
        </div>
        <div className="my-4 d-flex justify-content-between align-items-center">
            <span className="fst-italic">{description}</span>
            <div className="d-flex justify-content-end">
                <div className="me-2">
                    <ButtonOutlinePrimary label="Back" onClick={onBackClick} />
                </div>
                {children}
                {Array.isArray(sections) && sections.length >= 1 && (
                    <>
                        <div className="me-2">
                            <ButtonOutlinePrimary
                                label={`${btnToogle ? 'Editing' : 'Reorder'}`}
                                onClick={onToggleClick}
                            />
                        </div>
                        <div className="me-2">
                            <ButtonOutlinePrimary
                                label={'Preview'}
                                onClick={onPreview}
                            />
                        </div>
                    </>
                )}
                <div>
                    <ButtonPrimary label="Save" onClick={onSaveClick} />
                </div>
            </div>
        </div>
    </>
);

export const FormPageInput = ({ formName, formLang }) => {
    const dispatch = useDispatch();

    const handleChangeName = (e) => {
        debounce(dispatch(rootAction.reportTemplate.changeName(e.target.value)));
    };

    const handleChangeLang = (e) => {
        dispatch(rootAction.reportTemplate.changeLanguage(e.target.value));
    };

    return (
        <Row>
            <Col>
                <FormInputGroup
                    horizontal
                    label={'Name'}
                    htmlFor={'report_name'}
                    labelSize={3}
                    controlSize={9}
                >
                    <Form.Control
                        type="text"
                        value={formName}
                        onChange={handleChangeName}
                        id={'report_name'}
                        required={true}
                    />
                </FormInputGroup>
            </Col>
            <Col>
                <FormInputGroup
                    horizontal
                    label={'Language'}
                    htmlFor={'report_lang'}
                    labelSize={3}
                    controlSize={9}
                >
                    <Form.Select
                        id={'report_lang'}
                        value={formLang}
                        onChange={handleChangeLang}
                    >
                        {Array.isArray(itemLanguage) &&
                            itemLanguage.map((l) => (
                                <option key={l.id} value={l.val}>
                                    {l.language}
                                </option>
                            ))}
                    </Form.Select>
                </FormInputGroup>
            </Col>
        </Row>
    );
};

export const FormPageBorder = ({ className, children }) => (
    <div
        className={`card shadow ${className}`}
        style={{
            borderLeft: 0,
            borderBottom: 0,
            borderRight: 0,
            minHeight: '70vh'
        }}
    >
        <div className="card-body p-4">{children}</div>
    </div>
);

export const FormPageContent = ({
    toggleDnd,
    formType,
    formSections,
    onAddSection,
    onUpdateSection,
    onUpdateTheme,
    onDeleteSection,
    onDragEnd
}) => {
    return (
        <Row className="mt-3">
            <Col sm={3} className="pe-4">
                <SectionControl controlId={formType} onAddSection={onAddSection} />
            </Col>
            <Col sm={9} className="ps-4 dnd-content">
                {toggleDnd ? (
                    <DndContext onDragEnd={onDragEnd} collisionDetection={closestCenter}>
                        <SortableContext
                            items={formSections}
                            strategy={verticalListSortingStrategy}
                        >
                            {formSections.length >= 1 &&
                                formSections.map((el) => (
                                    <SectionContent
                                        key={el.id}
                                        id={el.id}
                                        controlId={el.controlId}
                                        title={el.title}
                                        contentId={el.contentId}
                                        theme={el.theme}
                                    >
                                        {el.content}
                                    </SectionContent>
                                ))}
                        </SortableContext>
                    </DndContext>
                ) : (
                    <>
                        {formSections.length >= 1 &&
                            formSections.map((el) => (
                                <SectionContent
                                    key={el.id}
                                    id={el.id}
                                    controlId={el.controlId}
                                    title={el.title}
                                    theme={el.theme}
                                    showBtnClose={true}
                                    contentId={el.contentId}
                                    onChange={onUpdateSection}
                                    onChangeTheme={onUpdateTheme}
                                    onDeleteClick={onDeleteSection}
                                >
                                    {el.content}
                                </SectionContent>
                            ))}
                    </>
                )}
            </Col>
        </Row>
    );
};
