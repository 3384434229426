import { useEffect } from "react";
import useTabMonitoring from "./useTabMonitoring";
import LoadingBar from "react-top-loading-bar";
import { ShowEntries } from "../../../../shared/component/table_property/ShowEntries";
import { SearchColumn } from "../../../../shared/component/table_property/SearchColumn";
import Pagination from "../../../../shared/component/table_property/Paginatination";

function TabMonitoring({survey_code}) {
    const {
        allTP,
        pageNumber,
        totalItem,
        setPageNumber,
        setShowItem,
        showItem,
        setTotalItem,
        searchText,
        setSearchText,
        handleInputSearchChange,
        handleSearchData,
        handleshowItem,
        onGetAllTP,
        progress,
        setProgress
        
    } = useTabMonitoring();

    useEffect(()=>{
        if (survey_code != undefined || survey_code != null) {
            onGetAllTP(survey_code)
        }
    },[survey_code])

    const paginate = (pageNumber) => {
        setPageNumber(pageNumber);
     };
    
     const previousPage =  () => {
          if (pageNumber != 1) {
            setPageNumber(pageNumber - 1);
            
          }
      };
    
      const nextPage = () => {
          if (pageNumber != Math.ceil(totalItem/ showItem)) {
            setPageNumber(pageNumber + 1);
          }
      };

      
    

    return (
      <>
       <LoadingBar color="#7749F8" progress={progress} onLoaderFinished={() => setProgress(0)} />

            <div>                
                <h6 className="my-4">Monitoring Individu :</h6>

                <div className="">
                        <div className='row justify-content-between mb-2'>
                            <div className='col-4 d-flex flex-row align-items-center'>
                                <div>
                                    Show
                                </div>

                                <ShowEntries onClick_item={handleshowItem} default_item={showItem}/>

                                <div className=''>
                                    entries
                                </div>
                            </div>

                            <div className='col-3 d-flex align-items-center'>
                            <SearchColumn onChange_input={handleInputSearchChange} onClick_btn={handleSearchData} />
                            </div>
                        </div>


                        <div className='table-responsive mt-1 '>
                            <table className="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th  style={{ verticalAlign: 'middle', textAlign: 'center' }} >No</th>
                                        <th  style={{ verticalAlign: 'middle', textAlign: 'center' }}>Target Participant Name</th>
                                     

                                        <th  style={{ verticalAlign: 'middle', textAlign: 'center' }}>Email</th>
                                        <th  style={{ verticalAlign: 'middle', textAlign: 'center' }}>Status</th>
                                    </tr>

                                </thead>
                                
                                {   Array.isArray(allTP) && allTP.length != 0 ?
                                    allTP.map((dt, i) => {
                                        
                                        return (
                                            <tbody key={i}>
                                                <tr style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                                    <td rowSpan={dt.raters.length != 0 ? dt.raters.length : 1}>{i + 1}
                                                    </td>
                                                    <td> {dt.full_name}</td>
                                                    <td>{dt.email?.toLowerCase()}</td>
                                                    <td>
                                                    {dt.raters[0].status == 'done' || dt.raters[0].status == 'Done' || dt.raters[0].status == 'DONE' ? dt.raters[0].status : '-'}
                                                    </td>
                                                </tr>
                                            </tbody>)
                                        
                                    }) :
                                    <tbody>
                                        <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                            <td colSpan={7}>No data</td>
                                        </tr>
                                    </tbody>
                                    }
                            

                            </table>
                        </div>
                        <Pagination showing={Array.isArray(allTP) ? allTP.length : 0} totalItem={totalItem ? totalItem : 0} showItem={showItem} previousPage={previousPage} nextPage={nextPage} paginate={paginate} limit={pageNumber}/>

                    </div>

            </div>
      </>
    )
}

export default TabMonitoring;