import { ADD_CONTENT_MANDATORY_SR, CLEAR_DATA_PARTICIPANT_RATER_SURVEY_FORM_SR, CLEAR_SURVEY_FIELD_SR, DELETE_ALL_MANDATORY_CONTENT_SR, DELETE_ALL_MANY_ANSWER_SR, DELETE_MANDATORY_CONTENT_SR, DELETE_MANY_ANSWER_SR, DELETE_SR_SURVEY_DETAIL_DATA, EDIT_MANY_ANSWER_SAME_CONTENT_SR, EDIT_MANY_ANSWER_SR, SET_DATA_PARTICIPANT_RATER_SURVEY_FORM_SR, SET_MANY_ANSWER_SR, SET_ONE_ANSWER_SR, SET_SR_SURVEY_DETAIL_DATA, SET_SURVEY_FIELD_SR } from "./SingleraterFormPartType";

const initOneAnswerSR = {
    participant_nik:"",
    participant_bu:"",
    participant_dept:"",
    participant_email:"",
    s_raters_id: 0,
    s_participant_id: 0,
    target_nik:"",
    target_email:"",
    survey_code:"",
    survey_name:"",
    section_code:"",
    section_name:"",
    content_code:"",
    content_name:"",
    content_type:"",
    choice_code:"",
    choice_text:"",
    choice_score:0,
    scale_display:"",
    is_mandatory: "false",
    answer:"",
    goal_id:"",
    created_at:"",
}

const initSurveyFieldSR = {
    participant_nik:"",
    participant_bu:"",
    participant_dept:"",
    participant_email:"",
    s_raters_id: 0,
    s_participant_id: 0,
    target_nik:"",
    target_email:"",
    survey_code:"",
    survey_name:"",
}

const initDataParticipantRaterSRForm = {
    s_raters_id: 0,
    s_participant_id: 0,
    survey_code: "",
    target_nik: "",
    target_full_name: "",
    target_email: "",
    survey_name: "",
    rater_role: "",
    rater_nik:"",
    rater_email:"",
    rater_bu: "",
    rater_dept: "",
}

const initInsertManyAnswerSR = [];
const initContentMandatoryList = [];

const initSRDetailData = {}

export function oneAnswerSurveyFormReducerSR(state = initOneAnswerSR, action) {
    switch (action.type) {
        case SET_ONE_ANSWER_SR:
            return {
                ...state,
                [action.data.field]: action.data.payload
            }
    
        default:
            return state;
    }
}

export function surveyFormDataReducerSR(state = initSurveyFieldSR, action) {
    switch (action.type) {
        case SET_SURVEY_FIELD_SR:
            return {
                ...state,
                [action.data.field]: action.data.payload
            }
        case CLEAR_SURVEY_FIELD_SR:
            return initSurveyFieldSR
        default:
            return state;
    }
}

export function manyAnswerSurveyFormReducerSR(state = initInsertManyAnswerSR, action) {
    switch (action.type) {
       case SET_MANY_ANSWER_SR:
          return [...state, action.data];
       case EDIT_MANY_ANSWER_SR:
          return [...state].map((el) => {

             if (el.section_code == action.data.key_section && el.content_code == action.data.key_content) {
               
                return {
                   ...el,
                   [action.data.field]: action.data.payload
                }
             } else {
                
                return el;
             }
          });
       case DELETE_MANY_ANSWER_SR:
        return state.filter((el) => el.section_code != action.data.key_section || el.content_code != action.data.key_content || el.choice_code != action.data.key_choice);
       case DELETE_ALL_MANY_ANSWER_SR:
        // console.log('delete all answer');
        return initInsertManyAnswerSR
       case EDIT_MANY_ANSWER_SAME_CONTENT_SR:
        return [...state].map((el) => {
    
                if (el.section_code == action.data.key_section && el.content_code == action.data.key_content && el.choice_code == action.data.key_choice) {
                  
                   return {
                      ...el,
                      [action.data.field]: action.data.payload
                   }
                } else {
                   
                   return el;
                }
             });

        default:
          return state;
    }
 }

 export function dataParticipantRaterSRFormReducer(state = initDataParticipantRaterSRForm, action) {
    switch (action.type) {
        case SET_DATA_PARTICIPANT_RATER_SURVEY_FORM_SR:
            return {
                ...state,
                [action.data.field]: action.data.payload
            }
        case CLEAR_DATA_PARTICIPANT_RATER_SURVEY_FORM_SR:
            return initDataParticipantRaterSRForm
        default:
            return state;
    }
}

export function contentMandatoryListSR(state = initContentMandatoryList, action) {
    switch (action.type) {
        case ADD_CONTENT_MANDATORY_SR:
            return [...state, action.data];
        case DELETE_MANDATORY_CONTENT_SR:
            const res = state.filter((el) => el.section_code != action.data.section_code || el.content_code != action.data.content_code );
            return res
        case DELETE_ALL_MANDATORY_CONTENT_SR:
            return initContentMandatoryList
        default:
            return state;
    }
}

export function SurveySRDetailData(state =  initSRDetailData, action) {
    switch (action.type) {
        case SET_SR_SURVEY_DETAIL_DATA:
            return action.data;
        case DELETE_SR_SURVEY_DETAIL_DATA:
            return initSRDetailData
        default:
            return state;
    }
}
