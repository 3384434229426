import { Dropdown } from "react-bootstrap";
import {
  ButtonPreview,
  ButtonPrimary,
} from "../../../shared/component/button/Button";
import { DropdownOnly } from "../../../shared/component/dropdown/DropdownInput";
import {  useEffect, useState } from "react";
import { Footer } from "../../layout/navbar/Footer";
import {  useNavigate } from "react-router-dom";
import { SearchColumn } from "../../../shared/component/table_property/SearchColumn";
import { ExternalRaters } from "../../admin_list/ExternalRaters";
import { useAddRaters } from "./useAddRaters";
import Pagination from "../../../shared/component/table_property/Paginatination";
import { InputOnlyForDropdown } from "../../../shared/component/input/Input";
import {  useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import ModalRelationship from "./components/ModalRelationship";
import useRefs from "react-use-refs";
import ModalInfo from "./components/ModalInfo";

function AddRatersView() {
  const seeRaterData = useSelector((state) => state.data_see_raters);
  const navigate = useNavigate();
  const [deptNameRef, buNameRef, levelRef] = useRefs();
  const [searchBU, setSearchBU] = useState('');
  const [searchDept, setSearchDept] = useState('');
  const [tab, setTab] = useState(1);
  const [showModalInfo, setShowModalInfo] = useState(false);
  const userData = useSelector((state) => state.user);

  const {
   buName,
   setBUName,
   deptName,
   setDeptName,
   level,
   setLevel,
   buData,
   departmentData,
   levelData,
   setSearch,
   setSearchBar,
   setPageNumber,
   handleInputSearchChange,
   allUser,
   pageNumber,
   showItem,
   totalItem,
   searchBar,
   search,
   onGetAllUserWithFilter,
   handleAddandSendInvitation, 
   openSelectRelation,
   setOpenSelectRelation,
   selectedRater,
   setSelectedRater,
   tpData,
   setTpData,
   onGetAllRaterList


  } = useAddRaters();


  useEffect(() => {

    seeRaterData.tp_id != undefined && onGetAllRaterList(seeRaterData.tp_id)
    
  }, [seeRaterData])
  

      const handleOnChangeText = (e) => {
        e.target.id == 'search_bar' && setSearchBar(e.target.value);
        e.target.id == 'search_bu' && setSearchBU(e.target.value);
        e.target.id == 'search_dept' && setSearchDept(e.target.value);
      }

      const handleOnSelect = (ref, val) => {
        ref.current.id == 'business_unit' && setBUName(val);
        ref.current.id == 'business_unit' && setPageNumber(1);

        ref.current.id == 'department' && setDeptName(val);
        ref.current.id == 'department' && setPageNumber(1);

        ref.current.id == 'level' && setLevel(val);
        ref.current.id == 'level' && setPageNumber(1);
    }

  const handleResetButton = () => {
    setBUName("ALL");
    setDeptName("ALL");
    setLevel("ALL");
    setSearchBar("")
    setSearch("");
  };

    const onChangeTab = (ev) => {
        setTab(ev.target.value);
      };

      const paginate = (pageNumber) => {
        setPageNumber(pageNumber);
     };
    
     const previousPage =  () => {
          if (pageNumber != 1) {
            setPageNumber(pageNumber - 1);
            
          }
      };
    
      const nextPage = () => {
          if (pageNumber != Math.ceil(totalItem/ showItem)) {
            setPageNumber(pageNumber + 1);
          }
      };


      const handleOnSubmitSearch = () => {
        setPageNumber(1);
        setSearch(searchBar);
      }

      const handletoAddInternalRater = (dataRater) => {
        console.log('data raters selected => ', dataRater);
        setOpenSelectRelation(true)
        const raterData = {
          nik: dataRater.nik,
          full_name: dataRater.full_name,
          gender:dataRater.gender == null || dataRater.gender == undefined ? "" : dataRater.gender,
          dob: dataRater.dob == undefined ? null : dataRater.dob,
          join_date: dataRater.join_date == undefined ? null : dataRater.join_date,
          email: dataRater.email == null || dataRater.email == undefined ? "" : dataRater.email,
          level: dataRater.level_user == null || dataRater.level_user == undefined ? "" : dataRater.level_user,
          position: dataRater.position_user == null || dataRater.position_user == undefined ? "" : dataRater.position_user,
          business_unit_name: dataRater.business_unit_name == null || dataRater.business_unit_name == undefined ? "" : dataRater.business_unit_name,
          department_name: dataRater.department_name == null || dataRater.department_name == undefined ? "" : dataRater.department_name,
          is_internal: 1,
          status: "not started",
          created_by: userData.email,
          updated_by:"",
          is_active: 1
        }
        setSelectedRater(raterData)
        setTpData(seeRaterData);
      }




  return (
    <>
      <div className="container tw-h-max tw-max-w-[1400px] mt-4">
        <div className="d-flex justify-content-between mb-2">
          <div>
            <h4>Add Raters for {seeRaterData.tp_name} </h4>
          </div>
        </div>
        <div
          className="col-12 shadow p-4 my-4"
          style={{ boxShadow: "0px 0.1rem 0.1rem 0px rgba(0, 0, 0, 0.25)" }}
        >
          <div className="col-12 mt-2 mb-3">
            <h5>Filter Data by</h5>
          </div>

          <div className="d-inline float-end">
            <div className="tooltips">
              <button
                className="me-2 btn btn-light "
                onClick={() => handleResetButton()}
              >
                <FontAwesomeIcon icon={faArrowsRotate} />
              </button>
              <span className="tooltiptext">Reset</span>
            </div>
          </div>

           {/* Filter */}
          <div className="col-12 d-flex justify-content-between mb-4">
                        <div className="col-3 tw-w-[30%]">
                            <div>Business Unit</div>
                            <div>
                                <DropdownOnly
                                    id={'business_unit'}
                                    innerRef={buNameRef}
                                    default_item={Array.isArray(buData) && buData.length > 0 ? (buName != '' ? buData.find((el) => el.business_unit_name == buName).business_unit_name : buData.find((el) => el.business_unit_name == 'ALL').business_unit_name) : null}
                                    search_bar={
                                        <InputOnlyForDropdown
                                            id={'search_bu'}
                                            type="text"
                                            placeholder="Search Business Unit ..."
                                            value={searchBU}
                                            handleOnChange={handleOnChangeText}
                                            input_width={'100%'}
                                        />
                                    }
                                    dropdown_item={
                                        Array.isArray(buData) && buData.length > 0 ? (searchBU != '' ? (buData.filter((el) => el.business_unit_name != null ? el.business_unit_name.toString().toLowerCase().includes(searchBU.toLowerCase()) : '').map((itm, i) => {
                                            return (
                                                <Dropdown.Item key={i} onClick={() => handleOnSelect(buNameRef, itm.business_unit_name)}>{itm.business_unit_name}</Dropdown.Item>
                                            )
                                        })) :
                                            (buData.map((itm, i) => {
                                                return (
                                                    <Dropdown.Item key={i} onClick={() => handleOnSelect(buNameRef, itm.business_unit_name)}>{itm.business_unit_name}</Dropdown.Item>
                                                )
                                            }))
                                        ) :
                                            (<Dropdown.Item>Select</Dropdown.Item>)
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-3 tw-w-[30%]">
                            <div>Department</div>
                            <div>
                                <DropdownOnly
                                    id={'department'}
                                    innerRef={deptNameRef}
                                    default_item={Array.isArray(departmentData) && departmentData.length > 0 ? (deptName != '' ? departmentData.find((el) => el.department_name == deptName).department_name : departmentData.find((el) => el.department_name == 'ALL').department_name) : null}
                                    search_bar={
                                        <InputOnlyForDropdown
                                            id={'search_dept'}
                                            type="text"
                                            placeholder="Search Department ..."
                                            value={searchDept}
                                            handleOnChange={handleOnChangeText}
                                            input_width={'100%'}
                                        />
                                    }
                                    dropdown_item={
                                        Array.isArray(departmentData) && departmentData.length > 0 ? (searchDept != '' ? (departmentData.filter((el) => el.department_name != null ? el.department_name.toString().toLowerCase().includes(searchDept.toLowerCase()) : '').map((itm, i) => {
                                            return (
                                                <Dropdown.Item key={i} onClick={() => handleOnSelect(deptNameRef, itm.department_name)}>{itm.department_name}</Dropdown.Item>
                                            )
                                        })) :
                                            (departmentData.map((itm, i) => {
                                                return (
                                                    <Dropdown.Item key={i} onClick={() => handleOnSelect(deptNameRef, itm.department_name)}>{itm.department_name}</Dropdown.Item>
                                                )
                                            }))
                                        ) :
                                            (<Dropdown.Item>Select</Dropdown.Item>)
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-3 tw-w-[30%]">
                            <div>Level</div>
                            <div>
                                <DropdownOnly
                                    id={'level'}
                                    innerRef={levelRef}
                                    default_item={Array.isArray(levelData) && levelData.length > 0 ? (level != '' ? levelData.find((el) => el.level_user == level).level_user : levelData.find((el) => el.level_user == 'ALL').level_user) : null}
                                    dropdown_item={
                                        Array.isArray(levelData) && levelData.length > 0 ? levelData.map((itm, i) => {
                                            return (
                                                <Dropdown.Item key={i} onClick={() => handleOnSelect(levelRef, itm.level_user)}>{itm.level_user}</Dropdown.Item>
                                            )
                                        }) :
                                            (<Dropdown.Item>Select</Dropdown.Item>)
                                    }
                                />
                            </div>
                        </div>
          </div>

         
        </div>

        <div
            className="col-12 card shadow"
            style={{
              borderLeft: 0,
              borderBottom: 0,
              borderRight: 0,
              minHeight: "70vh",
            }}
          >
            <div className="col-12 card-header p-1">
              <div
                className="btn-group"
                role="group"
                aria-label="Basic radio toggle button group"
              >
                <input
                  type="radio"
                  className="btn-check"
                  name="btn-radio"
                  id="btn-all"
                  autoComplete="off"
                  value={1}
                  defaultChecked
                  onChange={onChangeTab}
                />
  
                <label
                  className="btn btn-outline-primary"
                  htmlFor="btn-all"
                  style={{
                    minWidth: "20vw",
                    textAlign: "center",
                    borderRadius: "0.5rem 0 0 0.5rem",
                  }}
                >
                  All
                </label>
                <input
                  type="radio"
                  className="btn-check"
                  name="btn-radio"
                  id="btn-other-external"
                  autoComplete="off"
                  value={2}
                  onChange={onChangeTab}
                />
                <label
                  className="btn btn-outline-primary"
                  htmlFor="btn-other-external"
                  style={{
                    minWidth: "20vw",
                    textAlign: "center",
                    borderRadius: "0 0.5rem 0.5rem 0",
                  }}
                >
                  Other (External)
                </label>
              </div>
            </div>
  
            <div className="col-12 row justify-content-between card-body ">
              <div className="col-8">
                {tab == 1 ? (
                  <>
                    <SearchColumn id={'search_bar'} onChange_input={handleOnChangeText} onClick_btn={handleOnSubmitSearch} value_input={searchBar} placeholder_input={'Search . . .'} />

                    <br />
                    <>
                      { Array.isArray(allUser) && allUser.length != 0 ?
                      allUser.map((dt, i) => {
                        // const checkRat = CheckRatersInternal(dt.email)
                        return (
                          <div key={i}>
                            <div
                              className="mb-2 p-2 rounded d-flex justify-content-between align-items-center"
                              style={{ backgroundColor: "#DEE2E6" }}
                            >
                              <div className="col-8">
                                <div>
                                  <strong>{dt.full_name}</strong>  {dt.email == '' ? '' : `- ${dt.email}`}
                                </div>
                                <div>{dt.department_name}</div>
                              </div>
  
                              <div className="col-4 d-flex">
                                      <div className="mx-2" style={{width:'100%'}}>
                                      </div>
                                      {
                                        dt.email == '' ? 
                                        <ButtonPreview label="Info" onClick={()=>setShowModalInfo(true)} /> 
                                        :  
                                        <ButtonPrimary label="Add" onClick={()=>handletoAddInternalRater(dt)}/>
                                      }
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : <div>No data</div>
                    }
                    <Pagination 
                      showing={Array.isArray(allUser) ? allUser.length : 0} 
                      totalItem={totalItem} 
                      showItem={showItem} 
                      previousPage={previousPage} 
                      nextPage={nextPage} 
                      paginate={paginate} 
                      limit={pageNumber}/>
                      
                    </>
                  </>
                ) : (
                  <ExternalRaters
                    
                  />
                )}
              </div>
            </div>
          </div>
  
          <ModalRelationship show={openSelectRelation} onHideModal={()=>setOpenSelectRelation(false)} dataRater={selectedRater} handleSendInvitation={handleAddandSendInvitation}/>
          <ModalInfo show={showModalInfo} onHideModal={()=>setShowModalInfo(false)} />


      </div>
      <Footer
        disableNext={true}
        onClickPrev={() =>
          navigate(
            -1
          )
        }
      />
    </>
  );
}



export default AddRatersView;




