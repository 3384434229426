export const ConfigDataService = ({  doPost, doDelete }) => {
    const getAllGoals = async () => {
        try {
            return await doPost({ url: '/admin/view_config_goal' });
        } catch (error) {
            throw error;
        }
    };

    const postGoal = async (goal_name) => {
        try {
            return await doPost({
                url: '/admin/create_config_goal',
                data: {
                    goal_name: goal_name,
                    created_by: "admin"
                },
            });
        } catch (e) {
            throw e;
        }
    };

    const deleteGoal = async (id) => {
        try {
            return await doPost({
                url: '/admin/delete_config_goal',
                data: {
                    id: id
                },
            });
        } catch (e) {
            throw e;
        }
    };

    const getAllGoalsPICUrl = async () => {
        try {
            return await doPost({ url: '/pic/view_config_goal' });
        } catch (error) {
            throw error;
        }
    };

   

    return {
       getAllGoals,
       postGoal,
       deleteGoal,
       getAllGoalsPICUrl
    };
};
