export const MultiRaterService = ({ doPost }) => {
   const saveMultiRater = async (multiRaterData) => {
      try {
         return await doPost({
            url: '/admin/add_multirater',
            data: multiRaterData
         });
      } catch (err) {
         throw err;
      }
   }

   const getDetailSurveyMultirater = async (survey_code) => {
      try {
         return await doPost({
            url: '/admin/get_survey_detail',
            data: {
               survey_code: survey_code
            }
         });
      } catch (err) {
         throw err;
      }
   }

   const getMultiraterListData = async (search, limit, page_number,user_login) => {
      try {
         return await doPost({
            url: '/admin/get_list_survey_by_user',
            data: {
               search: search,
               created_by: "",
               user_login:user_login,
               survey_type:'multi',
               limit: limit,
               page_number: page_number
            }
         });
      } catch (err) {
         throw err;
      }
   }

   const deleteMultiraterSurvey = async (survey_code) => {
      try {
         return await doPost({
            url: `/admin/delete_survey`,
            data: {
               survey_code: survey_code
            },
         });
      } catch (e) {
         throw e;
      }
   }

   const sendInvitationtoAllRaters = async (survey_code) => {
      try {
         return await doPost({
            url: `/admin/send_invitation_all_raters`,
            data: {
               survey_code: survey_code
            },
         });
      } catch (e) {
         throw e;
      }
   }

   const updateMultiRater = async (multiRaterData) => {
      try {
         return await doPost({
            url: '/admin/update_survey',
            data: multiRaterData
         });
      } catch (err) {
         throw err;
      }
   }

   /* Service for PIC */
   const getDetailSurveyMultiraterPICUrl = async (survey_code) => {
      try {
         return await doPost({
            url: '/pic/get_survey_detail',
            data: {
               survey_code: survey_code
            }
         });
      } catch (err) {
         throw err;
      }
   }

   const getMultiraterListDataPICUrl = async (search, limit, page_number,user_login) => {
      try {
         return await doPost({
            url: '/pic/get_list_survey_by_user',
            data: {
               search: search,
               created_by: "",
               user_login:user_login,
               survey_type:'multi',
               limit: limit,
               page_number: page_number
            }
         });
      } catch (err) {
         throw err;
      }
   }

   const deleteMultiraterSurveyPICUrl = async (survey_code) => {
      try {
         return await doPost({
            url: `/pic/delete_survey`,
            data: {
               survey_code: survey_code
            },
         });
      } catch (e) {
         throw e;
      }
   }

   const sendInvitationtoAllRatersPICUrl = async (survey_code) => {
      try {
         return await doPost({
            url: `/pic/send_invitation_all_raters`,
            data: {
               survey_code: survey_code
            },
         });
      } catch (e) {
         throw e;
      }
   }

   const saveMultiRaterPICUrl = async (multiRaterData) => {
      try {
         return await doPost({
            url: '/pic/add_multirater',
            data: multiRaterData
         });
      } catch (err) {
         throw err;
      }
   }

   const updateMultiRaterPICUrl = async (multiRaterData) => {
      try {
         return await doPost({
            url: '/pic/update_survey',
            data: multiRaterData
         });
      } catch (err) {
         throw err;
      }
   }

   return {
      saveMultiRater,
      getMultiraterListData,
      deleteMultiraterSurvey,
      getDetailSurveyMultirater,
      sendInvitationtoAllRaters,
      updateMultiRater,
      getMultiraterListDataPICUrl,
      deleteMultiraterSurveyPICUrl,
      getDetailSurveyMultiraterPICUrl,
      sendInvitationtoAllRatersPICUrl,
      saveMultiRaterPICUrl,
      updateMultiRaterPICUrl
   };
};
