import { useEffect, useState } from "react";
import { useDeps } from "../../../shared/context/DependencyContext";

export const UseContactUs = () => {
  const { adminService } = useDeps();
  const [contactUsWhatsapp, setContactUsWhatsapp] = useState("");
  const [contactUsNumber, setContactUsNumber] = useState("");
  const [contactUsEmail, setContactUsEmail] = useState("");
  const [contactMessage, setContactMessage] = useState("");

  useEffect(() => {
    onGetContactUsData();
  }, []);

  const onGetContactUsData = async (contact_id) => {
    // console.log("onGetContactUs");
    try {
      const response = await adminService.contactUsService.getContactUs(contact_id);

      if (response.status == "SUCCESS") {
        setContactUsWhatsapp(response.data.whatsapp_number);
        setContactUsNumber(response.data.phone_number);
        setContactUsEmail(response.data.email);
        setContactMessage(response.data.message);
        // console.log(response.data);
      } 
    } catch (error) {
      console.log(error);
    } finally {
      // console.log("finally");
    }
  };

  return {
    contactUsWhatsapp,
    contactUsNumber,
    contactUsEmail,
    contactMessage,
    onGetContactUsData,
  };
};
