import { useState, useEffect } from "react";
import { useDeps } from "../../../shared/context/DependencyContext"
import screenToast from "../../../shared/hook/ScreenToast";
import useViewState from "../../../shared/hook/UseViewState";

function useContactUs() {
    const {adminService} = useDeps();
    const [number, setNumber] = useState('');
    const [wa, setWa] = useState('');
    const [message, setMessage] = useState('');
    const [email, setEmail] = useState('');
    const [defaultData , setDefaultData] = useState(false);
    const [changeData, setChangeData] = useState(false);
    const showToast = screenToast();
    const { viewState, setError, setSuccess } = useViewState();


    useEffect(() => {
      onGetContactUs()
    
      
    },[changeData])

    useEffect(() => {
        if (viewState.isError == true) {
            showToast.error(viewState.errorMsg);
            setError(false, '');
        }

        if (viewState.isSuccess == true) {
            showToast.success(viewState.successMsg);
            setSuccess(false, '');
        }
    }, [viewState]);
    


    const onPostContactUs = async () => {
        try {
            const response = await adminService.contactUsService.postContactUs(message,number,wa,email);

            if (response.status == 'SUCCESS') {
                setChangeData(!changeData);
                setSuccess(true,'Saved Successfully');
                
            }
            else {
                setError(true,response.status);
            }
        } catch (error) {
            console.log(error)
            setError(true,'Error System');
        } finally {
          
        }
    }

    const onUpdateContactUs = async () => {
        try {
            const response = await adminService.contactUsService.updateContactUs(message,number,wa,email);
  
            if (response.status == 'SUCCESS') {
                setChangeData(!changeData);
                setSuccess(true,'Saved Successfully');
                
            }
            else {
                setError(true,response.status);
            }
        } catch (error) {
            console.log(error)
            setError(true,'Error System');

        } finally {
          
        }
    }

    const onGetContactUs = async () => {
        try {
            const response = await adminService.contactUsService.getContactUs();

            if (response.status == 'SUCCESS') {
                setDefaultData(true);
                setMessage(response.data.message);
                setEmail(response.data.email);
                setNumber(response.data.phone_number);
                setWa(response.data.whatsapp_number);

            }
            // else {
            //     setError(true,response.status);

            // }
        } catch (error) {
            console.log(error);
            // setError(true,'Error System');
        } finally {
          
        }
    }

    const handleChangeMessage = (e) => {
        setMessage(e.target.value)
    }

    const handleChangeWa = (e) => {
        setWa((e.target.value))
    }

    const handleChangeEmail = (e) => {
        setEmail(e.target.value)
    }

    const handleChangeNumber = (e) => {
        setNumber(e.target.value)
    }

    const handleSaveData = () => {
        if (defaultData == true) {
            onUpdateContactUs()
        } else {
            onPostContactUs();
        }
    }


    return{
        
        message,
        wa,
        number,
        email,
        handleChangeEmail,
        handleChangeMessage,
        handleChangeNumber,
        handleChangeWa,
        handleSaveData,
        

    }
}

export default useContactUs;