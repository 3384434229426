import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { thTableQuestion } from "./component/question_constants";
import { IconButtonView } from "../../shared/component/button/IconButton";
import ViewQuestion from "./component/ViewQuestion";
import { SearchColumn } from "../../shared/component/table_property/SearchColumn";
import { ShowEntries } from "../../shared/component/table_property/ShowEntries";
import useQuestionBank from "./useQuestionBank";
import Pagination from "../../shared/component/table_property/Paginatination";
import "../target_participant/add_target_participant/tp_from_history/History.css";
import LoadingBar from "react-top-loading-bar";

function QuestionBankView() {
  const {
    allQuestionHistory,
    totalItem,
    pageNumber,
    showItem,
    handleshowItem,
    handleInputSearchChange,
    handleSearchData,
    setPageNumber,
    setSearchText,
    progress,
    setProgress
  } = useQuestionBank();
  const [view, setView] = useState(false);
  const [question, setQuestion] = useState();

  const handleOpenDetailQuestion = (question) => {
    setView(true);
    setQuestion(question);
  };

  const paginate = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const previousPage = () => {
    if (pageNumber != 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const nextPage = () => {
    if (pageNumber != Math.ceil(totalItem / showItem)) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handleResetButton = () => {
    setSearchText("");
  };

  return (

    <>
       <LoadingBar color="#7749F8" progress={progress} onLoaderFinished={() => setProgress(0)} />

      <div className="container tw-h-max tw-max-w-[1400px]">
        <div className="m-4 mt-2 d-flex">
          <h4>Question History</h4>
        </div>

        <div className="shadow p-4 rounded-3 bg-white">
          <div className="pb-4 justify-content-between d-flex flex-row">
            <div className="col-4 d-flex flex-row align-items-center">
              <div>Show</div>

              <ShowEntries
                onClick_item={handleshowItem}
                default_item={showItem}
              />

              <div className="">entries</div>
            </div>

            <div className="col-3 d-flex align-items-center">
              <div className="tooltips">
                <button
                  className="me-2 btn btn-light "
                  onClick={() => handleResetButton()}
                >
                  <FontAwesomeIcon icon={faArrowsRotate} />
                </button>
                <span className="tooltiptext">Reset</span>
              </div>
              <SearchColumn
                onChange_input={handleInputSearchChange}
                onClick_btn={handleSearchData}
              />
            </div>
          </div>

          <div className="table-responsive">
            <table className="table table-striped table-bordered">
              <thead className="tw-h-16">
                <tr>
                  {thTableQuestion.map((th, i) => {
                    return <HeadTable key={i} label={th} />;
                  })}
                  <th
                    colSpan={2}
                    style={{ textAlign: "center", verticalAlign: "middle" }}
                  >
                    View Detail
                  </th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(allQuestionHistory) &&
                  allQuestionHistory.length != 0 ? (
                  allQuestionHistory.map((dt, i) => {
                    return (
                      <tr key={i} style={{ verticalAlign: "middle" }}>
                        {pageNumber == 1 ? (
                          <td style={{ textAlign: "center" }}>{i + 1}</td>
                        ) : (
                          <td style={{ textAlign: "center" }}>
                            {i + 1 + showItem * (pageNumber - 1)}
                          </td>
                        )}
                        <td>{dt.goal_name}</td>
                        <td>{dt.group_survey}</td>
                        <td>{dt.dimension}</td>
                        <td>{dt.aspect}</td>
                        <td>{dt.item}</td>
                        <td style={{ textAlign: "center" }}>
                          <IconButtonView
                            onClick={() => handleOpenDetailQuestion(dt)}
                          />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td style={{ textAlign: "center" }} colSpan={7}>
                      No data
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <Pagination
            showing={
              Array.isArray(allQuestionHistory) ? allQuestionHistory.length : 0
            }
            totalItem={totalItem}
            showItem={showItem}
            previousPage={previousPage}
            nextPage={nextPage}
            paginate={paginate}
            limit={pageNumber}
          />
        </div>
        <ViewQuestion
          show={view}
          onHide={() => setView(false)}
          size={"lg"}
          question={question}
        />
      </div>
    </>
    
  );
}

export default QuestionBankView;

export function HeadTable({ label }) {
  return (
    <th style={{ textAlign: "center", verticalAlign: "middle" }}>{label}</th>
  );
}
