function Pagination({
  showing,
  totalItem,
  showItem,
  paginate,
  previousPage,
  nextPage,
  limit }) {
  const pageNumbers = [];


  for (let i = 1; i <= Math.ceil(totalItem / showItem); i++) {
    pageNumbers.push(i);
  }

  // Menentukan halaman pertama yang akan ditampilkan
  const startPage = Math.max(1, (limit - 2));
  // Menentukan halaman terakhir yang akan ditampilkan
  const endPage = Math.min(startPage + 4, pageNumbers.length);



  return (
    <div className="d-flex justify-content-between mt-4">
      <div className="tw-self-center">
        Showing {showing} of {totalItem} entries
      </div>
      <nav aria-label="Page navigation example">
        <ul className="pagination m-0">
          <li className="page-item" onClick={() => previousPage()}>
            <a
              className="page-link tw-rounded-l-lg tw-cursor-pointer tw-p-1 tw-shadow-sm tw-border tw-border-gray-300 
                      focus:tw-outline-none focus:tw-border-violet-500 focus:tw-ring-1 focus:tw-ring-violet-500"
            >
              Previous
            </a>
          </li>
          {
            pageNumbers.slice(startPage - 1, endPage).map((number, i) => {
              return (
                <li
                  className="page-item"
                  key={i}
                  onClick={() => paginate(number)}
                >
                  <a
                    className={`page-link tw-cursor-pointer tw-p-1 tw-shadow-sm tw-border tw-border-gray-300 
                              focus:tw-outline-none focus:tw-border-violet-500 focus:tw-ring-1 focus:tw-ring-violet-500 ${limit == number ? "tw-bg-gradient-to-tr tw-from-indigo-200 tw-to-indigo-100" : ""}`}
                  >
                    {number}
                  </a>
                </li>
              );
            })
          }

          <li className="page-item" onClick={() => nextPage()}>
            <a
              className="page-link tw-cursor-pointer tw-rounded-r-lg tw-p-1 tw-shadow-sm tw-border tw-border-gray-300 
                       focus:tw-outline-none focus:tw-border-violet-500 focus:tw-ring-1 focus:tw-ring-violet-500"
            >
              Next
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Pagination;
