export const TargetParticipantHistoryService = ({ doPost }) => {

    const getHistoryTargetParticipantData = async (business_unit_name,department_name,level,search,limit,page_number) => {
       try {
            return await doPost({
                url: '/admin/view_participant_history',
                data: {
                    business_unit_name: business_unit_name,
                    department_name: department_name,
                    level: level,
                    search: search,
                    limit: limit,
                    page_number: page_number 
                  },
            });
       } catch (error) {
          throw error;
       }
    };

    const getForSelectHistoryTP = async (reqData) => {
      try {
           return await doPost({
               url: '/admin/view_participant_history',
               data: reqData
           });
      } catch (error) {
         throw error;
      }
   };

   const getForSelectHistoryTPPICUrl = async (reqData) => {
      try {
           return await doPost({
               url: '/pic/view_participant_history',
               data: reqData
           });
      } catch (error) {
         throw error;
      }
   };

   

    const getHistoryTargetParticipantDataPICUrl = async (business_unit_name,department_name,level,search,limit,page_number) => {
      try {
           return await doPost({
               url: '/pic/view_participant_history',
               data: {
                   business_unit_name: business_unit_name,
                   department_name: department_name,
                   level: level,
                   search: search,
                   limit: limit,
                   page_number: page_number 
                 },
           });
      } catch (error) {
         throw error;
      }
   };
 
    return {
       getHistoryTargetParticipantData,
       getHistoryTargetParticipantDataPICUrl,
       getForSelectHistoryTP,
       getForSelectHistoryTPPICUrl
    };
 };