import { ACTIONS, REPORT_TYPE } from '../constants';
import AddReportTemplateView from '../AddReportTemplateView';
import UpdateReportTemplateView from '../UpdateReportTemplateView';

const TemplateMultiRaterIndividuForm = ({ action, role }) => {
    const viewProps = {
        role: role,
        formType: REPORT_TYPE.MULTI_INDIVIDU,
        pageTitle: action + ' Multi-Rater Report Individu Template',
        pageDescription: 'Description'
    };

    return (
        <>
            {action === ACTIONS.CREATE ? (
                <AddReportTemplateView {...viewProps} />
            ) : action === ACTIONS.UPDATE ? (
                <UpdateReportTemplateView {...viewProps} />
            ) : null}
        </>
    );
};

export default TemplateMultiRaterIndividuForm;
