import { ButtonPrimary } from "../../../shared/component/button/Button";
import { InputFile, InputOnly, TextAreaOnly } from "../../../shared/component/input/Input";
import useIntroduction from "./useIntroduction";

function IntroductionView() {
    const {
        introductionText,
        handleChangeText,
        handleSubmit
    } = useIntroduction();
    return(
        <div>
             <div className="d-flex justify-content-between align-items-center m-2">
                 <h5 > Introduction Page</h5>
                 <ButtonPrimary label="Save" onClick={()=>handleSubmit()}/>
                
             </div>
             <div className="col-10 m-4">
                 <form>
                     {/* <div className="row mb-3">
                         <div className="col-4">
                             <label>Image</label>
                         </div>
                         <div className="col-6">
                            <InputFile accept={"image/*"} input_width={'100%'} />
                         </div>
                     </div> */}
                     <div className="row mb-3">
                         <div className="col-4">
                             <label>Text</label>
                         </div>
                         <div className="col-6">
                            <TextAreaOnly type={'text'} input_height={'25vh'} input_width={'100%'} value={introductionText} handleOnChange={handleChangeText}/>
                         </div>
                     </div>
                 </form>
             </div>
        </div>
     )
}

export default IntroductionView;