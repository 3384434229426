export const ListRaterService = ({ doPost }) => {
  const getAllRaterList = async (
    limit,
    page_number,
    target_participant_id
  ) => {
    try {
      return await doPost({
        url: "/participant/list_rater_for_participant",
        data: {
          limit: limit,
          page_number: page_number,
          target_participant_id: target_participant_id,
        },
      });
    } catch (e) {
      throw e;
    }
  };

  return {
    getAllRaterList,
  };
};
