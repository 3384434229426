export const SingleRaterService = ({ doPost }) => {  
    const saveSingleRater = async (singleRaterData) => {
       try {
          return await doPost({
             url: '/admin/add_multirater',
             data: singleRaterData
          });
       } catch (err) {
          throw err;
       }
    }
    const getDetailSurveySinglerater = async (survey_code) => {
       try {
          return await doPost({
             url: '/admin/get_survey_detail',
             data: {
                survey_code: survey_code
             }
          });
       } catch (err) {
          throw err;
       }
    }
 
    const getSingleraterListData = async (search, limit, page_number,user_login) => {
       try {
          return await doPost({
             url: '/admin/get_list_survey_by_user',
             data: {
                search: search,
                created_by: "",
                user_login:user_login,
                survey_type:'single',
                limit: limit,
                page_number: page_number
             }
          });
       } catch (err) {
          throw err;
       }
    }
 
    
    const deleteSingleraterSurvey = async (survey_code) => {
       try {
          return await doPost({
             url: `/admin/delete_survey`,
             data: {
                survey_code: survey_code
             },
          });
       } catch (e) {
          throw e;
       }
    }
 
    
    const sendInvitationtoAllRaters = async (survey_code) => {
       try {
          return await doPost({
             url: `/admin/send_invitation_all_raters`,
             data: {
                survey_code: survey_code
             },
          });
       } catch (e) {
          throw e;
       }
    }
 
    const updateSingleRater = async (singleRaterData) => {
       try {
          return await doPost({
             url: '/pic/update_survey',
             data: singleRaterData
          });
       } catch (err) {
          throw err;
       }
    }
 
    /* Service for PIC */ 
    const getDetailSurveySingleraterPICUrl = async (survey_code) => {
       try {
          return await doPost({
             url: '/pic/get_survey_detail',
             data: {
                survey_code: survey_code
             }
          });
       } catch (err) {
          throw err;
       }
    }
 
    
    const getSingleraterListDataPICUrl = async (search, limit, page_number,user_login) => {
       try {
          return await doPost({
             url: '/pic/get_list_survey_by_user',
             data: {
                search: search,
                created_by: "",
                user_login:user_login,
                survey_type:'single',
                limit: limit,
                page_number: page_number
             }
          });
       } catch (err) {
          throw err;
       }
    }
 
    const deleteSingleraterSurveyPICUrl = async (survey_code) => {
       try {
          return await doPost({
             url: `/pic/delete_survey`,
             data: {
                survey_code: survey_code
             },
          });
       } catch (e) {
          throw e;
       }
    }
 
    const sendInvitationtoAllRatersPICUrl = async (survey_code) => {
       try {
          return await doPost({
             url: `/pic/send_invitation_all_raters`,
             data: {
                survey_code: survey_code
             },
          });
       } catch (e) {
          throw e;
       }
    }
 
    
    const saveSingleRaterPICUrl = async (singleRaterData) => {
       try {
          return await doPost({
             url: '/pic/add_multirater',
             data: singleRaterData
          });
       } catch (err) {
          throw err;
       }
    }
 
    const updateSingleRaterPICUrl = async (singleRaterData) => {
       try {
          return await doPost({
             url: '/pic/update_survey',
             data: singleRaterData
          });
       } catch (err) {
          throw err;
       }
    }
 
    return {
      saveSingleRater,
      getSingleraterListData,
      deleteSingleraterSurvey,
      getDetailSurveySinglerater,
      sendInvitationtoAllRaters,
      updateSingleRater,
      getSingleraterListDataPICUrl,
      deleteSingleraterSurveyPICUrl,
      getDetailSurveySingleraterPICUrl,
      sendInvitationtoAllRatersPICUrl,
      saveSingleRaterPICUrl,
      updateSingleRaterPICUrl
    };
 };