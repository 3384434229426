import { ADD_SCALE_CHOICE_SR, CLEAR_SCALE_CHOICE_SR, CLEAR_VIEW_CONTENT_UPDATE_SR, DELETE_CONTENT_CHOICE_SR, DELETE_CONTENT_SR, DELETE_OWNERSHIP_SR, DELETE_PARTICIPANT_SR, DELETE_SCALE_CHOICE_SR, DELETE_SECTION_SR, EDIT_CONTENT_CHOICE_SR, EDIT_CONTENT_SR, EDIT_PARTICIPANT_SR, EDIT_SCALE_CHOICE_SR, EDIT_SECTION_SR, LOAD_PARTICIPANT_SR, LOAD_SCALE_CHOICE_SR, LOAD_SCORE_SR, LOAD_SECTION_CONTENT_SR, LOAD_SETTING_SR, LOAD_SINGLE_RATER, OWNERSHIP_LIST_SR, RESET_CONTENT_VIEW_SR, RESET_IS_EDITING_SR, RESET_OWNERSHIP_SR, RESET_PARTICIPANT_SR, RESET_SCALE_CHOICE_SR, RESET_SCORE_SR, RESET_SECTION_SR, RESET_SETTING_LABEL_DATA_SR, RESET_SETTING_SR, RESET_SINGLE_RATER, RESET_VALID_INPUT, RESET_VIEW_EDIT_PARTICIPANT_KEY_EMAIL_SR, RESET_VIEW_EDIT_PARTICIPANT_SR, RESET_VIEW_TAB_SR, SET_CONTENT_BULK_SR, SET_CONTENT_CHOICE_SCALE_SR, SET_CONTENT_CHOICE_SR, SET_CONTENT_SR, SET_IS_EDITING_SR, SET_PARTICIPANT_SR, SET_SCALE_CHOICE_SR, SET_SCORE_SR, SET_SECTION_SR, SET_SETTING_LABEL_DATA_SR, SET_SETTING_SR, SET_SINGLE_RATER, SET_VALID_CONTENT, SET_VALID_PARTICIPANT, SET_VALID_SETTING, SET_VIEW_CONTENT_UPDATE_SR, SET_VIEW_EDIT_PARTICIPANT_KEY_EMAIL_SR, SET_VIEW_EDIT_PARTICIPANT_SR, SET_VIEW_SR } from "./UpdateSingleRaterType";

// define action constant
const setSRViewAction = (reqData) => ({
   type: SET_VIEW_SR,
   data: reqData
});

const resetViewTabSR = () => ({
   type: RESET_VIEW_TAB_SR
});

const loadSRAction = (reqData) => ({
   type: LOAD_SINGLE_RATER,
   data: reqData
});

const setSRAction = (reqData) => ({
   type: SET_SINGLE_RATER,
   data: reqData
});

const resetSingleRater = () => ({
   type: RESET_SINGLE_RATER
});

const loadSettingSRAction = (reqData) => ({
   type: LOAD_SETTING_SR,
   data: reqData
});

const setSettingSRAction = (reqData) => ({
   type: SET_SETTING_SR,
   data: reqData
});

const resetSettingSR = () => ({
   type: RESET_SETTING_SR
});

const setContentViewSRAction = (reqData) => ({
   type: SET_VIEW_CONTENT_UPDATE_SR,
   data: reqData
});

const clearContentViewSRAction = () => ({
   type: CLEAR_VIEW_CONTENT_UPDATE_SR
});

const resetContentViewSR = () => ({
   type: RESET_CONTENT_VIEW_SR
});

const loadSectionContentSRAction = (reqData) => ({
   type: LOAD_SECTION_CONTENT_SR,
   data: reqData
});

const setSectionSRAction = (reqData) => ({
   type: SET_SECTION_SR,
   data: reqData
});

const editSectionSRAction = (reqData) => ({
   type: EDIT_SECTION_SR,
   data: reqData
});

const deleteSectionSRAction = (reqData) => ({
   type: DELETE_SECTION_SR,
   data: reqData
});

const resetSectionSR = () => ({
   type: RESET_SECTION_SR
});

const setContentSRAction = (reqData) => ({
   type: SET_CONTENT_SR,
   data: reqData
});

const setContentBulkSRAction = (reqData) => ({
   type: SET_CONTENT_BULK_SR,
   data: reqData
});

const editContentSRAction = (reqData) => ({
   type: EDIT_CONTENT_SR,
   data: reqData
});

const deleteContentSRAction = (reqData) => ({
   type: DELETE_CONTENT_SR,
   data: reqData
});

const loadScaleChoiceSRAction = (reqData) => ({
   type: LOAD_SCALE_CHOICE_SR,
   data: reqData
});

const setScaleChoiceSRAction = (reqData) => ({
   type: SET_SCALE_CHOICE_SR,
   data: reqData
});

const addScaleChoiceSRAction = (reqData) => ({
   type: ADD_SCALE_CHOICE_SR,
   data: reqData
});

const editScaleChoiceSRAction = (reqData) => ({
   type: EDIT_SCALE_CHOICE_SR,
   data: reqData
});

const deleteScaleChoiceSRAction = (reqData) => ({
   type: DELETE_SCALE_CHOICE_SR,
   data: reqData
});

const clearScaleChoiceSRAction = (reqData) => ({
   type: CLEAR_SCALE_CHOICE_SR,
   data: reqData
});

const resetScaleChoiceSR = () => ({
   type: RESET_SCALE_CHOICE_SR
});

const setContentChoiceSRAction = (reqData) => ({
   type: SET_CONTENT_CHOICE_SR,
   data: reqData
});

const editContentChoiceSRAction = (reqData) => ({
   type: EDIT_CONTENT_CHOICE_SR,
   data: reqData
});

const deleteContentChoiceSRAction = (reqData) => ({
   type: DELETE_CONTENT_CHOICE_SR,
   data: reqData
});

const loadScoreSRAction = (reqData) => ({
   type: LOAD_SCORE_SR,
   data: reqData
});

const setScoreSRAction = (reqData) => ({
   type: SET_SCORE_SR,
   data: reqData
});

const resetScoreSR = () => ({
   type: RESET_SCORE_SR
});

const loadParticipantSRAction = (reqData) => ({
   type: LOAD_PARTICIPANT_SR,
   data: reqData
});

const setParticipantSRAction = (reqData) => ({
   type: SET_PARTICIPANT_SR,
   data: reqData
});

const editParticipantSRAction = (reqData) => ({
   type: EDIT_PARTICIPANT_SR,
   data: reqData
});

const deleteParticipantSRAction = (reqData) => ({
   type: DELETE_PARTICIPANT_SR,
   data: reqData
});

const resetParticipantSR = () => ({
   type: RESET_PARTICIPANT_SR
});

const setEditParticipantViewSR = (reqData) => ({
   type: SET_VIEW_EDIT_PARTICIPANT_SR,
   data: reqData
});

const resetViewEditParticipantSR = () => ({
   type: RESET_VIEW_EDIT_PARTICIPANT_SR
});

const setIsEditingActionSR = (reqData) => ({
   type: SET_IS_EDITING_SR,
   data: reqData
});

const resetIsEditingActionSR = (reqData) => ({
   type: RESET_IS_EDITING_SR
});

// TODO: FIX SCALE CHOICE
const setContentChoiceScaleSRAction = (reqData) => ({
   type: SET_CONTENT_CHOICE_SCALE_SR,
   data: reqData
});

const setValidSettingSRAction = (reqData) => ({
   type: SET_VALID_SETTING,
   data: reqData
});

const setValidContentSRAction = (reqData) => ({
   type: SET_VALID_CONTENT,
   data: reqData
});

const setValidParticipantSRAction = (reqData) => ({
   type: SET_VALID_PARTICIPANT,
   data: reqData
});

const resetValidInputSRAction = (reqData) => ({
   type: RESET_VALID_INPUT,
   data: reqData
});

const listOwnershipSR = (reqData) => ({
   type: OWNERSHIP_LIST_SR,
   data: reqData
});

const resetListOwnershipSR = (reqData) => ({
   type: RESET_OWNERSHIP_SR,
  
});

const deleteOwnershipSR = (reqData) => ({
   type: DELETE_OWNERSHIP_SR,
   data: reqData
});

const setEditParticipantViewByEmailSR = (reqData) => ({
   type: SET_VIEW_EDIT_PARTICIPANT_KEY_EMAIL_SR,
   data: reqData
});

const resetViewEditParticipantByEmailSR = () => ({
   type: RESET_VIEW_EDIT_PARTICIPANT_KEY_EMAIL_SR
});

const setSettingLabelDataSRAction = (reqData) => ({
   type: SET_SETTING_LABEL_DATA_SR,
   data: reqData
});

const resetSettingLabelDataSR = () => ({
   type: RESET_SETTING_LABEL_DATA_SR
});


export default {
   setSRViewAction,
   resetViewTabSR,

   loadSRAction,
   setSRAction,
   resetSingleRater,

   loadSettingSRAction,
   setSettingSRAction,
   resetSettingSR,

   setContentViewSRAction,
   clearContentViewSRAction,
   resetContentViewSR,

   loadSectionContentSRAction,
   setSectionSRAction,
   editSectionSRAction,
   deleteSectionSRAction,
   resetSectionSR,

   setContentSRAction,
   setContentBulkSRAction,
   editContentSRAction,
   deleteContentSRAction,

   loadScaleChoiceSRAction,
   setScaleChoiceSRAction,
   addScaleChoiceSRAction,
   editScaleChoiceSRAction,
   deleteScaleChoiceSRAction,
   clearScaleChoiceSRAction,
   resetScaleChoiceSR,

   setContentChoiceSRAction,
   editContentChoiceSRAction,
   deleteContentChoiceSRAction,

   loadScoreSRAction,
   setScoreSRAction,
   resetScoreSR,

   loadParticipantSRAction,
   setParticipantSRAction,
   editParticipantSRAction,
   deleteParticipantSRAction,
   resetParticipantSR,

   setEditParticipantViewSR,
   resetViewEditParticipantSR,

   setIsEditingActionSR,
   resetIsEditingActionSR,

   setValidSettingSRAction,
   setValidContentSRAction,
   setValidParticipantSRAction,
   resetValidInputSRAction,

   listOwnershipSR,
   resetListOwnershipSR,
   deleteOwnershipSR,

   setEditParticipantViewByEmailSR,
   resetViewEditParticipantByEmailSR,

   setSettingLabelDataSRAction,
   resetSettingLabelDataSR

}