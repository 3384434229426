import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Footer.css";
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { ButtonPrimary } from "../../../shared/component/button/Button";

export const Footer = ({ onClickNext, onClickPrev, disableNext, disablePrev }) => {
  return (
    <div className="FooterParticipant" >
   
      <div>
        {disablePrev ?
          '' : <ButtonPrimary style={{ maxWidth: '30px', maxHeight: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            label={<FontAwesomeIcon icon={faCaretLeft} size="xl" />}
            onClick={onClickPrev}
          />
        }

      </div>

      <div>
        {disableNext ? '' : <ButtonPrimary style={{ maxWidth: '30px', maxHeight: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          label={<FontAwesomeIcon icon={faCaretRight} size="xl" />} onClick={onClickNext}
        />}
      </div>
    </div>
  );
};


export const FooterPreview = ({ onClickNext, onClickPrev, disableNext, disablePrev }) => {
  return (
    <div className="FooterPreview" >
   
      <div>
        {disablePrev ?
          '' : <ButtonPrimary style={{ maxWidth: '30px', maxHeight: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            label={<FontAwesomeIcon icon={faCaretLeft} size="xl" />}
            onClick={onClickPrev}
          />
        }

      </div>

      <div>
        {disableNext ? '' : <ButtonPrimary style={{ maxWidth: '30px', maxHeight: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          label={<FontAwesomeIcon icon={faCaretRight} size="xl" />} onClick={onClickNext}
        />}
      </div>
    </div>
  );
};
