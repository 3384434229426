export const PICListService = ({  doPost, doDelete }) => {
    const getAllPIC = async (limit,page_number) => {
        try {
            return await doPost({
                url: '/admin/get_all_pc',
                data: {
                    limit: limit,
                    page_number: page_number
                },
            });
        } catch (e) {
            throw e;
        }
    };

    const postPIC = async (nik,name,email,number) => {
        try {
            return await doPost({
                url: '/admin/assign_user_projectComitee',
                data: {
                    nik: nik,
                    name: name,
                    email: email,
                    number: number,
                  
                },
            });
        } catch (e) {
            throw e;
        }
    };

    const deletePIC = async (nik,name,email,number) => {
        try {
            return await doPost({
                url: '/admin/delete_user_pc',
                data: {
                    nik: nik,
                    name: name,
                    email: email,
                    number: number,
                  
                },
            });
        } catch (e) {
            throw e;
        }
    };

    const checkNIK = async (nik) => {
        try {
            return await doPost({
                url: `/admin/check_user_master_admin`,
                data: {
                    user_nik: nik,
                },
            });
        } catch (e) {
            throw e;
        }
    }

    const searchData = async (limit,page_number,search) => {
        try {
            return await doPost({
                url: `/admin/get_all_pc_search`,
                data:{
                    limit: limit,
                    page_number: page_number,
                    search_bar: search
                  },
            });
        } catch (e) {
            throw e;
        }
    };

    return {
       getAllPIC,
       postPIC,
       deletePIC,
       checkNIK,
       searchData
    };
};
