import { useSelector } from "react-redux";
import { TableSingleRater } from "./TableSinglerater";

export const SingleraterSurveyFormList = () => {
  const user = useSelector((state) => state.user);

  return (
    <div
      className="tw-p-4 tw-py-4 tw-pb-[5rem]  tw-w-full 
        tw-justify-center tw-h-full tw-overflow-auto tw-bg-slate-50"
    >
      <div style={{ textAlign: "center", color: "#5227CC" }}>
        Techconnect Single-Rater Survey Form
        <br/>
          Hi <b>{user.name}</b> ! You are requested to fill out the following surveys.
      </div>
      <div>
        <TableSingleRater/>
      </div>
    </div>
  );
};
