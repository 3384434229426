import { useEffect, useState } from "react";
import { useDeps } from "../../../shared/context/DependencyContext";
import { useSelector } from "react-redux";
import screenToast from "../../../shared/hook/ScreenToast";
import useViewState from "../../../shared/hook/UseViewState";
import { CONSTANTS } from "../../../common/constants";
import screenAlert from "../../../shared/hook/SweetAlert";

const useSingleraterList = () => {
    const {adminService ,picService} = useDeps();
    const [allSRSurvey, setAllSRSurvey] = useState([]);
    const [showItem, setShowItem] = useState(10);
    const [changeData, setChangeData] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [totalItem, setTotalItem] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const user = useSelector((state) => state.user);
    const showToast = screenToast();
    const { viewState, setError, setSuccess } = useViewState();
    const [progress, setProgress] = useState(0);
    const showAlert = screenAlert();



    useEffect(() => {
        if (pageNumber == 1) {
          onGetSingleraterList(searchText,showItem,pageNumber);
        }else{
          setPageNumber(1);
        }
    }, [showItem, changeData])

    useEffect(() => {
      onGetSingleraterList(searchText,showItem,pageNumber);
    }, [pageNumber])

    useEffect(() => {
      if (viewState.isError == true) {
          showToast.error(viewState.errorMsg);
          setError(false, '');
      }

      if (viewState.isSuccess == true) {
          showToast.success(viewState.successMsg);
          setSuccess(false, '');
      }
    }, [viewState]);

    const onGetSingleraterList = async (search,limit,page_number) => {
      setProgress(100);
      
      
        try {
          setProgress(30);
          if (user.role == CONSTANTS.ROLE.ADMIN) {
            const response = await adminService.singleRaterService.getSingleraterListData(search,limit,page_number,user.email);
            setProgress(60);
            
            if (response.status == 'SUCCESS') {
                setAllSRSurvey(response.data.data);
                setTotalItem(response.data.total_item)
              
            }
          } else {
            const response = await picService.singleRaterService.getSingleraterListDataPICUrl(search,limit,page_number,user.email);
            setProgress(60);
            
            if (response.status == 'SUCCESS') {
                setAllSRSurvey(response.data.data);
                setTotalItem(response.data.total_item)
                // console.log(response.data.data);
              
            }
          }

            
        } catch (error) {
            console.log(error)
         
        } finally {
          setProgress(100);
        }
    }

    const onDeleteSurvey = async (survey_code) => {
      try {
        if (user.role == CONSTANTS.ROLE.ADMIN) {
            const response = await adminService.singleRaterService.deleteSingleraterSurvey(survey_code);

            if (response.status == 'SUCCESS') {
              setChangeData(!changeData);
              setSuccess(true,'Deleted Successfully')

            }else {
                setError(true,response.status)
            }
        } else {
            const response = await picService.singleRaterService.deleteSingleraterSurveyPICUrl(survey_code);

          
              if (response.status == 'SUCCESS') {
                setChangeData(!changeData);
                setSuccess(true,'Deleted Successfully')
              }else {
                  setError(true,response.status)
              }
        }
        
      } catch (error) {
      console.log(error)
      setError(true,'Error System')
        } finally {
            
        }
    }

    const onSendInvitationtoAllRaters = async (survey_code) => {
      try {
        if (user.role == CONSTANTS.ROLE.ADMIN) {
          const response = await adminService.singleRaterService.sendInvitationtoAllRaters(survey_code);

        
          if (response.status == 'SUCCESS') {
            setChangeData(!changeData);
            setSuccess(true,'Invitation Sent')
          }else {
              setError(true,response.status)
          }
        } else {
              const response = await picService.singleRaterService.sendInvitationtoAllRatersPICUrl(survey_code);

            
            if (response.status == 'SUCCESS') {
              setChangeData(!changeData);
              setSuccess(true,'Invitation Sent')
            }else {
                setError(true,response.status)
            }
        }
        
      } catch (error) {
      console.log(error)
      setError(true,'Error System')
        } finally {
            
        }
    }

    const handleSearchBar = () => {
      onGetSingleraterList(searchText,showItem,pageNumber);
    }

    const handleChangeSearchText = (e) => {
        setSearchText(e.target.value);
    }

    const handleShowItem = (item) => {
      setShowItem(item);
    }

    const handleDeleteSurvey = (survey_code) => {
        showAlert.warning({
            text: "Are you sure to delete this?",
            buttons: {
                cancel: {
                    text: "Cancel",
                    className: ""
                },
                confirm: {
                    text: "Yes",
                    value: true,
                    className: "bg-primary"
                }
            }
        }).then(value => {
            if (value) {
                onDeleteSurvey(survey_code);
            }
        });
      
  }

 
    return{
      allSRSurvey,
      pageNumber,
      setPageNumber,
      searchText,
      setSearchText,
      showItem,
      setShowItem,
      totalItem,
      handleChangeSearchText,
      handleDeleteSurvey,
      handleSearchBar,
      handleShowItem,
      onSendInvitationtoAllRaters,
      progress,
      setProgress

    }
}

export default useSingleraterList;

const responseSurveyList = {
    status: "SUCCESS",
    data: {
      limit: 10,
      page: 1,
      total_item: 3,
      data: [
        {
          survey_code: "MRC202402155035",
          survey_name: "Nama survey ganti lagi",
          created_by: "novita.widyastuti@sinarmasmining.com",
          is_active: 1,
          total_field: 3
        },
        {
          survey_code: "MRC202402155035",
          survey_name: "Nama Survey 1",
          created_by: "novita.widyastuti@techconnect.co.id",
          is_active: 1,
          total_field: 3
        },
        {
          survey_code: "MRC202402155035",
          survey_name: "Nama Survey",
          created_by: "Admin",
          is_active: 1,
          total_field: 3
        }
      ]
    }
  }