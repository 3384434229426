export const DashboardListService = ({ doPost }) => {
 
    const getDashboardMetrics= async () => {
       try {
           return await doPost({ url: '/admin/dashboard/metrics' });
       } catch (error) {
           throw error;
       }
    };
 
    const active_single_rater_surveys= async () => {
        try {
            return await doPost({ url: '/admin/dashboard/active_single_rater_surveys' });
        } catch (error) {
            throw error;
        }
     };
    const active_multi_rater_surveys= async () => {
        try {
            return await doPost({ url: '/admin/dashboard/active_multi_rater_surveys' });
        } catch (error) {
            throw error;
        }
     };
    const top_5_active_participant_by_department= async () => {
        try {
            return await doPost({ url: '/admin/top_5_active_participant_by_department' });
        } catch (error) {
            throw error;
        }
     };
    const top_5_active_participant_by_business_unit= async () => {
        try {
            return await doPost({ url: '/admin/top_5_active_participant_by_business_unit' });
        } catch (error) {
            throw error;
        }
     };
    
    const yearly_survey_data= async () => {
        try {
            return await doPost({ url: '/admin/yearly_survey_data' });
        } catch (error) {
            throw error;
        }
     };
    const monthly_single_raters= async () => {
        try {
            return await doPost({ url: '/admin/monthly_single_rater_data' });
        } catch (error) {
            throw error;
        }
     };
    const monthly_multi_raters= async () => {
        try {
            return await doPost({ url: '/admin/monthly_multi_rater_data' });
        } catch (error) {
            throw error;
        }
     };
    const yearly_status_single_data= async () => {
        try {
            return await doPost({ url: '/admin/yearly_status_single_rater_data' });
        } catch (error) {
            throw error;
        }
     };
     const monthly_status_single_data= async () => {
        try {
            return await doPost({ url: '/admin/monthly_status_single_rater_data' });
        } catch (error) {
            throw error;
        }
     };
     const yearly_status_multi_data= async () => {
        try {
            return await doPost({ url: '/admin/yearly_status_multi_rater_data' });
        } catch (error) {
            throw error;
        }
     };
     const monthly_status_multi_data= async () => {
        try {
            return await doPost({ url: '/admin/monthly_status_multi_rater_data' });
        } catch (error) {
            throw error;
        }
     };
    const top_goals= async () => {
        try {
            return await doPost({ url: '/admin/top_goals' });
        } catch (error) {
            throw error;
        }
     };
     const top_groups= async () => {
        try {
            return await doPost({ url: '/admin/top_groups' });
        } catch (error) {
            throw error;
        }
     };

     const top_dimensions= async () => {
        try {
            return await doPost({ url: '/admin/top_dimensions' });
        } catch (error) {
            throw error;
        }
     };

     const top_aspects= async () => {
        try {
            return await doPost({ url: '/admin/top_aspects' });
        } catch (error) {
            throw error;
        }
     };

     const top_10_pic_by_department= async () => {
        try {
            return await doPost({ url: '/admin/get_top_10_pic_by_department' });
        } catch (error) {
            throw error;
        }
     };
     const top_10_pic_by_business_unit= async () => {
        try {
            return await doPost({ url: '/admin/get_top_10_pic_by_business_unit' });
        } catch (error) {
            throw error;
        }
     };
    const top_10_single_rater_by_participant= async () => {
        try {
            return await doPost({ url: '/admin/get_top_10_single_rater_by_participant' });
        } catch (error) {
            throw error;
        }
     };
     const top_10_multi_rater_by_participant= async () => {
        try {
            return await doPost({ url: '/admin/get_top_10_multi_rater_by_participant' });
        } catch (error) {
            throw error;
        }
     };

     const server_time= async () => {
        try {
            return await doPost({ url: '/admin/server-time' });
        } catch (error) {
            throw error;
        }
     };
    return {
        getDashboardMetrics,
        active_multi_rater_surveys,
        active_single_rater_surveys,
       top_goals,
       top_groups,
       top_dimensions,
       top_aspects,
       top_10_pic_by_business_unit,
       top_10_pic_by_department,
       yearly_survey_data,
       monthly_multi_raters,
       monthly_single_raters,
       yearly_status_single_data,
       monthly_status_single_data,
       yearly_status_multi_data,
       monthly_status_multi_data,
       top_5_active_participant_by_department,
       top_5_active_participant_by_business_unit,
       top_10_single_rater_by_participant,
       top_10_multi_rater_by_participant,
       server_time,
    
    };
 };