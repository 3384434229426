import { useEffect, useState } from "react";
import { useDeps } from "../../shared/context/DependencyContext";
import swal from "sweetalert";
import screenToast from "../../shared/hook/ScreenToast";
import useViewState from "../../shared/hook/UseViewState";
import screenAlert from "../../shared/hook/SweetAlert";

function useAdminList() {
    const { adminService } = useDeps();
    const [allAdmin, setAllAdmin] = useState([]);
    const [isloading, setIsloading] = useState(false);
    const [modalAdd, setModalAdd] = useState(false);
    const [userNik, setUserNik] = useState('');
    const [userName, setUserName] = useState('');
    const [userNumber, setUserNumber] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [nikFound, setNikFound] = useState(false);
    const [showItem, setShowItem] = useState(10);
    const [changeData, setChangeData] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [totalItem, setTotalItem] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [userDep, setUserDep] = useState('');
    const [userBU, setUserBU] = useState('');
    const showToast = screenToast();
    const { viewState, setError, setSuccess } = useViewState();
    const [progress, setProgress] = useState(0);
    const showAlert = screenAlert();


    useEffect(() => {
        if (pageNumber == 1) {
            onSearchData(showItem, pageNumber, searchText);
        }
        setPageNumber(1);

    }, [showItem, changeData])

    useEffect(() => {
        onSearchData(showItem, pageNumber, searchText);
    }, [pageNumber])


    useEffect(() => {
        if (viewState.isError == true) {
            showToast.error(viewState.errorMsg);
            setError(false, '');
        }

        if (viewState.isSuccess == true) {
            showToast.success(viewState.successMsg);
            setSuccess(false, '');
        }
    }, [viewState]);


    const onGetAllAdmin = async (limit, page_number) => {
        setProgress(10);
        setIsloading(true);
        try {
            setProgress(30);
            const response = await adminService.adminListService.getAllAdmin(limit, page_number);

            if (response.status == 'SUCCESS') {
                setAllAdmin(response.data.data);
                setProgress(60);
                setTotalItem(response.data.totalItem)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsloading(false);
            setProgress(100);

        }

    }

    const onDeleteAdmin = async (index, nik, name, email, number) => {
        setIsloading(true);
        try {
            const response = await adminService.adminListService.deleteAdmin(nik, name, email, number);

            if (response.status == 'SUCCESS') {
                setChangeData(!changeData);
                setSuccess(true, 'Deleted Successfully')
            } else {
                setError(true, response.status)
            }
        } catch (error) {
            console.log(error)
            setError(true, 'Error System')
        } finally {
            setIsloading(false);
        }
    }

    const onCheckNIK = async () => {
        setUserName('');
        setUserEmail('');
        setUserNumber('');
        setUserBU('');
        setUserDep('');
        setIsloading(true);
        try {
            const response = await adminService.adminListService.checkNIK(userNik);
            if (response.status == 'SUCCESS') {
                if (response.data == 'NO DATA') {
                    setError(true, 'Nik not found')
                } else {
                    setNikFound(true);
                    setUserName(response.data.full_name);
                    setUserEmail(response.data.email);
                    setUserNumber(response.data.phone_number);
                    setUserBU(response.data.business_unit_name);
                    setUserDep(response.data.department_name)
                }
            } else {
                setError(true, response.status);
                setNikFound(false);
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsloading(false);
        }
    }

    const onPostAdmin = async (nik, name, email, number) => {
        setIsloading(true);
        try {
            const response = await adminService.adminListService.postAdmin(nik, name, email, number);

            if (response.status == 'SUCCESS') {
                setChangeData(!changeData);
                setSuccess(true, 'Saved Successfully')
            } else {
                setError(true, response.status)
            }
        } catch (error) {
            console.log(error)
            setError(true, 'Error System')
        } finally {
            setIsloading(false);
        }
    }

    const onSearchData = async (limit, page_number, search) => {
        setIsloading(true);
        setProgress(10);
        try {
            setProgress(30);
            const response = await adminService.adminListService.searchData(limit, page_number, search);

            if (response.status == 'SUCCESS') {
                setProgress(60);
                setAllAdmin(response.data.data);
                setTotalItem(response.data.totalItem)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsloading(false);
            setProgress(100);
        }
    }

    const handleChangeUserNIK = (e) => {
        setUserNik(e.target.value);
        setNikFound(false);
    }

    const handleChangeUserEmail = (e) => {
        setUserEmail(e.target.value);
    }

    const handleChangeUserNumber = (e) => {
        setUserNumber(e.target.value);
    }

    const handleHideModal = () => {
        setUserNik('');
        setUserName('');
        setUserEmail('');
        setUserNumber('');
        setUserBU('');
        setUserDep('');
        setModalAdd(false);
        setNikFound(false);
    }

    const handleSaveAdmin = async () => {
        await onPostAdmin(userNik, userName, userEmail, userNumber);
        setUserNik('');
        setUserName('');
        setUserEmail('');
        setUserNumber('');
        setUserBU('');
        setUserDep('');
        setModalAdd(false);
        setNikFound(false);
    }

    const handleshowItem = (item) => {
        setShowItem(item);
    }

    const handleInputSearchChange = (e) => {
        setSearchText(e.target.value);
        // onSearchData(showItem,1,e.target.value);
    }

    const handleSearchData = () => {
        if (pageNumber == 1) {
            onSearchData(showItem, pageNumber, searchText)
        } else {
            setPageNumber(1);
        }
    }

    const handleDeleteAdmin = (index, nik, name, email, number) => {
        showAlert.warning({
            text: "Are you sure to delete this?",
            buttons: {
                cancel: {
                    text: "Cancel",
                    className: ""
                },
                confirm: {
                    text: "Yes",
                    value: true,
                    className: "bg-success"
                }
            }
        }).then(value => {
            if (value) {
                onDeleteAdmin(index, nik, name, email, number);
            }
        });
    }


    return {
        onGetAllAdmin,
        allAdmin,
        isloading,
        onDeleteAdmin,
        handleChangeUserNIK,
        onCheckNIK,
        userNik,
        userName,
        userEmail,
        userNumber,
        handleChangeUserEmail,
        handleChangeUserNumber,
        modalAdd,
        setModalAdd,
        handleHideModal,
        nikFound,
        setNikFound,
        handleSaveAdmin,
        handleshowItem,
        showItem,
        onSearchData,
        handleInputSearchChange,
        searchText,
        handleDeleteAdmin,
        totalItem,
        pageNumber,
        setPageNumber,
        handleSearchData,
        userBU,
        userDep,
        progress,
        setProgress
    }

}

export default useAdminList;