import axios from 'axios';
import axiosRetry from 'axios-retry';
import { authHeaderInterceptor } from './interceptors/AuthHeaderInterceptors';
import { authExpiredInterceptor, responseHandler } from './interceptors/AuthExpiredInterceptors';

export const clientInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
    },
    responseType: "json"
});
clientInstance.interceptors.request.use(authHeaderInterceptor);
clientInstance.interceptors.response.use(responseHandler, authExpiredInterceptor);

axiosRetry(clientInstance, {
    retries: 5,
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition: (error) => {
        if (error.code === 'ERR_NETWORK') {
            return true;
        }
        return error.response && error.response.status === 500;
    }
});