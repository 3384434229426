import { useSelector } from "react-redux";
import { BodyFooter } from "../../../shared/component/card/BodyFooter"
import { useState } from "react";


export const MultiraterSurveyFormList = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const user = useSelector((state) => state.user);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };

    return windowWidth >= 500 ? (
        <div className="tw-p-4 tw-py-4 tw-pb-[5rem]  tw-w-full 
        tw-justify-center tw-h-full tw-overflow-auto tw-bg-slate-50">
            <div style={{ textAlign: "center", color: "#5227CC" }}>
                <h2>Techconnect Multi-Rater Survey Form</h2>
                <h6>
                    Hi {user.name} ! You are requested to provide feedback for the below participants.
                </h6>

            </div>
            <div>
                <BodyFooter />
            </div>
        </div>
    ) : (
        <div className="tw-p-4 tw-py-4 tw-pb-[5rem]  tw-w-full 
        tw-justify-center tw-h-full tw-overflow-auto tw-bg-slate-50">
            <div style={{ textAlign: "center", color: "#5227CC" }}>
               Techconnect Multi-Rater Survey Form
               <br/>
                Hi <b>{user.name}</b> ! You are requested to provide feedback for the below participants.
                

            </div>
            <div>
                <BodyFooter />
            </div>
        </div>
    )
}