// src/redux/reducers/spreadsheetReducer.js

import {
    OPEN_SPREADSHEET_IMPORT,
    CLOSE_SPREADSHEET_IMPORT,
    SET_IMPORTED_DATA
} from '../SpreadSheet/SpreadSheetType';

const initialState = {
    isOpen: false,
    importedData: []
};

const spreadSheetReducer = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_SPREADSHEET_IMPORT:
            return {
                ...state,
                isOpen: true
            };
        case CLOSE_SPREADSHEET_IMPORT:
            return {
                ...state,
                isOpen: false
            };
        case SET_IMPORTED_DATA:
            return {
                ...state,
                importedData: action.payload
            };
        default:
            return state;
    }
};

export default spreadSheetReducer;
