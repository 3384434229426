import { useState } from 'react';

const usePagination = () => {
    // States
    const [showItem, setShowItem] = useState(10); //limit
    const [pageNumber, setPageNumber] = useState(1); //page

    // Handlers
    const handleShowItem = setShowItem;
    const handlePaginate = setPageNumber;
    const handlePreviousPage = () => {
        if (pageNumber !== 1) {
            setPageNumber(pageNumber - 1);
        }
    };
    const getIndexNo = (i, page, limit) => {
        return i + 1 + (page === 1 ? 0 : limit * (page - 1));
    };

    // Props
    const paginationProps = {
        showItem: showItem,
        previousPage: handlePreviousPage,
        paginate: handlePaginate,
        limit: pageNumber
    };

    return {
        showItem,
        setShowItem,
        pageNumber,
        setPageNumber,
        paginationProps,
        handleShowItem,
        handlePaginate,
        handlePreviousPage,
        getIndexNo
    };
};

export default usePagination;
