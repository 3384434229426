import { useEffect } from "react";
import { useDeps } from "../../../../shared/context/DependencyContext"
import screenToast from "../../../../shared/hook/ScreenToast";
import useViewState from "../../../../shared/hook/UseViewState";
import { useDispatch } from "react-redux";
import { rootAction } from "../../../../redux/RootAction";

function useTabPICProject() {
    const {picService} = useDeps();
    const showToast = screenToast();
    const { viewState, setError, setSuccess } = useViewState();
    const dispatch = useDispatch();


    useEffect(() => {
        if (viewState.isError == true) {
            showToast.error(viewState.errorMsg);
            setError(false, '');
        }

        if (viewState.isSuccess == true) {
            showToast.success(viewState.successMsg);
            setSuccess(false, '');
        }
    }, [viewState]);

    const handleDeletePicProject = async (survey_code,pic_email,user_login) => {

        try {
             const response = await picService.sharingSurveyService.deleteOwnership({
                survey_code:survey_code,
                email:pic_email
             })

             if (response.status == 'SUCCESS') {
                dispatch(rootAction.updateSingleRater.deleteOwnershipSR(pic_email))
                setSuccess(true,"Deleted Successfully")
             } else {
                setError(true,"FAILED")
             }
        } catch (error) {
            console.log(error);
            setError(true,"FAILED SYSTEM")
        }finally{

        }
    }

    return{
        handleDeletePicProject
    }
    
}

export default useTabPICProject