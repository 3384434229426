import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CONSTANTS } from "../../../common/constants";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { IconButtonGeneral } from "../../../shared/component/button/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { rootAction } from "../../../redux/RootAction";
import { clearParticipantAccountData } from "../../../common/utils";
import trendyLogo from "../../../assets/images/Trendy-horizontal-color.png";

/**
 * This part of code is made using Tailwind.css
 */

export function NavbarParticipant({ isExpanded }) {
   const [navEmail, setNavEmail] = useState('');
   const [windowWidth, setWindowWidth] = useState(window.innerWidth);
   const authReducer = useSelector((state) => state.user);
   const navigate = useNavigate();

   const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

   // render first time only
   useEffect(() => {
      setNavEmail(authReducer.email);
   }, []);
   

   return windowWidth >= 500 ? (
      <nav className={`tw-w-full tw-h-14 tw-flex tw-backdrop-filter tw-backdrop-blur-md 
                     tw-bg-white/80 tw-fixed tw-border-r tw-shadow-sm`}
         style={{ zIndex: 40 }}>
         <div className={`tw-flex tw-items-center tw-justify-end tw-font-medium tw-w-1/6`}>
            {!isExpanded &&
               <img
                  src={trendyLogo}
                  className={`tw-overflow-hidden tw-transition-all ${!isExpanded ? "tw-w-36 2xl:tw-w-40" : "tw-w-0"
                     }`}
                  alt=""
                  onClick={()=>navigate(CONSTANTS.ROUTER.PARTICIPANT_INTRODUCTION)}

               />
            }
         </div>

         <div className="tw-flex tw-items-center tw-font-medium tw-w-2/6">

         </div>
         <div className="tw-flex tw-items-center tw-font-medium tw-w-1/6"></div>
         <div className={`tw-flex tw-items-center tw-justify-end tw-font-medium tw-w-2/6
                         tw-p-1`}>
            <div className={`tw-flex tw-justify-between tw-items-center tw-overflow-hidden tw-transition-all tw-mr-4`}>
               <NavLinksParticipant navEmail={navEmail} />
            </div>
         </div>
      </nav>
   ) : (
      <nav className={`tw-w-full tw-h-14 tw-flex tw-backdrop-filter tw-backdrop-blur-md 
                     tw-bg-white/80 tw-fixed tw-border-r tw-shadow-sm`}
         style={{ zIndex: 40 }}>
            <div className={`tw-flex tw-items-center tw-justify-end tw-font-medium tw-w-1/3`}>
            {!isExpanded &&
               <img
                  src={trendyLogo}
                  className={`tw-overflow-hidden tw-transition-all ${!isExpanded ? "tw-w-36 2xl:tw-w-40" : "tw-w-0"
                     }`}
                  alt=""
                  onClick={()=>navigate(CONSTANTS.ROUTER.PARTICIPANT_INTRODUCTION)}
               />
            }
         </div>
       
         <div className="tw-flex tw-items-center tw-font-medium tw-w-2/6">

         </div>
         <div className="tw-flex tw-items-center tw-font-medium tw-w-1/6"></div>
         <div className={`tw-flex tw-items-center tw-justify-end tw-font-medium tw-w-6/6`}>
            <div className={`tw-flex tw-justify-between tw-items-center tw-overflow-hidden tw-transition-all tw-mr-4`}>
               
               <NavLinksParticipant navEmail={navEmail} />
            </div>
         </div>
      </nav> 
   );
};

export function NavLinksParticipant({ navEmail }) {
   const navigate = useNavigate();
   const dispatch =useDispatch();
   
   const handleLogout = async () => {
      setClicked(true);
      clearParticipantAccountData(dispatch);
      dispatch(rootAction.user.userLogoutAction());
      navigate(CONSTANTS.ROUTER.LOGIN_AS_PARTICIPANT, { replace: true });
   }

   const participantQuickLinks = [
      {
         name:
            <div className="d-flex align-items-center">
               <div className="tw-leading-4 tw-flex tw-flex-col">
                  <span className="tw-font-semibold">{navEmail}</span>
               </div>

               <IconButtonGeneral
                  icon={faBars}
                  color_enabled={'#8b5cf6'}
                  disabled={false}
                  size={'xl'}
                  onClick={() => { }}
               />
            </div>,
         submenu: true,
         sublinks: [
            { name: 'Instruction', path: `${CONSTANTS.ROUTER.PARTICIPANT_INTRODUCTION}` },
            { name: 'FAQ', path: `${CONSTANTS.ROUTER.PARTICIPANT_FAQ}` },
            { name: 'Contact Us', path: `${CONSTANTS.ROUTER.PARTICIPANT_CONTACT_US}` },
            { name: 'Sign Out', path: `${CONSTANTS.ROUTER.LOGIN_AS_PARTICIPANT}`,on_click: (() => handleLogout()) },

         ]
      },
   ];

   const links = participantQuickLinks;

   const [isClicked, setClicked] = useState(false);
   const [isClickedShow, setClickedShow] = useState(false);

   useEffect(() => {
      if (isClicked === true) {
         setTimeout(() => {
            setClicked(false);
            setClickedShow(false);
         }, 500);
      }
   }, [isClicked]);

   const handleSetClicked = (value) => {
      if (value.on_click) {
         handleLogout();
      }else {  
         setClicked(true)
      }
   }
   return (
      <>
         {
            links.map((value, key) => (
               <div key={key} className="tw-flex tw-group tw-grid-cols-6 "
                  onMouseLeave={() => {
                     setClickedShow(false);
                  }}
               >
                  <span className={`tw-p-3 tw-inline-block tw-cursor-pointer tw-group tw-transition-all 
                                    tw-duration-300 tw-ease-in-out`}
                     onClick={() => {
                        setClickedShow(!isClickedShow);
                     }}
                  >
                     <span className={`tw-bg-left-bottom tw-bg-gradient-to-r tw-from-violet-500 tw-to-violet-500 tw-bg-[length:0%_2px] 
                                       tw-bg-no-repeat group-hover:tw-bg-[length:100%_2px] tw-transition-all tw-duration-500 tw-ease-out`}>
                        {value.name}
                     </span>
                  </span>
                  {
                     value.submenu && (
                        <div className={`
                        tw-absolute tw-top-12  tw-bg-white tw-px-3 tw-border-2 tw-hidden
                        tw-border-gray-200 tw-border-solid tw-rounded-lg tw-shadow-sm 
                        tw-right-12
                        ${isClicked ? 'tw-pointer-events-none' : ''} ${isClickedShow ? 'hover:tw-block group-hover:tw-block' : ''}`} style={{ minWidth: '8vw' }}
                           onMouseLeave={() => {
                              setClickedShow(!isClickedShow);
                           }}

                        >
                           {
                              value.sublinks.map((value, key) => (
                                 value.head ?
                                    <div key={key} className="tw-my-3">
                                       <div className="tw-font-semibold">{value.head}</div>
                                       {
                                          value.sublink.map((value, key) => (
                                             <div key={key} className="tw-my-3">
                                                <Link className="tw-text-gray-600 tw-no-underline hover:tw-text-violet-500" to={value.path}
                                                   onClick={()=>setClicked(true)}>
                                                   {value.name}
                                                </Link>
                                             </div>
                                          ))
                                       }
                                    </div> :
                                    <div key={key} className="tw-my-3">
                                       <Link className="tw-text-gray-600 tw-no-underline hover:tw-text-violet-500" to={value.path}
                                          onClick={() => {
                                             handleSetClicked(value);
                                          }}
                                       >
                                          {value.name}
                                       </Link>
                                    </div>
                              ))
                           }
                        </div>
                     )
                  }
               </div >
            ))
         }
      </>
   )
}
