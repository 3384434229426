import { useEffect, useState } from "react";
import { useDeps } from "../../../shared/context/DependencyContext";
import { useSelector } from "react-redux";

export const useMultiraterSurveyList = () => {
  const { participantService } = useDeps();
  const [multiRatersList, setMultiRatersList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalItem, setTotalItem] = useState(0);
  const [limit, setLimit] = useState(10);
  const [searchText, setSearchText] = useState("");
  const rater = useSelector((state)=>state.user);



  useEffect(() => {
    if (pageNumber == 1) {
      onGetAllMultiRatersList(limit,pageNumber,searchText)
    }
    setPageNumber(1);

    // console.log(`rater nik ${rater.nik}`);
    }, [limit])

    useEffect(() => {
    }, [pageNumber])


  const handleInputSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleSearchData = () => {
    if (pageNumber == 1) {
      onGetAllMultiRatersList(limit,pageNumber,searchText)
    } else {
      setPageNumber(1);
    }
  };

  const onGetAllMultiRatersList = async (
    limit,
    page_number,
    search_bar
  ) => {
    try {
      const response =
        await participantService.multiRaterListService.getAllMultiRatersList(
          limit,
          page_number,
          search_bar,
          rater.nik
        );

      if (response.status == "SUCCESS") {
        setMultiRatersList(response.data.data);
        setTotalItem(response.data.total_item);
        console.log('response survey list', response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      // console.log("finally");
    }
  };

  const handleshowItem = (item) => {
    setLimit(item);
}

  return {
    handleInputSearchChange,
    handleSearchData,
    onGetAllMultiRatersList,
    setMultiRatersList,
    handleshowItem,
    limit,
    multiRatersList,
    totalItem,
    setPageNumber,
    pageNumber,
    searchText
  };
};



