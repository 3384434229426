import Lottie from "lottie-react";
import loginAnimation from "../../assets/json/login.json";
import loginLogo from "../../assets/images/logo_horizontal_tc.png";
import trendyLogo from "../../assets/images/Trendy-horizontal-color.png";
import { useEffect, useState } from "react";
import { InputOnly } from "../../shared/component/input/Input";
import { useNavigate } from "react-router-dom";
import { CONSTANTS } from "../../common/constants";
import { useDeps } from "../../shared/context/DependencyContext";
import { useDispatch } from "react-redux";
import useViewState from "../../shared/hook/UseViewState";
import screenToast from "../../shared/hook/ScreenToast";
import { rootAction } from "../../redux/RootAction";

function LoginView({ route }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState(0);
  let role = 0;
  if (route == CONSTANTS.USER.PARTICIPANT) {
    role = 3;
  } else if (route == CONSTANTS.USER.PIC) {
    role = 2;
  } else if (route == CONSTANTS.USER.ADMIN) {
    role = 1;
  }

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Membersihkan listener ketika komponen di-unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleOnChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleOnChangeOTP = (e) => {
    setOtp(e.target.value);
  };

  const navigate = useNavigate();

  // navigate with route and token
  const { generalService } = useDeps();
  const dispatch = useDispatch();

  const handleBackToLogin = () => {
    navigate(CONSTANTS.ROUTER.LOGIN);
  };

  const handleChangeEmail = () => {
    setOtpErr(false);
    setEmail("");
    setStep(step - 1);
  };

  const handleBackToRequestOTP = () => {
    setOtpErr(false);
    setOtpExp(false);
    setStep(step - 1);
  };

  const handleRequestOTP = async () => {
    setEmailErr(false);

    try {
      const response = await generalService.authService.doRequestOTP({
        email: email,
        role: role,
      });

      if (response.status == CONSTANTS.API.SUCCESS) {
        setStep(step + 1);
      } else {
        setError(true, response.data);
        setEmailErr(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleVerifyOTP = async () => {
    try {
      const response = await generalService.authService.doVerifyOTP({
        email: email,
        otp: otp,
        role: role,
      });

      if (response.status == CONSTANTS.API.SUCCESS) {
        dispatch(
          rootAction.user.userLoginAction({
            token: response.data.token,
            email: response.data.profile_data.email,
            name: response.data.profile_data.nama,
            role: response.data.profile_data.role,
            nik: response.data.profile_data.nik,           
            dob: response.data.profile_data.dob,
            gender: response.data.profile_data.gender,
            join_date: response.data.profile_data.join_date,
            no_hp: response.data.profile_data.no_hp,
            level: response.data.profile_data.level,
            position: response.data.profile_data.position,
            business_unit_name: response.data.profile_data.business_unit_name,
            department_name: response.data.profile_data.department_name,
            is_internal: response.data.profile_data.is_internal,
          })
        );

        if (route == CONSTANTS.USER.PARTICIPANT && response.data) {
          navigate(
            `${CONSTANTS.ROUTER.PARTICIPANT_DASHBOARD}/${CONSTANTS.ROUTER.PARTICIPANT_INTRODUCTION}`
          );
        } else if (route == CONSTANTS.USER.PIC && response.data) {
          navigate(`${CONSTANTS.ROUTER.PIC_CMS}/${CONSTANTS.ROUTER.DASHBOARD}`);
        } else if (route == CONSTANTS.USER.ADMIN && response.data) {
          navigate(
            `${CONSTANTS.ROUTER.ADMIN_CMS}/${CONSTANTS.ROUTER.DASHBOARD}`
          );
        }
      } else {
        let errorMsg =
          response.data == CONSTANTS.API.WRONG_OTP
            ? "Wrong OTP. Please check your email."
            : response.data == CONSTANTS.API.OTP_EXPIRED
            ? "OTP is expired. Please request OTP again."
            : "Failed, please try again.";
        setError(true, errorMsg);
        setOtpErr(true);

        if (response.data == CONSTANTS.API.OTP_EXPIRED) {
          setOtpExp(true);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  // screen state
  const { viewState, setError, setSuccess } = useViewState();
  const [emailErr, setEmailErr] = useState(false);
  const [otpErr, setOtpErr] = useState(false);
  const [otpExp, setOtpExp] = useState(false);
  const showToast = screenToast();

  useEffect(() => {
    if (viewState.isError == true) {
      showToast.error(viewState.errorMsg);
      setError(false, "");
    }

    if (viewState.isSuccess == true) {
      showToast.success(viewState.successMsg);
      setSuccess(false, "");
    }
  }, [viewState]);

  return windowWidth >= 500 ? (
    <div className="w-100 d-flex align-items-center">
      <div className="h-100 col-md-8 d-flex justify-content-around align-items-center flex-column tw-py-[5rem]">
        <div className="d-flex justify-content-center">
          <img src={trendyLogo} alt="trendy" width="470" height="470" />
        </div>
        <Lottie
          animationData={loginAnimation}
          className="tw-h-[420px] tw-drop-shadow-lg"
        />
        <div className="d-flex justify-content-center">
          <img src={loginLogo} alt="techconnect" />
        </div>
      </div>
      <div className="h-100 col-md-4 d-flex align-items-center flex-row tw-bg-slate-50 tw-rounded-l-3xl">
        <div
          className="w-100 d-flex align-items-center tw-p-5 justify-content-center"
          style={{ paddingRight: 0, paddingLeft: 0, minHeight: "70vh" }}
        >
          <div className="card align-items-center p-4 w-75 tw-border-none tw-h-96 tw-bg-slate-50">
            <div className="mb-4 col-12">
              <h5 style={{ color: "#7749F8", fontWeight: "bold", margin: 0 }}>
                Sign In As {route}{" "}
              </h5>
            </div>
            {step === 1 ? (
              <>
                <div className="w-100">
                  <InputOnly
                    input_width={"100%"}
                    placeholder={"Email Address"}
                    id="email"
                    handleOnChange={handleOnChangeEmail}
                    style={{ height: "48px", margin: "1.5rem 0" }}
                    is_invalid={emailErr}
                    value={email}
                  />
                  <button
                    className="btn btn-primary btn-block col-12 my-4 tw-h-[48px]"
                    type="submit"
                    onClick={handleRequestOTP}
                  >
                    Request OTP
                  </button>
                </div>
                <div className="mb-4 col-12 d-flex justify-content-center w-100">
                  <span
                    style={{
                      color: "#7749F8",
                      margin: 0,
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={handleBackToLogin}
                  >
                    Back to Sign In
                  </span>
                </div>
              </>
            ) : (
              <>
                <div className="w-100">
                  <div className="d-inline-block mb-1">
                    <div
                      className="col-12"
                      style={{ fontSize: "0.9rem", color: "#7749F8" }}
                    >
                      An email has been sent to
                    </div>
                    <div
                      className="col-12"
                      style={{
                        fontSize: "1rem",
                        color: "#7749F8",
                        fontWeight: "bolder",
                      }}
                    >
                      {email}
                    </div>
                  </div>
                  <InputOnly
                    input_width={"100%"}
                    placeholder={"OTP"}
                    id="otp"
                    handleOnChange={handleOnChangeOTP}
                    style={{ height: "48px", margin: "1.5rem 0" }}
                    is_invalid={otpErr}
                  />
                  <button
                    className="btn btn-primary btn-block col-12 my-4 tw-h-[48px]"
                    type="submit"
                    onClick={handleVerifyOTP}
                  >
                    Submit
                  </button>
                  <div className="mb-4 col-12 d-flex justify-content-center w-100">
                    {otpExp ? (
                      <span
                        style={{
                          color: "#7749F8",
                          margin: 0,
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={handleBackToRequestOTP}
                      >
                        Back to Request OTP
                      </span>
                    ) : (
                      <span
                        style={{
                          color: "#7749F8",
                          margin: 0,
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={handleChangeEmail}
                      >
                        Change email
                      </span>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="w-100 align-items-center" style={{ overflow: "auto" }}>
      <div className="h-2 col-md-8 d-flex justify-content-around align-items-center flex-column tw-py-[5rem]">
        <div
          className="text-center d-flex flex-column"
          style={{ color: "#7749F8", fontWeight: "bolder" }}
        >
          <div className="d-flex justify-content-center">
            <img src={trendyLogo} alt="trendy" width="270" height="270" />
          </div>
        </div>
        <Lottie
          animationData={loginAnimation}
          className="tw-h-[8rem] tw-drop-shadow-lg"
        />
        <div className="d-flex justify-content-center">
          <img src={loginLogo} alt="techconnect" />
        </div>
      </div>
      <div className="h-30 col-md-4 d-flex align-items-center flex-row bg-white tw-rounded-l-xl">
        <div
          className="w-100 d-flex align-items-center tw-p-5 justify-content-center"
          style={{ paddingRight: 0, paddingLeft: 0, minHeight: "5vh" }}
        >
          <div className="card align-items-center p-4 w-75 tw-border-none tw-h-96">
            <div className="mb-4 col-12">
              <h5 style={{ color: "#7749F8", margin: 0 }}>
                Sign In As {route}{" "}
              </h5>
            </div>
            {step === 1 ? (
              <>
                <div className="w-100">
                  <InputOnly
                    input_width={"100%"}
                    placeholder={"Email Address"}
                    id="email"
                    handleOnChange={handleOnChangeEmail}
                    style={{ height: "3rem", margin: "1.5rem 0" }}
                    is_invalid={emailErr}
                    value={email}
                  />
                  <button
                    className="btn btn-primary btn-block col-12 my-4 tw-h-[48px]"
                    type="submit"
                    onClick={handleRequestOTP}
                  >
                    Request OTP
                  </button>
                </div>
                <div className="mb-4 col-12 d-flex justify-content-center w-100">
                  <span
                    style={{
                      color: "#7749F8",
                      margin: 0,
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={handleBackToLogin}
                  >
                    Back to Sign In
                  </span>
                </div>
              </>
            ) : (
              <>
                <div className="w-100">
                  <div className="d-inline-block mb-1">
                    <div
                      className="col-12"
                      style={{ fontSize: "0.9rem", color: "#7749F8" }}
                    >
                      An email has been sent to
                    </div>
                    <div
                      className="col-12"
                      style={{
                        fontSize: "1rem",
                        color: "#7749F8",
                        fontWeight: "bolder",
                      }}
                    >
                      {email}
                    </div>
                  </div>
                  <InputOnly
                    input_width={"100%"}
                    placeholder={"OTP"}
                    id="otp"
                    handleOnChange={handleOnChangeOTP}
                    style={{ height: "48px", margin: "1.5rem 0" }}
                    is_invalid={otpErr}
                  />
                  <button
                    className="btn btn-primary btn-block col-12 my-4 tw-h-[48px]"
                    type="submit"
                    onClick={handleVerifyOTP}
                  >
                    Submit
                  </button>
                  <div className="mb-4 col-12 d-flex justify-content-center w-100">
                    {otpExp ? (
                      <span
                        style={{
                          color: "#7749F8",
                          margin: 0,
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={handleBackToRequestOTP}
                      >
                        Back to Request OTP
                      </span>
                    ) : (
                      <span
                        style={{
                          color: "#7749F8",
                          margin: 0,
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={handleChangeEmail}
                      >
                        Change email
                      </span>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default LoginView;
