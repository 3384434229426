import { Col, Row } from 'react-bootstrap';
import EChartsReact from 'echarts-for-react';
import { CONTENTS } from '../../constants';

const { SR_BAR_GROUP, SR_BAR_DIMENSION, SR_BAR_ASPECT, SR_BAR_ITEM } = CONTENTS;

const defaultOptions = {
    tooltip: {
        trigger: 'axis',
        axisPointer: { type: 'shadow' }
    },
    legend: {
        orient: 'horizontal',
        bottom: 0
    },
    grid: {
        top: 0,
        left: '3%',
        right: '4%',
        bottom: 30,
        containLabel: true
    },
    xAxis: { type: 'value' }
};

const isSingleR = (cid) => {
    return [SR_BAR_GROUP, SR_BAR_DIMENSION, SR_BAR_ASPECT, SR_BAR_ITEM].includes(cid);
};

const createColor = (theme) => {
    const themeColors = {
        theme1: ['#2485f4', '#ea4335', '#fbbc04', '#34a853', '#ff6d01', '#46BDC6'],
        theme2: ['#C1D0D7', '#004561', '#FF6F31', '#2F818E', '#194CAB', '#4CDC8B'],
        theme3: ['#C5D9D7', '#2D729D', '#8594B0', '#EB5600', '#FF99AC', '#FFD4B8'],
        theme4: ['#FF99AC', '#0B6374', '#FC6360', '#5F5FA6', '#8DD8D3', '#D7E6A3'],
        theme5: ['#006391', '#E24A38', '#FDBC34', '#28998B', '#C0DE00', '#F5959C']
    };

    return themeColors[theme];
};

const createLegends = (categories) => {
    const maxLength = 50;
    return {
        type: 'category',
        data: categories.map((l) => {
            return l.length > maxLength ? l.slice(0, maxLength) + '...' : l;
        })
    };
};

const genRowData = (name, values = []) => {
    return {
        name: name,
        type: 'bar',
        stack: 'total',
        label: { show: true },
        emphasis: { focus: 'series' },
        data: values,
        bottom: 10
    };
};

const createData = (contentId, { values, legends }) => {
    if (isSingleR(contentId)) {
        return genRowData('Self', values[0]);
    } else {
        return legends.map((legend, i) => {
            return genRowData(legend, values[i]);
        });
    }
};

const BarChartData = ({ contentId, result, defaultTheme }) => {
    const theme = defaultTheme || 'theme1';
    const legends = createLegends(result.categories);
    const data = createData(contentId, result);
    const color = createColor(theme);
    const options = {
        ...defaultOptions,
        color: color,
        yAxis: legends,
        series: data,
        legend: isSingleR(contentId) ? false : {}
    };

    return (
        <Row className="mx-2">
            <Col>
                <EChartsReact option={options} style={{ width: '600px' }} />
            </Col>
        </Row>
    );
};

export default BarChartData;
