export const  SET_ONE_ANSWER_SR = 'set_one_answer_sr';
export const  SET_MANY_ANSWER_SR = 'set_many_answer_sr';
export const EDIT_MANY_ANSWER_SR = 'edit_many_answer_sr';
export const DELETE_MANY_ANSWER_SR = 'delete_many_answer_sr';
export const SET_SURVEY_FIELD_SR = 'set_survey_field_sr';
export const CLEAR_SURVEY_FIELD_SR = 'clear_survey_field_sr';
export const DELETE_ALL_MANY_ANSWER_SR = 'delete_all_many_answer_sr';
export const SET_DATA_PARTICIPANT_RATER_SURVEY_FORM_SR = 'set_data_participant_rater_survey_form_sr';
export const CLEAR_DATA_PARTICIPANT_RATER_SURVEY_FORM_SR = 'clear_data_participant_rater_survey_form_sr';
export const EDIT_MANY_ANSWER_SAME_CONTENT_SR = 'edit_many_answer_same_content_sr';
export const ADD_CONTENT_MANDATORY_SR = 'content_mandatory_sr';
export const DELETE_MANDATORY_CONTENT_SR = 'delete_mandatory_content_sr';
export const DELETE_ALL_MANDATORY_CONTENT_SR = 'delete_all_mandatory_content_sr';
export const SET_SR_SURVEY_DETAIL_DATA = 'set_sr_survey_detail_data';
export const DELETE_SR_SURVEY_DETAIL_DATA = 'delete_sr_survey_detail_data';
