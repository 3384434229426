import { IconButtonDelete, IconButtonPrimary } from "../../shared/component/button/IconButton";
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import AddGoal from "./component/AddGoal";
import useConfigData from "./useConfigData";
import LoadingBar from "react-top-loading-bar";

function ConfigDataView() {
    const {
        handleChangeGoal,
        allGoal,
        onPostGoalData,
        showModal,
        setShowModal,
        handleDeleteGoal,
        isloading,
        goal,
        progress,
        setProgress
    } = useConfigData();



    return (
        <>
        <LoadingBar color="#7749F8" progress={progress} onLoaderFinished={() => setProgress(0)} />

<div className="container tw-h-max tw-max-w-[1400px]">
    <div className='m-4 mt-2 d-flex'><h4>Configuration Data </h4>
        <div>{isloading}</div>
    </div>
    <div className='shadow p-8 rounded-3 bg-white'>
        <div className="col-5">
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h5>Goal</h5>
                    <IconButtonPrimary
                        icon={faPlus}
                        onClick={() => setShowModal(true)}
                    />
                </div>

                <div className="card-body">
                    {
                        Array.isArray(allGoal) && allGoal.length != 0 ? (
                            allGoal.map((dt, i) => {
                                return (
                                    <ul className="list-group list-group-flush" style={{ textAlign: 'left', paddingBottom: '4px' }} key={i}>
                                        <li className="list-group-item border d-flex align-items-center">
                                            <div className="col-11">
                                                {dt.goal_name}
                                            </div>
                                            <div className="col-1">
                                                <IconButtonDelete onClick={() => handleDeleteGoal(dt.id)} />
                                            </div>

                                        </li>
                                    </ul>
                                )
                            })
                        ) : (
                            <div>No data</div>
                        )
                    }
                </div>
            </div>

        </div>
        <AddGoal
            show={showModal}
            onChangeGoal={handleChangeGoal}
            onHide={() => setShowModal(false)}
            onSave={() => onPostGoalData()}
            goal={goal}
        />
    </div>

</div>
        </>
    )

}

export default ConfigDataView;


export const goalList = [
    {
        id: 1,
        goal: 'Other'
    },
    {
        id: 2,
        goal: 'Post Training Evaluation Post Training Evaluation Post Training Evaluation Post Training Evaluation Post Training Evaluation  Post Training Evaluation Post Training Evaluation '
    },
    {
        id: 3,
        goal: 'Engagement'
    },
    {
        id: 4,
        goal: 'Leadership Development'
    }
]