import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import EChartsReact from 'echarts-for-react';


const createColor = (theme) => {
    const themeColors = {
        theme1: ['#ca6510', '#ffc107', '#198754', '#0a58ca', '#0a58ca', '#8B4513'],
        theme2: ['#C93232', '#E27228', '#143F8F', '#FC4479', '#DDDD16', '#4B0082'],
        theme3: ['#4285F4', '#EC594D', '#FBBE0B', '#84CA97', '#FF6F04', '#32CD32'],
        theme4: ['#BF8C7B', '#3E5656', '#E79A3C', '#447874', '#D2D479', '#8B008B'],
        theme5: ['#5CB6AA', '#2D729D', '#1F3E78', '#EB5600', '#FF99AC', '#FFD700']
    };

    return themeColors[theme];
};

const createRadar = (categories) => {
    return {
        shape: 'circle',
        indicator: categories.map((cat) => {
            return {
                name: cat,
                max: 6
            };
        }),
        textStyle: { fontWeight: 'bold' }
    };
};

const createLegends = (legends = []) => {
    return {
        data: legends,
        orient: 'horizontal',
        bottom: 0,
        textStyle: { color: '#777' }
    };
};

const createData = (legends = [], values = []) => {
    const data = legends.map((legend, i) => {
        return {value: values[i], name: legend };
    });

    return [{ type: 'radar', data: data }];
};

const RadarChartData = ({ contentId, result, defaultTheme }) => {
    const [theme, setTheme] = useState(defaultTheme || 'theme1');
    const radar = createRadar(result.categories);
    const legendProps = createLegends(result.legends);
    const data = createData(result.legends, result.values);
    const color = createColor(theme);
    const options = {
        grid: { top: 0 },
        radar: radar,
        color: color,
        legend: legendProps,
        series: data
    };

    return (
        <Row className="mx-2">
            <Col>
                <EChartsReact option={options} style={{ width: '600px' }} />
            </Col>
        </Row>
    );
};

export default RadarChartData;
