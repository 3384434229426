export const  SET_ONE_ANSWER = 'set_one_answer';
export const  SET_MANY_ANSWER = 'set_many_answer';
export const EDIT_MANY_ANSWER = 'edit_many_answer';
export const DELETE_MANY_ANSWER = 'delete_many_answer';
export const SET_SURVEY_FIELD = 'set_survey_field';
export const CLEAR_SURVEY_FIELD = 'clear_survey_field';
export const DELETE_ALL_MANY_ANSWER = 'delete_all_many_answer';
export const SET_DATA_PARTICIPANT_RATER_SURVEY_FORM = 'set_data_participant_rater_survey_form';
export const CLEAR_DATA_PARTICIPANT_RATER_SURVEY_FORM = 'clear_data_participant_rater_survey_form';
export const EDIT_MANY_ANSWER_SAME_CONTENT = 'edit_many_answer_same_content'
export const ADD_CONTENT_MANDATORY = 'content_mandatory';
export const DELETE_MANDATORY_CONTENT = 'delete_mandatory_content';
export const DELETE_ALL_MANDATORY_CONTENT = 'delete_all_mandatory_content';
export const SET_MR_SURVEY_DETAIL_DATA = 'set_mr_survey_detail_data';
export const DELETE_MR_SURVEY_DETAIL_DATA = 'delete_mr_survey_detail_data';








export const ONE_ANSWER_DETAIL = {
    participant_nik:"participant_nik",
    participant_bu:"participant_bu",
    participant_dept:"participant_dept",
    participant_email:"participant_email",
    target_nik:"target_nik",
    target_email:"target_email",
    survey_code:"survey_code",
    survey_name:"survey_name",
    content_code:"content_code",
    content_name:"content_name",
    content_type:"content_type",
    content_score:"content_score",
    choice_code:"choice_code",
    choice_text:"choice_text",
    choice_score:"choice_score",
    scale_display:"scale_display",
    is_mandatory: "is_mandatory",
    answer:"answer",
    goal_id:"goal_id",
    created_at:"created_at",
}
