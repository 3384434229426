import { ButtonPrimary } from "../../../shared/component/button/Button";
import { InputOnly, TextAreaOnly } from "../../../shared/component/input/Input";
import useContactUs from "./useContactUs";

function ContactUsView() {
    const {
        message,
        wa,
        number,
        email,
        handleSaveData,
        handleChangeEmail,
        handleChangeMessage,
        handleChangeNumber,
        handleChangeWa,
        
    } = useContactUs();

    

    return(
       <div>
            <div className="d-flex justify-content-between align-items-center m-2">
                <h5 > Contact Us Configure</h5>
                <ButtonPrimary label="Save" onClick={()=>handleSaveData()}/>
               
            </div>
            <div className="col-10 m-4">
                <form>
                    <div className="row mb-3">
                        <div className="col-4">
                            <label>Important Message</label>
                        </div>
                        <div className="col-6">
                            <TextAreaOnly 
                            value={message}
                            handleOnChange={handleChangeMessage}
                            input_width={'100%'} input_height={'25vh'} type={'text'} />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-4">
                            <label>WhatsApp</label>
                        </div>
                        <div className="col-6">
                            <InputOnly 
                            value={wa}
                            handleOnChange={handleChangeWa}
                            input_width={'100%'} type={'number'}/>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-4">
                            <label>Number</label>
                        </div>
                        <div className="col-6">
                        <InputOnly 
                        value={number}
                        handleOnChange={handleChangeNumber}
                        input_width={'100%'} type={'number'}/>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-4">
                            <label>Email</label>
                        </div>
                        <div className="col-6">
                            <InputOnly 
                            value={email}
                            handleOnChange={handleChangeEmail}
                            input_width={'100%'} type={'email'}/>
                        </div>
                    </div>
                </form>
            </div>
       </div>
    )
}

export default ContactUsView;