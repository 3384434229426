export const dataMultiRaterSurvey = [
  [
    {
      limit: 2,
      page: 1,
      totalItem: 2,
      data: [
        {
          full_name: "Novita",
          email: "novita.widyastuti@smm.com",
          survey_name: "Kuesioner HR Corporate",
          survey_code: "",
          participant_availability: "",
          role_in_survey: "Supervisor",
          status: "Yet to start",
          total_item: "",
          start_date: "",
          end_date: "",
          is_active: "",
        },
        {
          full_name: "Anug",
          email: "nugroho.fitrianto@smm.com",
          survey_name: "",
          survey_code: "",
          participant_availability: "",
          role_in_survey: "Subordinate",
          status: "In Progress",
          total_item: "",
          start_date: "",
          end_date: "",
          is_active: "",
        },
        {
          full_name: "Dipta",
          email: "pradipta.megantara@smm.com",
          survey_name: "",
          survey_code: "",
          participant_availability: "",
          role_in_survey: "Peer",
          status: "Yet to start",
          total_item: "",
          start_date: "",
          end_date: "",
          is_active: "",
        },
        {
          full_name: "Renaldi",
          email: "renaldi.ristiawan@smm.com",
          survey_name: "",
          survey_code: "",
          participant_availability: "",
          role_in_survey: "Peer",
          status: "Yet to start",
          total_item: "",
          start_date: "",
          end_date: "",
          is_active: "",
        },
        {
          full_name: "Steven",
          email: "steven.jonathan@smm.com",
          survey_name: "",
          survey_code: "",
          participant_availability: "",
          role_in_survey: "Supervisor",
          status: "Completed",
          total_item: "",
          start_date: "",
          end_date: "",
          is_active: "",
        },
        {
          full_name: "Bagas",
          email: "bagas.adi@smm.com",
          survey_name: "",
          survey_code: "",
          participant_availability: "",
          role_in_survey: "Self",
          status: "Completed",
          total_item: "",
          start_date: "",
          end_date: "",
          is_active: "",
        },
      ],
    },
  ],
];

export const thTableMultiRaterSurvey = [
  "Target Participant Name",
  "Email",
  "Project Name",
  "Your Relationship",
  "Status",
];

export const filterFieldMultiRaterSurvey = [
  {
    field_name: "Name",
    field_type: "category",
    field_category: [],
  },
  {
    field_name: "Email",
    field_type: "text",
    field_category: [],
  },
  {
    field_name: "Project Name",
    field_type: "text",
    field_category: [],
  },
  {
    field_name: "Your Relationship",
    field_type: "text",
    field_category: [],
  },
  {
    field_name: "Status",
    field_type: "text",
    field_category: [],
  },
];
