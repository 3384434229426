import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleMinus } from '@fortawesome/free-solid-svg-icons';
import { CONTENT_SECTIONS as CONTROLS } from '../constants';
import RichTextEditor from './contents/RichTextEditor';
import DisplayData from './contents/DisplayData';
import RadarChartData from './contents/RadarChartData';
import BarChartData from './contents/BarChartData';
import CommentData from './contents/CommentData';
import DataTable from './contents/DataTable';
import DevelopmentForm from './contents/DevelopmentForm';
import DemographyData from './contents/DemographyData';
import SummaryTable from './contents/SummaryTable';

function SectionContent({
    id,
    controlId,
    contentId,
    title,
    theme,
    showBtnClose,
    onChange,
    onChangeTheme,
    onDeleteClick,
    children
}) {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } =
        useSortable({ id: id });
    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    };
    const displayRichText = [
        CONTROLS.CHAPTER,
        CONTROLS.SUB_CHAPTER,
        CONTROLS.TEXT
    ].includes(controlId);
    const defaultProps = {
        contentId: contentId,
        title: title
    };

    const handleOnChange = (ev, editor) => {
        if (onChange) onChange(id, editor.getData());
    };
    const handleOnChangeTheme = (theme) => {
        if (onChangeTheme) onChangeTheme(id, theme);
    };

    return (
        <div
            key={id}
            ref={setNodeRef}
            style={style}
            className={`mb-1 px-1 pb-1 border ${isDragging ? 'border-info' : ''}`}
            {...attributes}
            {...listeners}
        >
            <div style={{ fontSize: '12px' }}>
                {showBtnClose && (
                    <div className="d-flex justify-content-start ms-auto fs-6">
                        <small>
                            <FontAwesomeIcon
                                icon={faCircleMinus}
                                onClick={(e) => onDeleteClick(id, e)}
                            />
                        </small>
                    </div>
                )}
                {displayRichText ? (
                    <RichTextEditor readOnly={!showBtnClose} onChange={handleOnChange}>
                        {children}
                    </RichTextEditor>
                ) : controlId === CONTROLS.DATA ? (
                    <DisplayData {...defaultProps} />
                ) : controlId === CONTROLS.RADAR_CHART_DATA ? (
                    <RadarChartData
                        {...defaultProps}
                        defaultTheme={theme}
                        showThemePicker={showBtnClose}
                        onChange={handleOnChangeTheme}
                    />
                ) : controlId === CONTROLS.BAR_CHART_DATA ? (
                    <BarChartData
                        {...defaultProps}
                        defaultTheme={theme}
                        showThemePicker={showBtnClose}
                        onChange={handleOnChangeTheme}
                    />
                ) : controlId === CONTROLS.DATA_TABLE ? (
                    <DataTable {...defaultProps} />
                ) : controlId === CONTROLS.COMMENTS_DATA ? (
                    <CommentData {...defaultProps} />
                ) : controlId === CONTROLS.DEVELOPMENT_FORM ? (
                    <DevelopmentForm {...defaultProps} />
                ) : controlId === CONTROLS.DEMOGRAPHY_DATA ? (
                    <DemographyData {...defaultProps} />
                ) : controlId === CONTROLS.SURVEY_DATA ? (
                    <DisplayData {...defaultProps} />
                ) : controlId === CONTROLS.SUMMARY_TABLE ? (
                    <SummaryTable {...defaultProps} />
                ) : null}
            </div>
        </div>
    );
}

export default SectionContent;
