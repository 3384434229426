import { useEffect, useState } from "react";
import { useDeps } from "../../../shared/context/DependencyContext";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import screenToast from "../../../shared/hook/ScreenToast";
import useViewState from "../../../shared/hook/UseViewState";
import { logDOM } from "@testing-library/react";

export const useAddRaters = () => {
  const {  generalService , participantService } = useDeps();
  const [showItem, setShowItem] = useState(25);
  const [search, setSearch] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [allUser, setAllUser] = useState([]);
  const [totalItem, setTotalItem] = useState(0);
  const [buData, setBuData] = useState([{ business_unit_name: "ALL", business_unit_code: "11111111" }]);
  const [departmentData, setDepartmentData] = useState([{department_name: "ALL", department_code: "11111111"}]);
  const [levelData, setLevelData] = useState([{ level_user: "ALL" }]);
  const [buName, setBUName] = useState('');
  const [deptName, setDeptName] = useState('');
  const [level, setLevel] = useState('');
  const [searchBar, setSearchBar] = useState('');
  const [openSelectRelation, setOpenSelectRelation] = useState(false);
  const [selectedRater, setSelectedRater] = useState();
  const [tpData, setTpData] = useState();
  const showToast = screenToast();
  const [raterList, setRaterList] = useState([]);


  useEffect(() => {
    let filterData = {
      limit: showItem,
      page_number: pageNumber,
      search: search,
      level: level == 'ALL' ? '' : level,
      bu_name: buName == 'ALL' ? '' : buName,
      dept_name: deptName == 'ALL' ? '' : deptName
   }
    if (pageNumber == 1) {
      onGetAllUserWithFilter(filterData)
    }else {
      setPageNumber(1);
    }
    
  }, [buName,deptName,level,search]);

  useEffect(() => {

    let filterData = {
      limit: showItem,
      page_number: pageNumber,
      search: search,
      level: level == 'ALL' ? '' : level,
      bu_name: buName == 'ALL' ? '' : buName,
      dept_name: deptName == 'ALL' ? '' : deptName
   }
    onGetAllUserWithFilter(filterData)
  }, [pageNumber]);


  useEffect(() => {
    onGetBUData();
    onGetDepartmentData();
    onGetLevelData();
  }, []);



  const handleInputSearchChange = (e) => {
    setSearch(e.target.value);
  };


  const onGetAllUserWithFilter = async (filterData) => {
    
    try {
      const response = await generalService.generalDataService.getUserMaster(filterData);

      if (response.status == "SUCCESS") {
        setAllUser(response.data.data)
        setTotalItem(response.data.total_item)
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  /* BU Data */
  const onGetBUData = async () => {
    // console.log("onGetBU");
    try {
      const response = await generalService.generalDataService.getBusinessUnitData();
      if (response.status == "SUCCESS") {
        setBuData([...buData, ...response.data]);
      } else {
        // console.log(response.status);
      }
    } catch (error) {
      console.log(error);
    } finally {
      // console.log("finally");
    }
  };

  /* Dept Data */
  const onGetDepartmentData = async () => {
    try {
      const response =
        await generalService.generalDataService.getDepartmentData();

      if (response.status == "SUCCESS") {
        setDepartmentData([...departmentData, ...response.data]);
        // console.log(response.data[0]);
      } else {
        // console.log(response.status);
      }
    } catch (error) {
      console.log(error);
    } finally {
      // console.log("finally");
    }
  };

  /* Level Data */
  const onGetLevelData = async () => {
    try {
      const response = await generalService.generalDataService.getLevelData();

      if (response.status == "SUCCESS") {
        setLevelData([...levelData, ...response.data]);
      } else {
        // console.log(response.status);
      }
    } catch (error) {
      console.log(error);
    } finally {
      // console.log("finally");
    }
  };

  /* Function to add rater to db and send invitation */
  const onAddtoDbandSendInvitation = async (surveyCode,userEmail,targetData,raterData) => {
    const dt = {
      target_participant:targetData,
      RatersAdd:[raterData],
      survey_code:surveyCode,
      user_email: userEmail
    }

    try {
      const response = await participantService.addRatersService.addRaterAndSendInvitation(dt);

      console.log('surveyCode', surveyCode);
      console.log('userEmail',userEmail);
      console.log('targetData',JSON.stringify(targetData));
      console.log('dt',JSON.stringify(dt));
      // masih failed 
      
      

      if (response.status == "SUCCESS") {
        showToast.success('Successfully added and sent invitations')
      }
      
    } catch (error) {
      console.log(error);
      showToast.error("Something went wrong. Please try again later.")
    } finally {
      setIsLoading(false);
      setOpenSelectRelation(false);
    }
  }

  /* Function to confirmation before add rater to db and send invitation */
  const handleAddandSendInvitation = (tp_name,rater_name,role,roleVal) => {
    const targetParticipant = {
      id : tpData? tpData.tp_id : 0,
      nik: tpData? tpData.tp_nik : '',
      email:tpData ? tpData.tp_email :'',
    }

    const rater = {
      ...selectedRater,
      survey_code:tpData.survey_code,
      role_in_survey_id:roleVal
    }
    
    
    swal({
      icon: "warning",
      title: '',
      text: `Are you sure to add ${rater_name} as ${role} of ${tp_name} and send the invitation?`,
      buttons: {
          cancel: {
              text: "Cancel",
              value: false,
              visible: true,
              className: "",
              closeModal: true,
          },
          confirm: {
              text: "Yes",
              value: true,
              visible: true,
              className: "",
              closeModal: true,
          }
      },
      
  }).then(willAdd => {
      if (willAdd) {
        onAddtoDbandSendInvitation(tpData.survey_code,tpData.user_email, targetParticipant,rater)
      }else{
        setOpenSelectRelation(false)
      }
  });
  }


  const onGetAllRaterList = async (tp_id) => {
    try {
      const response =
        await participantService.listRaterService.getAllRaterList(
          20,
          1,
          tp_id
        );

      if (response.status == "SUCCESS") {
        setRaterList(response.data.data);
        console.log('raterlist', response);
        
      } else {
        console.log(response.status);
      }
    } catch (error) {
      console.log(error);
    } finally {
      // console.log("finally");
    }
  };

  return {
   pageNumber,
   showItem,
   buName,
   setBUName,
   deptName,
   setDeptName,
   level,
   setLevel,
   handleInputSearchChange,
   buData,
   departmentData,
   levelData,
   allUser,
   totalItem,
   setPageNumber,
   setSearch,
   setSearchBar,
   searchBar,
   search,
   onGetAllUserWithFilter,
   handleAddandSendInvitation,
   openSelectRelation,
   setOpenSelectRelation,
   selectedRater,
   setSelectedRater,
   tpData,
   setTpData,
   raterList,
   onGetAllRaterList,
   

  };
};
