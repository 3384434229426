import { useState } from "react";
import { Form, Modal, Col, Row } from "react-bootstrap";
import { reportTypeOptions } from "../../constants";
import { ButtonOutlinePrimary } from "../../../../shared/component/button/Button";

const ModalCommentsCustom = ({show, onHide, onSave}) => {
    const [reportType, setReportType] = useState(null);

    const handleOnHide = () => {
        setReportType(null)
        onHide()
    }

    const handleOnSave = () => {
        if (reportType) {
            onSave(reportType)
        }
    }

    return (
        <Modal show={show} size={'md'} centered onHide={handleOnHide}>
            <Modal.Body>
                MODAL
            </Modal.Body>
        </Modal>
    )
}

export default ModalCommentsCustom;
