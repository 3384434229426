import { useEffect, useId, useState } from "react";
import useQuestionHistory from "./useQuestionHistory";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import screenAlert from "../../../shared/hook/SweetAlert";
import { rootAction } from "../../../redux/RootAction";
import { ButtonOutlinePrimary, ButtonPrimary } from "../../../shared/component/button/Button";
import { ShowEntries } from "../../../shared/component/table_property/ShowEntries";
import { SearchColumn } from "../../../shared/component/table_property/SearchColumn";
import { IconButtonView } from "../../../shared/component/button/IconButton";
import Pagination from "../../../shared/component/table_property/Paginatination";
import ModalQuestionDetail from "./ModalQuestionDetail";
import { CONSTANTS } from "../../../common/constants";

function QuestionHistory() {
   const [limit, setLimit] = useState(10);
   const [pageNumber, setPageNumber] = useState(1);
   const [searchBar, setSearchBar] = useState('');
   const [search, setSearch] = useState('');
   const { allQuestionHistory, totalItem } = useQuestionHistory({ limit, pageNumber, search });
   const [contentData, setContentData] = useState([]);
   const sectionViewReducer = useSelector((state) => state.view_content_multi_rater);
   const sectionReducer = useSelector((state) => state.section_multi_rater.find((el) => el.section_id === sectionViewReducer.section_id));
   const showAlert = screenAlert();
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const sectionViewReducerSR = useSelector((state) => state.view_content_single_rater);
   const sectionReducerSR = useSelector((state) => state.section_single_rater.find((el) => el.section_id === sectionViewReducerSR.section_id));
   const currentUrl = window.location.pathname.split('/')[2];


   const [view, setView] = useState(false);
   const [question, setQuestion] = useState();

   useEffect(() => {
      if (currentUrl == `${CONSTANTS.ROUTER.ADD_SINGLE_RATER_SURVEY}`) {
       setContentData(sectionReducerSR.content) 
     } else {
       setContentData(sectionReducer.content);
     }
   }, []);

   const handleOnClickLimit = (val) => {
      setPageNumber(1);
      setLimit(val);
   }

   const handleOnSubmitSearch = () => {
      setPageNumber(1);
      setSearch(searchBar);
   }

   const handleOnChangeText = (e) => {
      e.target.id == 'search_bar' && setSearchBar(e.target.value);
   }

   const handleOpenDetail = (question) => {
      setView(true);
      setQuestion(question);
   };

   const previousPage = () => {
      if (pageNumber != 1) {
         setPageNumber(pageNumber - 1);

      }
   }

   const nextPage = () => {
      if (pageNumber != Math.ceil(totalItem / limit)) {
         setPageNumber(pageNumber + 1);
      }
   }

   const paginate = (pageNumber) => {
      setPageNumber(pageNumber);
   }

   const handleOnCheck = (e, val, id) => {
      if (e.target.checked) {
         setContentData([...contentData, {
            content_id: id,
            content_type: '',
            content_code: '',
            content_name: '',
            item: val.item,
            item_in_eng: val.item_in_eng,
            help: '',
            score_same_as_scale: 1,
            score: 0,
            goal_id: val.goal_id,
            group_survey: val.group_survey,
            dimension: val.dimension,
            aspect: val.aspect,
            is_mandatory: 0,
            has_box_answer: 0,
            action_for_strength: val.action_for_strength,
            action_for_weakness: val.action_for_weakness,
            content_choice: []
         }]);
      }

      if (!e.target.checked) {
         setContentData(contentData.filter((el) => el.content_id != id));
      }
   }

   const handleOnSubmit = () => {
      showAlert.info({
         text: "Are you sure to add questions for survey?",
         buttons: {
            cancel: {
               text: "Cancel",
               className: "bg-white text-dark"
            },
            confirm: {
               text: "Confirm",
               value: true,
               className: "bg-success"
            }
         }
      }).then(value => {
         if (value) {
            let content = [...contentData].map((con, i) => {
               return {
                  ...con,
                  content_id: i + 1,
                  content_name: 'Question ' + (i + 1)
               }
            });

            if (currentUrl == `${CONSTANTS.ROUTER.ADD_SINGLE_RATER_SURVEY}`) {
               dispatch(rootAction.singleRater.setContentBulkSRAction({
                  key: sectionReducerSR.section_id,
                  payload: content
               }));
               dispatch(rootAction.singleRater.setContentViewSRAction({
                  section_id: sectionReducerSR.section_id,
                  content_id: content[content.length - 1].content_id
               }));

            } else {
               dispatch(rootAction.multiRater.setContentBulkMRAction({
                  key: sectionReducer.section_id,
                  payload: content
               }));
               dispatch(rootAction.multiRater.setContentViewMRAction({
                  section_id: sectionReducer.section_id,
                  content_id: content[content.length - 1].content_id
               }));
            }
            navigate(-1);
         }
      });
   }

   return (
      <div className="container tw-h-max">

         {/* Header */}
         <div className='my-4 d-flex'><h4>Question History</h4></div>
         <div className="my-4 d-flex justify-content-between align-items-center">
            <span className="fst-italic">Please select the question you want to add to project, then <strong>click the top right button</strong></span>
            <div className="d-flex">
               <div className="me-2">
                  <ButtonOutlinePrimary label="Back" onClick={() => navigate(-1)} />
               </div>
               <div>
                  <ButtonPrimary label='Add to Project' onClick={handleOnSubmit} />
               </div>
            </div>
         </div>

         {/* Card */}
         <div className='card shadow p-4 border-0'>

            {/* Table Setting */}
            <div className='justify-content-between mb-4 d-flex flex-row'>
               <div className='col-4 d-flex flex-row align-items-center' >
                  <div>Show</div>
                  <ShowEntries onClick_item={handleOnClickLimit} default_item={limit} />
                  <div className="">entries</div>
               </div>
               <div className='col-3 d-flex align-items-center'>
                  <SearchColumn id={'search_bar'} onChange_input={handleOnChangeText} onClick_btn={handleOnSubmitSearch} />
               </div>
            </div>

            {/* Table */}
            <div className="table-responsive">
               <table className="table table-striped table-bordered">
                  <thead>
                     <tr>
                        <th style={{ verticalAlign: 'middle', textAlign: 'center' }} >&#x2714;</th>
                        <th style={{ verticalAlign: 'middle', textAlign: 'center' }} >No</th>
                        <th style={{ verticalAlign: 'middle', textAlign: 'center' }} >Goal</th>
                        <th style={{ verticalAlign: 'middle', textAlign: 'center' }} >Group</th>
                        <th style={{ verticalAlign: 'middle', textAlign: 'center' }} >Dimension</th>
                        <th style={{ verticalAlign: 'middle', textAlign: 'center' }} >Aspect</th>
                        <th style={{ verticalAlign: 'middle', textAlign: 'center' }} >Item (Main Language)</th>
                        <th style={{ verticalAlign: 'middle', textAlign: 'center' }} >Action</th>
                     </tr>
                  </thead>
                  <tbody>
                     {
                        allQuestionHistory.map((question, i) => {
                           return <QuestionRow question={question} index={i} pageNumber={pageNumber} limit={limit} handleOpenDetail={handleOpenDetail} handleOnCheck={handleOnCheck} setView={setView} />
                        })
                     }
                  </tbody>
               </table>
            </div>

            {/* Footer */}
            <Pagination showing={Array.isArray(allQuestionHistory) ? allQuestionHistory.length : 0} totalItem={totalItem} showItem={limit} paginate={paginate} previousPage={previousPage} nextPage={nextPage} limit={pageNumber} />
         </div>

         <ModalQuestionDetail show={view} onHide={() => setView(false)} size={"lg"} question={question} />
      </div>

   );
}

function QuestionRow({ question, index, pageNumber, limit, handleOpenDetail, handleOnCheck, setView }) {
   const id = useId();

   return (
      <tr key={id} style={{ verticalAlign: 'middle' }}>
         <td style={{ textAlign: 'center' }}>
            <div className="form-check d-flex justify-content-center" style={{ scale: '1.5' }}>
               <input
                  className="form-check-input"
                  type="checkbox"
                  defaultValue=""
                  id={`flexCheckDefault`}
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => handleOnCheck(e, question, id)}
               />
            </div>
         </td>
         <td style={{ textAlign: 'center' }}>{((pageNumber - 1) * limit) + index + 1}</td>
         <td>{question.goal_name}</td>
         <td>{question.group_survey}</td>
         <td>{question.dimension}</td>
         <td>{question.aspect}</td>
         <td>{question.item}</td>
         <td style={{ textAlign: 'center' }}><IconButtonView onClick={() => handleOpenDetail(question)} /></td>

      </tr>)
}

export default QuestionHistory;