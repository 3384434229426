import React, { useEffect, useState } from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

import Header from './components/Header';
import SummaryCards from './components/SummaryCards';
import SurveyTables from './components/SurveyTables';

import LoadingSpinner from './components/LoadingSpinner';
import './components/styles.css';
import ParticipantTables from './components/ParticipantTables';
import CustomBarChart from './components/CustomBarChart';
import Top10Chart from './components/Top10Chart';
import BarChartSurvey from './components/BarSurvey';
import useAdminDashboard from './useAdminDashboard';
import Top5Chart from './components/top5Chart';
import BarChartStatus from './components/BarChartStatus';


const AdminDashboard = () => {
  const {
    dataMetrics,
    data1,
    data2,
    data3,
    data4,
    dataDepartments,
    dataBusinessUnits,
    dataSingleRaterSurveys,
    dataMultiRaterSurveys,
    dataTop5ActiveParticipantByDepartment,
    dataTop5ActiveParticipantByBusinessUnit,
    dataTop10SingleRaterByParticipant,
    dataTop10MultiRaterByParticipant,
    dataYearly,
    dataMonthly,
    dataYearlyStatusSingle,
    dataMonthlyStatusSingle,
    dataYearlyStatusMulti,
    dataMonthlyStatusMulti,
    serverTime,
    loading,
    error
  } = useAdminDashboard();
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedSingleYear, setSelectedSingleYear] = useState(null);
  const [selectedMultiYear, setSelectedMultiYear] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false); 


  const handleBarClick = (year) => {
    setSelectedYear(year);
  };

  const handleSingleBarClick = (year) => {
    setSelectedSingleYear(year);
  };

  const handleMultiBarClick = (year) => {
    setSelectedMultiYear(year);
  };
  const filteredMonthlyData = dataMonthly.filter(data => data.year === selectedYear);
  const filteredMonthlyStatusSingleData = dataMonthlyStatusSingle.filter(data => data.year === selectedSingleYear);
  const filteredMonthlyStatusMultiData = dataMonthlyStatusMulti.filter(data => data.year === selectedMultiYear);


  const downloadChartsAndTables = async () => {
    try {
      setIsDownloading(true);
      const pdf = new jsPDF('p', 'mm', 'a4');
      const elements = document.querySelectorAll('.pdf-element');
      let currentY = 10;

      for (const element of elements) {
        const canvas = await html2canvas(element, { scale: 2 });  // Increase scale for better resolution
        const imgData = canvas.toDataURL('image/png');

        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth() - 20;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        if (currentY + pdfHeight > pdf.internal.pageSize.getHeight()) {
          pdf.addPage();
          currentY = 10;
        }

        pdf.addImage(imgData, 'PNG', 10, currentY, pdfWidth, pdfHeight);
        currentY += pdfHeight + 10;
      }

      pdf.save('dashboard.pdf');
    } catch (error) {
      console.error('Error downloading charts and tables:', error);
    }finally {
      setIsDownloading(false); // Reset downloading state
    }
  };
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  return (
    <div className="container tw-h-max">
      <Header serverTime={serverTime} onDownload={downloadChartsAndTables} />
      {isDownloading && <LoadingSpinner />} 
      <div className="pdf-element">
        <SummaryCards dataMetrics={dataMetrics} />
      </div>
      <div className="pdf-element">
      <SurveyTables singleRaterSurveys={dataSingleRaterSurveys} multiRaterSurveys={dataMultiRaterSurveys} />
      </div>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px' }}>
      <div className="chart-container pdf-element">
        <Top5Chart data={dataTop5ActiveParticipantByDepartment} title="Top 5 Active Participant by Department" yAxisKey="department"  />
        </div>
        <div className="chart-container pdf-element">
        <Top5Chart data={dataTop5ActiveParticipantByBusinessUnit} title="Top 5 Active Participant by Business Unit" yAxisKey="business_name"  />
        </div>
      </div>


      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px',height: 400  }}>
      <div className="chart-container pdf-element" >
          <h2>Survey Data</h2>
          <BarChartSurvey
            data={dataYearly}
            dataKeyX="year"
            dataKeysY={['multi_rater', 'single_rater']}
            onBarClick={handleBarClick}
          />
        </div>
        {selectedYear && (
          <div className="chart-container pdf-element">
            <h2>{selectedYear} Monthly Data</h2>
            <BarChartSurvey
              data={filteredMonthlyData}
              dataKeyX="month"
              dataKeysY={['multiRater', 'singleRater']}
            />
          </div>
        )}
      </div>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px',height:'400px' }}>
      <div className="chart-container pdf-element" >
          <BarChartStatus
            title="Yearly Single Rater Participant Data"
            data={dataYearlyStatusSingle}
            dataKeyX="year"
            dataKeysY={['add_participant', 'done_participant']}
            onBarClick={handleSingleBarClick}
          />
        </div>
        {selectedSingleYear && (
          <div className="chart-container pdf-element" >
            <h2>{selectedSingleYear} Monthly  Single Rater Data</h2>
            <BarChartStatus
              data={filteredMonthlyStatusSingleData}
              dataKeyX="month"
              dataKeysY={['add_participant', 'done_participant']}
            />
          </div>
        )}
      </div>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px',height:'400px' }}>
      <div className="chart-container pdf-element"  >
          <BarChartStatus
            title="Yearly Multi Rater Participant Data"
            data={dataYearlyStatusMulti}
            dataKeyX="year"
            dataKeysY={['add_participant', 'done_participant']}
            onBarClick={handleMultiBarClick}
          />
        </div>
        {selectedMultiYear && (
          <div className="chart-container pdf-element"  >
            <h2 >{selectedMultiYear} Monthly Status Multi Data</h2>
            <BarChartStatus
              data={filteredMonthlyStatusMultiData}
              dataKeyX="month"
              dataKeysY={['add_participant', 'done_participant']}
            />
          </div>
        )}
      </div>

      <div className="pdf-element">

      <ParticipantTables 
      singleRaterSurveys={dataTop10SingleRaterByParticipant}
        multiRaterSurveys={dataTop10MultiRaterByParticipant}  style={{ marginBottom: '20px' }} />
        </div>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px' }}>
      <div className="chart-container pdf-element">
        <CustomBarChart title="Top 5 Goal" data={data1} yAxisKey="goal"  />
        </div>
        <div className="chart-container pdf-element">
        <CustomBarChart title="Top 5 Group" data={data2} yAxisKey="group_name" />
        </div>
        <div className="chart-container pdf-element">
        <CustomBarChart title="Top 5 Dimension" data={data3} yAxisKey="dimension_name"  />
        </div>
        <div className="chart-container pdf-element">
        <CustomBarChart title="Top 5 Aspect" data={data4} yAxisKey="aspect_name" />
        </div>
        <div className="chart-container pdf-element">
        <Top10Chart data={dataDepartments} style={{ marginBottom: '20px' }} title="Top 10 PIC by Department" yAxisKey="department" />
        </div>
        <div className="chart-container pdf-element">
        <Top10Chart data={dataBusinessUnits} 
        title="Top 10 PIC by Business Unit" 
        yAxisKey="business_unit" 
        style={{ marginBottom: '20px' }}
        />
        </div>
      </div>
    </div>
  );
};



export default AdminDashboard;
