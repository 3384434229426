export const initPosistion = {x: 0, y: 0};

export const useTransform = () => {
    const handleTransform = (ref, adjustX) => {
        if (ref == null) {
            return initPosistion;
        }

        return {
            x: window.getComputedStyle(ref?.current).width,
            y: parseInt(ref?.current?.offsetTop) - parseInt(adjustX)
        }
    }

    return {
        handleTransform,
    }
}
