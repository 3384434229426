import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CONSTANTS } from "../../../common/constants";
import { useSelector } from "react-redux";
import trendyLogo from "../../../assets/images/Trendy-horizontal-color.png"

/**
 * This part of code is made using Tailwind.css
 */

export function Navbar({ isExpanded }) {
   const [navEmail, setNavEmail] = useState('');

   const userReducer = useSelector((state) => state.user);

   // render first time only
   useEffect(() => {
      setNavEmail(userReducer.email);
   }, []);

   return (
      <nav className={`tw-w-full tw-h-14 tw-flex tw-backdrop-filter tw-backdrop-blur-md 
                     tw-bg-white/80 tw-fixed tw-border-r tw-shadow-sm`}
         style={{ zIndex: 40 }}>
         <div className={`tw-flex tw-items-center tw-justify-end tw-font-medium tw-w-1/6`}>
            {!isExpanded &&
               <img
                  src={trendyLogo}
                  className={`tw-overflow-hidden tw-transition-all ${!isExpanded ? "xl:tw-w-36 2xl:tw-w-40 lg:tw-w-0" : "tw-w-0"
                     }`}
                  alt=""
               />
            }
         </div>
         <div className="tw-flex tw-items-center tw-font-medium tw-w-2/6">
            <div className="tw-flex tw-items-center tw-w-full">
               <NavLinks />
            </div>
         </div>
         <div className="tw-flex tw-items-center tw-font-medium tw-w-1/6"></div>
         <div className={`tw-flex tw-items-center tw-justify-end tw-font-medium tw-w-2/6
                         tw-p-3`}>
            <div className={`tw-flex tw-justify-between tw-items-center tw-overflow-hidden tw-transition-all tw-mr-4`}>
               <div className="tw-leading-4 tw-flex tw-flex-col">
                  <span className="tw-font-semibold">{navEmail}</span>
               </div>
            </div>
         </div>
      </nav>
   );
};

export function NavLinks() {
   const adminQuickLinks = [
      {
         name: 'Project Menu',
         submenu: true,
         sublinks: [
            { name: 'Question Bank', path: `${CONSTANTS.ROUTER.QUESTION_BANK}` },
            { name: 'Target Participant Data', path: `${CONSTANTS.ROUTER.TARGET_PARTICIPANT_DATA}` },
            { name: 'Raters Data', path: `${CONSTANTS.ROUTER.RATERS_HISTORY}` },
            { name: 'Multi-Rater Survey List', path: `${CONSTANTS.ROUTER.MULTI_RATER_SURVEY_LIST}` },
         ]
      },
      {
         name: 'Project Management',
         submenu: true,
         sublinks: [
            {
               head: 'User Management',
               sublink: [
                  { name: 'Admin List', path: `${CONSTANTS.ROUTER.ADMIN_LIST}` },
                  { name: 'PIC List', path: `${CONSTANTS.ROUTER.PIC_LIST}` }
               ]
            },
            {
               head: 'Configure',
               sublink: [
                  { name: 'Support', path: `${CONSTANTS.ROUTER.ADMIN_SUPPORT}` },
               ]
            }
         ]
      }
   ];

   const picQuickLinks = [
      {
         name: 'Project Menu',
         submenu: true,
         sublinks: [
            { name: 'Question Bank', path: `${CONSTANTS.ROUTER.QUESTION_BANK}` },
            { name: 'Target Participant Data', path: `${CONSTANTS.ROUTER.TARGET_PARTICIPANT_DATA}` },
            { name: 'Raters Data', path: `${CONSTANTS.ROUTER.RATERS_HISTORY}` },
            { name: 'Multi-Rater Survey List', path: `${CONSTANTS.ROUTER.MULTI_RATER_SURVEY_LIST}` },
         ]
      }
   ];

   const participantQuickLinks = [];

   const [links, setLinks] = useState([]);
   const [isClicked, setClicked] = useState(false);
   const [isClickedShow, setClickedShow] = useState(false);

   const userReducer = useSelector((state) => state.user);

   // render first time only
   useEffect(() => {
      if (userReducer.role == 1) {
         setLinks(adminQuickLinks);
      } else if (userReducer.role == 2) {
         setLinks(picQuickLinks);
      } else if (userReducer.role == 3) {
         setLinks(participantQuickLinks);
      }
   }, []);

   useEffect(() => {
      if (isClicked === true) {
         setTimeout(() => {
            setClicked(false);
            setClickedShow(false);
         }, 500);
      }
   }, [isClicked]);

   return (
      <>
         {
            links.map((value, key) => (
               <div key={key} className="tw-flex tw-group tw-grid-cols-6 tw-ml-12"
                  onMouseLeave={() => {
                     setClickedShow(false);
                  }}
               >
                  <span className={`tw-p-3 tw-inline-block tw-cursor-pointer tw-group tw-transition-all 
                                    tw-duration-300 tw-ease-in-out`}
                     onClick={() => {
                        setClickedShow(!isClickedShow);
                     }}
                  >
                     <span className={`tw-bg-left-bottom tw-bg-gradient-to-r tw-from-violet-500 tw-to-violet-500 tw-bg-[length:0%_2px] 
                                       tw-bg-no-repeat group-hover:tw-bg-[length:100%_2px] tw-transition-all tw-duration-500 tw-ease-out`}>
                        {value.name}
                     </span>
                  </span>
                  {
                     value.submenu && (
                        <div className={`tw-absolute tw-top-12 tw-bg-white tw-px-3 tw-border-2 tw-hidden
                                    tw-border-gray-200 tw-border-solid tw-rounded-lg tw-shadow-sm 
                                    ${isClicked ? 'tw-pointer-events-none' : ''} ${isClickedShow ? 'hover:tw-block group-hover:tw-block' : ''}`}
                           onMouseLeave={() => {
                              setClickedShow(!isClickedShow);
                           }}
                        >
                           {
                              value.sublinks.map((value, key) => (
                                 value.head ?
                                    <div key={key} className="tw-my-3">
                                       <div className="tw-font-semibold">{value.head}</div>
                                       {
                                          value.sublink.map((value, key) => (
                                             <div key={key} className="tw-my-3">
                                                <Link className="tw-text-gray-600 tw-no-underline hover:tw-text-violet-500" to={value.path}
                                                   onClick={() => {
                                                      setClicked(true);
                                                   }}>
                                                   {value.name}
                                                </Link>
                                             </div>
                                          ))
                                       }
                                    </div> :
                                    <div key={key} className="tw-my-3">
                                       <Link className="tw-text-gray-600 tw-no-underline hover:tw-text-violet-500" to={value.path}
                                          onClick={() => {
                                             setClicked(true);
                                          }}
                                       >
                                          {value.name}
                                       </Link>
                                    </div>
                              ))
                           }
                        </div>
                     )
                  }
               </div >
            ))
         }
      </>
   )
}
