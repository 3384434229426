import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDeps } from "../../../../shared/context/DependencyContext";
import { CONSTANTS } from "../../../../common/constants";

function useTabParticipant({ nik }) {
   const { adminService, picService } = useDeps();
   const user = useSelector((state) => state.user);
   const [emailLoginAsParticipant, setEmailLoginAsParticipant] = useState([]);

   useEffect(() => {
      Array.isArray(nik) && nik.length > 0 && onCheckEmailAsParticipantByNIK(nik)
   }, [nik]);

   const onCheckEmailAsParticipantByNIK = async (nik) => {
      try {
         if (user.role == CONSTANTS.ROLE.ADMIN) {
            const response = await adminService.userMasterService.getEmailParticipantbyNIK(nik);
            if (response.status == 'SUCCESS' && response.data != 'NO DATA') {
               setEmailLoginAsParticipant(response.data);
            } else {
               setEmailLoginAsParticipant([]);
            }
         } else {
            const response = await picService.userMasterService.getEmailParticipantbyNIKPICUrl(nik);
            if (response.status == 'SUCCESS' && response.data != 'NO DATA') {
               setEmailLoginAsParticipant(response.data);
            } else {
               setEmailLoginAsParticipant([]);
            }
         }
      } catch (error) {
         console.log(error);
      }
   }

   return {
      emailLoginAsParticipant,
   }
}

export default useTabParticipant;