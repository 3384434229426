import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCopy, faExclamation, faEye, faChartLine, faPenToSquare, faSave, faTrash, faMagnifyingGlassChart } from '@fortawesome/free-solid-svg-icons';
import './Button.css';
import { useState } from 'react';


export const IconButtonDelete = ({ onClick, disabled }) => {
    return (
        <button
            className='icon-btn color-danger'
            disabled={disabled}
            onClick={disabled ? () => { } : onClick}

        >
            <FontAwesomeIcon icon={faTrash} size='xl' />
        </button>
    )
}

export const IconButtonDuplicate = ({ onClick, disabled }) => {
    return (
        <button
            className="color-warning icon-btn"
            onClick={disabled ? () => { } : onClick}
            disabled={disabled}

        >
            <FontAwesomeIcon icon={faCopy} size='xl' />
        </button>
    )
}

export const IconButtonView = ({ onClick, disabled }) => {
    return (
        <button
            className="color-view icon-btn"
            onClick={disabled ? () => { } : onClick}
            disabled={disabled}
        >
            <FontAwesomeIcon icon={faEye} size='xl' />
        </button>
    )


}

export const IconButtonEdit = ({ onClick, disabled }) => {
    return (
        <button
            className="color-success icon-btn"
            onClick={disabled ? () => { } : onClick}
            disabled={disabled}
        >
            <FontAwesomeIcon icon={faPenToSquare} size='xl' />
        </button>
    )
}

export const IconButtonWarning = ({ onClick }) => {
    return (
        <button
            className="outline-color-warning icon-btn"
            onClick={onClick}

        >
            <FontAwesomeIcon icon={faExclamation} size='xl' />
        </button>
    )
}

export const IconButtonSave = ({ onClick, disabled }) => {
    return (
        <button
            className="color-primary icon-btn"
            onClick={disabled ? () => { } : onClick}
            disabled={disabled}
        >
            <FontAwesomeIcon icon={faSave} size='xl' />
        </button>
    )


}

export const IconButtonPrimary = ({ onClick, icon, disabled, style }) => {
    return (
        <button
            className="color-primary icon-btn"
            onClick={disabled ? () => { } : onClick}
            disabled={disabled}
            style={style}
        >
            <FontAwesomeIcon icon={icon} size='xl' />
        </button>
    )

}

export const IconButtonSuccess = ({ onClick, icon, disabled }) => {
    return (
        <button
            className="color-success icon-btn"
            onClick={onClick}
            disabled={disabled}
        >
            <FontAwesomeIcon icon={faPenToSquare} size='xl' />
        </button>
    )
}

export const IconButtonWithLabel = ({ onClick, icon, disabled, bgColor, label }) => {
    return (
        <button
            className={`${bgColor ? bgColor : 'color-success'} tw-font-medium tw-justify-evenly rounded-3
                         gap-3 h-100 p-3 d-flex align-items-center text-white w-100`}
            onClick={onClick} disabled={disabled}>
            {icon ? <FontAwesomeIcon icon={icon} size='l' /> : <></>}
            {label ? <span className="">{label}</span> : <></>}
        </button>
    )

}

export const IconButtonGeneral = ({ onClick, icon, disabled, color_enabled, size, style }) => {
    return (
        <button
            className="color-general icon-btn"
            onClick={disabled ? () => { } : onClick}
            disabled={disabled}
            style={style}

        >
            <FontAwesomeIcon icon={icon} size={size} style={{ color: disabled ? 'rgba(200, 35, 51, 0.2)' : color_enabled }} />
        </button>
    )
}

export const IconButtonPrimarySmall = ({ onClick, icon, disabled, style }) => {
    return (
        <button
            className="color-primary icon-btn-sm"
            onClick={disabled ? () => { } : onClick}
            disabled={disabled}
            style={style}
        >
            <FontAwesomeIcon icon={icon} size='xl' />
        </button>
    )

}



export const IconButtonCheck = ({ onClick }) => {
    const [check, setCheck] = useState(false);

    const handleCheck = () => {
        setCheck(true)
        onClick();
    }

    return (
        check ?
            <IconButtonPrimary icon={faCheck} disabled={true} />
            :
            <IconButtonPrimarySmall style={{ backgroundColor: '#7749F8' }} onClick={() => handleCheck()} />


    )
}

export const IconButtonChart = ({ onClick, disabled }) => {
    return (
        <button
            className="color-view icon-btn"
            onClick={disabled ? () => { } : onClick}
            disabled={disabled}

        >
            <FontAwesomeIcon icon={faChartLine} size='xl' />
        </button>
    )
}

export const IconButtonPreview = ({ onClick, disabled }) => {
    return (
        <button
            className="color-warning icon-btn"
            onClick={disabled ? () => { } : onClick}
            disabled={disabled}
        >
            <FontAwesomeIcon icon={faMagnifyingGlassChart} size='xl' />
        </button>
    )
}
