import { useNavigate } from "react-router-dom";
import { InstructionView } from "./InstructionView";
import "bootstrap/dist/css/bootstrap.min.css";
import useOnlineForm from "../online_form/useOnlineForm";
import { CONSTANTS } from "../../../common/constants";
import { ShadowCard } from "../../../shared/component/card/ShadowCard";
import { Card, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardList, faClipboardUser, faListCheck } from "@fortawesome/free-solid-svg-icons";
import { ButtonPrimary } from "../../../shared/component/button/Button";
import IntroductionModal from "./IntroductionModal";
import IntroductionModalMobile from "./IntroductionModalMobile";
import ModalAddUser from "../../../shared/component/modal/AddUserModal";
import { useSelector } from "react-redux";

export const IntroductionViewParticipant = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const multiPath = `${CONSTANTS.ROUTER.PARTICIPANT_DASHBOARD}/${CONSTANTS.ROUTER.PARTICIPANT_MULTIRATER_LIST}`;
  const singlePath = `${CONSTANTS.ROUTER.PARTICIPANT_DASHBOARD}/${CONSTANTS.ROUTER.PARTICIPANT_SINGLERATER_LIST}`;
  const navigate = useNavigate();
  const { countMR, onGetCountActiveSurvey, countSR } = useOnlineForm();
  const [modalShow, setModalShow] = useState(true)
  const userReducer = useSelector((state) => state.user);


  useEffect(() => {
    onGetCountActiveSurvey();
  }, []);

  useEffect(() => {
    if (userReducer.is_internal == 1 && userReducer.dob != null && userReducer.gender != null && userReducer.no_hp != null) {
          setModalShow(false)
    } else if (userReducer.is_internal == 0 && userReducer.dob != null && userReducer.gender != null && userReducer.no_hp != null  && userReducer.position != null && userReducer.business_unit_name != null) {
      setModalShow(false)
    }else {
      setModalShow(true)
    }
    
  }, [userReducer]);


  return windowWidth >= 500 ? (
    <div className="container mt-4">
      <div className="row">
        <div className="col-md-6 col-sm-12 mb-3">
          <InstructionView />
        </div>

        <div className="col-md-5 col-sm-12 mb-3 ms-4">

          <div className="card mb-3 shadow-sm border border-0">
              <Card.Body>
                  <div className="d-flex align-items-center">
                      <div>
                        <FontAwesomeIcon icon={faClipboardUser} size="2x" />
                      </div>
                      <div className="flex-grow-1 ms-3" >
                        <Card.Title>Multi Rater Survey</Card.Title>
                        <Card.Text>
                        {countMR != 0 ? countMR : "no"} surveys available
                        </Card.Text>
                      </div>
                     
                      <div>
                      <ButtonPrimary  onClick={() => navigate(multiPath)} label="Go to Survey"/>
                      </div>  
                  </div>
                  </Card.Body>
                  </div>


          <div className="card mb-3 shadow-sm border border-0">
              <Card.Body>
                  <div className="d-flex align-items-center">
                      <div>
                        <FontAwesomeIcon icon={faClipboardList} size="2x" />
                      </div>
                      <div className="flex-grow-1 ms-3" >
                        <Card.Title>Single Rater Survey</Card.Title>
                        <Card.Text>
                        {countSR != 0 ? countSR : "no"} surveys available
                        </Card.Text>
                      </div>
                     
                      <div>
                      <ButtonPrimary  onClick={() => navigate(singlePath)} label="Go to Survey"/>
                      </div>
                      {/* <ButtonPrimary label="Modal tampilan" onClick={()=>setModalShow(true)}/>   */}
                  </div>
              </Card.Body>
          </div>
        </div>
      </div>
      <IntroductionModal
      title={`Please complete your details`} size={'xl'}
      show={modalShow}
      onHide={()=>setModalShow(false)}
    />
    </div>
  ) : (
    <div className="container mt-4">
    <div className="row">
      <div className="col-md-6 col-sm-12 mb-3">
        <InstructionView />
      </div>

      <div className="col-md-5 col-sm-12 mb-3 ms-4">

        <div className="card mb-3">
            <Card.Body>
                <div className="d-flex align-items-center">
                    <div className="flex-grow-1 ms-3" >
                      <Card.Title>Multi Rater Survey</Card.Title>
                      <Card.Text>
                      {countMR != 0 ? countMR : "no"} surveys available
                      </Card.Text>
                    </div>
                   
                    <div>
                    <ButtonPrimary  onClick={() => navigate(multiPath)} label="Click"/>
                    </div>  
                </div>
                </Card.Body>
                </div>


        <div className="card mb-3">
            <Card.Body>
                <div className="d-flex align-items-center">
                   
                    <div className="flex-grow-1 ms-3" >
                      <Card.Title>Single Rater Survey</Card.Title>
                      <Card.Text>
                      {countSR != 0 ? countSR : "no"} surveys available
                      </Card.Text>
                    </div>
                   
                    <div>
                    <ButtonPrimary  onClick={() => navigate(singlePath)} label="Click"/>
                    </div>  
                  
                </div>
            </Card.Body>
            
        </div>
      </div>
    </div>
    <IntroductionModalMobile
      title={`Please complete your details`} size={'xl'}
    show={modalShow}
    />
  </div>
  );
};
