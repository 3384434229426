import { ADD_SCALE_CHOICE, CLEAR_DATA_PREVIEW_MR, CLEAR_SCALE_CHOICE, CLEAR_VIEW_CONTENT, DELETE_CONTENT, DELETE_CONTENT_CHOICE, DELETE_PARTICIPANT, DELETE_SCALE_CHOICE, DELETE_SECTION, DUPLICATE_MULTI_RATER, DUPLICATE_PARTICIPANT, DUPLICATE_SCALE_CHOICE, DUPLICATE_SCORE, DUPLICATE_SECTION_CONTENT, DUPLICATE_SETTING, EDIT_CONTENT, EDIT_CONTENT_CHOICE, EDIT_PARTICIPANT, EDIT_SCALE_CHOICE, EDIT_SECTION, RESET_CONTENT_VIEW, RESET_MULTI_RATER, RESET_PARTICIPANT, RESET_SCALE_CHOICE, RESET_SCORE, RESET_SECTION, RESET_SETTING, RESET_SETTING_LABEL_DATA_MR, RESET_VALID_INPUT, RESET_VIEW_EDIT_PARTICIPANT, RESET_VIEW_EDIT_PARTICIPANT_KEY_EMAIL, RESET_VIEW_TAB, SET_CONTENT, SET_CONTENT_BULK, SET_CONTENT_CHOICE, SET_CONTENT_CHOICE_SCALE, SET_DATA_PREVIEW_MR, SET_MULTI_RATER, SET_PARTICIPANT, SET_REV_SCALE_CONTENT_CHOICE, SET_SCALE_CHOICE, SET_SCALE_CONTENT_CHOICE, SET_SCORE, SET_SECTION, SET_SETTING, SET_SETTING_LABEL_DATA_MR, SET_VALID_CONTENT, SET_VALID_PARTICIPANT, SET_VALID_SETTING, SET_VIEW, SET_VIEW_CONTENT, SET_VIEW_EDIT_PARTICIPANT, SET_VIEW_EDIT_PARTICIPANT_KEY_EMAIL } from "./MultiRaterType";

// define action constant
const setMRViewAction = (reqData) => ({
   type: SET_VIEW,
   data: reqData
});

const setMRAction = (reqData) => ({
   type: SET_MULTI_RATER,
   data: reqData
});

const setSettingMRAction = (reqData) => ({
   type: SET_SETTING,
   data: reqData
});

const setSectionMRAction = (reqData) => ({
   type: SET_SECTION,
   data: reqData
});

const editSectionMRAction = (reqData) => ({
   type: EDIT_SECTION,
   data: reqData
});

const deleteSectionMRAction = (reqData) => ({
   type: DELETE_SECTION,
   data: reqData
});

const setContentMRAction = (reqData) => ({
   type: SET_CONTENT,
   data: reqData
});

const setContentBulkMRAction = (reqData) => ({
   type: SET_CONTENT_BULK,
   data: reqData
});

const editContentMRAction = (reqData) => ({
   type: EDIT_CONTENT,
   data: reqData
});

const deleteContentMRAction = (reqData) => ({
   type: DELETE_CONTENT,
   data: reqData
});

const setContentChoiceMRAction = (reqData) => ({
   type: SET_CONTENT_CHOICE,
   data: reqData
});

const setContentChoiceScaleMRAction = (reqData) => ({
   type: SET_CONTENT_CHOICE_SCALE,
   data: reqData
});

const editContentChoiceMRAction = (reqData) => ({
   type: EDIT_CONTENT_CHOICE,
   data: reqData
});

const deleteContentChoiceMRAction = (reqData) => ({
   type: DELETE_CONTENT_CHOICE,
   data: reqData
});

const setContentViewMRAction = (reqData) => ({
   type: SET_VIEW_CONTENT,
   data: reqData
});

const clearContentViewMRAction = () => ({
   type: CLEAR_VIEW_CONTENT
});

const setScaleChoiceMRAction = (reqData) => ({
   type: SET_SCALE_CHOICE,
   data: reqData
});

const addScaleChoiceMRAction = (reqData) => ({
   type: ADD_SCALE_CHOICE,
   data: reqData
});

const editScaleChoiceMRAction = (reqData) => ({
   type: EDIT_SCALE_CHOICE,
   data: reqData
});

const deleteScaleChoiceMRAction = (reqData) => ({
   type: DELETE_SCALE_CHOICE,
   data: reqData
});

const clearScaleChoiceMRAction = (reqData) => ({
   type: CLEAR_SCALE_CHOICE,
   data: reqData
});

const setScoreMRAction = (reqData) => ({
   type: SET_SCORE,
   data: reqData
});

const setParticipantMRAction = (reqData) => ({
   type: SET_PARTICIPANT,
   data: reqData
});

const deleteParticipantMRAction = (reqData) => ({
   type: DELETE_PARTICIPANT,
   data: reqData
});

const editParticipantMRAction = (reqData) => ({
   type: EDIT_PARTICIPANT,
   data: reqData
});

const setEditParticipantView = (reqData) => ({
   type: SET_VIEW_EDIT_PARTICIPANT,
   data: reqData
});

const resetViewTab = () => ({
   type: RESET_VIEW_TAB
});

const resetMultiRater = () => ({
   type: RESET_MULTI_RATER
});

const resetSetting = () => ({
   type: RESET_SETTING
});

const resetSection = () => ({
   type: RESET_SECTION
});

const resetContentView = () => ({
   type: RESET_CONTENT_VIEW
});

const resetScaleChoice = () => ({
   type: RESET_SCALE_CHOICE
});

const resetScore = () => ({
   type: RESET_SCORE
});

const resetParticipant = () => ({
   type: RESET_PARTICIPANT
});

const resetViewEditParticipant = () => ({
   type: RESET_VIEW_EDIT_PARTICIPANT
});

const duplicateMRAction = (reqData) => ({
   type: DUPLICATE_MULTI_RATER,
   data: reqData
});

const duplicateSettingMRAction = (reqData) => ({
   type: DUPLICATE_SETTING,
   data: reqData
});

const duplicateSectionContentMRAction = (reqData) => ({
   type: DUPLICATE_SECTION_CONTENT,
   data: reqData
});

const duplicateScaleChoiceMRAction = (reqData) => ({
   type: DUPLICATE_SCALE_CHOICE,
   data: reqData
});

const duplicateScoreMRAction = (reqData) => ({
   type: DUPLICATE_SCORE,
   data: reqData
});

const duplicateParticipantMRAction = (reqData) => ({
   type: DUPLICATE_PARTICIPANT,
   data: reqData
});


const clearDataPreviewMR = () => ({
   type: CLEAR_DATA_PREVIEW_MR
});

const setDataPreviewMR = (reqData) => ({
   type: SET_DATA_PREVIEW_MR,
   data: reqData
});

const setValidSettingMRAction = (reqData) => ({
   type: SET_VALID_SETTING,
   data: reqData
});

const setValidContentMRAction = (reqData) => ({
   type: SET_VALID_CONTENT,
   data: reqData
});

const setValidParticipantMRAction = (reqData) => ({
   type: SET_VALID_PARTICIPANT,
   data: reqData
});

const resetValidInputMRAction = (reqData) => ({
   type: RESET_VALID_INPUT,
   data: reqData
});

const setEditParticipantViewByEmail = (reqData) => ({
   type: SET_VIEW_EDIT_PARTICIPANT_KEY_EMAIL,
   data: reqData
});

const resetViewEditParticipantByEmail = () => ({
   type: RESET_VIEW_EDIT_PARTICIPANT_KEY_EMAIL
});

const setSettingLabelDataMRAction = (reqData) => ({
   type: SET_SETTING_LABEL_DATA_MR,
   data: reqData
});

const resetSettingLabelDataMR = () => ({
   type: RESET_SETTING_LABEL_DATA_MR
});

export default {
   setMRViewAction,
   setMRAction,
   setSettingMRAction,
   setSectionMRAction,
   editSectionMRAction,
   deleteSectionMRAction,
   setContentMRAction,
   editContentMRAction,
   deleteContentMRAction,
   setContentChoiceMRAction,
   setContentChoiceScaleMRAction,
   editContentChoiceMRAction,
   deleteContentChoiceMRAction,
   setContentViewMRAction,
   clearContentViewMRAction,
   setScaleChoiceMRAction,
   addScaleChoiceMRAction,
   editScaleChoiceMRAction,
   deleteScaleChoiceMRAction,
   clearScaleChoiceMRAction,
   setScoreMRAction,
   setParticipantMRAction,
   deleteParticipantMRAction,
   editParticipantMRAction,
   setEditParticipantView,
   resetViewTab,
   resetMultiRater,
   resetSetting,
   resetSection,
   resetContentView,
   resetScaleChoice,
   resetScore,
   resetParticipant,
   resetViewEditParticipant,
   duplicateMRAction,
   duplicateSettingMRAction,
   duplicateSectionContentMRAction,
   duplicateScaleChoiceMRAction,
   duplicateScoreMRAction,
   duplicateParticipantMRAction,
   setContentBulkMRAction,
   clearDataPreviewMR,
   setDataPreviewMR,
   setValidSettingMRAction,
   setValidContentMRAction,
   setValidParticipantMRAction,
   resetValidInputMRAction,
   setEditParticipantViewByEmail,
   resetViewEditParticipantByEmail,
   setSettingLabelDataMRAction,
   resetSettingLabelDataMR
}