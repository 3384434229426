import { USER_LOGIN, USER_LOGOUT, USER_REFRESH_TOKEN } from "./UserType";

// initial state
const initUserState = {
   token: '',
   email: '',
   name: '',
   role: 0,
   nik: ''
}

// define reducer action
export function userReducer(state = initUserState, action) {
   switch (action.type) {
      case USER_LOGIN:
         return Object.assign({}, state, action.data);
      case USER_LOGOUT:
         return Object.assign({}, state, {
            token: '',
            email: '',
            name: '',
            role: 0,
            nik: ''
         });
      case USER_REFRESH_TOKEN:
         return {
            ...state,
            token: action.data
         }
      default:
         return state;
   }
}