export const randomTypes = {
    FRACTION: 1,
    BASE10: 2,
    BASE100: 3
};

export const createRandom = (rndType, min, max, plus = 1, power = 1) => {
    let parts = []; //start, end, lower, upper
    const isFraction = rndType === randomTypes.FRACTION;

    switch (rndType) {
        case randomTypes.FRACTION:
            parts = [0, 4, min, max];
            break;
        case randomTypes.BASE10:
            parts = [2, 3, min, max];
            break;
        case randomTypes.BASE100:
            parts = [2, 4, min, max];
            break;
        default:
            break;
    }

    let rand = Math.random().toString().substring(parts[0], parts[1]);
    rand = isFraction ? parseFloat(rand) : parseInt(rand);

    /*if (power === 1) {
        return rand
    }*/

    return rand < parts[2]
        ? rand + plus
        : rand >= parts[3]
        ? isFraction
            ? parseFloat(rand * power)
            : parseInt(rand * power)
        : rand;
};
