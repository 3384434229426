import { useLocation } from 'react-router-dom';
import { CONSTANTS } from '../../../common/constants';

const { ROUTER, ROLE } = CONSTANTS;

const createUrl = (role, suffix) => {
    const suffixUrl = suffix ? '/' + suffix : '';

    if (role === ROLE.ADMIN) {
        return ROUTER.ADMIN_CMS + '/' + ROUTER.REPORT_TEMPLATE + suffixUrl;
    } else if (role === ROLE.PIC) {
        return ROUTER.PIC_CMS + '/' + ROUTER.REPORT_TEMPLATE + suffixUrl;
    }
};

const useRouting = (role) => {
    const { pathname } = useLocation();

    const urlListTemplate = () => createUrl(role);

    const urlAddTemplateSR = () => createUrl(role, ROUTER.ADD_REPORT_SINGLE);

    const urlEditTemplateSR = () => createUrl(role, ROUTER.EDIT_REPORT_SINGLE);

    const urlAddTemplateMRG = () => createUrl(role, ROUTER.ADD_REPORT_MULTI_GROUP);

    const urlEditTemplateMRG = () => createUrl(role, ROUTER.EDIT_REPORT_MULTI_GROUP);

    const urlAddTemplateMRI = () => createUrl(role, ROUTER.ADD_REPORT_MULTI);

    const urlEditTemplateMRI = () => createUrl(role, ROUTER.EDIT_REPORT_MULTI);

    const urlPreviewTemplate = (code) => {
        const url = createUrl(role, ROUTER.PREVIEW_TEMPLATE);
        const newState = { location: pathname, code: code };

        return [url, newState];
    };

    const urlReportPreviewTemplate = () =>
        createUrl(role, ROUTER.REPORT_PREVIEW_TEMPLATE);

    const urlFromState = (state) => {
        const { __prev, __state } = state;

        if (__state instanceof Object && __state.tab_report !== undefined) {
            return [__prev, __state];
        }
        return [__prev, undefined];
    };

    return {
        pathname,
        urlListTemplate,
        urlAddTemplateSR,
        urlEditTemplateSR,
        urlAddTemplateMRG,
        urlEditTemplateMRG,
        urlAddTemplateMRI,
        urlEditTemplateMRI,
        urlPreviewTemplate,
        urlReportPreviewTemplate,
        urlFromState
    };
};

export default useRouting;
