
export const FaqService = ({  doPost }) => {
   

    const postFaq = async (question,answer,create_by) => {
        try {
            return await doPost({
                url: '/admin/create_faq',
                data: {
                    question: question,
                    answer: answer,
                   
                  },                  
            });
        } catch (e) {
            throw e;
        }
    };

    const getAllFaq = async (limit,page_number) => {
        try {
            return await doPost({
                url: '/admin/get_faq',
                data: {
                    limit: limit,
                    page_number: page_number
                },
            });
        } catch (e) {
            throw e;
        }
    };


    const getFaqSearch = async (search_text,limit,page_number) => {
        try {
            return await doPost({
                url: '/admin/search_faq',
                data: {
                    search_bar :search_text,
                    limit: limit,
                    page_number: page_number
                },
            });
        } catch (e) {
            throw e;
        }
    };


    const updateFaq = async (id,question,answer) => {
        // console.log("updateFaq=======");
        // console.log(`Id updateFaq ${id}`);

        // console.log(`question updateFaq ${question}`);
        // console.log(`answer updateFaq ${answer}`);

        try {
            return await doPost({
                url: '/admin/update_faq',
                data: {
                    faq_id: id,
                    question: question,
                    answer: answer,
                  },                  
            });
        } catch (e) {
           
            throw e;
        }
    };

    const deleteFaq = async (id) => {
        try {
            return await doPost({
                url: '/admin/delete_faq',
                data: {
                    faq_id: id,
                  },                  
            });
        } catch (e) {
            throw e;
        }
    };

    return {
        postFaq,
        getAllFaq,
        getFaqSearch,
        updateFaq,
        deleteFaq
      
    };
};