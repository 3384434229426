import { useDispatch, useSelector } from "react-redux";
import SectionCard, { PicCardSurvey } from "../../../../shared/component/card/SectionCard"
import { Footer, FooterPreview } from "../../../layout/navbar/Footer";


export const WelcomeMultiRaterView = ({onClickNext,pic,welcome_text,preview=false, onClickPrev}) => {

    if (preview) {
        return (
            <div>
                <div className="container tw-h-max tw-max-w-[1400px]">
                 
                    <SectionCard
                        titleCard={'WELCOME'}
                        descCard={welcome_text}
                    />
                    <PicCardSurvey
                        pic={pic}
                    />
                </div>
                <FooterPreview
                    disablePrev={true} disableNext={false} onClickNext={onClickNext}
                />
            </div>
        )
    } else{
        return (
            <>
                <div className="tw-p-4 tw-py-4 tw-pb-[5rem]  tw-w-full 
                                tw-justify-center tw-h-full tw-overflow-auto tw-bg-slate-50">
                 
                    <SectionCard
                        titleCard={'WELCOME'}
                        descCard={welcome_text}
                    />
                    <PicCardSurvey
                        pic={pic}
                    />
                </div>
                <Footer
                    disablePrev={false} disableNext={false} onClickNext={onClickNext} onClickPrev={onClickPrev}
                />
            </>
        )
    }
}