import { Col } from 'react-bootstrap';
import FormSelect from '../dropdown/FormSelect';

const ColorInfo = ({ id, title, value, onChange, colorCount, colors, showLine }) => {
    const themes = [
        { label: 'Theme 1', value: 'theme1' },
        { label: 'Theme 2', value: 'theme2' },
        { label: 'Theme 3', value: 'theme3' },
        { label: 'Theme 4', value: 'theme4' },
        { label: 'Theme 5', value: 'theme5' },
    ];

    return (
        <Col className="">
            <FormSelect
                id={id}
                size="sm"
                items={themes}
                value={value}
                onChange={onChange}
            />
            <div className="mt-2 p-2 border">
                <div className="d-flex align-items-start">
                    <p>Text Color</p>
                    <BoxColor color={'#000'} />
                </div>
                <div className="d-flex align-items-start">
                    <p>Background Color </p>
                    <BoxColor color={'#fff'} />
                </div>
                {showLine && (
                    <div className="d-flex align-items-start">
                        <p>Background Line Color </p>
                        <BoxColor color={'#adb5bd'} />
                    </div>
                )}
                <div className="d-flex align-items-start">
                    <p>{title} </p>
                    {[...Array(colorCount).keys()].map((n) => (
                        <BoxColor key={n} color={colors[n]} />
                    ))}
                </div>
            </div>
        </Col>
    );
};

const BoxColor = ({ color }) => {
    return (
        <div className="border mx-2">
            <div
                style={{
                    backgroundColor: color,
                    width: '20px',
                    height: '20px',
                    borderRadius: '15px'
                }}
            ></div>
        </div>
    );
};

export default ColorInfo;
