import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ButtonOutlinePrimary, ButtonPreview } from "../../../shared/component/button/Button";
import { WelcomeMultiRaterView } from "../../participant_features/multirater_survey_form/components/WelcomeMultiRaterView";
import FinishFormView from "../../participant_features/multirater_survey_form/components/FinishFormView";
import SectionCard from "../../../shared/component/card/SectionCard";
import QuestionView from "../../participant_features/multirater_survey_form/components/QuestionView";
import { FooterPreview } from "../../layout/navbar/Footer";
import { rootAction } from "../../../redux/RootAction";

function PreviewSinglerater() {
    const [sec, setSec] = useState(SURVEY_PART.WELCOME);
    const singleRaterData = useSelector((state) => state.preview_data_sr);
    const user = useSelector((state) => state.user);
    const [surveyDetailData, setSurveyDetailData] = useState();
    const [sectionPageIndex, setSectionPageIndex] = useState(0);
    const navigate = useNavigate();
    const dispatch = useDispatch();




    useEffect(() => {
        setSurveyDetailData(singleRaterData)
        // console.log('settingReducer',settingReducer);
    }, [singleRaterData])




    const handleFromWelcomeToQuestion = () => {
        setSec(SURVEY_PART.QUESTION);
        // console.log('handleFromWelcomeToQuestion');
        setSectionPageIndex(0);
    }

    const handleFinishtoQuestion = () => {
        setSec(SURVEY_PART.QUESTION);
        setSectionPageIndex(surveyDetailData.section.length - 1);
    }

    const handleNextPage = () => {
        if (sectionPageIndex < surveyDetailData.section.length - 1) {
            setSectionPageIndex(sectionPageIndex + 1);
        } else if ((sectionPageIndex + 1) == surveyDetailData.section.length) {
            setSec(SURVEY_PART.FINISH)
        }
    }

    const handlePrevPage = () => {
        if (sectionPageIndex > 0) {
            setSectionPageIndex(sectionPageIndex - 1);
        } else if (sectionPageIndex == 0) {
            setSec(SURVEY_PART.WELCOME)
        }
    }

    const handleBack = () => {
        dispatch(rootAction.singleRater.clearDataPreviewSR());
        navigate(-1)
    }

    if (surveyDetailData != undefined) {
        return (
            <div className="container tw-h-max tw-max-w-[1400px]">
                <div className="d-flex justify-content-end">
                    <div className="me-2" >
                        <ButtonPreview label="PREVIEW PAGE" disabled={true} />
                    </div>
                    <div>
                        <ButtonOutlinePrimary label="Back" onClick={handleBack} />
                    </div>
                </div>
                {Array.isArray(surveyDetailData.section) && surveyDetailData.section.length != 0 &&
                    surveyDetailData.section.map((ch, i) => {
                        // console.log('i', i);
                        // console.log('sectionPageIndex', sectionPageIndex);
                        return (
                            <div key={i}>
                                {sec == SURVEY_PART.WELCOME && i == sectionPageIndex ?
                                    <WelcomeMultiRaterView preview={true} onClickNext={() => handleFromWelcomeToQuestion()} pic={user.email} welcome_text={surveyDetailData.setting.welcome_text} /> :
                                    sec == SURVEY_PART.FINISH && i == sectionPageIndex ?
                                        <FinishFormView finish_text={surveyDetailData.setting.finish_text} onClickPrev={() => handleFinishtoQuestion()} preview={true} /> :
                                        i == sectionPageIndex ?
                                            <SurveyContentPreview surveyDetailData={surveyDetailData} sectionPageIndex={sectionPageIndex} handleNextPage={() => handleNextPage()} handlePrevPage={() => handlePrevPage()} /> : <></>
                                }
                            </div>
                        )
                    })
                }

            </div>
        )
    } else {
        return (
            <div>Loading  . . . .</div>
        )
    }
}

export default PreviewSinglerater

const SURVEY_PART = {
    WELCOME: 'welcome',
    QUESTION: 'question',
    FINISH: 'finish'
}

function SurveyContentPreview({ sectionPageIndex, handleNextPage, handlePrevPage, surveyDetailData }) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);


    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Membersihkan listener ketika komponen di-unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <div >

            <div

            >
                <SectionCard
                    titleCard={surveyDetailData.section[sectionPageIndex].section_name}
                    descCard={surveyDetailData.section[sectionPageIndex].description}
                />
                <QuestionView
                    content={surveyDetailData.section[sectionPageIndex].content}

                    sectionCode={surveyDetailData.section[sectionPageIndex].section_code}
                    languageItem={surveyDetailData.setting.language}
                    sectionName={surveyDetailData.section[sectionPageIndex].section_name}
                />



            </div>


            <FooterPreview
                disablePrev={false} disableNext={false} onClickNext={() => handleNextPage()} onClickPrev={() => handlePrevPage()} />



        </div>
    )
}