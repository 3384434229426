export const thField_Raters = [
    'No',
    'Survey Code',
    'Survey Type',
    'Survey Name',
    'NIK',
    'Name',
    'Email',
    'Number',
    'Business Unit',
    'Position',
    'Status'
]

export const filTer_Raters = [
    {
        field_name:'NIK',
        field_type:'text',
    },
    {
        field_name:'Name',
        field_type:'text',
    },
    {
        field_name:'Email',
        field_type:'text',
    },
    {
        field_name:'Business Unit',
        field_type:'text',
    },
    {
        field_name:'Position',
        field_type:'text',
    },
    {
        field_name:'Level',
        field_type:'text',
    },
    {
        field_name:'Survey Name',
        field_type:'text',
    },
    {
        field_name:'Status',
        field_type:'category',
        field_category:[
            'not started','ongoing','finish'
        ]
    },
]

export const data_Raters = [
    {
      survey_code: "",
      survey_name: "",
      nik: "",
      full_name: "",
      email: "",
      level: "",
      position: "",
      business_unit_name: "",
      department_name: "",
      no_hp: "",
      status: "",
      total_field:0 
    }
]