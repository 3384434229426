import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import LoadingBar from 'react-top-loading-bar';
import { useDeps } from '../../shared/context/DependencyContext';
import useFormReportTemplate from './useFormReportTemplate';
import { rootAction } from '../../redux/RootAction';
import { CONSTANTS } from '../../common/constants';
import { alertSave } from './utils/alertInfo';
import useRouting from './utils/useRouting';
import { mapToSection } from './utils/useSection';
import screenAlert from '../../shared/hook/SweetAlert';
import screenToast from '../../shared/hook/ScreenToast';
import RowUnderLine from './components/RowUnderline';
import {
    FormPageHeader,
    FormPageInput,
    FormPageBorder,
    FormPageContent
} from './FormPageTemplate';

const UpdateReportTemplateView = ({
    role,
    formType,
    pageTitle,
    pageDescription,
    children
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { adminService } = useDeps();
    const user = useSelector((state) => state.user);
    const reportTemplate = useSelector((state) => state.reportTemplate);
    const reportUrl = useSelector((state) => state.reportTemplateUrl);
    const [tplCode, setTplCode] = useState(location.state);
    const [progress, setProgress] = useState(0);
    const [saved, setSaved] = useState(false);
    const showAlert = screenAlert();
    const showToast = screenToast();
    const { urlListTemplate, urlPreviewTemplate, urlFromState } = useRouting(role);

    const {
        toggleDnd,
        formSections,
        setFormSections,
        handleSectionUpdate,
        handleSectionChangeTheme,
        handleSectionRemove,
        handleSectionDragEnd,
        handleAddFormElement,
        handleToggleOrderEdit
    } = useFormReportTemplate();

    // Event handler
    const handleNavigateBack = (e) => {
        const [url, state] = urlFromState(reportUrl);
        navigate(url, state);
    };

    const handlePreview = (e) => {
        const [url, state] = urlPreviewTemplate(location.state.code);
        navigate(url, { state: state });
    };

    const handleSaveTemplate = async (e) => {
        e.preventDefault();

        showAlert.info(alertSave).then((value) => {
            if (!value) {
                return;
            }

            (async () => {
                try {
                    // user.role == CONSTANTS.ROLE.ADMIN
                    const response = await adminService.reportTemplateService.updateData({
                        ...reportTemplate,
                        updated_by: user.email,
                        user_role: `${user.role}`
                    });

                    if (!response) {
                        showToast.error('Something went wrong. Please try again later.');
                        return;
                    }

                    if (response.status === CONSTANTS.API.SUCCESS) {
                        showToast.success(response.status);
                        setSaved(true);
                        if (user.role === CONSTANTS.ROLE.ADMIN) {
                            // clearReportTemplateRedux(dispatch);
                            // navigate(urlListTemplate());
                        }
                    } else {
                        showToast.error(response.status);
                    }
                } catch (err) {
                    console.log(err);
                    showToast.error('Something went wrong. Please try again later.');
                }
            })();
        });
    };

    // Side Effect
    const onGetSingleReportTemplate = async (code) => {
        setProgress(10);
        try {
            const response =
                user.role == CONSTANTS.ROLE.ADMIN
                    ? await adminService.reportTemplateService.getSingleData({
                          tpl_code: code
                      })
                    : null;
            setProgress(30);
            if (response && response.status == 'SUCCESS' && response.data != 'NO DATA') {
                const sections = Array.isArray(response.data.sections)
                    ? response.data.sections.map((el, idx) => {
                          return mapToSection({
                              id: idx,
                              section_code: el.section_code,
                              section_id: el.section_id,
                              index: el.section_index,
                              content: el.content,
                              theme: el.theme
                          });
                      })
                    : [];

                dispatch(
                    rootAction.reportTemplate.updateTemplate({
                        code: response.data.tpl_code,
                        type: response.data.tpl_type,
                        name: response.data.name,
                        lang: response.data.lang,
                        is_active: response.data.is_active,
                        created_by: response.data.created_by,
                        updated_by: user.email
                    })
                );

                setProgress(60);
                if (sections.length > 0) {
                    // when formSections changed, it will immediately fire redux UPDATE_SECTIONS
                    setFormSections(sections);
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setProgress(100);
        }
    };
    useEffect(() => {
        if (!tplCode) {
            navigate(urlListTemplate());
            return;
        }

        onGetSingleReportTemplate(tplCode.code);
    }, [tplCode]);

    return (
        <Container className="tw-h-max">
            <LoadingBar
                color="#7749F8"
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
            />

            <FormPageHeader
                title={pageTitle}
                description={pageDescription}
                sections={formSections}
                btnToogle={toggleDnd}
                onBackClick={handleNavigateBack}
                onToggleClick={handleToggleOrderEdit}
                onPreview={handlePreview}
                onSaveClick={handleSaveTemplate}
            />

            <FormPageBorder>
                <FormPageInput
                    formName={reportTemplate.name}
                    formLang={reportTemplate.lang}
                />

                <RowUnderLine />

                {children}

                <FormPageContent
                    toggleDnd={toggleDnd}
                    formSections={formSections}
                    formType={formType}
                    onAddSection={handleAddFormElement}
                    onUpdateSection={handleSectionUpdate}
                    onUpdateTheme={handleSectionChangeTheme}
                    onDeleteSection={handleSectionRemove}
                    onDragEnd={handleSectionDragEnd}
                />
            </FormPageBorder>
        </Container>
    );
};

export default UpdateReportTemplateView;
