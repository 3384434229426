import { useEffect, useState } from "react";
import { useDeps } from "../../shared/context/DependencyContext";
import { data_Raters } from "./component/raters_constants";
import { useSelector } from "react-redux";
import { CONSTANTS } from "../../common/constants";

function useRatersHistory() {
    const {adminService,picService} = useDeps();
    const [allRatersHistory, setAllRatersHistory] = useState([]);
    const [showItem, setShowItem] = useState(10);
    const [searchText, setSearchText] = useState('');
    const [totalItem, setTotalItem] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [progress, setProgress] = useState(0);
    const user = useSelector((state) => state.user);



    useEffect(() => {
        if (pageNumber == 1) {
            onGetSearchRatersHistory(searchText,showItem,pageNumber)
        }
        setPageNumber(1);

    }, [showItem])

    useEffect(() => {
        onGetSearchRatersHistory(searchText,showItem,pageNumber)
    }, [pageNumber])


    const onGetSearchRatersHistory = async (search_text,limit,page_number) => {
        setProgress(10);
        try {
            setProgress(30);
            if (user.role == CONSTANTS.ROLE.ADMIN) {
                const response = await adminService.ratersHistoryService.getWithSearchRatersHistory(search_text,limit,page_number);
                setProgress(60);
                if (response.status == 'SUCCESS') {
                  setAllRatersHistory(response.data.data);
                  setTotalItem(response.data.total_item)
                  console.log("raters history response data",response);
                    // console.log('total item raters history', response.data.total_item);
                //   console.log(response.data.data);
                }
            }else {
                const response = await picService.ratersHistoryService.getWithSearchRatersHistoryPICUrl(search_text,limit,page_number);
                setProgress(60);
                if (response.status == 'SUCCESS') {
                  setAllRatersHistory(response.data.data);
                  setTotalItem(response.data.total_item)
                //   console.log(response.data.data);
                }
            }
           
        } catch (error) {
            console.log(error)
        } finally {
            // console.log('finally onGetSearchRatersHistory')
            setProgress(100);
        }
    }
    
    const handleshowItem = (item) => {
        setShowItem(item);
    }

    const handleInputSearchChange = (e) => {
        setSearchText(e.target.value);
    }

    const handleSearchData = () => {
        if (pageNumber == 1) {
            onGetSearchRatersHistory(searchText,showItem, pageNumber)
        } else {
            setPageNumber(1);
        }
    }

    return{
        allRatersHistory,
        totalItem,
        handleshowItem,
        showItem,
        handleInputSearchChange,
        handleSearchData,
        pageNumber,
        setPageNumber,
        setSearchText,
        progress,
       setProgress
    }
    
}

export default useRatersHistory;