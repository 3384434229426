import { useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useDeps } from '../../../../shared/context/DependencyContext';
import { CONSTANTS } from '../../../../common/constants';
import { GROUP_BY } from '../../../report_template/constants';
import screenToast from '../../../../shared/hook/ScreenToast';
import { createTimestamp } from '../../../../common/utils';

const useTabReport = () => {
    const { adminService } = useDeps();
    const [participants, setParticipants] = useState([]);
    const [groupTemplates, setGroupTemplates] = useState([]);
    const [individualTemplates, setIndividualTemplates] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [isDownloading, setIsDownloading] = useState(false);
    const [downloadPercentage, setDownloadPercentage] = useState(0);
    const user = useSelector((state) => state.user);
    const showToast = screenToast();

    const colors = { view: '#17a2b8', excel: '#1D6F42', pdf: '#f40f02' };

    const itemSelect = [
        { label: 'Project', value: GROUP_BY.PROJECT },
        { label: 'Business Unit', value: GROUP_BY.BUSINESS_UNIT },
        { label: 'Department', value: GROUP_BY.DEPARTMENT }
    ];

    const handleChangeSearchText = (e) => {
        setSearchText(e.target.value);
    };

    const handleLoadSurveyReport = async (code) => {
        try {
            const response = await adminService.reportTemplateService.getSurveyReport(
                code
            );

            if (response && response.status == 'SUCCESS' && response.data != 'NO DATA') {
                return response.data;
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleLoadTemplatesByType = async (type) => {
        try {
            const response = await adminService.reportTemplateService.getListData(
                '',
                50,
                1,
                type
            );

            if (response.status === 'SUCCESS') {
                return response.data.data;
            }
        } catch (error) {
            console.log(error);
            return [];
        }
    };

    const handleDownloadReport = async (survey_code) => {
        try {
            // TODO: Refactor to axios file client
            const response = await axios({
                method: 'POST',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    Authorization: `Bearer ${user.token}`
                },
                baseURL: process.env.REACT_APP_BASE_URL,
                url:
                    user.role == CONSTANTS.ROLE.ADMIN
                        ? '/admin/generate_raw_report'
                        : user.role == CONSTANTS.ROLE.PIC
                        ? '/pic/generate_raw_report'
                        : '',
                data: {
                    survey_code: survey_code
                },
                responseType: 'blob'
            });

            if (!response) {
                showToast.error('Something went wrong. Please try again later.');
                return;
            }

            // Create blob link to download
            var blob = new Blob([response.data], {
                type: response.headers['content-type']
            });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `report_${survey_code}_${createTimestamp()}.xlsx`;
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
        } catch (err) {
            console.log(err);
        }
    };

    const handleDownloadDocument = async (
        report_type,
        tpl_code,
        survey_code,
        participant_id = null,
        group_by = null
    ) => {
        try {
            setDownloadPercentage(1);
            setIsDownloading(true);

            await axios({
                method: 'POST',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    Accept: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    Authorization: `Bearer ${user.token}`
                },
                baseURL: process.env.REACT_APP_BASE_URL,
                url:
                    user.role == CONSTANTS.ROLE.ADMIN
                        ? '/admin/generate_docx_report'
                        : user.role == CONSTANTS.ROLE.PIC
                        ? '/pic/generate_docx_report'
                        : '',
                data: {
                    report_type: report_type,
                    survey_code: survey_code,
                    tpl_code: tpl_code,
                    participant_id: participant_id,
                    group_by: group_by
                },
                responseType: 'blob',
                onDownloadProgress(pEvent) {
                    setDownloadPercentage(
                        Math.round((pEvent.loaded / pEvent.total) * 100)
                    );
                }
            }).then((response) => {
                setDownloadPercentage(100);

                // Create blob link to download
                var blob = new Blob([response.data], {
                    type: response.headers['content-type']
                });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `${createTimestamp()}_${report_type}_${survey_code}.docx`;
                link.click();

                // Clean up and remove the link (still bug)
                // link.parentNode.removeChild(link);

                setIsDownloading(false);
            }).catch((err) => {
                showToast.error('Something went wrong: ' + err.message);
                setIsDownloading(false);
            })
        } catch (err) {
            console.log(err);
        }
    };

    return {
        showToast,
        user,
        colors,
        itemSelect,
        participants,
        setParticipants,
        groupTemplates,
        setGroupTemplates,
        individualTemplates,
        setIndividualTemplates,
        searchText,
        setSearchText,
        isDownloading,
        downloadPercentage,
        handleChangeSearchText,
        handleLoadSurveyReport,
        handleLoadTemplatesByType,
        handleDownloadReport,
        handleDownloadDocument
    };
};

export default useTabReport;
