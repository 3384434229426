import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// Pemetaan label untuk nama-nama data
const labelMapping = {
  'add_participant': 'Add Participant',
  'done_participant': 'Done Participant'
};

const BarChartStatus = ({ title, data, dataKeyX, dataKeysY, onBarClick }) => (
  <div style={{ width: '100%', height: '400px' }}>
    <h3>{title}</h3>
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        data={data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        onClick={(e) => {
          if (e && e.activeLabel) {
            if (typeof onBarClick === 'function') {
              onBarClick(e.activeLabel);
            }
          }
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={dataKeyX} />
        <YAxis />
        <Tooltip />
        <Legend />
        {dataKeysY.map((key, index) => (
          <Bar
            key={index}
            dataKey={key}
            name={labelMapping[key] || key}  
            fill={index % 2 === 0 ? "#8884d8" : "#82ca9d"}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  </div>
);

export default BarChartStatus;
