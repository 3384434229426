import { useEffect, useState } from "react";
import { useDeps } from "../../../../../shared/context/DependencyContext";
import { useSelector } from "react-redux";
import { CONSTANTS } from "../../../../../common/constants";

function useQuestionContent() {
   const [goalData, setGoalData] = useState([]);
   const { adminService, picService } = useDeps();
   const user = useSelector((state) => state.user);

   useEffect(() => {
      onGetGoal();
   }, []);

   /* Goal Data */
   const onGetGoal = async () => {
      try {
         if (user.role == CONSTANTS.ROLE.ADMIN) {
            const response = await adminService.configDataService.getAllGoals();

            if (response.status == 'SUCCESS') {
               if (response.data != 'NO DATA') {
                  setGoalData(response.data);
               }
            }
         } else {
            const response = await picService.configDataService.getAllGoalsPICUrl();

            if (response.status == 'SUCCESS') {
               if (response.data != 'NO DATA') {
                  setGoalData(response.data);
               }
            }
         }
      } catch (error) {
         console.log(error)
      }
   }

   return {
      goalData,
   }
}

export default useQuestionContent;