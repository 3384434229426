import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useBlocker } from './useBlocker';

export function useCallbackPrompt(when, exception) {
   const navigate = useNavigate();
   const location = useLocation();
   const [showPrompt, setShowPrompt] = useState(false);
   const [lastLocation, setLastLocation] = useState(null);
   const [confirmedNavigation, setConfirmedNavigation] = useState(false);
   const [isReplace, setIsReplace] = useState(false);

   const cancelNavigation = useCallback(() => {
      setShowPrompt(false);
      setLastLocation(null);
   }, []);

   const handleBlockedNavigation = useCallback(
      (nextLocation) => {
         // Navigate nextLocation if pathname is in execption
         if (exception && nextLocation.location.pathname.includes(exception)) {
            setShowPrompt(false);
            setLastLocation(nextLocation);
            setConfirmedNavigation(true);
            return true;
         }

         if (
            !confirmedNavigation &&
            nextLocation.location.pathname !== location.pathname
         ) {
            setShowPrompt(true);
            setLastLocation(nextLocation);
            return false;
         }
         return true;
      },
      [confirmedNavigation, location]
   );

   const confirmNavigation = useCallback(() => {
      setShowPrompt(false);
      setConfirmedNavigation(true);
      setIsReplace(true);
   }, []);

   useEffect(() => {
      if (confirmedNavigation && lastLocation) {
         navigate(lastLocation.location?.pathname, { replace: isReplace });

         // Clean-up state on confirmed navigation
         setConfirmedNavigation(false);
      }
   }, [confirmedNavigation, lastLocation]);

   useBlocker(handleBlockedNavigation, when);

   return [showPrompt, confirmNavigation, cancelNavigation];
}
