export const alertDiscard = {
    text: 'Discard your template and start a new one.',
    buttons: {
        cancel: {
            text: 'Cancel',
            className: 'bg-white text-dark'
        },
        confirm: {
            text: 'Confirm',
            value: true,
            className: 'bg-success'
        }
    }
};

export const alertSave = {
    text: 'Are you sure to save Report Template?',
    buttons: {
        cancel: {
            text: 'Cancel',
            className: 'bg-white text-dark'
        },
        confirm: {
            text: 'Confirm',
            value: true,
            className: 'bg-success'
        }
    }
};

export const alertDelete = {
    text: 'Are you sure to delete this Report Template?',
    buttons: {
        cancel: {
            text: 'Cancel',
            className: ''
        },
        confirm: {
            text: 'Yes',
            value: true,
            className: 'bg-primary'
        }
    }
};
