import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { rootAction } from "../../../../redux/RootAction";
import TreeViewUpdate from "./tree/TreeViewUpdate";
import { ButtonContent } from "../../../../shared/component/button/Button";
import SectionViewUpdate from "./section/SectionViewUpdate";
import QuestionContentUpdate from "./question/QuestionViewUpdate";
import { scaleLabelDataCopy } from "../../../cms_multirater/tab/tab_content/contentData";

function TabContentUpdate({ setIsEditing }) {
    const [counter, setCounter] = useState(0);
    const sectionReducer = useSelector((state) => state.update_section_single_rater);
    const contentView = useSelector((state) => state.update_view_content_single_rater);
    const dispatch = useDispatch();
 
    useState(() => {
       let current = sectionReducer.length > 0 ? (sectionReducer[sectionReducer.length - 1].section_id) + 1 : 1;
       setCounter(current);
    }, []);
 
    const handleAddSection = () => {
       let section = {
          section_id: counter,
          section_code: '',
          section_name: 'Section ' + (counter),
          description: '',
          content_layout: 'hor',
          using_scale: 0,
          scale_range: 0,
          scale_display: 'circle_label',
          content: []
       }
 
       setCounter(prevState => prevState + 1);
       dispatch(rootAction.updateSingleRater.setSectionSRAction(section));
       dispatch(rootAction.updateSingleRater.setContentViewSRAction({
          section_id: section.section_id,
          content_id: 0
       }));
       setIsEditing(true);
    }

    const handleCopySection = (sec,scaleLabel) => {
      if (sec != undefined) {
          let sectionCopy = {
              section_id: counter,
              section_code: '',
              section_name: 'Section ' + (counter),
              description: sec.description,
              content_layout: sec.content_layout,
              using_scale: sec.using_scale,
              scale_range: sec.scale_range,
              scale_display: sec.scale_display,
              content: sec.content
          }

          if (sec.using_scale == 1) {
              dispatch(rootAction.updateSingleRater.setScaleChoiceSRAction(scaleLabelDataCopy(sectionCopy.section_id,scaleLabel.content_choice)))
          }

          setCounter(prevState => prevState + 1);
          dispatch(rootAction.updateSingleRater.setSectionSRAction(sectionCopy));
          dispatch(rootAction.updateSingleRater.setContentViewSRAction({
             section_id: sectionCopy.section_id,
             content_id: 0
          }));
          setIsEditing(true);
      }
  }
 
    return (
       <div className="col-12 row">
          <div className="col-3">
 
             <div className="border-bottom pb-1 d-flex justify-content-center"
                style={{ boxShadow: 'inset 0 -0.1rem 0 0 rgb(0,0,0,0.2)', height: '6vh' }}>
                <div><ButtonContent label={'+ Section'} onClick={handleAddSection} disabled={false} /></div>
             </div>
 
             <div >
                <TreeViewUpdate />
             </div>
 
          </div>
          {contentView.section_id != 0 && contentView.content_id == 0 ? <SectionViewUpdate setIsEditing={setIsEditing} handleCopySection={handleCopySection} /> : (contentView.content_id != 0 ? <QuestionContentUpdate setIsEditing={setIsEditing} /> : <></>)}
       </div>
    )
 }
 
 export default TabContentUpdate;