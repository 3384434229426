import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconButtonDelete, IconButtonEdit } from "../../../shared/component/button/IconButton"
import { ButtonPrimary } from "../../../shared/component/button/Button"

import AddFaq from "./AddFaq"
import { SearchColumn } from "../../../shared/component/table_property/SearchColumn"
import { ShowEntries } from "../../../shared/component/table_property/ShowEntries"
import useFaq from "./useFaq"
import Pagination from "../../../shared/component/table_property/Paginatination"
import { useEffect } from "react"
import LoadingBar from "react-top-loading-bar"

function FaqView() {
    const {
        allFaq,
        answerSelect,
        pageNumber,
        showItem,
        totalItem,
        setPageNumber,
        questionSelect,
        setShowItem,
        handleChangeAnswer,
        handleChangeQuestion,
        modalAdd,
        setModalAdd,
        modalEdit,
        handleSearchData,
        handleAddSave,
        handleHideModalEdit,
        handleOpenModalEdit,
        handleSaveUpdate,
        handleDelete,
        handleInputSearchChange,
        handleshowItem,
        progress,
        setProgress
    } = useFaq();

    const paginate = (pageNumber) => {
        setPageNumber(pageNumber);
    };

    const previousPage = () => {
        if (pageNumber != 1) {
            setPageNumber(pageNumber - 1);

        }
    };

    const nextPage = () => {
        if (pageNumber != Math.ceil(totalItem / showItem)) {
            setPageNumber(pageNumber + 1);
        }
    };

    



    return (
        <>
        <LoadingBar color="#7749F8" progress={progress} onLoaderFinished={() => setProgress(0)} />

        <div>
            <div className='m-4 d-flex justify-content-between'>
                <h5 className="me-4">Frequently Ask Question</h5>

                <ButtonPrimary
                    label={
                        <div className="d-flex justify-content-center align-items-center">
                            <FontAwesomeIcon icon={faPlus} size="xl" />
                            <div className="ml-1" style={{ fontSize: '1 rem', fontWeight: 'bold' }}>Add</div>
                        </div>
                    }
                    onClick={() => setModalAdd(true)}

                />

            </div>



            <div>
                <div className="d-flex flex-row justify-content-between">
                    <div className="col-2 d-flex flex-row justify-content-center">
                        <div className="d-flex align-items-center mr-1">Show</div>
                        <ShowEntries onClick_item={handleshowItem} default_item={showItem} />
                        <div className="d-flex align-items-center ml-1">entries</div>
                    </div>
                    <div className="col-3">
                        <SearchColumn onChange_input={handleInputSearchChange} onClick_btn={handleSearchData} />
                    </div>
                </div>
                <div className="mt-1">
                    <table className="table table-striped table-bordered table-sm">
                        <thead>
                            <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                <th rowSpan={2} style={{ verticalAlign: "middle" }}>
                                    No
                                </th>
                                <th rowSpan={2} style={{ verticalAlign: "middle" }}>
                                    Question
                                </th>
                                <th rowSpan={2} style={{ verticalAlign: "middle" }}>
                                    Answer
                                </th>
                                <th colSpan={2} style={{ verticalAlign: "middle" }}>
                                    Actions
                                </th>
                            </tr>
                            <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                {/* <th>Edit</th> */}
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(allFaq) && allFaq.length != 0 ?

                                allFaq.map((dt, i) => {
                                    return (
                                        <tr key={i} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                            {
                                                pageNumber == 1 ? <td>{i + 1}</td> : <td>{(i + 1) + (showItem * (pageNumber - 1))}</td>
                                            }
                                            <td >{dt.question}</td>
                                            <td>{dt.answer}</td>
                                            {/* <td>
                        <IconButtonEdit onClick={()=>handleOpenModalEdit(dt.id,dt.question,dt.answer)}/>
                        </td> */}
                                            <td>
                                                <IconButtonDelete onClick={() => handleDelete(dt.id)} />
                                            </td>

                                        </tr>

                                    );
                                }) : <tr>
                                    <td colSpan={5} style={{ textAlign: 'center' }}>No data</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
                <Pagination showing={Array.isArray(allFaq) ? allFaq.length : 0} totalItem={totalItem} showItem={showItem} previousPage={previousPage} nextPage={nextPage} paginate={paginate} limit={pageNumber} />

            </div>

            {/* Modal untuk Add */}
            <AddFaq
                show={modalAdd}
                onHide={() => setModalAdd(false)}
                onSave={() => handleAddSave()}
                answer={answerSelect}
                question={questionSelect}
                handleChangeAnswer={handleChangeAnswer}
                handleChangeQuestion={handleChangeQuestion}
            />

            {/* Modal untuk Edit */}
            <AddFaq
                show={modalEdit}
                title={'Edit'}
                onHide={() => handleHideModalEdit()}
                onSave={() => handleSaveUpdate()}
                answer={answerSelect}
                question={questionSelect}
                handleChangeAnswer={handleChangeAnswer}
                handleChangeQuestion={handleChangeQuestion}
            />



        </div>
      </>
        
    )
}

export default FaqView;

