import swal from "sweetalert";

const swalOption = (data) => ({
   title: data.title ?? null,
   text: data.text ?? null,
   buttons: data.buttons ? {
      cancel: data.buttons.cancel ? {
         text: data.buttons.cancel.text ?? null,
         value: data.buttons.cancel.value ?? null,
         className: data.buttons.cancel.className ?? null,
         visible: true
      } : false,
      confirm: data.buttons.confirm ? {
         text: data.buttons.confirm.text ?? null,
         value: data.buttons.confirm.value ?? null,
         className: data.buttons.confirm.className ?? null,
         visible: true
      } : false,
   } : false,
   dangerMode: data.isDanger ?? null
});

const screenAlert = () => {
   const success = (data) => swal({
      icon: "success",
      ...swalOption(data)
   });

   const error = (data) => swal({
      icon: "error",
      ...swalOption(data)
   });

   const info = (data) => swal({
      icon: "info",
      ...swalOption(data)
   });

   const warning = (data) => swal({
      icon: "warning",
      ...swalOption(data)
   });

   return { success, error, info, warning };
}

export default screenAlert;