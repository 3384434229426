import { combineReducers } from "@reduxjs/toolkit";
import { userReducer } from "./User/UserReducer";
import { isValidMultiRaterReducer, LabelDataSettingMultiRaterReducer, multiRaterReducer, participantMultiRaterReducer, previewDataMRReducer, scaleChoiceMultiRaterReducer, scoreMultiRaterReducer, sectionMultiRaterReducer, settingMultiRaterReducer, viewContentMultiRaterReducer, viewEditParticipantReducer, viewEditParticipantReducerByEmail, viewMultiRaterReducer } from "./MultiRater/MultiRaterReducer";
import { preferenceReducer } from "./Preferences/PreferencesReducer";
import { contentMandatoryList, dataParticipantRaterMRFormReducer, manyAnswerSurveyFormReducer, oneAnswerSurveyFormReducer, surveyFormDataReducer, SurveyMRDetailData } from "./MultiraterFormPart/MultiraterFormPartReducer";
import { dataSeeRatersReducer } from "./AddRater/AddRaterReducer";
import { ownershipMRReducer, updateIsEditingReducer, updateIsValidMultiRaterReducer, updateLabelDataSettingMultiRaterReducer, updateMultiRaterReducer, updateParticipantMultiRaterReducer, updateScaleChoiceMultiRaterReducer, updateScoreMultiRaterReducer, updateSectionMultiRaterReducer, updateSettingMultiRaterReducer, updateViewContentMultiRaterReducer, updateViewEditParticipantReducer, updateViewEditParticipantReducerByEmail, updateViewMultiRaterReducer } from "./UpdateMultiRater/UpdateMultiRaterReducer";
import { singleRaterReducer, participantSingleRaterReducer, previewDataSRReducer, scaleChoiceSingleRaterReducer, scoreSingleRaterReducer, sectionSingleRaterReducer, settingSingleRaterReducer, viewContentSingleRaterReducer, viewEditParticipantSRReducer, viewSingleRaterReducer, isValidSingleRaterReducer, viewEditParticipantSRReducerByEmail, LabelDataSettingSingleRaterReducer } from "./SingleRater/SingleRaterReducer";
import { ownershipSRReducer, updateIsEditingReducerSR, updateIsValidSingleRaterReducer, updateLabelDataSettingSingleRaterReducer, updateParticipantSingleRaterReducer, updateScaleChoiceSingleRaterReducer, updateScoreSingleRaterReducer, updateSectionSingleRaterReducer, updateSettingSingleRaterReducer, updateSingleRaterReducer, updateViewContentSingleRaterReducer, updateViewEditParticipantReducerSR, updateViewEditParticipantReducerSRByEmail, updateViewSingleRaterReducer } from "./UpdateSingleRater/UpdateSingleRaterReducer";
import { contentMandatoryListSR, dataParticipantRaterSRFormReducer, manyAnswerSurveyFormReducerSR, oneAnswerSurveyFormReducerSR, surveyFormDataReducerSR, SurveySRDetailData } from "./SingleraterFormPart/SingleraterFormPartReducer";
import { reportTemplateReducer, reportTemplateUrlReducer, reportPreviewUrlReducer } from "./ReportTemplate/ReportTemplateReducer";
import SpreadSheetReducer from "./SpreadSheet/SpreadSheetReducer";
export const rootReducer = () => combineReducers({
   user: userReducer,
   view_multi_rater: viewMultiRaterReducer,
   multi_rater: multiRaterReducer,
   setting_multi_rater: settingMultiRaterReducer,
   view_content_multi_rater: viewContentMultiRaterReducer,
   section_multi_rater: sectionMultiRaterReducer,
   scale_choice_multi_rater: scaleChoiceMultiRaterReducer,
   score_multi_rater: scoreMultiRaterReducer,
   preference: preferenceReducer,
   label_data_setting_multi_rater: LabelDataSettingMultiRaterReducer,


   /* MR Form Reducer */
   insertOneAnswer: oneAnswerSurveyFormReducer,
   insertManyAnswer: manyAnswerSurveyFormReducer,
   surveyFormData: surveyFormDataReducer,
   data_participant_rater_MR_form: dataParticipantRaterMRFormReducer,
   mandatoryContentList: contentMandatoryList,
   survey_MR_detail_data: SurveyMRDetailData,

   // add_rater: addRatersReducer,
   participant_multi_rater: participantMultiRaterReducer,
   view_edit_participant_multi_rater: viewEditParticipantReducer,
   update_multi_rater: updateMultiRaterReducer,
   update_participant_multi_rater: updateParticipantMultiRaterReducer,
   update_scale_choice_multi_rater: updateScaleChoiceMultiRaterReducer,
   update_score_multi_rater: updateScoreMultiRaterReducer,
   update_section_multi_rater: updateSectionMultiRaterReducer,
   update_setting_multi_rater: updateSettingMultiRaterReducer,
   update_view_content_multi_rater: updateViewContentMultiRaterReducer,
   update_view_edit_participant_multi_rater: updateViewEditParticipantReducer,
   update_view_multi_rater: updateViewMultiRaterReducer,
   update_is_editing: updateIsEditingReducer,
   data_see_raters: dataSeeRatersReducer,
   preview_data_mr: previewDataMRReducer,
   ownership_list_mr:ownershipMRReducer,
   view_edit_participant_multi_rater_by_email: viewEditParticipantReducerByEmail,
   update_view_edit_participant_multi_rater_by_email: updateViewEditParticipantReducerByEmail,
   update_label_data_setting_multi_rater: updateLabelDataSettingMultiRaterReducer,




   /* SINGLE RATER REDUCER */
   view_single_rater: viewSingleRaterReducer,
   single_rater: singleRaterReducer,
   setting_single_rater: settingSingleRaterReducer,
   view_content_single_rater: viewContentSingleRaterReducer,
   section_single_rater: sectionSingleRaterReducer,
   scale_choice_single_rater: scaleChoiceSingleRaterReducer,
   score_single_rater: scoreSingleRaterReducer,
   participant_single_rater: participantSingleRaterReducer,
   view_edit_participant_single_rater: viewEditParticipantSRReducer,
   preview_data_sr: previewDataSRReducer,
   view_edit_participant_single_rater_by_email: viewEditParticipantSRReducerByEmail,
   label_data_setting_single_rater: LabelDataSettingSingleRaterReducer,



   /* VALIDATION */
   valid_multi_rater: isValidMultiRaterReducer,
   update_valid_multi_rater: updateIsValidMultiRaterReducer,
   valid_single_rater: isValidSingleRaterReducer,
   update_valid_single_rater: updateIsValidSingleRaterReducer,

   /* UPDATE SINGLE RATER  */
   update_single_rater: updateSingleRaterReducer,
   update_participant_single_rater: updateParticipantSingleRaterReducer,
   update_scale_choice_single_rater: updateScaleChoiceSingleRaterReducer,
   update_score_single_rater: updateScoreSingleRaterReducer,
   update_section_single_rater: updateSectionSingleRaterReducer,
   update_setting_single_rater: updateSettingSingleRaterReducer,
   update_view_content_single_rater: updateViewContentSingleRaterReducer,
   update_view_edit_participant_single_rater: updateViewEditParticipantReducerSR,
   update_view_single_rater: updateViewSingleRaterReducer,
   update_is_editing_single_rater: updateIsEditingReducerSR,
   ownership_list_sr:ownershipSRReducer,
   update_view_edit_participant_single_rater_by_email: updateViewEditParticipantReducerSRByEmail,
   update_label_data_setting_single_rater: updateLabelDataSettingSingleRaterReducer,




   /* SINGLE RATER FORM */
   insertOneAnswerSR: oneAnswerSurveyFormReducerSR,
   insertManyAnswerSR: manyAnswerSurveyFormReducerSR,
   surveyFormDataSR: surveyFormDataReducerSR,
   dataParticipantRaterSRForm: dataParticipantRaterSRFormReducer,
   mandatoryContentListSR: contentMandatoryListSR,
   survey_SR_detail_data: SurveySRDetailData,


      // SPreadSheetReducer
   spreadsheet: SpreadSheetReducer,


      /* REPORT TEMPLATE */
   reportTemplate: reportTemplateReducer,
   reportTemplateUrl: reportTemplateUrlReducer,
   reportPreviewUrl: reportPreviewUrlReducer,
});
