import { useState } from "react";
import { useDeps } from "../../../shared/context/DependencyContext";
import { useEffect } from "react";
import { CONSTANTS } from "../../../common/constants";
import { useDispatch, useSelector } from "react-redux";
import { rootAction } from "../../../redux/RootAction";
import { useNavigate } from "react-router-dom";
import screenAlert from "../../../shared/hook/SweetAlert";
import { clearParticipantAccountData } from "../../../common/utils";
import useViewState from "../../../shared/hook/UseViewState";
import screenToast from "../../../shared/hook/ScreenToast";

function useMultiRaterForm() {
    const { participantService } = useDeps();
    const [surveyDetailData, setSurveyDetailData] = useState();
    const accountData = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const showAlert = screenAlert();
    const { viewState, setError, setSuccess } = useViewState();
    const showToast = screenToast();



   
    const mandatoryContentList = useSelector((state) => state.mandatoryContentList);

    useEffect(() => {
      if (viewState.isError == true) {
          showToast.error(viewState.errorMsg);
          setError(false, '');
      }

      if (viewState.isSuccess == true) {
          showToast.success(viewState.successMsg);
          setSuccess(false, '');
      }
  }, [viewState]);

    const handleLogout = async () => {
    clearParticipantAccountData(dispatch);
    dispatch(rootAction.user.userLogoutAction());
    navigate(CONSTANTS.ROUTER.LOGIN_AS_PARTICIPANT, { replace: true });

    }
   

    const onGetDetailSurveyData = async (survey_code) => {
       
        try {
          const response = await participantService.multiRaterSurveyFormService.getDetailSurveyFormData(survey_code);

          if (response.status == 'SUCCESS') {
            console.log('response survey data', response.data);
              setSurveyDetailData(response.data);
          }
        } catch (error) {
            console.log(error)
        } finally {
            // console.log('final onGetDetailSurveyData')
        }
    }

    const handleSubmitManyAnswer = async (data,is_auto_login) => {
        // console.log('data answer many',JSON.stringify(data));
        // console.log('data answer many data', data);
        try {
          const response = await participantService.multiRaterSurveyFormService.postManyAnswer(data);
          // console.log('AUTO LOGIN MR SURVEY', is_auto_login);

          if (response.status == 'SUCCESS') {
              // setSuccess(true, 'Submitted Successfully')
              // hapus redux dr many answer
              if (is_auto_login) {
                handleSuccessAndLogout()
                setSuccess(true, 'Submitted Successfully')

              } else {
                handleSuccessAndBack()
                setSuccess(true, 'Submitted Successfully')

              }
              dispatch(rootAction.surveyForm.deleteAllManyAnswer())
              dispatch(rootAction.surveyForm.clearDataParticipantRaterMRForm())
          }
        } catch (error) {
            console.log(error)
            setError(true,'FAILED')
        } finally {
            // console.log('final onGetDetailSurveyData')
        }
    }

    const handleSuccessAndBack = () => {
          showAlert.success({
            title: "Success",
            text: "Your survey has been successfully submitted!",
            buttons: {
                confirm: {
                    text: "Back To Survey List",
                    className: "bg-success",
                    value: true
                }
            }
            }).then(value => {
                if (value) {
                  navigate(-1)
                }
            });
     }


     const handleSuccessAndLogout = () => {
      showAlert.success({
        title: "Success",
        text: "Your survey has been successfully submitted!",
        buttons: {
            confirm: {
                text: "Exit",
                className: "bg-success",
                value: true
            }
        }
        }).then(value => {
            if (value) {
              handleLogout();
            }
        });
 }

   


    return {
        surveyDetailData,
        handleSubmitManyAnswer,
        
        onGetDetailSurveyData
        
    }
}

export default useMultiRaterForm;




