import { ADD_SCALE_CHOICE, CLEAR_SCALE_CHOICE, CLEAR_VIEW_CONTENT_UPDATE, DELETE_CONTENT, DELETE_CONTENT_CHOICE, DELETE_OWNERSHIP_MR, DELETE_PARTICIPANT, DELETE_SCALE_CHOICE, DELETE_SECTION, EDIT_CONTENT, EDIT_CONTENT_CHOICE, EDIT_PARTICIPANT, EDIT_SCALE_CHOICE, EDIT_SECTION, LOAD_MULTI_RATER, LOAD_PARTICIPANT, LOAD_SCALE_CHOICE, LOAD_SCORE, LOAD_SECTION_CONTENT, LOAD_SETTING, OWNERSHIP_LIST_MR, RESET_CONTENT_VIEW, RESET_IS_EDITING, RESET_MULTI_RATER, RESET_OWNERSHIP_MR, RESET_PARTICIPANT, RESET_SCALE_CHOICE, RESET_SCORE, RESET_SECTION, RESET_SETTING, RESET_SETTING_LABEL_DATA_MR, RESET_VALID_INPUT, RESET_VIEW_EDIT_PARTICIPANT, RESET_VIEW_EDIT_PARTICIPANT_KEY_EMAIL, RESET_VIEW_TAB, SET_CONTENT, SET_CONTENT_BULK, SET_CONTENT_CHOICE, SET_CONTENT_CHOICE_SCALE, SET_IS_EDITING, SET_MULTI_RATER, SET_PARTICIPANT, SET_SCALE_CHOICE, SET_SCORE, SET_SECTION, SET_SETTING, SET_SETTING_LABEL_DATA_MR, SET_VALID_CONTENT, SET_VALID_PARTICIPANT, SET_VALID_SETTING, SET_VIEW, SET_VIEW_CONTENT_UPDATE, SET_VIEW_EDIT_PARTICIPANT, SET_VIEW_EDIT_PARTICIPANT_KEY_EMAIL } from "./UpdateMultiRaterType";

// define action constant
const setMRViewAction = (reqData) => ({
   type: SET_VIEW,
   data: reqData
});

const resetViewTab = () => ({
   type: RESET_VIEW_TAB
});

const loadMRAction = (reqData) => ({
   type: LOAD_MULTI_RATER,
   data: reqData
});

const setMRAction = (reqData) => ({
   type: SET_MULTI_RATER,
   data: reqData
});

const resetMultiRater = () => ({
   type: RESET_MULTI_RATER
});

const loadSettingMRAction = (reqData) => ({
   type: LOAD_SETTING,
   data: reqData
});

const setSettingMRAction = (reqData) => ({
   type: SET_SETTING,
   data: reqData
});

const resetSetting = () => ({
   type: RESET_SETTING
});

const setContentViewMRAction = (reqData) => ({
   type: SET_VIEW_CONTENT_UPDATE,
   data: reqData
});

const clearContentViewMRAction = () => ({
   type: CLEAR_VIEW_CONTENT_UPDATE
});

const resetContentView = () => ({
   type: RESET_CONTENT_VIEW
});

const loadSectionContentMRAction = (reqData) => ({
   type: LOAD_SECTION_CONTENT,
   data: reqData
});

const setSectionMRAction = (reqData) => ({
   type: SET_SECTION,
   data: reqData
});

const editSectionMRAction = (reqData) => ({
   type: EDIT_SECTION,
   data: reqData
});

const deleteSectionMRAction = (reqData) => ({
   type: DELETE_SECTION,
   data: reqData
});

const resetSection = () => ({
   type: RESET_SECTION
});

const setContentMRAction = (reqData) => ({
   type: SET_CONTENT,
   data: reqData
});

const setContentBulkMRAction = (reqData) => ({
   type: SET_CONTENT_BULK,
   data: reqData
});

const editContentMRAction = (reqData) => ({
   type: EDIT_CONTENT,
   data: reqData
});

const deleteContentMRAction = (reqData) => ({
   type: DELETE_CONTENT,
   data: reqData
});

const loadScaleChoiceMRAction = (reqData) => ({
   type: LOAD_SCALE_CHOICE,
   data: reqData
});

const setScaleChoiceMRAction = (reqData) => ({
   type: SET_SCALE_CHOICE,
   data: reqData
});

const addScaleChoiceMRAction = (reqData) => ({
   type: ADD_SCALE_CHOICE,
   data: reqData
});

const editScaleChoiceMRAction = (reqData) => ({
   type: EDIT_SCALE_CHOICE,
   data: reqData
});

const deleteScaleChoiceMRAction = (reqData) => ({
   type: DELETE_SCALE_CHOICE,
   data: reqData
});

const clearScaleChoiceMRAction = (reqData) => ({
   type: CLEAR_SCALE_CHOICE,
   data: reqData
});

const resetScaleChoice = () => ({
   type: RESET_SCALE_CHOICE
});

const setContentChoiceMRAction = (reqData) => ({
   type: SET_CONTENT_CHOICE,
   data: reqData
});

const editContentChoiceMRAction = (reqData) => ({
   type: EDIT_CONTENT_CHOICE,
   data: reqData
});

const deleteContentChoiceMRAction = (reqData) => ({
   type: DELETE_CONTENT_CHOICE,
   data: reqData
});

const loadScoreMRAction = (reqData) => ({
   type: LOAD_SCORE,
   data: reqData
});

const setScoreMRAction = (reqData) => ({
   type: SET_SCORE,
   data: reqData
});

const resetScore = () => ({
   type: RESET_SCORE
});

const loadParticipantMRAction = (reqData) => ({
   type: LOAD_PARTICIPANT,
   data: reqData
});

const setParticipantMRAction = (reqData) => ({
   type: SET_PARTICIPANT,
   data: reqData
});

const editParticipantMRAction = (reqData) => ({
   type: EDIT_PARTICIPANT,
   data: reqData
});

const deleteParticipantMRAction = (reqData) => ({
   type: DELETE_PARTICIPANT,
   data: reqData
});

const resetParticipant = () => ({
   type: RESET_PARTICIPANT
});

const setEditParticipantView = (reqData) => ({
   type: SET_VIEW_EDIT_PARTICIPANT,
   data: reqData
});

const resetViewEditParticipant = () => ({
   type: RESET_VIEW_EDIT_PARTICIPANT
});

const setIsEditingAction = (reqData) => ({
   type: SET_IS_EDITING,
   data: reqData
});

const resetIsEditingAction = (reqData) => ({
   type: RESET_IS_EDITING
});

// TODO: FIX SCALE CHOICE
const setContentChoiceScaleMRAction = (reqData) => ({
   type: SET_CONTENT_CHOICE_SCALE,
   data: reqData
});

const setValidSettingMRAction = (reqData) => ({
   type: SET_VALID_SETTING,
   data: reqData
});

const setValidContentMRAction = (reqData) => ({
   type: SET_VALID_CONTENT,
   data: reqData
});

const setValidParticipantMRAction = (reqData) => ({
   type: SET_VALID_PARTICIPANT,
   data: reqData
});

const resetValidInputMRAction = (reqData) => ({
   type: RESET_VALID_INPUT,
   data: reqData
});

const listOwnershipMR = (reqData) => ({
   type: OWNERSHIP_LIST_MR,
   data: reqData
});

const resetListOwnershipMR = (reqData) => ({
   type: RESET_OWNERSHIP_MR,
  
});

const deleteOwnershipMR = (reqData) => ({
   type: DELETE_OWNERSHIP_MR,
   data: reqData
});

const setEditParticipantViewByEmail = (reqData) => ({
   type: SET_VIEW_EDIT_PARTICIPANT_KEY_EMAIL,
   data: reqData
});

const resetViewEditParticipantByEmail = () => ({
   type: RESET_VIEW_EDIT_PARTICIPANT_KEY_EMAIL
});


const setSettingLabelDataMRAction = (reqData) => ({
   type: SET_SETTING_LABEL_DATA_MR,
   data: reqData
});

const resetSettingLabelDataMR = () => ({
   type: RESET_SETTING_LABEL_DATA_MR
});

export default {
   setMRViewAction,
   resetViewTab,

   loadMRAction,
   setMRAction,
   resetMultiRater,

   loadSettingMRAction,
   setSettingMRAction,
   resetSetting,

   setContentViewMRAction,
   clearContentViewMRAction,
   resetContentView,

   loadSectionContentMRAction,
   setSectionMRAction,
   editSectionMRAction,
   deleteSectionMRAction,
   resetSection,

   setContentMRAction,
   setContentBulkMRAction,
   editContentMRAction,
   deleteContentMRAction,

   loadScaleChoiceMRAction,
   setScaleChoiceMRAction,
   addScaleChoiceMRAction,
   editScaleChoiceMRAction,
   deleteScaleChoiceMRAction,
   clearScaleChoiceMRAction,
   resetScaleChoice,

   setContentChoiceMRAction,
   editContentChoiceMRAction,
   deleteContentChoiceMRAction,

   loadScoreMRAction,
   setScoreMRAction,
   resetScore,

   loadParticipantMRAction,
   setParticipantMRAction,
   editParticipantMRAction,
   deleteParticipantMRAction,
   resetParticipant,

   setEditParticipantView,
   resetViewEditParticipant,

   setIsEditingAction,
   resetIsEditingAction,

   setValidSettingMRAction,
   setValidContentMRAction,
   setValidParticipantMRAction,
   resetValidInputMRAction,

   listOwnershipMR,
   resetListOwnershipMR,
   deleteOwnershipMR,

   setEditParticipantViewByEmail,
   resetViewEditParticipantByEmail,

   setContentChoiceScaleMRAction,

   setSettingLabelDataMRAction,
   resetSettingLabelDataMR,
}