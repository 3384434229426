import { useEffect, useState } from "react";
import { Footer } from "../../../layout/navbar/Footer";
import SectionCard from "../../../../shared/component/card/SectionCard";
import QuestionView from "./QuestionView";

function SurveyContentView({ sectionPageIndex, handleNextPage, handlePrevPage, surveyDetailData }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
 


  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

    useEffect(() => {
      // console.log('surveyDetailData',surveyDetailData)
        window.addEventListener('resize', handleResize);
    
        // Membersihkan listener ketika komponen di-unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

      
    
    return (
        <div>
                <div 
                  className={`tw-p-4 tw-py-4 tw-pb-[5rem]  tw-w-full tw-justify-center tw-h-full tw-overflow-auto tw-bg-slate-50`} 
                  >
                  <SectionCard 
                        titleCard={surveyDetailData.section[sectionPageIndex].section_name} 
                        descCard={surveyDetailData.section[sectionPageIndex].description} 
                  />
                 
                  <QuestionView
                        content={surveyDetailData.section[sectionPageIndex].content} 
                        widthWindow={windowWidth} 
                        sectionCode={surveyDetailData.section[sectionPageIndex].section_code} 
                        languageItem={surveyDetailData.setting.language}
                        sectionName={surveyDetailData.section[sectionPageIndex].section_name}
                        />
                </div>

      <Footer
        disablePrev={false} disableNext={false} onClickNext={() => handleNextPage()} onClickPrev={() => handlePrevPage()} />

           
        </div>
    )
}

export default SurveyContentView;
