import { Modal } from "react-bootstrap";
import { ButtonPrimary } from "../../../shared/component/button/Button";
import { TextAreaOnly } from "../../../shared/component/input/Input";

function AddFaq({title,show,size,onHide,onSave,answer,question,handleChangeAnswer,handleChangeQuestion}) {
    return(
      <Modal
      show={show}
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
        
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {title} FAQ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
              <div>
                  <div className="row mb-2">
                      <div className="col-3">
                          <label>Question</label>
                      </div>
                      <div className="col-9">
                          <TextAreaOnly type={'text'} input_height={'15vh'} input_width={'100%'} value={question} handleOnChange={handleChangeQuestion}/>
                      </div>
                  </div>
                  <div className="row mb-3">
                      <div className="col-3">
                          <label>Answer</label>
                      </div>
                      <div className="col-9">
                          <TextAreaOnly type={'text'} input_height={'15vh'} input_width={'100%'} value={answer} handleOnChange={handleChangeAnswer}/>
                      </div>
                  </div>
              </div>
        </Modal.Body>
        <Modal.Footer>
          <ButtonPrimary onClick={onSave} label='Save'></ButtonPrimary>
        </Modal.Footer>
      </Modal>
      )
  }  
  
  


export default AddFaq;

