import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const Top10Chart = ({ data, title, yAxisKey }) =>
  {
    const isEmptyData = !Array.isArray(data) || data.length === 0;    
    // console.log("dari top 10 chart",data)
    return(
    <div style={{ width: '100%', height: 400 ,marginBottom: '20px' }}>
        <h3 >{title}</h3>
        {isEmptyData ? (
          <div style={{ textAlign: 'center', marginTop: 50 }}>
            <p>Data tidak tersedia.</p>
          </div>
        ) : (
    // console.log("dari top 10 chart",data)
    <ResponsiveContainer width="100%" height={400} style={{ display: 'grid', gap: '20px' }}>
      <BarChart
        layout="vertical"
        width={500}
        height={400}
        data={data}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >

        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" />
        <YAxis dataKey={yAxisKey} type="category" tick={{ fontSize: 11 }}/>
        <Tooltip />
        <Legend />
        <Bar dataKey="survey_count" name = "Count of Survey" fill="#82ca9d" />
      </BarChart>
    </ResponsiveContainer>
    )}
    </div>
  );
};
export default Top10Chart;
