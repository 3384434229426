export const GeneralDataService = ({ doPost, doDelete }) => {

    /* Get Data Business Unit*/
    const getBusinessUnitData = async () => {
        try {
            return await doPost({ url: '/general_user/get_all_bu' });
        } catch (error) {
            throw error;
        }
    };

    /* Get Data Department*/
    const getDepartmentData = async () => {
        try {
            return await doPost({ url: '/general_user/get_all_dept' });
        } catch (error) {
            throw error;
        }
    };

    /* Get Data Level*/
    const getLevelData = async () => {
        try {
            return await doPost({ url: '/general_user/get_all_level' });
        } catch (error) {
            throw error;
        }
    };

 /* Get All employee With Filter*/
 const getAllUserWithFilter = async (limit, page_number, search,level,bu_name,dept_name) => {
    try {
        return await doPost({
            url: '/general_user/get_user_master',
            data: 
                {
                    limit: limit,
                    page_number: page_number,
                    search :search,
                    level: level,
                    bu_name: bu_name,
                    dept_name: dept_name
                  }
            ,
        });
} catch (e) {
    throw e;
}  
};

    /* Get All employee with filter and search */

    const getUserMaster = async (filterData) => {
        try {
            return await doPost({
                url: '/general_user/get_user_master',
                data: filterData,
            });
        } catch (e) {
            throw e;
        }
    };
    
     const getAllUsersMgmt= async () => {
        try {
            return await doPost({ url: '/admin/get_all_nik' });
        } catch (error) {
            throw error;
        }
     };
  
     const getAllUsersMgmtAlt= async () => {
        try {
            return await doPost({ url: '/admin/get_all_mgmt_alt' });
        } catch (error) {
            throw error;
        }
     };

    return {
       getBusinessUnitData,
       getDepartmentData,
       getLevelData,
       getAllUserWithFilter,
       getUserMaster,
       getAllUsersMgmt,
       getAllUsersMgmtAlt
    };
};
