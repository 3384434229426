
import { ADD_CONTENT_MANDATORY, CLEAR_DATA_PARTICIPANT_RATER_SURVEY_FORM, CLEAR_SURVEY_FIELD, CONTENT_MANDATORY, DELETE_ALL_MANDATORY_CONTENT, DELETE_ALL_MANY_ANSWER, DELETE_MANDATORY_CONTENT, DELETE_MANY_ANSWER, DELETE_MR_SURVEY_DETAIL_DATA, EDIT_MANY_ANSWER, EDIT_MANY_ANSWER_SAME_CONTENT, SET_DATA_PARTICIPANT_RATER_SURVEY_FORM, SET_MANY_ANSWER, SET_MR_SURVEY_DETAIL_DATA, SET_ONE_ANSWER, SET_SURVEY_FIELD } from "./MultiraterFormPartType";

const setOneAnswer = (answerData) => ({
    type: SET_ONE_ANSWER,
    data: answerData
 });

 const setManyAnswer = (answerData) => ({
    type: SET_MANY_ANSWER,
    data: answerData
 });

 const deleteManyAnswer = (answerData) => ({
    type: DELETE_MANY_ANSWER,
    data: answerData
 });

 const deleteAllManyAnswer = () => ({
   type: DELETE_ALL_MANY_ANSWER,
});

 const editManyAnswer = (answerData) => ({
    type: EDIT_MANY_ANSWER,
    data: answerData
 });

 const setSurveyField = (reqData) => ({
    type: SET_SURVEY_FIELD,
    data: reqData
 });

 const clearDataParticipantRaterMRForm = () => ({
   type: CLEAR_DATA_PARTICIPANT_RATER_SURVEY_FORM,
});

 const setDataParticipantRaterMRForm = (dataReq) => ({
    type: SET_DATA_PARTICIPANT_RATER_SURVEY_FORM,
    data: dataReq
 });

 const clearSurveyField = () => ({
   type: CLEAR_SURVEY_FIELD,
});

const editManyAnswerSameContent = (answerData) => ({
   type: EDIT_MANY_ANSWER_SAME_CONTENT,
   data: answerData
});

const addContentMandatory = (dataReq) => ({
   type: ADD_CONTENT_MANDATORY,
   data: dataReq
});

const deleteMandatoryContent = (dataReq) => ({
   type: DELETE_MANDATORY_CONTENT,
   data: dataReq
});

const deleteAllMAndatoryContent = () => ({
   type: DELETE_ALL_MANDATORY_CONTENT,
   
});

const setMRDetailData = (dataReq) => ({
   type: SET_MR_SURVEY_DETAIL_DATA,
   data: dataReq
});

const deleteMRDetailData = (dataReq) => ({
   type: DELETE_MR_SURVEY_DETAIL_DATA,
   
});


export default {
    setOneAnswer,
    setManyAnswer,
    deleteManyAnswer,
    editManyAnswer,
    setSurveyField,
    deleteAllManyAnswer,
    clearDataParticipantRaterMRForm,
    setDataParticipantRaterMRForm,
    clearSurveyField,
    editManyAnswerSameContent,
    addContentMandatory,
    deleteAllMAndatoryContent,
    deleteMandatoryContent,
    setMRDetailData,
    deleteMRDetailData
}