export const paginationContent = [
   { id: 1, pagination: 'By Section' },
   { id: 2, pagination: 'By Section - By Content' },
   { id: 3, pagination: 'One Page' }
];

export const itemLanguage = [
   { id: 1, language: 'All (Main & English)', val: 'all' },
   { id: 2, language: 'Main Language', val: 'main' },
   { id: 3, language: 'English', val: 'en' }
];

export const projectAnonimity = [
   { id: 1, text: 'Public (shared to anyone)', val: 0 },
   { id: 2, text: 'Private (only project creator can access)', val: 1 }
];

export const reminderInterval = [
   { id: 1, text: 'No', val: 0 },
   { id: 2, text: 'H+3 After Invitation', val: 3 },
   { id: 3, text: 'H+5 After Invitation', val: 5 }
];

export const participantAvailability = [
   { id: 1, text: 'No Nomination', val: 'no' },
   { id: 2, text: 'Nomination', val: 'nomination' }
];

export const statusSurvey = [
   { id: 0, text: 'Non-Active' },
   { id: 1, text: 'Active' },
]

export const loginAccess = [
   {id:0 , text :'Email-OTP'},
   {id:1, text :'Login By Link'},
]