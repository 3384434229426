import { useEffect, useState } from "react";
import {
  InputWithLabel
} from "../../shared/component/input/Input";
import { DropdownWithLabel } from "../../shared/component/dropdown/DropdownInput";
import { Card, Dropdown } from "react-bootstrap";
import {
  ButtonPrimary,
} from "../../shared/component/button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { useAddRaters } from "../participant_features/participant_add_raters/useAddRaters";
import { useSelector } from "react-redux";

export const ExternalRaters = () => {
  const {handleAddandSendInvitation} = useAddRaters();
  const roleInSurvey = [
    { id: 1, role_name: 'Self' },
    { id: 2, role_name: 'Supervisor' },
    { id: 3, role_name: 'Subordinate' },
    { id: 4, role_name: 'Peer' },
    { id: 5, role_name: 'Stakeholder' },
    { id: 6, role_name: 'Other' },
 ]
  const [fullName, setFullName] = useState("");
  const [emailRater, setEmailRater] = useState("");
  const [number, setNumber] = useState("");
  const [company, setCompany] = useState("");
  const [position, setPosition] = useState("")
  const [relationshipName, setRelationshipName] = useState("Stakeholder");
  const [relationshipId, setRelationshipId] = useState("5");
  const seeRaterData = useSelector((state) => state.data_see_raters);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [numberErrorMessage, setNumberErrorMessage] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [validNumber, setValidNumber] = useState(false)


  const [validForm, setValidForm] = useState(false)

  useEffect(()=>{
    if (fullName != "" && emailRater != "" && number != "" && position != "" && company != "" && validEmail && validNumber) {
       setValidForm(true);
    } else {
      setValidForm(false)
    }
  },[fullName,emailRater,number,position,company])

  

  const handleChangeRelationship = (id,role_name) => {
    setRelationshipName(role_name)
    setRelationshipId(id)
  }

  const handleOnChangeText = (e) => {
    e.target.id == 'name_rater' && setFullName(e.target.value);
    e.target.id == 'email_rater' && setEmailRater(e.target.value);
    e.target.id == 'number_rater' && setNumber(e.target.value);
    e.target.id == 'position_rater' && setPosition(e.target.value);
    e.target.id == 'company_rater' && setCompany(e.target.value);
  }

 

  const validateUserEmail =  () => {
    let emailErrorMessage = ''
    if (!emailRater.match(/^[a-zA-Z0-9_.-]+@([a-zA-Z0-9_-]+\.)+[A-Za-z]+$/)){
      setValidEmail(false);
        if (emailRater == 0 || emailRater == '') {
            emailErrorMessage = `Email can't be empty!`
        }
        else {
            emailErrorMessage = 'Invalid email format'
        }
    }else {
      setValidEmail(true)
    }
     setEmailErrorMessage(emailErrorMessage);
    
  }

  const validateUserNumber =  () => {
    let numberErrorMessage = '';
    if (!number.match(/^[0-9]{9,15}$/)) {
      setValidNumber(false);
        if (number == 0 || number == '') {
            numberErrorMessage = `Number can't be empty!`
        }
        else if (number.length < 10) {
          numberErrorMessage= 'Min number length is 9 characters'
        } else if (number.length > 15) {
          numberErrorMessage= 'Max number length is 15 characters'
        } 
        else {
            numberErrorMessage = 'Invalid number format'
        }
        
    }else {
      setValidNumber(true);
    }
    setNumberErrorMessage(numberErrorMessage);

    
  }

  useEffect(()=>{
    validateUserEmail()
   },[emailRater])

   useEffect(()=>{
    validateUserNumber()
   },[number])




  return (
    <>
      
      <Card style={{ padding: "2rem" }}>
        <div style={{fontSize:'1.5rem', marginBottom:'1rem', fontWeight:'bold'}}>Fill in the rater data</div>
        <InputWithLabel label={"Name"} style={{ marginBottom: "1rem" }} id={'name_rater'} handleOnChange={handleOnChangeText} value={fullName}/>

        <div style={{ marginBottom: "1rem" }}>
            <InputWithLabel label={"Email"} id={'email_rater'} handleOnChange={handleOnChangeText} value={emailRater}/>
            <div className="d-flex justify-content-end text-danger" style={{fontSize:'0.8rem'}}>{emailErrorMessage}</div>
        </div>


        <div style={{ marginBottom: "1rem" }} >
          <InputWithLabel label={"Number"} id={'number_rater'}  handleOnChange={handleOnChangeText} value={number}/>
          <div className="d-flex justify-content-end text-danger" style={{fontSize:'0.8rem'}}>{numberErrorMessage}</div>
        </div>

        <DropdownWithLabel
          id={'relationship_rater'}
          style={{ marginBottom: "1rem" }}
          label={"Relationship"}
          default_item = {relationshipName}
          dropdown_item={
            roleInSurvey.length != 0 ? (
              roleInSurvey.map((itm, i) => {
                return <Dropdown.Item key={i} onClick={()=>handleChangeRelationship(itm.id,itm.role_name)}>{itm.role_name}</Dropdown.Item>;
              })
            ) : (
              <Dropdown.Item>Select</Dropdown.Item>
            )
          }
        />
        <InputWithLabel label={"Position"} style={{ marginBottom: "1rem" }} id={'position_rater'}  handleOnChange={handleOnChangeText} value={position}/>
        <InputWithLabel label={"Company"} style={{ marginBottom: "1rem" }}  id={'company_rater'} handleOnChange={handleOnChangeText} value={company}/>
        {validForm ? <div style={{color:'yellowgreen'}}><FontAwesomeIcon icon={faCircleCheck} style={{color:'yellowgreen'}}/> data has been completed</div> : <div style={{fontStyle:'italic', color:'red'}}><FontAwesomeIcon icon={faCircleExclamation} style={{color:'red'}}/> incomplete data</div>}
        
        <div className="d-flex justify-content-center">
          <ButtonPrimary
           disabled={validForm? false : true}
            label="Add and Send Invitation"
            onClick={() =>
              handleAddandSendInvitation(seeRaterData.tp_name,fullName,relationshipName)
            }
          />
        </div>
      </Card>
    </>
  );
};
