import { useLocation, useNavigate } from "react-router-dom";
import { ButtonPrimary } from "../../../shared/component/button/Button"
import { CardListRater } from "../../../shared/component/card/CardListRater"
import { CONSTANTS } from "../../../common/constants";
import { Footer } from "../../layout/navbar/Footer";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { rootAction } from "../../../redux/RootAction";


export const ListRaterView = () => {
    const navigate = useNavigate();
    const seeRaterData = useSelector((state) => state.data_see_raters);
    const dispatch = useDispatch()

    const navigateBacktoMultiraterList = () => {
       dispatch(rootAction.addRater.deleteDataSeeRaters())
       navigate(-1);
    }


    return (
       <>
        <div className="container">
            <div style={{textAlign: "center", color:"#5227CC", fontSize:25}}>
            List of raters for {seeRaterData.tp_name}
            </div>
                <div className='d-flex justify-content-end'>
                    {/* <button className="btn btn-primary btn-block col-1 my-2" type="submit"
                    style={{width:"104px"}}>Add Rater</button> */}
                    <ButtonPrimary label="Add Rater" onClick={()=>navigate(`${CONSTANTS.ROUTER.PARTICIPANT_DASHBOARD}/${CONSTANTS.ROUTER.PARTICIPANT_ADD_RATERS}`)} />
                </div>
            <div className="m-2" style={{display:"flex", justifyContent:"center"}}>
                <CardListRater/>
            </div>
        </div>
        <Footer disableNext={true} onClickPrev={()=>navigateBacktoMultiraterList()}/>
       </>
    )
}