import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useEditParticipant from "./useEditParticipant";
import screenAlert from "../../../../../shared/hook/SweetAlert";
import screenToast from "../../../../../shared/hook/ScreenToast";
import { rootAction } from "../../../../../redux/RootAction";
import { ButtonOutlinePrimary, ButtonPrimary } from "../../../../../shared/component/button/Button";
import { InputWithLabel } from "../../../../../shared/component/input/Input";

function EditParticipantUpdate() {
   const navigate = useNavigate();
   const [addRaters, setAddRaters] = useState(false);
   const [editRaters, setEditRaters] = useState({ isShow: false, data: {} });
   const [participantRaters, setParticipantRaters] = useState({});
   const editParticipantView = useSelector((state) => state.update_view_edit_participant_single_rater);
   const participantReducer = useSelector((state) => state.update_participant_single_rater.find((el) => el.nik == editParticipantView));
   const editParticipantViewByEmail = useSelector((state) => state.update_view_edit_participant_single_rater_by_email);
   const participantReducerByEmail = useSelector((state) => state.update_participant_single_rater.find((el) => el.email == editParticipantViewByEmail ));
    
   const dispatch = useDispatch();
   const { participantData, emailLoginAsParticipant } = useEditParticipant({ nik: editParticipantView });
   const showAlert = screenAlert();
   const showToast = screenToast();

   useEffect(() => {
      setParticipantRaters(participantReducer);
      if (editParticipantView == '') {
         setParticipantRaters(participantReducerByEmail)
     }
   }, []);

   const handleOnChangeText = (e) => {
      if (e.target.id == 'email_participant') {
         setParticipantRaters({
            ...participantRaters,
            email: e.target.value,
            raters: [...participantRaters.raters.map((rat) => {
               if (rat.nik == editParticipantView) {
                  return {
                     ...rat,
                     email: e.target.value
                  }
               } else {
                  return rat;
               }
            })]
         });
      }
   }

   const handleOnChangeTextByEmail = (e) => {
      if (e.target.id == 'email_participant') {
          setParticipantRaters({
              ...participantRaters,
              email: e.target.value,
              raters: [...participantRaters.raters.map((rat) => {
                  if (rat.email == editParticipantViewByEmail) {
                      return {
                          ...rat,
                          email: e.target.value
                      }
                  } else {
                      return rat;
                  }
              })]
          });
      }
      if (e.target.id == 'name') {
          setParticipantRaters({
              ...participantRaters,
              full_name: e.target.value,
              raters: [...participantRaters.raters.map((rat) => {
                  if (rat.email == editParticipantViewByEmail) {
                      return {
                          ...rat,
                          full_name: e.target.value
                      }
                  } else {
                      return rat;
                  }
              })]
          });
      }

      if (e.target.id == 'phone_number') {
          setParticipantRaters({
              ...participantRaters,
              phone_number: e.target.value,
              raters: [...participantRaters.raters.map((rat) => {
                  if (rat.email == editParticipantViewByEmail) {
                      return {
                          ...rat,
                          phone_number: e.target.value
                      }
                  } else {
                      return rat;
                  }
              })]
          });
      }

      if (e.target.id == 'position') {
          setParticipantRaters({
              ...participantRaters,
              position: e.target.value,
              raters: [...participantRaters.raters.map((rat) => {
                  if (rat.email == editParticipantViewByEmail) {
                      return {
                          ...rat,
                          position: e.target.value
                      }
                  } else {
                      return rat;
                  }
              })]
          });
      }

      if (e.target.id == 'business_unit') {
          setParticipantRaters({
              ...participantRaters,
              business_unit_name: e.target.value,
              raters: [...participantRaters.raters.map((rat) => {
                  if (rat.email == editParticipantViewByEmail) {
                      return {
                          ...rat,
                          business_unit_name: e.target.value
                      }
                  } else {
                      return rat;
                  }
              })]
          });
      }


  }


   const handleOnCreateRaters = (val) => {
      if (!participantRaters.raters.find((rat) => rat.nik == val.nik)) {
         setParticipantRaters({
            ...participantRaters,
            raters: [...participantRaters.raters, val]
         });
      } else {
         showToast.error("Already add raters")
      }
   }

   const handleOnEditRaters = (e, val, email) => {
      setEditRaters({
         ...editRaters,
         isShow: !editRaters.isShow,
         data: {
            ...val,
            email: email ? email : val?.email
         }
      });
   }

   const handleOnSaveEditRaters = (val) => {
      setParticipantRaters({
         ...participantRaters,
         raters: [...participantRaters.raters].map((el) => {
            if (el.nik == val.nik) {
               return val;
            } else {
               return el;
            }
         })
      });
   }

   const handleOnDeleteRaters = (e, val) => {
      setParticipantRaters({
         ...participantRaters,
         raters: participantRaters.raters.filter((el) => el.nik != val)
      });
   }

   const handleOnSubmitRaters = () => {
      showAlert.info({
         text: "Are you sure to save raters for \"" + participantRaters.full_name + "\"?",
         buttons: {
            cancel: {
               text: "Cancel",
               className: "bg-white text-dark"
            },
            confirm: {
               text: "Confirm",
               value: true,
               className: "bg-success"
            }
         }
      }).then(value => {
         if (value) {
            dispatch(rootAction.updateSingleRater.editParticipantSRAction(participantRaters));     
            dispatch(rootAction.updateSingleRater.resetViewEditParticipantSR());
            dispatch(rootAction.updateSingleRater.resetViewEditParticipantByEmailSR());  
            navigate(-1);
         }
      });
   }

   const handleBack = () => {
      dispatch(rootAction.updateSingleRater.resetViewEditParticipantSR());
      dispatch(rootAction.updateSingleRater.resetViewEditParticipantByEmailSR());  

      navigate(-1);

  }

  if (editParticipantView == '' || participantRaters.is_internal == 0) {     
   return (
       <div className="container" style={{ overflowY: 'auto' }}>
           <div className='col-12 m-4'>
               <h4>Edit Participant</h4>
           </div>
           <div className='my-4 d-flex justify-content-between align-items-center'>
               <span className="fst-italic">Change survey participant information and raters information.</span>
               <div className="d-flex justify-content-end">
                   <div className=" me-2">
                       <ButtonOutlinePrimary label="Back" onClick={() => navigate(-1)} />
                   </div>
                   <div className="">
                       <ButtonPrimary label="Save" onClick={handleOnSubmitRaters} />
                   </div>
               </div>
           </div>

           <div className='card shadow p-4 border-0' >
               <div className="col-12 d-flex flex-column" style={{ borderBottom: '0.1rem solid black' }} >
                   <div className="w-full">
                       <h5 className="m-0">Participant Information</h5>
                   </div>
                   <div className="w-full d-flex">
                       <div className="col-6 pe-4">
                           <div className="my-4">
                               <InputWithLabel id={'name'} label={'Name'} value={participantRaters.full_name} handleOnChange={handleOnChangeTextByEmail} />
                           </div>
                           <div className="my-4">
                               <InputWithLabel id={'nik'} label={'NIK'} disabled={true} value={participantRaters.nik} handleOnChange={handleOnChangeTextByEmail} bg_input={'rgba(171, 181, 190, 0.20)'} />
                           </div>
                           <div className="my-4">
                               <InputWithLabel id={'phone_number'} label={'Number'} value={participantRaters.phone_number} handleOnChange={handleOnChangeTextByEmail} />
                           </div>
                            <div className="my-4">
                                               <InputWithLabel id={'email_participant'} label={'Email'} value={participantRaters.email?.toLowerCase()} handleOnChange={handleOnChangeTextByEmail} disabled={false}  />
                                                   {!participantRaters?.email &&
                                                       <div className="d-flex flex-row mt-2">
                                                           <span className="tw-w-1/5"></span>
                                                           <span className="tw-w-4/5" style={{ fontSize: '0.8rem', color: 'red' }}>
                                                               Email cannot be empty. Please edit with a valid email.
                                                           </span>
                                                       </div>
                                                   }                                                   
                                              
                               
                           </div>

                       </div>
                       <div className="col-6 ps-4">
                           <div className="my-4">
                               <InputWithLabel id={'position'} label={'Position'}  value={participantRaters.position} handleOnChange={handleOnChangeTextByEmail} />
                           </div>
                           <div className="my-4">
                               <InputWithLabel id={'business_unit'} label={'Business Unit / Company'} value={participantRaters.business_unit_name} handleOnChange={handleOnChangeTextByEmail} />
                           </div>
                       </div>
                   </div>
               </div>

              
               
           </div>

       </div>
   )
   
} else {
   return (
       <div className="container" style={{ overflowY: 'auto' }}>
           <div className='col-12 m-4'>
               <h4>Edit Participant</h4>
           </div>
           <div className='my-4 d-flex justify-content-between align-items-center'>
               <span className="fst-italic">Change survey participant information and raters information.</span>
               <div className="d-flex justify-content-end">
                   <div className=" me-2">
                       <ButtonOutlinePrimary label="Back" onClick={() => handleBack()} />
                   </div>
                   <div className="">
                       <ButtonPrimary label="Save" onClick={handleOnSubmitRaters} />
                   </div>
               </div>
           </div>

           <div className='card shadow p-4 border-0' >
               <div className="col-12 d-flex flex-column" style={{ borderBottom: '0.1rem solid black' }} >
                   <div className="w-full">
                       <h5 className="m-0">Participant Information</h5>
                   </div>
                   <div className="w-full d-flex">
                       <div className="col-6 pe-4">
                           <div className="my-4">
                               <InputWithLabel label={'Name'} disabled={true} value={participantRaters.full_name} bg_input={'rgba(171, 181, 190, 0.20)'} />
                           </div>
                           <div className="my-4">
                               <InputWithLabel label={'NIK'} disabled={true} value={participantRaters.nik} bg_input={'rgba(171, 181, 190, 0.20)'} />
                           </div>
                           <div className="my-4">
                               <InputWithLabel label={'Number'} value={participantRaters.phone_number} disabled={true} bg_input={'rgba(171, 181, 190, 0.20)'} />
                           </div>
                            <div className="my-4">
                                               <InputWithLabel id={'email_participant'} label={'Email'} value={participantRaters.email?.toLowerCase()} handleOnChange={handleOnChangeText} disabled={false}  />
                                                   {!participantRaters?.email &&
                                                       <div className="d-flex flex-row mt-2">
                                                           <span className="tw-w-1/5"></span>
                                                           <span className="tw-w-4/5" style={{ fontSize: '0.8rem', color: 'red' }}>
                                                               Email cannot be empty. Please edit with a valid email.
                                                           </span>
                                                       </div>
                                                   }                                                   
                                              
                               
                           </div>

                       </div>
                       <div className="col-6 ps-4">
                           <div className="my-4">
                               <InputWithLabel label={'Position'} disabled={true} value={participantRaters.position} bg_input={'rgba(171, 181, 190, 0.20)'} />
                           </div>
                           <div className="my-4">
                               <InputWithLabel label={'Level'} disabled={true} value={participantRaters.level} bg_input={'rgba(171, 181, 190, 0.20)'} />
                           </div>
                           <div className="my-4">
                               <InputWithLabel label={'Department'} disabled={true} value={participantRaters.department_name} bg_input={'rgba(171, 181, 190, 0.20)'} />
                           </div>
                           <div className="my-4">
                               <InputWithLabel label={'Business Unit'} disabled={true} value={participantRaters.business_unit_name} bg_input={'rgba(171, 181, 190, 0.20)'} />
                           </div>
                       </div>
                   </div>
               </div>

               
           </div>
       </div>
   )
}
}



export default EditParticipantUpdate;