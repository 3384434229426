import { Modal } from "react-bootstrap";
import { InputTextWithLabel, TextAreaWithLabel } from "../../../shared/component/input/Input";

function ModalQuestionDetail({ size, show, onHide, question }) {
    return (
        <Modal size={size} show={show} onHide={onHide} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header className="p-4" closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Detail Question
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4">
                <div className="mb-4">
                    <InputTextWithLabel label='Goal' id='goal' disabled={true} value={question?.goal_name} />
                </div>
                <div className="my-4">
                    <InputTextWithLabel label='Group' id='group' disabled={true} value={question?.group_survey} />
                </div>
                <div className="my-4">
                    <InputTextWithLabel label='Dimension' id='dimension' disabled={true} value={question?.dimension} />
                </div>
                <div className="my-4">
                    <InputTextWithLabel label='Aspect' id='aspect' disabled={true} value={question?.aspect} />
                </div>
                <div className="my-4">
                    <TextAreaWithLabel label='Action for Strength' id='action-strength' disabled={true} value={question?.action_for_strength} />
                </div>
                <div className="my-4">
                    <TextAreaWithLabel label='Action for Weakness' id='action-weakness' disabled={true} value={question?.action_for_weakness} />
                </div>
                <div className="my-4">
                    <TextAreaWithLabel label='Item (Main Language)' id='item-main' disabled={true} value={question?.item} />
                </div>
                <div className="mt-4">
                    <TextAreaWithLabel label='Item (English)' id='item-english' disabled={true} value={question?.item_in_eng} />

                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ModalQuestionDetail;