import { useEffect, useState } from "react";
import { useDeps } from "../../../shared/context/DependencyContext";
import { useSelector } from "react-redux";
import { CONSTANTS } from "../../../common/constants";

function useQuestionHistory({ limit, pageNumber, search }) {
   const { adminService, picService } = useDeps();
   const [allQuestionHistory, setAllQuestionHistory] = useState([]);
   const [totalItem, setTotalItem] = useState(0);
   const user = useSelector((state) => state.user);

   useEffect(() => {
      let filterData = {
         limit: limit,
         page_number: pageNumber,
         search_text: search
      }

      onGetSearchQuestionHistory(filterData);
   }, [limit, pageNumber, search]);

   const onGetSearchQuestionHistory = async ({ search_text, limit, page_number }) => {
      try {
         const response =
            user.role == CONSTANTS.ROLE.ADMIN ? await adminService.questionHistoryService.getWithSearchQuestionHistory(search_text, limit, page_number) :
               (user.role == CONSTANTS.ROLE.PIC ? await picService.questionHistoryService.getWithSearchQuestionHistoryPICUrl(search_text, limit, page_number) : null);

         if (response.status == 'SUCCESS') {
            setAllQuestionHistory(response.data.data);
            setTotalItem(response.data.total_item)
         } else {
            setAllQuestionHistory([]);
            setTotalItem(0);
         }
      } catch (error) {
         console.log(error)
      }
   }

   return {
      allQuestionHistory,
      totalItem
   }
}
export default useQuestionHistory;