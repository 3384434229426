import { CKEditor as Editor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const RichTextEditor = ({ onChange, readOnly, children }) => (
    <Editor
        editor={ClassicEditor}
        /*config={{
            // plugins: [ Bold, Italic, Underline, Essentials ],
            // toolbar: [ 'bold', 'italic' ],
        }}*/
        data={children}
        onChange={onChange}
        disabled={readOnly}
    />
)

export default RichTextEditor;
