// src/redux/actions/spreadsheetActions.js
import axios from "axios";
import {
    OPEN_SPREADSHEET_IMPORT,
    CLOSE_SPREADSHEET_IMPORT,
    SET_IMPORTED_DATA,
} from './SpreadSheetType';

export const openSpreadsheetImport = () => ({
    type: OPEN_SPREADSHEET_IMPORT
});

export const closeSpreadsheetImport = () => ({
    type: CLOSE_SPREADSHEET_IMPORT
});

export const setImportedData = (data) => ({
    type: SET_IMPORTED_DATA,
    payload: data
});

// export const validateSpreadsheetData = (spreadsheetData) => async (dispatch) => {
//     try {
//         const response = await axios.post('http://127.0.0.1:8000/admin/get_all_participant');
//         if (response.data.status === "SUCCESS") {
//             const apiData = response.data.data;
//             const validatedData = spreadsheetData.map((row) => {
//                 const matchedRecord = apiData.find(item => item.nik === row.nik || item.email === row.email);
//                 return matchedRecord ? { ...row, ...matchedRecord } : row;
//             });
//             dispatch(setImportedData(validatedData));
//         }
//     } catch (error) {
//         console.error('Error validating spreadsheet data:', error);
//     }
// };

export default {
    openSpreadsheetImport,
    closeSpreadsheetImport,
    setImportedData,
}