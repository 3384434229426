export const SharingSurveyService = ({ doPost }) => {

    const getPICbyNIK = async (nik) => {
        try {
            return await doPost({
                url: '/pic/get_pic_by_nik',
                data: {
                   nik:nik
                },
            });
        } catch (e) {
            throw e;
        }
    };

    const sharingSurvey = async (data) => {
        try {
            return await doPost({
                url: '/pic/survey_sharing',
                data: data,
            });
        } catch (e) {
            throw e;
        }
    };

    const deleteOwnership = async (data) => {
        try {
            return await doPost({
                url: '/pic/delete_ownership_survey',
                data: data,
            });
        } catch (e) {
            throw e;
        }
    };



    return {
        getPICbyNIK,
        sharingSurvey,
        deleteOwnership
    };
};