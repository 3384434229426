import { toast } from "react-toastify";

const toastOptions = {
   position: "bottom-right",
   autoClose: 5000,
   hideProgressBar: false,
   closeOnClick: true,
   pauseOnHover: false,
   draggable: false,
   progress: undefined,
   theme: "light",
};

const screenToast = () => {
   const success = (message) => toast.success(message, toastOptions);

   const warning = (message) => toast.warning(message, toastOptions);

   const error = (message) => toast.error(message, toastOptions);

   return { success, warning, error }
}

export default screenToast;