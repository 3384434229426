import TabSettings from "../tab/tab_settings/TabSettings";
import TabContent from "../tab/tab_content/TabContent";
import TabParticipant from "../tab/tab_participant/TabParticipant";
import { ButtonOutlinePrimary, ButtonPrimary } from "../../../shared/component/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { rootAction } from "../../../redux/RootAction";
import { useDeps } from "../../../shared/context/DependencyContext";
import { CONSTANTS } from "../../../common/constants";
import screenToast from "../../../shared/hook/ScreenToast";
import { useNavigate } from "react-router-dom";
import { clearAddMultiraterRedux } from "../../../common/utils";
import screenAlert from "../../../shared/hook/SweetAlert";

function AddMultiRater() {
    const { adminService, picService } = useDeps();
    const navigate = useNavigate();
    const showToast = screenToast();
    const viewTab = useSelector((state) => state.view_multi_rater);
    const multiRaterReducer = useSelector((state) => state.multi_rater);
    const settingReducer = useSelector((state) => state.setting_multi_rater);
    const sectionReducer = useSelector((state) => state.section_multi_rater);
    const scoreReducer = useSelector((state) => state.score_multi_rater);
    const participantReducer = useSelector((state) => state.participant_multi_rater);
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const showAlert = screenAlert();
    const scaleReducer = useSelector((state) => state.scale_choice_multi_rater);
    const currentUrl = window.location.pathname;
    const validReducer = useSelector((state) => state.valid_multi_rater);

    // handler
    const onChangeTab = (e) => {
        dispatch(rootAction.multiRater.setMRViewAction(e.target.value));
    };

    const onSaveMultiRater = async (e) => {
        e.preventDefault();

        if (settingReducer.goal_id == 0 || settingReducer.goal_id == undefined || settingReducer.goal_id == null || settingReducer.survey_name == '') {
            showAlert.warning({
                text: "Please select goal and input survey name !",
                buttons: {
                    cancel: {
                        text: "OK",
                        className: "bg-white text-dark"
                    }
                }
            })
        } else if (!validReducer.setting || !validReducer.content || !validReducer.participant) {
            showAlert.warning({
                text: "You have error at:\n" +
                    (!validReducer.setting ? " [Settings] " : "") +
                    (!validReducer.content ? " [Content] " : "") +
                    (!validReducer.participant ? " [Participant] " : "") +
                    "\nPlease edit your input and try again.",
                buttons: {
                    cancel: {
                        text: "OK",
                        className: "bg-white text-dark"
                    }
                }
            })
        } else {
            showAlert.info({
                text: "Are you sure to save Multi-Rater survey?",
                buttons: {
                    cancel: {
                        text: "Cancel",
                        className: "bg-white text-dark"
                    },
                    confirm: {
                        text: "Confirm",
                        value: true,
                        className: "bg-success"
                    }
                }
            }).then(value => {
                if (value) {
                    (async () => {
                        try {
                            let section = [...sectionReducer].map((sec) => {
                                let isUsingScale = false;

                                return {
                                    ...sec,
                                    content: [...sec.content].map((con) => {
                                        let choice = con.content_choice;

                                        if (con.content_type === 'scale' && con.score_same_as_scale === 1) {
                                            choice = [...scaleReducer.find((data) => data.section_id == sec.section_id).content_choice]
                                            isUsingScale = true
                                        } else if (con.content_type === 'scale' && con.score_same_as_scale === 0) {
                                            let reverse = [...scaleReducer.find((data) => data.section_id == sec.section_id).content_choice]

                                            choice = reverse.map((rev, i) => {
                                                return {
                                                    ...rev,
                                                    choice_score: reverse.length - i
                                                }
                                            });

                                            isUsingScale = true
                                        } else if (con.content_type === 'text_input' || con.content_type === 'essay') {
                                            choice = []
                                        }

                                        return {
                                            ...con,
                                            content_choice: choice
                                        }
                                    }),
                                    using_scale: !isUsingScale ? 0 : sec.using_scale,
                                    scale_range: !isUsingScale ? 0 : sec.scale_range
                                }
                            });

                            let multiRaterData = {
                                ...multiRaterReducer,
                                setting: { ...settingReducer },
                                participant: [...participantReducer],
                                section: section,
                                score_weight: { ...scoreReducer }
                            }

                            // console.log(JSON.stringify(multiRaterData));
                            

                            const response =
                                user.role == CONSTANTS.ROLE.ADMIN ? await adminService.multiRaterService.saveMultiRater(multiRaterData) :
                                    (user.role == CONSTANTS.ROLE.PIC ? await picService.multiRaterService.saveMultiRaterPICUrl(multiRaterData) : null);

                            if (!response) {
                                showToast.error("Something went wrong. Please try again later.");
                                return;
                            }

                            if (response.status == CONSTANTS.API.SUCCESS) {
                                clearAddMultiraterRedux(dispatch);
                                showToast.success(response.status);
                                if (user.role == CONSTANTS.ROLE.ADMIN) {
                                    navigate(`${CONSTANTS.ROUTER.ADMIN_CMS}/${CONSTANTS.ROUTER.MULTI_RATER_SURVEY_LIST}`)
                                } else if (user.role == CONSTANTS.ROLE.PIC) {
                                    navigate(`${CONSTANTS.ROUTER.PIC_CMS}/${CONSTANTS.ROUTER.MULTI_RATER_SURVEY_LIST}`)
                                }
                            } else {
                                showToast.error(response.status);
                            }

                            // console.log('MUltirater json', JSON.stringify(multiRaterData));
                        } catch (err) {
                            console.log(err);
                            showToast.error("Something went wrong. Please try again later.");
                        }
                    })();
                }
            });
        }


    }

    const handlePreviewPage = () => {
        let section = [...sectionReducer].map((sec) => {
            let isUsingScale = false;

            return {
                ...sec,
                content: [...sec.content].map((con) => {
                    let choice = con.content_choice;

                    if (con.content_type === 'scale' && con.score_same_as_scale === 1) {
                        choice = [...scaleReducer.find((data) => data.section_id == sec.section_id).content_choice]
                        isUsingScale = true
                    } else if (con.content_type === 'scale' && con.score_same_as_scale === 0) {
                        let reverse = [...scaleReducer.find((data) => data.section_id == sec.section_id).content_choice]

                        choice = reverse.map((rev, i) => {
                            return {
                                ...rev,
                                choice_score: reverse.length - i
                            }
                        });

                        isUsingScale = true
                    } else if (con.content_type === 'text_input' || con.content_type === 'essay') {
                        choice = []
                    }

                    return {
                        ...con,
                        content_choice: choice
                    }
                }),
                using_scale: !isUsingScale ? 0 : sec.using_scale,
                scale_range: !isUsingScale ? 0 : sec.scale_range
            }
        });

        let multiRaterData = {
            ...multiRaterReducer,
            setting: { ...settingReducer },
            participant: [...participantReducer],
            section: section,
            score_weight: { ...scoreReducer }
        }
        dispatch(rootAction.multiRater.setDataPreviewMR(multiRaterData))
        navigate(`${currentUrl}/${CONSTANTS.ROUTER.PREVIEW_MULTIRATER}`)

    }

    const handleDiscardSurvey = (e) => {
        e.preventDefault();

        showAlert.info({
            text: "Discard your survey and start a new one.",
            buttons: {
                cancel: {
                    text: "Cancel",
                    className: "bg-white text-dark"
                },
                confirm: {
                    text: "Confirm",
                    value: true,
                    className: "bg-success"
                }
            }
        }).then(value => {
            if (value) {
                clearAddMultiraterRedux(dispatch);
            }
        });
    }

    return (
        <div className="container tw-h-max">

            <div className='my-4 d-flex justify-content-between'>
                <h4> Create Multi-Rater Project </h4>
            </div>
            <div className='my-4 d-flex justify-content-between align-items-center'>
                <span className="fst-italic">Please fill out the required field in Settings, then add a question in Content or add a Participant for the survey.</span>
                <div className="d-flex justify-content-end">
                    <div className=" me-2">
                        <ButtonOutlinePrimary label={'Discard'} onClick={handleDiscardSurvey} />
                    </div>

                    <div className=" me-2">
                        <ButtonOutlinePrimary label={'Preview'} onClick={handlePreviewPage} />
                    </div>

                    <div className="">
                        <ButtonPrimary
                            label="Save" onClick={onSaveMultiRater} />
                    </div>




                </div>
            </div>

            <div className='card shadow' style={{ borderLeft: 0, borderBottom: 0, borderRight: 0, minHeight: '70vh' }}>

                <div className="card-header d-flex p-0 bg-transparent border-0">
                    <div
                        className="btn-group w-100"
                        role="group"
                        aria-label="Basic radio toggle button group"

                    >
                        <input
                            type="radio"
                            className="btn-check"
                            name="btn-radio"
                            id="btn-settings"
                            autoComplete="off"
                            value={1}
                            checked={viewTab == 1 ? true : false}
                            onChange={onChangeTab}


                        />
                        <label className={`btn btn-outline-primary ${viewTab == 1 && 'btn-new-style'}`} htmlFor="btn-settings" style={{ width: '12vw', borderRadius: '0.5rem 0 0 0.5rem' }}>
                            Settings
                        </label>
                        <input
                            type="radio"
                            className="btn-check"
                            name="btn-radio"
                            id="btn-content"
                            autoComplete="off"
                            value={2}
                            checked={viewTab == 2 ? true : false}
                            onChange={onChangeTab}
                        />
                        <label className={`btn btn-outline-primary ${viewTab == 2 && 'btn-new-style'}`} htmlFor="btn-content" style={{ width: '12vw', borderRadius: 0 }}>
                            Content
                        </label>
                        <input
                            type="radio"
                            className="btn-check"
                            name="btn-radio"
                            id="btn-participant"
                            autoComplete="off"
                            value={3}
                            checked={viewTab == 3 ? true : false}
                            onChange={onChangeTab}
                        />
                        <label className={`btn btn-outline-primary ${viewTab == 3 && 'btn-new-style'}`} htmlFor="btn-participant" style={{ width: '12vw', borderRadius: '0 0.5rem 0.5rem 0' }}>
                            Participant
                        </label>
                    </div>
                </div>

                <div className="card-body p-4">
                    {
                        viewTab == 1 ? <TabSettings /> :
                            (viewTab == 2 ? <TabContent /> :
                                (viewTab == 3 ? <TabParticipant /> :
                                    <></>))
                    }
                </div>
            </div>

        </div>
    )
}

export default AddMultiRater;