export const LoginByLinkService = ({ doPost }) => {
    
    const getDetailSurveyFormData = async (data) => {
       try {
            return await doPost({
                url: '/participant/get_all_survey_question_with_autologin',
                data: data,
            });
       } catch (error) {
          throw error;
       }
    };

    const postManyAnswer = async (data) => {
      try {
         return await doPost({
             url: '/participant/insert_many_answer',
             data: data,
         });
      } catch (error) {
         throw error;
      }
    }

   
    
 
    return {
       getDetailSurveyFormData,
       postManyAnswer,
       
    };
 };
 