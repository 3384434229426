export const AddRatersService = ({ doPost }) => {
  const addRaterAndSendInvitation = async (addRaterData) => {
    try {
      return await doPost({
        url: "/participant/add_raters_from_participant",
        data: addRaterData,
      });
    } catch (e) {
      throw e;
    }
  };


  return {
    addRaterAndSendInvitation,
  };
};
