import { useEffect, useState } from "react";
import { useDeps } from "../../../../shared/context/DependencyContext";
import screenToast from "../../../../shared/hook/ScreenToast";
import useViewState from "../../../../shared/hook/UseViewState";
import { useDispatch, useSelector } from "react-redux";
import { rootAction } from "../../../../redux/RootAction";

function usePICProject() {
    const { adminService ,picService} = useDeps();
    const [progress, setProgress] = useState(0);
    const [picData, setPicData] = useState();
    const dispatch = useDispatch();

    const showToast = screenToast();
    const { viewState, setError, setSuccess } = useViewState();

    useEffect(() => {
        if (viewState.isError == true) {
            showToast.error(viewState.errorMsg);
            setError(false, '');
        }

        if (viewState.isSuccess == true) {
            showToast.success(viewState.successMsg);
            setSuccess(false, '');
        }
    }, [viewState]);

    const handleDeletePicProject = async (survey_code,pic_email,user_login) => {

        try {
             const response = await picService.sharingSurveyService.deleteOwnership({
                survey_code:survey_code,
                email:pic_email,
             })

             if (response.status == 'SUCCESS') {
                dispatch(rootAction.updateMultiRater.deleteOwnershipMR(pic_email))
                setSuccess(true,"Deleted Successfully")

             } else {
                setError(true,"FAILED")
             }
        } catch (error) {
            console.log(error);
            setError(true,"FAILED SYSTEM")
        }finally{

        }
    }


    const onSearchPICData = async (nik) => {
       
        setProgress(10);

        try {
            setProgress(30);
            const response = await adminService.picListService.searchData(1, 1,nik);
            setProgress(60);
            if (response.status == 'SUCCESS') {
               if (response.data != 'NO DATA') {
                 alert('Data NIK tidak ditemukan')
               } else if (Array.isArray(response.data.data) && response.data.data.length == 1) {
                 setPicData(response.data.data[0])
               } else {
                 alert('NIK tidak tepat, coba lagi')
               }
            }
        } catch (error) {
            console.log(error)
        } finally {
            
            setProgress(100);
        }
    }


    return{
        onSearchPICData,
        handleDeletePicProject
    }
}


export default usePICProject