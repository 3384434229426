import { Dropdown } from "react-bootstrap"
import { ButtonPrimary } from "../../../../../../shared/component/button/Button"
import { DropdownOnly } from "../../../../../../shared/component/dropdown/DropdownInput"
import { InputOnlyForDropdown } from "../../../../../../shared/component/input/Input"
import useSelectRatersInternal from "./useSelectRatersInternal"
import React, { useState } from "react"
import useRefs from "react-use-refs"
import { ShowEntries } from "../../../../../../shared/component/table_property/ShowEntries"
import { SearchColumn } from "../../../../../../shared/component/table_property/SearchColumn"
import Pagination from "../../../../../../shared/component/table_property/Paginatination"
import { faArrowsRotate, faUpLong } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import screenToast from "../../../../../../shared/hook/ScreenToast"
import ModalAddEditRaters from "../ModalAddEditRaters"

function SelectRatersInternal({targetParticipantData,scrollToTop, raters, handleOnAddRaters, handleOnDeleteRaters}) {
    const [limit, setLimit] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchBar, setSearchBar] = useState('');
    const [search, setSearch] = useState('');
    const [searchBU, setSearchBU] = useState('');
    const [searchDept, setSearchDept] = useState('');
    const [searchLevel, setSearchLevel] = useState('');
    const [buName, setBUName] = useState('');
    const [deptName, setDeptName] = useState('');
    const [level, setLevel] = useState('');
    const [buNameRef, deptNameRef, levelRef] = useRefs();
    const { employeeData, totalItem, buData, departmentData, levelData,onGetBUData,
        onGetDepartmentData,
        onGetLevelData,
        onFilterRaters } = useSelectRatersInternal({ limit, pageNumber, search, buName, deptName, level });
   const showToast = screenToast();
   const [openModalAdd, setopenModalAdd] = useState(false);
   const [candidateRate, setCandidateRate] = useState();
   




    const handleOnSelect = (ref, val) => {
        ref.current.id == 'business_unit' && setBUName(val);
        ref.current.id == 'business_unit' && setPageNumber(1);

        ref.current.id == 'department' && setDeptName(val);
        ref.current.id == 'department' && setPageNumber(1);

        ref.current.id == 'level' && setLevel(val);
        ref.current.id == 'level' && setPageNumber(1);
    }

    const handleOnClickLimit = (val) => {
        setPageNumber(1);
        setLimit(val);
    }

    const handleOnSubmitSearch = () => {
        setPageNumber(1);
        setSearch(searchBar);
    }

    const handleOnChangeText = (e) => {
        e.target.id == 'search_bar' && setSearchBar(e.target.value);
        e.target.id == 'search_bu' && setSearchBU(e.target.value);
        e.target.id == 'search_dept' && setSearchDept(e.target.value);
        e.target.id == 'search_level' && setSearchLevel(e.target.value);
    }

    const previousPage = () => {
        if (pageNumber != 1) {
            setPageNumber(pageNumber - 1);

        }
    }

    const nextPage = () => {
        if (pageNumber != Math.ceil(totalItem / limit)) {
            setPageNumber(pageNumber + 1);
        }
    }

    const paginate = (pageNumber) => {
        setPageNumber(pageNumber);
    }

    const handleOnCheck = (e, val) => {
        if (e.target.checked) {
            
            
            setCandidateRate({ 
                nik: val.nik,
                full_name: val.full_name,
                email: val.email,
                phone_number: val.phone_number,
                dob:val.dob != '' ? val.dob : null,
                gender:val.gender,
                join_date:val.join_date != '' ? val.join_date : null,
                level: val.level_user,
                position: val.position_user,
                business_unit_name: val.business_unit_name,
                department_name: val.department_name,
                is_internal: 1,
                is_active: 1,
                status: 'not started'
            })
            setopenModalAdd(true)
        }

        if (!e.target.checked) {
        // console.log('uncheck', e.target.checked);
            handleOnDeleteRaters(e,val.nik)
        }
    }

    const handleRefreshEmployeeData = () => {
        let filterData = {
            business_unit_name: buName == 'ALL' ? '' : buName,
            department_name: deptName == 'ALL' ? '' : deptName,
            level: level == 'ALL' ? '' : level,
            search: search,
            limit: limit,
            page_number: pageNumber,
          }
        onFilterRaters(filterData)
    }


    return(
        <div className='card shadow p-4 border-0' style={{marginTop:'20%'}} >
        <div className="pb-4" >
            <div className="w-full justify-content-between my-4" >
                <h5 className="m-0 tw-self-center">Employee List</h5>
                <div className="my-4 d-flex justify-content-between align-items-center">
                    <span className="fst-italic">Please select employees that you would like to add as raters of <strong>{targetParticipantData?.full_name},</strong> by  <strong>click Add button</strong> </span>
                   <div className="d-flex">
                    <div className="me-2">
                       <ButtonPrimary label={<div>
                        Raters Information
                        <FontAwesomeIcon icon={faUpLong} style={{marginLeft:'0.5rem'}}  />
                       </div>} onClick={ scrollToTop} />
                    </div>
                </div>
                 </div>
                
            </div>
            
             {/* Card */}
            <div>

            {/* Header */}
            <div className="col-12">

                {/* Filter */}
                <div className="col-12 d-flex justify-content-between mb-4">
                    <div className="justify-content-center align-items-center">
                        <h6 className="">Filter</h6>
                        <h6>Data by</h6>
                    </div>

                    <div className="col-3 tw-w-[30%]">
                        <div>Business Unit</div>
                        <div>
                            <DropdownOnly
                                id={'business_unit'}
                                innerRef={buNameRef}
                                default_item={Array.isArray(buData) && buData.length > 0 ? (buName != '' ? buData.find((el) => el.business_unit_name == buName)?.business_unit_name : buData.find((el) => el.business_unit_name == 'ALL')?.business_unit_name) : null}
                                handleOpenDropdown={onGetBUData}
                                search_bar={
                                    <InputOnlyForDropdown
                                        id={'search_bu'}
                                        type="text"
                                        placeholder="Search Business Unit"
                                        value={searchBU}
                                        handleOnChange={handleOnChangeText}
                                        input_width={'100%'}
                                    />
                                }
                                dropdown_item={
                                    Array.isArray(buData) && buData.length > 0 ? (searchBU != '' ? (buData.filter((el) => el.business_unit_name != null ? el.business_unit_name.toString().toLowerCase().includes(searchBU.toLowerCase()) : '').map((itm, i) => {
                                        return (
                                            <Dropdown.Item key={i} onClick={() => handleOnSelect(buNameRef, itm.business_unit_name)}>{itm.business_unit_name}</Dropdown.Item>
                                        )
                                    })) :
                                        (buData.map((itm, i) => {
                                            return (
                                                <Dropdown.Item key={i} onClick={() => handleOnSelect(buNameRef, itm.business_unit_name)}>{itm.business_unit_name}</Dropdown.Item>
                                            )
                                        }))
                                    ) :
                                        (<Dropdown.Item>Select</Dropdown.Item>)
                                }
                            />
                        </div>
                    </div>
                    <div className="col-3 tw-w-[30%]">
                        <div>Department</div>
                        <div>
                            <DropdownOnly
                                id={'department'}
                                innerRef={deptNameRef}
                                default_item={Array.isArray(departmentData) && departmentData.length > 0 ? (deptName != '' ? departmentData.find((el) => el.department_name == deptName)?.department_name : departmentData.find((el) => el.department_name == 'ALL')?.department_name) : null}
                                handleOpenDropdown={onGetDepartmentData}
                                search_bar={
                                    <InputOnlyForDropdown
                                        id={'search_dept'}
                                        type="text"
                                        placeholder="Search Department"
                                        value={searchDept}
                                        handleOnChange={handleOnChangeText}
                                        input_width={'100%'}
                                    />
                                }
                                dropdown_item={
                                    Array.isArray(departmentData) && departmentData.length > 0 ? (searchDept != '' ? (departmentData.filter((el) => el.department_name != null ? el.department_name.toString().toLowerCase().includes(searchDept.toLowerCase()) : '').map((itm, i) => {
                                        return (
                                            <Dropdown.Item key={i} onClick={() => handleOnSelect(deptNameRef, itm.department_name)}>{itm.department_name}</Dropdown.Item>
                                        )
                                    })) :
                                        (departmentData.map((itm, i) => {
                                            return (
                                                <Dropdown.Item key={i} onClick={() => handleOnSelect(deptNameRef, itm.department_name)}>{itm.department_name}</Dropdown.Item>
                                            )
                                        }))
                                    ) :
                                        (<Dropdown.Item>Select</Dropdown.Item>)
                                }
                            />
                        </div>
                    </div>
                    <div className="col-3 tw-w-[30%]">
                        <div>Level</div>
                        <div>
                            <DropdownOnly
                                id={'level'}
                                innerRef={levelRef}
                                default_item={Array.isArray(levelData) && levelData.length > 0 ? (level != '' ? levelData.find((el) => el.level_user == level)?.level_user : levelData.find((el) => el.level_user == 'ALL')?.level_user) : null}
                                handleOpenDropdown={onGetLevelData}
                                search_bar={
                                    <InputOnlyForDropdown
                                        id={'search_level'}
                                        type="text"
                                        placeholder="Search Level"
                                        value={searchLevel}
                                        handleOnChange={handleOnChangeText}
                                        input_width={'100%'}
                                    />
                                }
                                dropdown_item={
                                    Array.isArray(levelData) && levelData.length > 0 ? (searchLevel != '' ? (levelData.filter((el) => el.level_user != null ? el.level_user.toLowerCase().includes(searchLevel.toLowerCase()) : '').map((itm, i) => {
                                        return (
                                            <Dropdown.Item key={i} onClick={() => handleOnSelect(levelRef, itm.level_user)}>{itm.level_user}</Dropdown.Item>
                                        )
                                    })) :
                                        (levelData.map((itm, i) => {
                                            return (
                                                <Dropdown.Item key={i} onClick={() => handleOnSelect(levelRef, itm.level_user)}>{itm.level_user}</Dropdown.Item>
                                            )
                                        }))
                                    ) :
                                        (<Dropdown.Item>Select</Dropdown.Item>)
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Table Setting */}
            <div className='justify-content-between mb-4 d-flex flex-row '>
                <div className='col-4 d-flex flex-row align-items-center' >
                    <div>
                        Show
                    </div>
                    <ShowEntries default_item={limit} onClick_item={handleOnClickLimit} />

                    <div className=''>
                        entries
                    </div>
                </div>
                <div className='col-3 d-flex align-items-center'>
                    <div className="d-inline float-end">
                     <div className="tooltips">
                        <button
                            className="me-2 btn btn-light "
                            onClick={()=>handleRefreshEmployeeData()}
                        >
                            <FontAwesomeIcon icon={faArrowsRotate} />
                        </button>
                    <span className="tooltiptext">Reload Data</span>
                        </div>
                    </div>
                    <SearchColumn id={'search_bar'} onChange_input={handleOnChangeText} onClick_btn={handleOnSubmitSearch} />
                </div>
            </div>

            {/* Table */}
            <div className='table-responsive'>
                <table className="table table-striped table-bordered" style={{ tableLayout: 'fixed' }}>
                    <thead>
                        <tr>
                            <th style={{ verticalAlign: 'middle', textAlign: 'center', width: '3rem' }} >&#x2714;</th>
                            <th style={{ verticalAlign: 'middle', textAlign: 'center', width: '4rem' }} >No</th>
                            <th style={{ verticalAlign: 'middle', textAlign: 'center', width: '15rem' }}>Name</th>
                            <th style={{ verticalAlign: 'middle', textAlign: 'center', width: '6rem' }}>NIK</th>
                            <th style={{ verticalAlign: 'middle', textAlign: 'center', width: '27rem' }}>Email</th>
                            <th style={{ verticalAlign: 'middle', textAlign: 'center', width: '4rem' }}>Level</th>
                            <th style={{ verticalAlign: 'middle', textAlign: 'center', width: '20rem' }}>Department</th>
                            <th style={{ verticalAlign: 'middle', textAlign: 'center', width: '20rem' }}>Business Unit</th>

                        </tr>
                    </thead>
                    <tbody>
                        {
                            Array.isArray(employeeData) && employeeData.length > 0 ? (
                                employeeData.map((el, i) => (
                                    <tr key={i} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                        <td >
                                            <div className="form-check d-flex justify-content-center" style={{ scale: '1.5' }}>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    defaultValue=""
                                                    id={`flexCheckDefault`}
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={(e) => handleOnCheck(e, el)}
                                                    checked={Array.isArray(raters) && raters.length > 0 && raters.find((val) => val.nik == el.nik) ? true : false}
                                                    disabled={el.nik == targetParticipantData.nik || el.email == targetParticipantData.email ? true : false}
                                                />
                                            </div>
                                        </td>
                                        <td>{((pageNumber - 1) * limit) + i + 1}</td>
                                        <td style={{ textAlign: 'left' }}>{el.full_name}</td>
                                        <td>{el.nik}</td>
                                        <td style={{ textAlign: 'left' }}>{el.email.toLowerCase()}</td>
                                        <td>{el.level_user}</td>
                                        <td style={{ textAlign: 'left' }}>{el.department_name}</td>
                                        <td style={{ textAlign: 'left' }}>{el.business_unit_name}</td>

                                    </tr>
                                ))
                            ) : (
                                <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                                    <td colSpan={8}>No data</td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>

            {/* Footer */}
            <Pagination showing={Array.isArray(employeeData) ? employeeData.length : 0} totalItem={totalItem} showItem={limit} paginate={paginate} previousPage={previousPage} nextPage={nextPage} limit={pageNumber} />
            </div>

            <ModalAddEditRaters
                type={'ADD'}
                title={'Add Raters Internal'}
                show={openModalAdd}
                size={'lg'}
                onHide={() => setopenModalAdd(false)}
                handleOnSave={handleOnAddRaters}
                data={candidateRate}
                ratersType={1}
            />
        </div>
        </div>
    )
}

export default SelectRatersInternal