import { useEffect, useState } from "react";
import { useDeps } from "../../../shared/context/DependencyContext";
import screenToast from "../../../shared/hook/ScreenToast";
import useViewState from "../../../shared/hook/UseViewState";

function useSharingSurvey(onHide) {
    const {  picService } = useDeps();
    const [selectedPIC, setSelectedPIC] = useState();
    const [message, setMessage] = useState(false);
    const showToast = screenToast();
    const { viewState, setError, setSuccess } = useViewState();

    useEffect(() => {
        if (viewState.isError == true) {
            showToast.error(viewState.errorMsg);
            setError(false, '');
        }

        if (viewState.isSuccess == true) {
            showToast.success(viewState.successMsg);
            setSuccess(false, '');
        }
    }, [viewState]);

    const handleGetPICbyNIK = async (nik) => {
        try {
            const response = await picService.sharingSurveyService.getPICbyNIK(nik)
                
            if (response && response.status == 'SUCCESS') {
                if (response.data != 'NO DATA' || response.data != null) {
                    setSelectedPIC(response.data);
                }

                if (response.data == 'NO DATA' || response.data == null || response.data == undefined) {
                    setMessage(false)
                }else{
                    setMessage(true)
                }
               
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleSharingSurvey = async (survey_code,owner,full_name,no_hp,nik,email,ownership_role,shared_by) => {
       
        try {
            const response = await picService.sharingSurveyService.sharingSurvey({
                survey_code:survey_code,
                owner: owner,
                full_name: full_name,
                no_hp: no_hp,
                nik: nik,
                email: email,
                ownership_role: ownership_role,
                shared_by: shared_by
            })
                
            if (response && response.status == 'SUCCESS') {
                setSuccess(true, 'Shared Successfully')
                setSelectedPIC()
                setMessage(false)
                onHide()
            }else {
                setError(true, response.status)
            }
        } catch (error) {
            console.log(error)

        }finally{
           
        }
    }

    return {
        handleGetPICbyNIK,
        selectedPIC,
        message,
        handleSharingSurvey,
        setMessage,
        setSelectedPIC
    }


    
}

export default useSharingSurvey