export const dataListRater = [
    {
        role:'Peer',
        nik:'19019328',
        name:'Thom Haye',
        email:'thomh@smm.com',
        position:'Product Manager',
    },
    {
      role:'Supervisor',
        nik:'19019329',
        name:'Jay Idzes',
        email:'jayz@smm.com',
        position:'DevOps',
    },
    {
      role:'Subordinate',
        nik:'19019321',
        name:'Rafael Struick',
        email:'rafaels@smm.com',
        position:'Back End Engineer',
    },
    {
      role:'Peer',
        nik:'19019322',
        name:'Jordi Amat',
        email:'jordia@smm.com',
        position:'Front End Engineer',
    }
]

export const thTableListRater = [
    'Relationship','NIK','Name','Email', 'Position'
];

export const filterFieldListRater = [
    {
      field_name:'Relationship',
      field_type:'category',
      field_category:[]
    },{
      field_name:'NIK',
      field_type:'text',
      field_category:[]
    },{
      field_name:'Name',
      field_type:'text',
      field_category:[]
    },{
      field_name:'Email',
      field_type:'text',
      field_category:[]
    },
    {
      field_name:'Position',
      field_type:'text',
      field_category:[]
    },
  
    
  ];
  