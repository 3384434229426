import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import { FormPageBorder } from './FormPageTemplate';
import {
    ButtonOutlinePrimary,
    ButtonPreview
} from '../../shared/component/button/Button';
import TCLogo from '../../assets/images/logo_horizontal_tc.png';
import { getControlId } from './utils/useSection';
import useRouting from './utils/useRouting';
import { CONTENT_SECTIONS as CONTROLS } from './constants';
import { Chapter, SubChapter, Text } from './components/previews/Text';
import RadarChartData from './components/contents/RadarChartData';
import BarChartData from './components/contents/BarChartData';
import DataTable from './components/contents/DataTable';
import CommentData from './components/contents/CommentData';
import DevelopmentForm from './components/previews/DevelopmentForm';
import DemographyData from './components/contents/DemographyData';
import SummaryTable from './components/contents/SummaryTable';
import DisplayDataStatic from './components/previews/DisplayDataStatic';

const data = [
    { label: 'Report for', text: '[Nama Peserta]' },
    { label: 'Business Unit', text: '' },
    { label: 'Department', text: '' },
    { label: 'Position', text: '' },
    { label: 'Creation Date', text: '2024-06-20' }
];

const TemplatePreviewForm = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const reportTemplate = useSelector((state) => state.reportTemplate);
    const { urlListTemplate } = useRouting();

    const handleNavigateBack = (e) => {
        navigate(state.location, { state: { code: state.code } });
    };

    useEffect(() => {
        if (!state) {
            // TODO: bugfix for PIC
            navigate(urlListTemplate());
            return;
        }
    }, []);

    return (
        <Container className="tw-h-max ">
            <Row className="justify-content-md-center">
                <Col md={10}>
                    <div className="d-flex justify-content-end mb-4">
                        <div className="me-2">
                            <ButtonPreview label="PREVIEW PAGE" disabled={true} />
                        </div>
                        <div>
                            <ButtonOutlinePrimary
                                label="Back"
                                onClick={handleNavigateBack}
                            />
                        </div>
                    </div>

                    <FormPageBorder>
                        <div style={{ marginBottom: '150px' }}>
                            <img src={TCLogo} />
                        </div>

                        <div style={{ marginBottom: '150px' }}>
                            <h2>Feedback {reportTemplate.type}</h2>
                        </div>

                        <dl className="row">
                            {data.map((d) => (
                                <>
                                    <dt className="col-sm-3">{d.label}</dt>
                                    <dd className="col-sm-9">: {d.text}</dd>
                                </>
                            ))}
                        </dl>
                    </FormPageBorder>

                    <FormPageBorder className="mt-3">
                        {Array.isArray(reportTemplate.sections) &&
                            reportTemplate.sections.map((section) => {
                                const cid = getControlId(section.section_code);
                                const props = {
                                    contentId: section.section_code,
                                    title: section.title
                                };
                                return (
                                    <div className="mb-3">
                                        {cid === CONTROLS.CHAPTER ? (
                                            <Chapter>{section.content}</Chapter>
                                        ) : cid === CONTROLS.SUB_CHAPTER ? (
                                            <SubChapter>{section.content}</SubChapter>
                                        ) : cid === CONTROLS.TEXT ? (
                                            <Text>{section.content}</Text>
                                        ) : cid === CONTROLS.DATA ? (
                                            <DisplayDataStatic {...props} ordered={true} />
                                        ) : cid === CONTROLS.RADAR_CHART_DATA ? (
                                            <RadarChartData
                                                {...props}
                                                defaultTheme={section.theme}
                                            />
                                        ) : cid === CONTROLS.BAR_CHART_DATA ? (
                                            <BarChartData
                                                {...props}
                                                defaultTheme={section.theme}
                                            />
                                        ) : cid === CONTROLS.DATA_TABLE ? (
                                            <DataTable {...props} />
                                        ) : cid === CONTROLS.DEVELOPMENT_FORM ? (
                                            <DevelopmentForm {...props} />
                                        ) : cid === CONTROLS.COMMENTS_DATA ? (
                                            <CommentData {...props} />
                                        ) : cid === CONTROLS.DEMOGRAPHY_DATA ? (
                                            <DemographyData {...props} />
                                        ) : cid === CONTROLS.SURVEY_DATA ? (
                                            <DisplayDataStatic {...props} />
                                        ) : cid === CONTROLS.SUMMARY_TABLE ? (
                                            <SummaryTable {...props} />
                                        ) : null}
                                    </div>
                                );
                            })}
                    </FormPageBorder>
                </Col>
            </Row>
        </Container>
    );
};

export default TemplatePreviewForm;
