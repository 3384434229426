export const RatersHistoryService = ({  doPost }) => {
    

    const getWithSearchRatersHistory = async (search_text,limit,page_number) => {
        try {
            return await doPost({
                url: '/admin/view_raters_history',
                data: {
                        search: search_text,
                        limit: limit,
                        page_number: page_number
                      
                  },                  
            });
        } catch (e) {
            throw e;
        }
    };

    const getWithSearchRatersHistoryPICUrl = async (search_text,limit,page_number) => {
        try {
            return await doPost({
                url: '/pic/view_raters_history',
                data: {
                        search: search_text,
                        limit: limit,
                        page_number: page_number
                      
                  },                  
            });
        } catch (e) {
            throw e;
        }
    };


    return {
        getWithSearchRatersHistory,
        getWithSearchRatersHistoryPICUrl
    };
};