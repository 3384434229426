import React from 'react';

const Header = ({ serverTime,onDownload }) => {
  return (
    <div className="header">
      <h1>Dashboard</h1>
      <p>Time Server: {serverTime}</p>
      <button onClick={onDownload}>Download PDF</button>
    </div>
  );
};

export default Header;