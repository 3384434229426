import { configureStore } from "@reduxjs/toolkit";
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from "redux-persist";
import storage from 'redux-persist/lib/storage';
import { rootReducer } from "./RootReducer";

const persistConfigLocal = {
   key: 'root',
   version: 1,
   storage: storage
}

const persistedReducer = persistReducer(persistConfigLocal, rootReducer());

export function Store() {
   let store = configureStore({
      reducer: persistedReducer,
      middleware: (getDefaultMiddleware) =>
         getDefaultMiddleware({
            serializableCheck: {
               ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
         }),
   });
   let persistore = persistStore(store);
   return { store, persistore }
}