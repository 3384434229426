import React, { useEffect, useState } from 'react'
import { useDeps } from '../../../shared/context/DependencyContext'
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import screenToast from '../../../shared/hook/ScreenToast';
import useViewState from '../../../shared/hook/UseViewState';
import { CONSTANTS } from '../../../common/constants';
import screenAlert from '../../../shared/hook/SweetAlert';

const useMultiraterList = () => {
    const {adminService ,picService} = useDeps();
    const [allSurvey, setAllSurvey] = useState([]);
    const [showItem, setShowItem] = useState(10);
    const [changeData, setChangeData] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [totalItem, setTotalItem] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const user = useSelector((state) => state.user);
    const showToast = screenToast();
    const { viewState, setError, setSuccess } = useViewState();
    const [progress, setProgress] = useState(0);
    const showAlert = screenAlert();
    const [loading,setLoading] = useState(false)


    useEffect(() => {
        if (pageNumber == 1) {
          onGetMultiraterList(searchText,showItem,pageNumber);
        }else{
          setPageNumber(1);
        }
        

    }, [showItem, changeData])

    useEffect(() => {
      onGetMultiraterList(searchText,showItem,pageNumber);
    }, [pageNumber])

    useEffect(() => {
      if (viewState.isError == true) {
          showToast.error(viewState.errorMsg);
          setError(false, '');
      }

      if (viewState.isSuccess == true) {
          showToast.success(viewState.successMsg);
          setSuccess(false, '');
      }
    }, [viewState]);

    const onGetMultiraterList = async (search,limit,page_number) => {
      setLoading(true)
      setProgress(10);
        try {
          setProgress(30);
          if (user.role == CONSTANTS.ROLE.ADMIN) {
            const response = await adminService.multiRaterService.getMultiraterListData(search,limit,page_number,user.email);
            setProgress(60);
            
            if (response.status == 'SUCCESS') {
                setAllSurvey(response.data.data);
                setTotalItem(response.data.total_item)
              
            }
          } else {
            const response = await picService.multiRaterService.getMultiraterListDataPICUrl(search,limit,page_number,user.email);
            setProgress(60);
            
            if (response.status == 'SUCCESS') {
                setAllSurvey(response.data.data);
                setTotalItem(response.data.total_item)
                // console.log(response.data.data);
              
            }
          }
            
        } catch (error) {
            console.log(error)
         
        } finally {
          setProgress(100);
          setLoading(false)
        }
    }

    const onDeleteSurvey = async (survey_code) => {
      try {
        if (user.role == CONSTANTS.ROLE.ADMIN) {
            const response = await adminService.multiRaterService.deleteMultiraterSurvey(survey_code);

            if (response.status == 'SUCCESS') {
              setChangeData(!changeData);
              setSuccess(true,'Deleted Successfully')

            }else {
                setError(true,response.status)
            }
        } else {
            const response = await picService.multiRaterService.deleteMultiraterSurveyPICUrl(survey_code);

          
              if (response.status == 'SUCCESS') {
                setChangeData(!changeData);
                setSuccess(true,'Deleted Successfully')
              }else {
                  setError(true,response.status)
              }
        }
        
      } catch (error) {
      console.log(error)
      setError(true,'Error System')
        } finally {
            
        }
    }

    const onSendInvitationtoAllRaters = async (survey_code) => {
      try {
        if (user.role == CONSTANTS.ROLE.ADMIN) {
          const response = await adminService.multiRaterService.sendInvitationtoAllRaters(survey_code);

        
          if (response.status == 'SUCCESS') {
            setChangeData(!changeData);
            setSuccess(true,'Invitation Sent')
          }else {
              setError(true,response.status)
          }
        } else {
              const response = await picService.multiRaterService.sendInvitationtoAllRatersPICUrl(survey_code);

            
            if (response.status == 'SUCCESS') {
              setChangeData(!changeData);
              setSuccess(true,'Invitation Sent')
            }else {
                setError(true,response.status)
            }
        }
        
      } catch (error) {
      console.log(error)
      setError(true,'Error System')
        } finally {
            
        }
    }

    const handleSearchBar = () => {
      onGetMultiraterList(searchText,showItem,pageNumber);
    }

    const handleChangeSearchText = (e) => {
        setSearchText(e.target.value);
    }

    const handleShowItem = (item) => {
      setShowItem(item);
    }

    const handleDeleteSurvey = (survey_code) => {
      showAlert.warning({
        text: "Are you sure to delete this?",
        buttons: {
            cancel: {
                text: "Cancel",
                className: ""
            },
            confirm: {
                text: "Yes",
                value: true,
                className: "bg-success"
            }
        }
        }).then(value => {
            if (value) {
              onDeleteSurvey(survey_code);
            }
        });
  }

 
    return{
      allSurvey,
      pageNumber,
      setPageNumber,
      searchText,
      setSearchText,
      showItem,
      setShowItem,
      totalItem,
      handleChangeSearchText,
      handleDeleteSurvey,
      handleSearchBar,
      handleShowItem,
      onSendInvitationtoAllRaters,
      progress,
      setProgress,
      loading

    }
}

export default useMultiraterList;


