import { Col, Modal } from "react-bootstrap";
import { ButtonPrimary } from "../../../../shared/component/button/Button";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function ModalRelationship({show,onHideModal,handleSendInvitation,dataRater}) {
    const [roleName, setRoleName] = useState("Stakeholder");
    const [roleVal, setroleVal] = useState(5);
  const seeRaterData = useSelector((state) => state.data_see_raters);



    const handleCloseReset = () => {
        onHideModal();
    }

    useEffect(() => {
            
        // roleVal == 2 && setRoleName("Supervisor");
        // roleVal == 3 && setRoleName("Subordinate");
        // roleVal == 4 && setRoleName("Peer") ;
        // roleVal == 5 && setRoleName("Stakeholder");
        

        console.log('ROLE VAL NYAAA', roleVal);
        console.log('ROLE NAME NYAAA', roleName);

        
    }, [roleVal,roleName])
    

    const handleAddandSendInvitation = () => {
        handleSendInvitation(seeRaterData.tp_name,dataRater.full_name,roleName,roleVal)
    } 

    const handleOnChangeRole = (e) => {
        setroleVal(e.target.value)
        e.target.value == 2 && setRoleName("Supervisor");
        e.target.value == 3 && setRoleName("Subordinate");
        e.target.value == 4 && setRoleName("Peer") ;
        e.target.value == 5 && setRoleName("Stakeholder");
    }

    return(
        <Modal
        size={'sm'}
        show={show}
        onHide={()=>handleCloseReset()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        
      >
        <Modal.Header closeButton style={{borderRadius:0}}>
          <Modal.Title id="contained-modal-title-vcenter">
              Select Relationship 
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="ps-4" style={{borderBottomColor:'white'}}>
                        <div className="form-check mt-2"  style={{cursor:'pointer'}}>
                            <input
                            className="form-check-input"
                            type="radio"
                            name="relationship"
                            id="supervisor"
                            value={2}
                            onChange={handleOnChangeRole}
                            style={{cursor:'pointer'}}
                            />
                            <label className="form-check-label" htmlFor="supervisor" >
                            Supervisor
                            </label>
                        </div>

                        <div className="form-check mt-2" style={{cursor:'pointer'}}>
                            <input
                            className="form-check-input"
                            type="radio"
                            name="relationship"
                            id="subordinate"
                            value={3}
                            onChange={handleOnChangeRole}
                            style={{cursor:'pointer'}}
                            />
                            <label className="form-check-label" htmlFor="subordinate"  >
                            Subordinate
                            </label>
                        </div>

                        <div className="form-check mt-2" style={{cursor:'pointer'}}>
                            <input
                            className="form-check-input"
                            type="radio"
                            name="relationship"
                            id="peer"
                            value={4}
                            onChange={handleOnChangeRole}
                            style={{cursor:'pointer'}}
                            />
                            <label className="form-check-label" htmlFor="peer"  >
                            Peer
                            </label>
                        </div>

                        <div className="form-check mt-2" style={{cursor:'pointer'}}>
                            <input
                            className="form-check-input"
                            type="radio"
                            name="relationship"
                            id="stakeholder"
                            value={5}
                            defaultChecked
                            onChange={handleOnChangeRole}
                            style={{cursor:'pointer'}}
                            />
                            <label className="form-check-label" htmlFor="stakeholder"  >
                            Stakeholder
                            </label>
                        </div>

        </Modal.Body>
      
        <Modal.Footer className="p-1">
            <ButtonPrimary label="Send Invitation" onClick={()=>handleAddandSendInvitation()}/>
        </Modal.Footer>
      </Modal>
    )
}

export default ModalRelationship