import { useEffect, useState } from "react";
import SingleRaterQuestionView from "./SingleRaterQuestionView";
import SectionCard from "../../../../../shared/component/card/SectionCard";
import { Footer } from "../../../../layout/navbar/Footer";

function SingleSurveyContentView({ sectionPageIndex, handleNextPage, handlePrevPage, surveyDetailData }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);


  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
    
        // Membersihkan listener ketika komponen di-unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
    
    return (
        <div>
                <div 
                  className={`tw-p-4 tw-py-4 tw-pb-[5rem]  tw-w-full tw-justify-center tw-h-full tw-overflow-auto tw-bg-slate-50`} 
                  >
                  <SectionCard
                        titleCard={surveyDetailData.section[sectionPageIndex].section_name} 
                        descCard={surveyDetailData.section[sectionPageIndex].description_section} 
                  />
                  <SingleRaterQuestionView
                        content={surveyDetailData.section[sectionPageIndex].content} 
                        widthWindow={windowWidth} 
                        sectionCode={surveyDetailData.section[sectionPageIndex].section_code} 
                        languageItem={surveyDetailData.setting.language}
                        sectionName={surveyDetailData.section[sectionPageIndex].section_name}
                        />
                </div>

      <Footer
        disablePrev={false} disableNext={false} onClickNext={() => handleNextPage()} onClickPrev={() => handlePrevPage()} />

           
        </div>
    )
}

export default SingleSurveyContentView;
