import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSpreadsheetImport } from 'react-spreadsheet-import';
import { openSpreadsheetImport, closeSpreadsheetImport, setImportedData, validateSpreadsheetData } from "../../../../../redux/SpreadSheet/SpreadSheetAction";
import { CONSTANTS } from "../../../../../common/constants";
import useImportFile from "./useImportFile";
import { rootAction } from "../../../../../redux/RootAction";
import screenAlert from '../../../../../shared/hook/SweetAlert';

function ImportFile ({ isOpen,onClose,onSubmit})  {
    const dispatch = useDispatch();
    const showAlert = screenAlert();
    const { niks, alt } = useImportFile();
   
    // Define fields
    const fields = [
        {
            label: "NIK",
            key: "nik",
            alternateMatches: ["NOMOR INDUK KEPENDUDUKAN", "NIK"],
            fieldType: {
                type: "input",
            },
            example: "00021003",

        },
        {
            label: "Name",
            key: "full_name",
            alternateMatches: ["name", "first"],
            fieldType: {
                type: "input",
            },
            example: "Aditya",
            validations: [
                {
                    rule: "required",
                    errorMessage: "Name is required",
                    level: "error",
                },
            ],
        },
        {
            label: "Email",
            key: "email",
            alternateMatches: ["email", "email"],
            fieldType: {
                type: "input",
            },
            example: "adityapratamabadra@gmail.com",
            validations: [
                {
                    rule: "required",
                    errorMessage: "Email is required",
                    level: "error",
                },
                {
                    rule: "regex",
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    errorMessage: "Invalid email format",
                    level: "error",
                },
            ],
        },
        {
            label: "Phone Number",
            key: "no_hp",
            alternateMatches: ["phone number", "nomor telepon"],
            fieldType: {
                type: "input",
            },
            example: "08565441111",
        },
        {
            label: "Gender",
            key: "gender",
            alternateMatches: ["gender", "jenis kelamin"],
            fieldType: {
                type: "input",
            },
            example: "LAKI-LAKI",
        },
        {
            label: "Date Of Birth",
            key: "dob",
            alternateMatches: ["dob", "Date Of Birth"],
            fieldType: {
                type: "input",
            },
            example: "LAKI-LAKI",
        },
        {
            label: "Position",
            key: "position",
            alternateMatches: ["Position", "posisi"],
            fieldType: {
                type: "input",
            },
            example: "HRGA Department",
        },
        {
            label: "Business Unit Name",
            key: "business_unit_name",
            alternateMatches: ["business_name", "Business Unit","BU"], 
            fieldType: {
                type: "input",
            },
            example: "Business",
        },
        {
            label: "Department",
            key: "department_name",
            alternateMatches: ["department", "department_name"],
            fieldType: {
                type: "input",
            },
            example: "HRGA Department",
        },
        {
            label: "level",
            key: "level",
            alternateMatches: ["level", "level"],
            fieldType: {
                type: "input",
            },
            example: "A1",
        },
        {
            label: "Join Date",
            key: "join_date",
            alternateMatches: ["join date", "level"],
            fieldType: {
                type: "input",
            },
            example: "2009-01-20",

        },
        {
            label: "Participant",
            key: "participant",
            alternateMatches: ["participant", "first"],
            fieldType: {
                type: "input",
            },
            example: "1",
        },
    ];

      // Helper function to convert empty strings to null
      const convertEmptyOrUndefinedToNull = (data) => {
        const result = {};
        for (const key in data) {
            result[key] = (data[key] === '' || data[key] === undefined) ? null : data[key];
        }
        return result;
    };



    // Hook to handle row data and errors
    const rowHook = (data, addError) => {
        // console.log("Row data before processing:", data);
        let participantData = {};
        let isParticipantFound = false;

        // Convert empty strings to null
        data = convertEmptyOrUndefinedToNull(data);

        // console.log("test convert ",data)
        // Step 1: Check email in user_mgmt
        const user = niks.find(u => u.email === data.email);
        // console.log("data user",user)
        if (user) {
            participantData = { 
                ...user, 
                participant: user.is_internal === 1 ? 'Internal' : 'Eksternal', 
            business_unit_name: data.business_unit_name 
         };
            addError("email", { message: "Email is registered in u_users_mgmt and participant information added", level: "info" });
        //    console.log("participant cek email dan ketemu", participantData)
            isParticipantFound = true;
        } else {
            // Step 1.1: Check email in user_mgmt_alt
            const altUser = alt.find(alt => alt.email === data.email);
            if (altUser) {
                const mainUser = niks.find(u => u.id === altUser.u_users_mgmt_id);
                if (mainUser) {
                    participantData = { 
                        ...mainUser, 
                        email: data.email, 
                        participant: mainUser.is_internal === 1 ? 'Internal' : 'Eksternal',
                    business_unit_name: data.business_unit_name 
                };
                    addError("email", { message: "Email is registered in u_users_mgmt_alt and participant information added", level: "info" });
                    isParticipantFound = true;
                }
            }
        }

        // Step 2: If email is not found, check NIK in user_mgmt
        if (!isParticipantFound && data.nik) {
            const userByNik = niks.find(u => u.nik === data.nik);
            // console.log("user By NIK", userByNik)
            if (userByNik) {
                participantData = { ...userByNik, 
                    email: data.email, 
                    participant: userByNik.is_internal === 1 ? 'Internal' : 'Eksternal',
                    business_unit_name: data.business_unit_name };
                addError("nik", { message: "NIK found and participant information added", level: "info" });
                isParticipantFound = true;
            } else {
                addError("nik", { message: "NIK not found in u_users_mgmt", level: "warning" });
            }
        }

        // Step 3: If neither email nor NIK is found, check mandatory fields
        if (!isParticipantFound) {
            const mandatoryFields = ['email', 'full_name'];
            mandatoryFields.forEach(field => {
                if (!data[field]) {
                    addError(field, { message: `${field} is mandatory`, level: "error" });
                }
            });
            
            // Set default participantData for external participants
            participantData = {
                ...data,
                participant: 'Eksternal',
                is_internal: 0,
                role_in_survey_id: 1,
                is_active: 1,
                raters: [
                    {
                        ...data,
                        participant: 'Eksternal',
                        is_internal: 0,
                        role_in_survey_id: 1,
                        is_active: 1,
                        status: 'not started'
                    }
                ]
            };
        }
        // console.log("Processed participantData:", participantData);  // Log processed data for debugging

        // Combine participantData with original data
        return { ...data, ...participantData };
    };

    // Handler for opening the import dialog
    const handleOpenImport = () => {
        dispatch(openSpreadsheetImport());
    };

    // Handler for closing the import dialog
    const handleCloseImport = () => {
        dispatch(closeSpreadsheetImport());
        onClose();
    };

    return (
        <div>
            
            <ReactSpreadsheetImport
                isOpen={isOpen}
                fields={fields}
                onClose={onClose}
                onSubmit={(data) => {
                const { validData } = data;
                if (validData && validData.length > 0) {
                    onSubmit(validData);
                } else {
                    console.error('No valid data to submit'); // Debug log
                }
            }}
                rowHook={rowHook}
            />
        </div>
    );
};

export default ImportFile;
