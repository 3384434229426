import { Table } from 'react-bootstrap';
import { CONTENTS } from '../../constants';

const {
    MRI_TABLE_ITEM,
    MRI_TABLE_HIGHEST,
    MRI_TABLE_LOWEST,
    MRI_TABLE_BLINDSPOT,
    MRI_TABLE_HIDDENSTR,
    MRI_TABLE_ACTSTR,
    MRI_TABLE_ACTWEAK,
    MRG_TABLE_ITEM,
    MRG_TABLE_HIGHEST,
    MRG_TABLE_LOWEST,
    MRG_TABLE_BLINDSPOT,
    MRG_TABLE_HIDDENSTR,
    MRG_TABLE_ACTSTR,
    MRG_TABLE_ACTWEAK
} = CONTENTS;

const DataTable = ({ contentId, title }) => {
    const props = { contentId: contentId };

    return (
        <>
            {(contentId.startsWith(MRI_TABLE_ITEM) ||
                contentId.startsWith(MRG_TABLE_ITEM)) && (
                <ItemScore {...props} />
            )}
            {(contentId.startsWith(MRI_TABLE_HIGHEST) ||
                contentId.startsWith(MRG_TABLE_HIGHEST)) && (
                <HighestLowestScore {...props} />
            )}
            {(contentId.startsWith(MRI_TABLE_LOWEST) ||
                contentId.startsWith(MRG_TABLE_LOWEST)) && (
                <HighestLowestScore {...props} />
            )}
            {(contentId.startsWith(MRI_TABLE_BLINDSPOT) ||
                contentId.startsWith(MRG_TABLE_BLINDSPOT)) && (
                <BlindSpotScore {...props} />
            )}
            {(contentId.startsWith(MRI_TABLE_HIDDENSTR) ||
                contentId.startsWith(MRG_TABLE_HIDDENSTR)) && (
                <HiddenStrengthScore {...props} />
            )}
            {(contentId.startsWith(MRI_TABLE_ACTSTR) ||
                contentId.startsWith(MRG_TABLE_ACTSTR)) && (
                <ActionForStrength {...props} />
            )}
            {(contentId.startsWith(MRI_TABLE_ACTWEAK) ||
                contentId.startsWith(MRG_TABLE_ACTWEAK)) && (
                <ActionForWeakness {...props} />
            )}
        </>
    );
};

const ItemScore = ({ contentId }) => {
    const defaultColumn = ['No.', 'Aspect', 'Item', 'Self'];
    let column = [];
    let dataCol = [];

    switch (contentId) {
        case MRI_TABLE_ITEM + '.1':
        case MRG_TABLE_ITEM + '.1':
            column = [...defaultColumn, 'Others', 'All'];
            dataCol = [11, 11];
            break;
        case MRI_TABLE_ITEM + '.2':
        case MRG_TABLE_ITEM + '.2':
            column = [
                ...defaultColumn,
                'Supervisor',
                'Subordinate',
                'Peer',
                'All'
            ];
            dataCol = [11, 11, 11, 11];
            break;
        case MRI_TABLE_ITEM + '.3':
        case MRG_TABLE_ITEM + '.3':
            column = [
                ...defaultColumn,
                'Supervisor',
                'Subordinate & Peer',
                'All'
            ];
            dataCol = [11, 11, 11];
            break;
        case MRI_TABLE_ITEM + '.4':
        case MRG_TABLE_ITEM + '.4':
            column = [...defaultColumn, 'Supervisor', 'Subordinate', 'Peer'];
            dataCol = [11, 11, 11];
            break;
        default:
            break;
    }
    column = [...column, 'Gap', 'Hidden/Blind'];

    const data = [
        ['1', 'Aspect 1', 'Item 1', 11, ...dataCol, 0, 0],
        ['2', 'Aspect 1', 'Item 2', 11, ...dataCol, 0, 0]
    ];

    return <CreateTable column={column} data={data} />;
};

const HighestLowestScore = ({ contentId }) => {
    const column = ['No.', 'Item', 'Aspect', 'Dimension', 'Score'];
    const num = parseInt(contentId.split('.')[3]) + 2;
    const data = [...Array(num).keys()].map((n) => {
        const isHighest =
            contentId.startsWith(MRI_TABLE_HIGHEST) ||
            contentId.startsWith(MRG_TABLE_HIGHEST);
        let item = n + 1;
        let score = isHighest
            ? 5.9 - parseFloat('0.' + item)
            : 5.1 + parseFloat('0.' + item);
        return [
            item,
            'Item ' + item,
            'Aspect 1',
            'Dimension 1',
            score.toFixed(2)
        ];
    });

    return <CreateTable column={column} data={data} />;
};

const BlindSpotScore = ({ contentId }) => {
    const column = [
        'No.',
        'Aspect',
        'Item',
        'Self',
        'Others',
        'All',
        'Gap',
        'Dimension'
    ];
    const num = parseInt(contentId.split('.')[3]) + 2;
    const data = [...Array(num).keys()].map((n) => {
        let item = n + 1;
        return [
            item,
            'Aspect 1',
            'Item ' + item,
            0.5,
            0.5,
            1.0,
            -0.5,
            'Dimension 1'
        ];
    });

    return <CreateTable column={column} data={data} />;
};

const HiddenStrengthScore = ({ contentId }) => {
    const column = [
        'No.',
        'Aspect',
        'Item',
        'Self',
        'Others',
        'All',
        'Gap',
        'Dimension'
    ];
    const num = parseInt(contentId.split('.')[3]) + 2;
    const data = [...Array(num).keys()].map((n) => {
        let item = n + 1;
        return [
            item,
            'Aspect 1',
            'Item ' + item,
            0.5,
            0.5,
            1.0,
            0.5,
            'Dimension 1'
        ];
    });

    return <CreateTable column={column} data={data} />;
};

const ActionForStrength = ({ contentId }) => {
    const column = ['No.', 'Action', 'Aspect', 'Dimension'];
    const num = parseInt(contentId.split('.')[3]) + 2;
    const data = [...Array(num).keys()].map((n) => {
        let item = n + 1;
        return [item, 'Action ' + item, 'Aspect 1', 'Dimension 1'];
    });

    return <CreateTable column={column} data={data} />;
};

const ActionForWeakness = ({ contentId }) => {
    const column = ['No.', 'Action', 'Aspect', 'Dimension'];
    const num = parseInt(contentId.split('.')[3]) + 2;
    const data = [...Array(num).keys()].map((n) => {
        let item = n + 1;
        return [item, 'Action ' + item, 'Aspect 1', 'Dimension 1'];
    });

    return <CreateTable column={column} data={data} />;
};

const CreateTable = ({ column, data }) => {
    return (
        <Table striped bordered hover size="sm" className="fw-light">
            <thead>
                <tr>
                    {Array.isArray(column) && column.map((c) => <th>{c}</th>)}
                </tr>
            </thead>
            <tbody>
                {Array.isArray(data) &&
                    data.map((row, rowId) => (
                        <tr>
                            {Array.isArray(row) &&
                                row.map((col, colId) => (
                                    <td>{data[rowId][colId]}</td>
                                ))}
                        </tr>
                    ))}
            </tbody>
        </Table>
    );
};

export default DataTable;
