import { useEffect, useState } from "react";
import {useDeps} from "../../../shared/context/DependencyContext"
import { useSelector } from "react-redux";

const useSingleraterSurveyList = () => {
    const { participantService } = useDeps();
    const [singleRaterList, setSingleRatersList] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalItem, setTotalItem] = useState(0);
    const [limit, setLimit] = useState(10);
    const [searchText, setSearchText] = useState("");
    const rater = useSelector((state)=>state.user);
  
  
  
    useEffect(() => {
      if (pageNumber == 1) {
        onGetAllSingleRatersList(limit,pageNumber,searchText)
      }
      setPageNumber(1);
  
      }, [limit])
  
      useEffect(() => {
        onGetAllSingleRatersList(limit,pageNumber,searchText)
      }, [pageNumber])
  
  
    const handleInputSearchChange = (e) => {
      setSearchText(e.target.value);
    };
  
    const handleSearchData = () => {
      if (pageNumber == 1) {
        onGetAllSingleRatersList(limit,pageNumber,searchText)
      } else {
        setPageNumber(1);
      }
    };
  
    const onGetAllSingleRatersList = async (
      limit,
      page_number,
      search_bar
    ) => {
      try {
        const response =
          await participantService.singleRaterListService.getAllSingleRatersList(
            limit,
            page_number,
            search_bar,
            rater.nik
          );
  
        if (response.status == "SUCCESS") {
          setSingleRatersList(response.data.data);
          setTotalItem(response.data.total_item);
        }
      } catch (error) {
        console.log(error);
      } finally {
        // console.log("finally");
      }
    };
  
    const handleshowItem = (item) => {
      setLimit(item);
  }
  
    return {
      handleInputSearchChange,
      handleSearchData,
      onGetAllSingleRatersList,
      setSingleRatersList,
      handleshowItem,
      limit,
      singleRaterList,
      totalItem,
      setPageNumber,
      pageNumber,
      searchText
    };
  };

  export default useSingleraterSurveyList