import { Row } from "react-bootstrap";
import "../../../../participant_features/multirater_survey_form/components/BagianSatu.css";
import { SingleRaterAnswerCard } from "../../../../../shared/component/card/SingleRaterAnswerCard"
import { useSelector } from "react-redux";
import { useEffect } from "react";

// Looping Card Si question disini

function SingleRaterQuestionView({
  content,
  widthWindow,
  sectionCode,
  languageItem,
  sectionName,
}) {
  const manyAnswerReducerSR = useSelector((state) => state.insertManyAnswerSR);

  return (
    <div>
      {Array.isArray(content) &&
        content.map((oneContent, index) => (
          oneContent.is_mandatory == 1 ?
          <div className="question-card-mandatory" key={index}>
          <div className="d-flex justify-content-center mt-1 mx-1" style={{fontWeight:'bold',fontSize:'1rem',backgroundColor:'red',color:'white',width:'7rem',borderRadius:'0.5rem'}}>
          Mandatory
          </div>
          
           <div  className="d-flex">
          
              {languageItem == 'en' && oneContent.item_in_eng != '' ? <div className="isi-text-question" >
                {oneContent.item_in_eng}
                <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word", maxWidth: "100%", overflowX: "auto" , fontFamily:'Segoe UI', marginTop:'1rem'}}>{oneContent.help}</pre>

              </div> :
                languageItem == 'main' && oneContent.item != '' ?
                  <div className="isi-text-question" >
                    {oneContent.item}
                    <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word", maxWidth: "100%", overflowX: "auto" , fontFamily:'Segoe UI', marginTop:'1rem'}}>{oneContent.help}</pre>

                  </div> :
                  <div className="isi-text-question" >
                    <div>{oneContent.item} </div>
                    <div style={{ fontStyle: 'italic' }}>{oneContent.item_in_eng}</div>
                    <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word", maxWidth: "100%", overflowX: "auto" , fontFamily:'Segoe UI', marginTop:'1rem'}}>{oneContent.help}</pre>

                  </div>
              }
              

              <div className="pilihan-question">
                <Row>
                  <SingleRaterAnswerCard
                    sectionCode={sectionCode}
                    sectionName={sectionName}
                    widthWindow={widthWindow}
                    content={oneContent}
                    oldContent={manyAnswerReducerSR.filter(e => e.section_code == sectionCode && e.content_code == oneContent.content_code)}
                  />
                </Row>
              </div>
          
          
           </div>
        </div>
          :
          <div className="question-card" key={index}>
           
            {languageItem == "en" && oneContent.item_in_eng != "" ? (
              <div className="isi-text-question">
                {oneContent.item_in_eng}
                <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word", maxWidth: "100%", overflowX: "auto" , fontFamily:'Segoe UI', marginTop:'1rem'}}>{oneContent.help}</pre>

              </div>
            ) : languageItem == "main" && oneContent.item != "" ? (
              <div className="isi-text-question">
                {oneContent.item}
                <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word", maxWidth: "100%", overflowX: "auto" , fontFamily:'Segoe UI', marginTop:'1rem'}}>{oneContent.help}</pre>

                </div>
            ) : (
              <div className="isi-text-question">
                <div>{oneContent.item}</div>
                <div style={{ fontStyle: "italic" }}>
                  {oneContent.item_in_eng}
                </div>
                <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word", maxWidth: "100%", overflowX: "auto" , fontFamily:'Segoe UI', marginTop:'1rem'}}>{oneContent.help}</pre>

              </div>
            )}

            <div className="pilihan-question">
              <Row>
                <SingleRaterAnswerCard
                  sectionCode={sectionCode}
                  sectionName={sectionName}
                  widthWindow={widthWindow}
                  content={oneContent}
                  oldContent={manyAnswerReducerSR.filter(
                    (e) =>
                      e.section_code == sectionCode &&
                      e.content_code == oneContent.content_code
                  )}
                />
              </Row>
            </div>
          </div>
        ))}
    </div>
  );
}

export default SingleRaterQuestionView;
