import { Modal } from "react-bootstrap";
import { ButtonPrimary } from "../button/Button";
import {   InputTextWithLabel, InputWithLabel, InputWithLabelButton } from "../input/Input";
import { useState } from "react";
import { useEffect } from "react";


function ModalAddUser({title,disableFromNIK,onHide,size,show,onChangeNIK,onClick_CheckNIK,onChangeEmail,onChangeNumber,onSave,value_name,value_email,value_department,value_bu,value_number}) {
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [numberErrorMessage, setNumberErrorMessage] = useState('');
  const [disableSave,setDisableSave] = useState(false);

  const validateUserEmail =  () => {
    let emailErrorMessage = ''
    if (!value_email.match(/^[a-zA-Z0-9_.-]+@([a-zA-Z0-9_-]+\.)+[A-Za-z]+$/)){
      setDisableSave(true);
        if (value_email == 0 || value_email == '') {
            emailErrorMessage = `Email can't be empty!`
        }
        else {
            emailErrorMessage = 'Invalid email format'
        }
    } else{
      setDisableSave(false);
    }
     setEmailErrorMessage(emailErrorMessage);
    
  }

  const validateUserNumber =  () => {
    let numberErrorMessage = '';
    if (!value_number.match(/^[0-9]{9,15}$/)) {
      setDisableSave(true);
        if (value_number == 0 || value_number == '') {
            numberErrorMessage = `Number can't be empty!`
        }
        else if (value_number.length < 10) {
          numberErrorMessage= 'Min number length is 9 characters'
        } else if (value_number.length > 15) {
          numberErrorMessage= 'Max number length is 15 characters'
        } 
        else {
            numberErrorMessage = 'Invalid number format'
        }
        
    }else {
      setDisableSave(false);
    }
    setNumberErrorMessage(numberErrorMessage);

    
  }


  useEffect(() => {
    validateUserEmail();
  },[value_email])

  useEffect(() => {
    validateUserNumber();
  },[value_number])

  return(
        <Modal
          show={show}
          size={size}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={onHide}
          
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
             {title} 
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-2" >
             <InputWithLabelButton 
                label={'NIK'} 
                class_btn={'btn btn-success'} 
                label_button={'Check NIK'} style_btn={{borderRadius:0,height:'100%',color:'white'}}
                onChange_input={onChangeNIK}
                onClick_btn={onClick_CheckNIK}
                />
            </div>

            <div className="mb-2">
              <InputTextWithLabel label='Name' id={'name'}  value={value_name} disabled={true}/>
            </div>

            <div className="mb-2">
              <InputWithLabel label='Email' id={'email'} handleOnChange={onChangeEmail} type={'email'} value={value_email}/>
              {disableFromNIK ? <></> : <div className="d-flex justify-content-end text-danger" style={{fontSize:'0.8rem'}}>
                  {emailErrorMessage}
              </div>}
               
            </div>
            

            <div className="mb-2">
              <InputWithLabel label='Number' id={'number'} handleOnChange={onChangeNumber}  type={'text'} value={value_number} />
              {disableFromNIK ? <></> : <div className="d-flex justify-content-end text-danger" style={{fontSize:'0.8rem'}}>
                  {numberErrorMessage}
              </div>}
              
            </div>
           

            <div className="mb-2">
              <InputTextWithLabel label='Business Unit' id={'bu'} value={value_bu}  disabled={true}/>
            </div>
            <div className="mb-2">
              <InputTextWithLabel label='Department' id={'department'}  value={value_department}  disabled={true} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <ButtonPrimary onClick={onSave} label='Save'
            disabled={ disableFromNIK || disableSave}
            ></ButtonPrimary>
            
          </Modal.Footer>
        </Modal>
        )
}

export default ModalAddUser;