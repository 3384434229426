import { useState } from "react";

const useViewState = () => {
    const [viewState, setViewState] = useState({ isLoading: false, isSuccess: false, successMsg: '', isWarning: false, warningMsg: '', isError: false, errorMsg: '' });

    const setLoading = (isLoading) => {
        setViewState(prevState => ({
            ...prevState,
            isLoading: isLoading
        }));
    }

    const setSuccess = (isSuccess, successMsg) => {
        setViewState(prevState => ({
            ...prevState,
            isSuccess: isSuccess, successMsg: successMsg
        }));
    }

    const setWarning = (isWarning, warningMsg) => {
        setViewState(prevState => ({
            ...prevState,
            isWarning: isWarning, warningMsg: warningMsg
        }));
    }
    const setError = (isError, errorMsg) => {
        setViewState(prevState => ({
            ...prevState,
            isError: isError, errorMsg: errorMsg
        }));
    }

    return { viewState, setLoading, setSuccess, setWarning, setError };
}

export default useViewState;
