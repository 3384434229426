import sectionJson from './utils/sectionOptions.json'

export const SectionOptions = sectionJson;

export const REPORT_TYPE = {
    SINGLE: 'Single-Rater',
    MULTI_GROUP: 'Multi-Rater Group',
    MULTI_INDIVIDU: 'Multi-Rater Individu',
}

export const ACTIONS = {
    CREATE: 'Create',
    UPDATE: 'Update',
    DELETE: 'Delete',
}

export const GROUP_BY = {
    PROJECT: 'project',
    BUSINESS_UNIT: 'business_unit',
    DEPARTMENT: 'department',
}

export const CONTENT_SECTIONS = {
    CHAPTER: '1',
    SUB_CHAPTER: '2',
    TEXT: '3',
    DATA: '4',
    RADAR_CHART_DATA: '5',
    BAR_CHART_DATA: '6',
    DATA_TABLE: '7',
    COMMENTS_DATA: '8',
    DEVELOPMENT_FORM: '9',
    DEMOGRAPHY_DATA: '10',
    SURVEY_DATA: '11',
    SUMMARY_TABLE: '12',
}

export const CONTENTS = {
    // SR
    SR_DEMO_LIST_GENDER: '1.10.6',
    SR_DATA_LIST: '1.11.1',
    SR_DATA_LIST_GD: '1.11.1.5',
    SR_DATA_LIST_GDA: '1.11.1.6',
    SR_DATA_LIST_GDAI: '1.11.1.7',
    SR_DATA_SCALE: '1.11.2',
    SR_DATA_PARTCP: '1.11.3',
    SR_BAR_GROUP: '1.6.1',
    SR_BAR_DIMENSION: '1.6.2',
    SR_BAR_ASPECT: '1.6.3',
    SR_BAR_ITEM: '1.6.4',
    SR_COMMENTS_ALL: '1.8.1',
    SR_COMMENTS_CUSTOM: '1.8.2',
    SR_SUMMARY_GROUP: '1.12.1',
    SR_SUMMARY_DIMENSION: '1.12.2',
    SR_SUMMARY_ASPECT: '1.12.3',
    SR_SUMMARY_ITEM: '1.12.4',
    // MRG
    MRG_RADAR_GROUP: '2.5.1',
    MRG_RADAR_DIMENSION: '2.5.2',
    MRG_RADAR_ASPECT: '2.5.3',
    MRG_BAR_GROUP: '2.6.1',
    MRG_BAR_DIMENSION: '2.6.2',
    MRG_BAR_ASPECT: '2.6.3',
    MRG_BAR_ITEM: '2.6.4',
    MRG_DATA_LIST: '2.4.1',
    MRG_DATA_LIST_GD: '2.4.1.5',
    MRG_DATA_LIST_GDA: '2.4.1.6',
    MRG_DATA_LIST_GDAI: '2.4.1.7',
    MRG_DATA_SCALE: '2.4.2',
    MRG_DATA_PARTCP_ALL: '2.4.3.1',
    MRG_DATA_PARTCP_NUM: '2.4.3.2',
    MRG_TABLE_ITEM: '2.7.1',
    MRG_TABLE_HIGHEST: '2.7.2',
    MRG_TABLE_LOWEST: '2.7.3',
    MRG_TABLE_BLINDSPOT: '2.7.4',
    MRG_TABLE_HIDDENSTR: '2.7.5',
    MRG_TABLE_ACTSTR: '2.7.6',
    MRG_TABLE_ACTWEAK: '2.7.7',
    // MRI
    MRI_DATA_LIST: '3.4.1',
    MRI_DATA_LIST_GD: '3.4.1.5',
    MRI_DATA_LIST_GDA: '3.4.1.6',
    MRI_DATA_LIST_GDAI: '3.4.1.7',
    MRI_DATA_SCALE: '3.4.2',
    MRI_DATA_PARTCP_ALL: '3.4.3.1',
    MRI_DATA_PARTCP_NUM: '3.4.3.2',
    MRI_RADAR_GROUP: '3.5.1',
    MRI_RADAR_DIMENSION: '3.5.2',
    MRI_RADAR_ASPECT: '3.5.3',
    MRI_BAR_GROUP: '3.6.1',
    MRI_BAR_DIMENSION: '3.6.2',
    MRI_BAR_ASPECT: '3.6.3',
    MRI_BAR_ITEM: '3.6.4',
    MRI_TABLE_ITEM: '3.7.1',
    MRI_TABLE_HIGHEST: '3.7.2',
    MRI_TABLE_LOWEST: '3.7.3',
    MRI_TABLE_BLINDSPOT: '3.7.4',
    MRI_TABLE_HIDDENSTR: '3.7.5',
    MRI_TABLE_ACTSTR: '3.7.6',
    MRI_TABLE_ACTWEAK: '3.7.7',
    MRI_COMMENTS_QUESTION: '3.8.1',
    MRI_COMMENTS_CHOICE: '3.8.2',
    DEV_FORM_CHALLENGE: '3.9.1',
    DEV_FORM_KEEPSTARTSTOP: '3.9.2',
}

export const reportTypeOptions = [
    {
        item: 'Single-Rater',
        eventKey: '1',
        val: REPORT_TYPE.SINGLE
    },
    {
        item: 'Multi-Rater Group',
        eventKey: '2',
        val: REPORT_TYPE.MULTI_GROUP
    },
    {
        item: 'Multi-Rater Individu',
        eventKey: '3',
        val: REPORT_TYPE.MULTI_INDIVIDU
    },
];

export const groupOptions = [
    'Group A',
    'Group B',
    'Group C',
    'Group D',
    'Group E',
    'Group F',
]

export const dimensionOptions = [
    'Dimension 1',
    'Dimension 2',
    'Dimension 3',
    'Dimension 4',
    'Dimension 5',
    'Dimension 6',
]

export const aspectOptions = [
    'Aspect A',
    'Aspect B',
    'Aspect C',
    'Aspect D',
    'Aspect E',
    'Aspect F',
]

export const itemOptions = [
    'Item 1',
    'Item 2',
    'Item 3',
    'Item 4',
    'Item 5',
    'Item 6',
]

export const ageOptions = [
    'under 25 years old',
    '25 - 29 years old',
    '30 - 39 years old',
    '40 - 49 years old',
    '50 years old and above',
]

export const yearOfServiceOptions = [
    'less than 1 year',
    '1 - < 2 years',
    '2 - < 5 years',
    '5 - < 10 years',
    '10 - < 15 years',
    '15 years or more',
]

export const departmentOptions = [
    'Department A',
    'Department B',
    'Department C',
    'Department D',
    'Department E',
    'Department F',
]

export const businessUnitOptions = [
    'Business Unit A',
    'Business Unit B',
    'Business Unit C',
    'Business Unit D',
    'Business Unit E',
    'Business Unit F',
]

export const companyOptions = [
    'Company A',
    'Company B',
    'Company C',
    'Company D',
    'Company E',
    'Company F',
]

export const genderOptions = [
    'Male',
    'Female',
]

export const levelOptions = [
    'Level A',
    'Level B',
    'Level C',
    'Level D',
    'Level E',
    'Level F',
]

//
// Section Options
//

/*const getCommonSections = (id) => {
    return [
        {
            id: `${id}.${CONTENT_SECTIONS.CHAPTER}`,
            text: 'Chapter',
            sections: [],
        },
        {
            id: `${id}.${CONTENT_SECTIONS.SUB_CHAPTER}`,
            text: 'Sub Chapter',
            sections: [],
        },
        {
            id: `${id}.${CONTENT_SECTIONS.TEXT}`,
            text: 'Text',
            sections: [],
        }
    ]
}

const demoDataOptions = [
    'Age',
    'Years of Services',
    'Department',
    'Bussiness Unit',
    'Company',
    'Gender',
    'Level',
]

const listGDAOptions = [
    'Group (G)',
    'Dimension (D)',
    'Aspect (A)',
    'Item',
    'G - Dimension',
    'G - D - Aspect',
    'G - D - A - Item'
]

const summaryTableSubOptions = [
    'YOS and Age',
    'Department',
    'Business Unit',
    'Company',
    'Gender',
    'Level',
]

const summaryTableSubSubOptions = [
    'Median',
    'Avg',
]

const comparisonOptions = [
    'self, others, all',
    'self, sup, sub, peer, stakeholder, all',
    'self, sup, (sub & peer & stakeholder), all',
    'self, sup, sub, peer, stakeholder',
]

const dataTableOptions = [
    'Item Score',
    'Highest Score',
    'Lowest Score',
    'Blind Spot Score',
    'Hidden Strength Score',
    'Action for Strength',
    'Action for Weakness',
]*/

// Commented for performance
/*export const SectionOptions = {
    SR: {
        id: '1',
        type: REPORT_TYPE.SINGLE,
        sections: [
            ...getCommonSections('1'),
            {
                id: '1.' + CONTENT_SECTIONS.DEMOGRAPHY_DATA,
                text: 'Demography Data',
                sections: demoDataOptions.map((item, index) => ({
                    id: '1.10.' + (index + 1),
                    text: item
                }))
            },
            {
                id: '1.' + CONTENT_SECTIONS.SURVEY_DATA,
                text: 'Survey Data',
                sections: [
                    {
                        id: '1.11.1',
                        text: 'List',
                        sections: listGDAOptions.map((item, index) => ({
                            id: '1.11.1.' + (index + 1),
                            text: item
                        })),
                    },
                    {
                        id: '1.11.2',
                        text: 'Scale',
                    },
                    {
                        id: '1.11.3',
                        text: 'Participant',
                    },
                ],
            },
            {
                id: '1.' + CONTENT_SECTIONS.BAR_CHART_DATA,
                text: 'Bar Chart Data',
                sections: [
                    {
                        id: '1.6.1',
                        text: 'Group',
                    },
                    {
                        id: '1.6.2',
                        text: 'Dimension',
                        showDialog: true,
                    },
                    {
                        id: '1.6.3',
                        text: 'Aspect',
                        showDialog: true,
                    },
                    {
                        id: '1.6.4',
                        text: 'Item',
                        showDialog: true,
                    },
                ],
            },
            {
                id: '1.' + CONTENT_SECTIONS.SUMMARY_TABLE,
                text: 'Summary Table',
                sections: [
                    {
                        id: '1.12.1',
                        text: 'Group',
                        sections: summaryTableSubOptions.map((item, index) => ({
                            id: '1.12.1.' + (index + 1),
                            text: item,
                            sections: summaryTableSubSubOptions.map((subItem, subIndex) => ({
                                id: '1.12.1.' + (index + 1) + '.' + (subIndex + 1),
                                text: subItem
                            }))
                        }))
                    },
                    {
                        id: '1.12.2',
                        text: 'Dimension',
                        sections: summaryTableSubOptions.map((item, index) => ({
                            id: '1.12.2.' + (index + 1),
                            text: item,
                            sections: summaryTableSubSubOptions.map((subItem, subIndex) => ({
                                id: '1.12.2.' + (index + 1) + '.' + (subIndex + 1),
                                text: subItem
                            }))
                        }))
                    },
                    {
                        id: '1.12.3',
                        text: 'Aspect',
                        sections: summaryTableSubOptions.map((item, index) => ({
                            id: '1.12.3.' + (index + 1),
                            text: item,
                            sections: summaryTableSubSubOptions.map((subItem, subIndex) => ({
                                id: '1.12.3.' + (index + 1) + '.' + (subIndex + 1),
                                text: subItem
                            }))
                        }))
                    },
                    {
                        id: '1.12.4',
                        text: 'Item',
                        sections: summaryTableSubOptions.map((item, index) => ({
                            id: '1.12.4.' + (index + 1),
                            text: item,
                            sections: summaryTableSubSubOptions.map((subItem, subIndex) => ({
                                id: '1.12.4.' + (index + 1) + '.' + (subIndex + 1),
                                text: subItem
                            }))
                        }))
                    },
                ],
            },
            {
                id: '1.' + CONTENT_SECTIONS.COMMENTS_DATA,
                text: 'Comments Data',
                sections: [
                    {
                        id: '1.8.1',
                        text: 'All Data',
                    },
                    {
                        id: '1.8.2',
                        text: 'Custom',
                        showDialog: true,
                    },
                ],
            },
        ],
    },
    MR_G: {
        id: '2',
        type: REPORT_TYPE.MULTI_GROUP,
        sections: [
            ...getCommonSections('2'),
            {
                id: '2.' + CONTENT_SECTIONS.DATA,
                text: 'Data',
                sections: [
                    {
                        id: '2.4.1',
                        text: 'List',
                        sections: listGDAOptions.map((item, index) => ({
                            id: `2.4.1.${index + 1}`,
                            text: item
                        })),
                    },
                    {
                        id: '2.4.2',
                        text: 'Scale',
                    },
                    {
                        id: '2.4.3',
                        text: 'Group',
                        sections: [
                            {
                                id: '2.4.3.1',
                                text: 'All Participants',
                            },
                            {
                                id: '2.4.3.2',
                                text: 'Num of Raters',
                            },
                        ]
                    },
                ]
            },
            {
                id: '2.' + CONTENT_SECTIONS.RADAR_CHART_DATA,
                text: 'Radar Chart Data',
                sections: [
                    {
                        id: '2.5.1',
                        text: 'Group',
                        sections: comparisonOptions.map((item, index) => ({
                            id: '2.5.1.' + (index + 1),
                            text: item
                        }))
                    },
                    {
                        id: '2.5.2',
                        text: 'Dimension',
                        sections: comparisonOptions.map((item, index) => ({
                            id: '2.5.2.' + (index + 1),
                            text: item
                        }))
                    },
                    {
                        id: '2.5.3',
                        text: 'Aspect',
                        sections: comparisonOptions.map((item, index) => ({
                            id: '2.5.3.' + (index + 1),
                            text: item
                        }))
                    },
                ]
            },
            {
                id: '2.' + CONTENT_SECTIONS.BAR_CHART_DATA,
                text: 'Bar Chart Data',
                sections: [
                    {
                        id: '2.6.1',
                        text: 'Group',
                        sections: comparisonOptions.map((item, index) => ({
                            id: '2.6.1.' + (index + 1),
                            text: item
                        }))
                    },
                    {
                        id: '2.6.2',
                        text: 'Dimension',
                        sections: comparisonOptions.map((item, index) => ({
                            id: '2.6.2.' + (index + 1),
                            text: item
                        }))
                    },
                    {
                        id: '2.6.3',
                        text: 'Aspect',
                        sections: comparisonOptions.map((item, index) => ({
                            id: '2.6.3.' + (index + 1),
                            text: item
                        }))
                    },
                    {
                        id: '2.6.4',
                        text: 'Item',
                        sections: comparisonOptions.map((item, index) => ({
                            id: '2.6.4.' + (index + 1),
                            text: item
                        }))
                    },
                ]
            },
            {
                id: '2.' + CONTENT_SECTIONS.DATA_TABLE,
                text: 'Data Table',
                sections: dataTableOptions.map((item, index) => (item == 'Item Score' ? {
                    id: `2.7.${index + 1}`,
                    text: item,
                    sections: comparisonOptions.map((subItem, subIndex) => ({
                        id: `2.7.${index + 1}.${subIndex + 1}`,
                        text: subItem
                    }))
                } : {
                    id: '2.7.' + (index + 1),
                    text: item,
                    sections: Array.from(
                        { length: 8 },
                        (_, subIndex) => ({
                            id: `2.7.${index + 1}.${subIndex + 1}`,
                            text: `Top data ${subIndex + 3}`
                        })
                    ),
                }))
            },
        ],
    },
    MR_I: {
        id: '3',
        type: REPORT_TYPE.MULTI_INDIVIDU,
        sections: [
            ...getCommonSections('3'),
            {
                id: '3.' + CONTENT_SECTIONS.DATA,
                text: 'Data',
                sections: [
                    {
                        id: '3.4.1',
                        text: 'List',
                        sections: listGDAOptions.map((item, index) => ({
                            id: `3.4.1.${index + 1}`,
                            text: item
                        })),
                    },
                    {
                        id: '3.4.2',
                        text: 'Scale',
                    },
                    {
                        id: '3.4.3',
                        text: 'Each Participant',
                        sections: [
                            {
                                id: '3.4.3.1',
                                text: 'All Raters',
                            },
                            {
                                id: '3.4.3.2',
                                text: 'Num of Raters',
                            },
                        ]
                    },
                ]
            },
            {
                id: '3.' + CONTENT_SECTIONS.RADAR_CHART_DATA,
                text: 'Radar Chart Data',
                sections: [
                    {
                        id: '3.5.1',
                        text: 'Group',
                        sections: comparisonOptions.map((item, index) => ({
                            id: '3.5.1.' + (index + 1),
                            text: item
                        }))
                    },
                    {
                        id: '3.5.2',
                        text: 'Dimension',
                        sections: comparisonOptions.map((item, index) => ({
                            id: '3.5.2.' + (index + 1),
                            text: item
                        }))
                    },
                    {
                        id: '3.5.3',
                        text: 'Aspect',
                        sections: comparisonOptions.map((item, index) => ({
                            id: '3.5.3.' + (index + 1),
                            text: item
                        }))
                    },
                ]
            },
            {
                id: '3.' + CONTENT_SECTIONS.BAR_CHART_DATA,
                text: 'Bar Chart Data',
                sections: [
                    {
                        id: '3.6.1',
                        text: 'Group',
                        sections: comparisonOptions.map((item, index) => ({
                            id: '3.6.1.' + (index + 1),
                            text: item
                        }))
                    },
                    {
                        id: '3.6.2',
                        text: 'Dimension',
                        sections: comparisonOptions.map((item, index) => ({
                            id: '3.6.2.' + (index + 1),
                            text: item
                        }))
                    },
                    {
                        id: '3.6.3',
                        text: 'Aspect',
                        sections: comparisonOptions.map((item, index) => ({
                            id: '3.6.3.' + (index + 1),
                            text: item
                        }))
                    },
                    {
                        id: '3.6.4',
                        text: 'Item',
                        sections: comparisonOptions.map((item, index) => ({
                            id: '3.6.4.' + (index + 1),
                            text: item
                        }))
                    },
                ]
            },
            {
                id: '3.' + CONTENT_SECTIONS.DATA_TABLE,
                text: 'Data Table',
                sections: dataTableOptions.map((item, index) => (item == 'Item Score' ? {
                    id: `3.7.${index + 1}`,
                    text: item,
                    sections: comparisonOptions.map((subItem, subIndex) => ({
                        id: `3.7.${index + 1}.${subIndex + 1}`,
                        text: subItem
                    }))
                } : {
                    id: '3.7.' + (index + 1),
                    text: item,
                    sections: Array.from(
                        { length: 8 },
                        (_, subIndex) => ({
                            id: `3.7.${index + 1}.${subIndex + 1}`,
                            text: `Top data ${subIndex + 3}`
                        })
                    ),
                }))
            },
            {
                id: '3.' + CONTENT_SECTIONS.COMMENTS_DATA,
                text: 'Comments Data',
                sections: [
                    {
                        id: '3.8.1',
                        text: 'Input Text Question',
                    },
                    {
                        id: '3.8.2',
                        text: 'No Answer Choice',
                    },
                ],
            },
            {
                id: '3.' + CONTENT_SECTIONS.DEVELOPMENT_FORM,
                text: 'Development Form',
                sections: [
                    {
                        id: '3.9.1',
                        text: 'Challenge',
                    },
                    {
                        id: '3.9.2',
                        text: 'Keep Start Stop',
                    },
                ],
            },
        ],
    },
}*/


