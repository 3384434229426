import "./HeaderFooter.css";
import nextButton from "../../../assets/svg/next_button.svg";
import { ButtonOutlinePrimary } from "../../../shared/component/button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { UseInstruction } from "./useInstruction";
import { useEffect, useState } from "react";
import { getImagePath } from "../../../common/utils";
import { useSelector } from "react-redux";

export const InstructionView = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { instructionDataText } = UseInstruction();
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const wordsPerSlide = 120; // Sesuaikan dengan batasan kata yang diinginkan
  const text = instructionDataText; // Gantilah dengan teks sebenarnya
  const slides = splitTextIntoSlides(text, wordsPerSlide);
  const showPreviousButton = currentSlideIndex > 0;
  const showNextButton = currentSlideIndex < slides.length - 1;
  const userData = useSelector((state) => state.user);


  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const isNextButtonDisabled = !canGoToNextSlide(
    text,
    currentSlideIndex,
    wordsPerSlide
  );

  const handleNextSlide = () => {
    if (showNextButton) {
      setCurrentSlideIndex(currentSlideIndex + 1);
    }
  };

  const handlePreviousSlide = () => {
    if (showPreviousButton) {
      setCurrentSlideIndex(currentSlideIndex - 1);
    }
  };


  return windowWidth >= 500 ? (
    <div className="row justify-content-center ">
      <div
        className="col-md-6 col-sm-12 mb-3 "
        style={{ width: "100vw"}}
      >
        <ul className="list-group list-group-flush shadow-sm border border-0" style={{borderRadius: "0.25rem"}}>
          <li className="list-group-item">
            <div style={{ textAlign: "center", color: "#7749F8" }}>
              <h2>Instruction</h2>
            </div>
          </li>
          {/* <li className="list-group-item"> */}
          <li className="list-group-item" style={{ maxHeight: "70vh" , textAlign:'center'}}>

            <p />
            {currentSlideIndex === 0 && (
              <div className="d-flex justify-content-center">
                {/* <img
                  src={getImagePath(instructionDataImage)}
                  style={{
                    maxWidth: "30vh",
                    maxHeight: "50vh",
                    objectFit: "cover",
                  }}
                /> */}
              </div>
            )}
            <p />
            <pre style={{fontFamily:'Segoe UI',fontSize:'1rem'}}>{slides[currentSlideIndex]}</pre>
          </li>
          <li
            className="list-group-item d-flex justify-content-center align-items-center"
            style={{ width: "100%" }}
          >
            {showPreviousButton && (
              <ButtonOutlinePrimary
                label={<FontAwesomeIcon icon={faChevronLeft} />}
                onClick={handlePreviousSlide}
                style={{ marginRight: "4px" }}
              />
            )}
            {showNextButton && (
              <ButtonOutlinePrimary
                label={<FontAwesomeIcon icon={faChevronRight} />}
                onClick={handleNextSlide}
                disabled={isNextButtonDisabled}
                className={isNextButtonDisabled ? "disabled" : ""}
                style={{ marginLeft: "4px" }}
              />
            )}
          </li>
        </ul>
      </div>
    </div>
    
  ) : (
    <div className="d-flex justify-content-center">
    <div
      className="card m-4"
      style={{ width: "100%"}}
    >
      <ul className="list-group list-group-flush">
        <li className="list-group-item">
          <div style={{ textAlign: "center", color: "#7749F8" }}>
            Instruction
          </div>
        </li>
        {/* <li className="list-group-item"> */}
        <li className="list-group-item" style={{ maxHeight: "70vh" , textAlign:'center'}}>

          <p />
          {currentSlideIndex === 0 && (
            <div className="d-flex justify-content-center">
              {/* <img
                src={getImagePath(instructionDataImage)}
                style={{
                  maxWidth: "30vh",
                  maxHeight: "50vh",
                  objectFit: "cover",
                }}
              /> */}
            </div>
          )}
          <p />
          <pre style={{fontFamily:'Segoe UI',fontSize:'0.5rem'}}>{slides[currentSlideIndex]}</pre>
        </li>
        <li
          className="list-group-item d-flex justify-content-center align-items-center"
          style={{ width: "100%" }}
        >
          {showPreviousButton && (
            <ButtonOutlinePrimary
              label={<FontAwesomeIcon icon={faChevronLeft} />}
              onClick={handlePreviousSlide}
              style={{ marginRight: "4px" }}
            />
          )}
          {showNextButton && (
            <ButtonOutlinePrimary
              label={<FontAwesomeIcon icon={faChevronRight} />}
              onClick={handleNextSlide}
              disabled={isNextButtonDisabled}
              className={isNextButtonDisabled ? "disabled" : ""}
              style={{ marginLeft: "4px" }}
            />
          )}
        </li>
      </ul>
    </div>
  </div>
  );
};

// Fungsi untuk membagi teks menjadi slide berdasarkan batasan kata
function splitTextIntoSlides(text, wordsPerSlide) {
  const words = text.split(" ");
  const slides = [];
  let currentSlide = "";

  for (const word of words) {
    if ((currentSlide + word).split(" ").length <= wordsPerSlide) {
      currentSlide += word + " ";
    } else {
      slides.push(currentSlide.trim());
      currentSlide = word + " ";
    }
  }

  if (currentSlide.trim() !== "") {
    slides.push(currentSlide.trim());
  }

  return slides;
}

// Fungsi untuk mengecek apakah bisa pindah ke slide berikutnya
function canGoToNextSlide(text, currentIndex, wordsPerSlide) {
  const slides = splitTextIntoSlides(text, wordsPerSlide);
  const nextIndex = currentIndex + 1;

  return nextIndex < slides.length;
}
