
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InputWithButton } from '../input/Input'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'

export function SearchColumn({ id, onChange_input, onClick_btn, value_input, placeholder_input }) {
   return (
      <InputWithButton
         id={id}
         label_button={<FontAwesomeIcon icon={faMagnifyingGlass} />}
         class_btn={'btn btn-light'}
         type_input={'text'}
         style_btn={{ borderRadius: 0, height: '100%', borderLeft: '1px solid rgb(209 213 219)' }}
         onChange_input={onChange_input}
         onClick_btn={onClick_btn}
         value_input={value_input}
         placeholder_input={placeholder_input}
      />
   )
}