import { Dropdown } from "react-bootstrap";
import { DropdownOnly } from "../dropdown/DropdownInput";

export function ShowEntries({ style, array_item = [10, 25, 50, 75, 100], style_dropdown, disabled, onClick_item, default_item }) {
    return (
        <div className='mx-2' style={style}>
            <DropdownOnly
                default_item={default_item != null ? default_item : array_item[0]}
                style={style_dropdown}
                disabled={disabled}
                dropdown_item={
                    array_item.map((arr, i) => {
                        return (
                            <Dropdown.Item key={i} onClick={() => onClick_item && onClick_item(arr)}>{arr}</Dropdown.Item>
                        )
                    })
                }
            />
        </div>
    )
}