import { Form, Col, Row } from "react-bootstrap";

const FormInputGroup = ({ label, children, controlId, horizontal = false, labelSize = 4, controlSize = 8 }) => (
    <>
        { horizontal ?
            <Form.Group as={Row} controlId={controlId}>
                <Form.Label column sm={labelSize}>{label}</Form.Label>
                <Col sm={controlSize}>{children}</Col>
            </Form.Group> :
            <Form.Group controlId={controlId}>
                <Form.Label>{label}</Form.Label>
                {children}
            </Form.Group>
        }
    </>
)

export default FormInputGroup
