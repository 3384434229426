export const MultiRatersListService = ({ doPost }) => {
  const getAllMultiRatersList = async (
    limit,
    page_number,
    search_bar,
    rater_nik
  ) => {
    try {
      return await doPost({
        url: "/participant/survey_assign_raters",
        data: {
          limit: limit,
          page_number: page_number,
          survey_type:'multi',
          search_bar: search_bar,
          rater_nik : rater_nik
        },
      });
    } catch (e) {
      throw e;
    }
  };

  const getCountActiveMRSurvey = async (
    nik
  ) => {
    try {
      return await doPost({
        url: "/participant/count_active_survey_assign_raters",
        data: {
          nik:nik,
          survey_type:'multi',
        },
      });
    } catch (e) {
      throw e;
    }
  };

  return {
    getAllMultiRatersList,
    getCountActiveMRSurvey
  };
};
