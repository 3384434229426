import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import screenAlert from "../../../../shared/hook/SweetAlert";
import { CONSTANTS } from "../../../../common/constants";
import { InputWithButton } from "../../../../shared/component/input/Input";
import { DropdownButtonOnly } from "../../../../shared/component/dropdown/DropdownInput";
import { Dropdown } from "react-bootstrap";
import { IconButtonDelete, IconButtonEdit } from "../../../../shared/component/button/IconButton";
import { rootAction } from "../../../../redux/RootAction";
import { ModalUploadFile } from "../../../target_participant/add_target_participant/tp_upload_file/ModalUploadFile";
import ImportFile from "../../../target_participant/add_target_participant/tp_upload_file/tab_upload/ImportFile";
import { useEffect } from "react";
import useTabParticipant from "./useTabParticipant";

function TabParticipant() {
    const [openImport, setOpenImport] = useState(false);
    const [openUpload, setOpenUpload] = useState(false);
    const navigate = useNavigate();
    const participantReducer = useSelector((state) => state.participant_single_rater);
    const dispatch = useDispatch();
    const showAlert = screenAlert();
    const [nikBulk, setNikBulk] = useState([]);
    const { emailLoginAsParticipant } = useTabParticipant({ nik: nikBulk });
    const spreadsheetState = useSelector((state) => state.spreadsheet);
    const isOpen = useSelector((state) => state.spreadsheet.isOpen);
    const importedData = useSelector((state) => state.spreadsheet.importedData);
    const [participantData, setParticipantData] = useState([]);

    useEffect(() => {
        dispatch(rootAction.singleRater.setValidParticipantSRAction(true));
    }, []);

    useEffect(() => {
        if (Array.isArray(participantReducer)) {
            let nik = [];
            participantReducer.map((par) => {
                if (par.nik) nik.push(par.nik);
            });

            setNikBulk(nik);
        }
    }, [participantReducer]);

    // useEffect(() => {
    //     let isValid = true;
    //     participantReducer.length > 0 && participantReducer.map((dt) => {
    //         if (Array.isArray(emailLoginAsParticipant) && emailLoginAsParticipant.length > 0) {
    //             let email = emailLoginAsParticipant.find((val) => val.nik == dt.nik)?.email;
    //             if (!dt.email) {
    //                 isValid = false;
    //                 dispatch(rootAction.singleRater.setValidParticipantSRAction(false));
    //                 return
    //             }
    //             if (email && email.toLowerCase() != dt.email.toLowerCase()) {
    //                 isValid = false;
    //                 dispatch(rootAction.singleRater.setValidParticipantSRAction(false));
    //                 return
    //             }
    //         }
    //     });

    //     if (isValid) dispatch(rootAction.singleRater.setValidParticipantSRAction(true));
    // }, [emailLoginAsParticipant]);

    const addTargetParticipantButton = [
        {
            label: 'From SAP',
            action: () => navigate(`${CONSTANTS.ROUTER.TP_FROM_SAP}`),
            isDisabled: false
        },
        {
            label: 'From History',
            action: () => {
                navigate(`${CONSTANTS.ROUTER.TP_FROM_HISTORY}`)
            },
            isDisabled: false
        },
        {
            label: 'Upload File',
            action: () => setOpenImport(true),
            // action: () => {
            //     setOpenUpload(true)
            // },
            isDisabled: false

        }
    ]

    const handleOnChangeText = (e) => {
        dispatch(rootAction.singleRater.setScoreSRAction({
            field: e.target.id,
            payload: e.target.value
        }));
    }

    const handleOnEditParticipant = (e, val) => {
        if (val.nik == null || val.nik == undefined || val.nik == '') {
            dispatch(rootAction.singleRater.setEditParticipantViewByEmailSR(val.email));
        } else {
            dispatch(rootAction.singleRater.setEditParticipantViewSR(val.nik));
        }
        navigate(`${CONSTANTS.ROUTER.EDIT_PARTICIPANT_SINGLE}`);
    }

    const handleOnDeleteParticipant = (e, val) => {
        showAlert.warning({
            title: "Warning",
            text: "Are you sure to remove \"" + val.full_name + "\"?",
            buttons: {
                cancel: {
                    text: "Cancel",
                    className: "bg-white text-dark"
                },
                confirm: {
                    text: "Delete",
                    value: true
                }
            },
            isDanger: true
        }).then(value => {
            if (value) {
                dispatch(rootAction.singleRater.deleteParticipantSRAction(val.nik));
            }
        });
    }
    const handleCloseImport = () => {
        setParticipantData([]);  // Reset state lokal ketika modal ditutup
        setOpenImport(false);  // Tutup modal upload
    };
    

    // const handleImportedData = (data) => {
    //     // Process imported data here
    //     dispatch(rootAction.singleRater.setImportedData(data));
    //     handleCloseImport();
    // };


    const normalizeData = (data) => {
        console.log("waktu normalisasi",data)
        return {
            nik: data.nik || data.nik,
            full_name: data.name || data.full_name,
            email: data.email,
            phone_number: data.phone_number || data.no_hp,
            level_user: data.level_user || data.level,
            position_user: data.position_user || data.position,
            business_unit_name:  data.business_unit_name,
            department_name: data.department_name,
            role_in_survey_id: 1,
            is_internal: data.is_internal !== undefined ? data.is_internal : (data.participant === 'Internal' ? 1 : 0),
            is_active: data.is_active !== undefined ? data.is_active : 1,
            dob: data.dob,
            gender: data.gender,
            join_date: data.join_date,
            participant: data.participant
        };
    };

    const handleImportData = (validData) => {
        // console.log('validData:', validData); // Debug log
        if (!validData || validData.length === 0) {
            // console.error('No valid data found'); // Debug log
            return;
        };
        const normalizedData = validData.map(normalizeData);
        // console.log("dari valid data Import ", validData);
        const newParticipants = normalizedData.map((data) => ({
            
            nik: data.nik != null ? data.nik : '',
            full_name: data.full_name != null ? data.full_name : '',
            email: data.email != null ? data.email : '',
            phone_number: data.phone_number != null ? data.phone_number : '',
            level: data.level_user != null ? data.level_user : '',
            position: data.position_user != null ? data.position_user : '',
            business_unit_name: data.business_unit_name != null ? data.business_unit_name : '',
            department_name: data.department_name != null ? data.department_name : '',
            role_in_survey_id: 1,
            is_internal: data.is_internal != null ? data.is_internal : '',
            is_active: 1,
            raters: [
                {
                    nik: data.nik != null ? data.nik : '',
                    full_name: data.full_name != null ? data.full_name : '',
                    email: data.email != null ? data.email : '',
                    phone_number: data.phone_number != null ? data.phone_number : '',
                    level: data.level_user != null ? data.level_user : '',
                    position: data.position_user != null ? data.position_user : '',
                    business_unit_name: data.business_unit_name != null ? data.business_unit_name : '',
                    department_name: data.department_name != null ? data.department_name : '',
                    role_in_survey_id: 1,
                    dob: data.dob != '' ? data.dob : null,
                    gender: data.gender == 'LAKI-LAKI' || data.gender == 'PEREMPUAN' ? data.gender : '',
                    join_date: data.join_date != '' ? data.join_date : null,
                    is_internal: data.is_internal != null ? data.is_internal : '',
                    is_active: 1,
                    status: 'not started'
                }
            ]
        }));
        // console.log('newParticipants:', newParticipants); // Debug log
        setParticipantData((prevData) => {
            const updatedData = [...prevData, ...newParticipants];
            // console.log('updatedData:', updatedData); // Debug log
            return updatedData;
        });

        // console.log('updatedData Participant:', participantData)
        handleCloseImport();
        // Automatically submit participant data after import
        handleOnSubmitParticipant(newParticipants);
    };

    const handleOnSubmitParticipant = (importedData=[]) => {
        const dataToSubmit = [...participantData, ...importedData];
        // console.log('participant data di add from upload file', participantData);
        showAlert.info({
            text: "Are you sure to save participants for survey?",
            buttons: {
                cancel: {
                    text: "Cancel",
                    className: "bg-white text-dark"
                },
                confirm: {
                    text: "Confirm",
                    value: true,
                    className: "bg-success"
                }
            }
        }).then(value => {
            if (value) {
                const dataSubmitAndCurrentData = [...dataToSubmit,...participantReducer]
                dispatch(rootAction.singleRater.setParticipantSRAction(dataSubmitAndCurrentData));


            }
        });
    };
    return (
        <div className="col-12 px-4">

            <div className="">
                <div className='d-flex justify-content-between my-4' >
                    <div className="tw-self-center">
                        <h5 className="m-0">Participant List</h5>
                    </div>
                    <DropdownButtonOnly
                        dropdown_item={
                            addTargetParticipantButton.map((addP, i) => {
                                return (
                                    <Dropdown.Item
                                        key={i} style={{ border: '1px solid white' }}
                                        onClick={addP.action}
                                        disabled={addP.isDisabled}
                                    >
                                        {addP.label}</Dropdown.Item>
                                )
                            })
                        }
                        label={"ADD"}
                    />
                </div>

                <div className="">
                    <div className='table-responsive'>
                        <table className="table table-striped table-bordered">
                            <thead>
                                <tr style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                    <th rowSpan={2}>No</th>
                                    <th rowSpan={2}>NIK</th>
                                    <th rowSpan={2}>Name</th>
                                    <th rowSpan={2}>Email</th>
                                    <th rowSpan={2}>Department</th>
                                    <th rowSpan={2}>Business Unit</th>
                                    <th rowSpan={2}> Participant <br></br> Type</th>
                                    <th colSpan={2}>Actions</th>
                                </tr>
                                <tr>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </tr>


                            </thead>

                            <tbody>
                                {Array.isArray(participantReducer) &&
                                    participantReducer.length > 0 ? participantReducer.map((dt, i) => {
                                        
                                        return (
                                            <tr key={i} style={{ verticalAlign: 'middle' }}>
                                                <td style={{ textAlign: 'center' }}>{i + 1}</td>
                                                <td>{dt.nik != undefined && dt.nik != null ? dt.nik : '-'}</td>
                                                <td >
                                                    {dt.full_name}
                                                </td>
                                                <td >{dt.email ? dt.email.toLowerCase() : "-"}</td>
                                                <td>{dt.department_name}</td>
                                                <td>{dt.business_unit_name}</td>
                                                <td>{dt.is_internal == 1 ? 'Internal' : 'Eksternal'}</td>
                                                <td><IconButtonEdit onClick={(e) => handleOnEditParticipant(e, dt)} /></td>
                                                <td style={{ textAlign: 'center' }}><IconButtonDelete onClick={(e) => handleOnDeleteParticipant(e, dt)} /></td>
                                            </tr>

                                        )
                                    }) : <tr><td colSpan={9} className="text-center">No Data</td></tr>
                                }
                            </tbody>

                        </table>
                    </div>

                </div>
                <ImportFile
                    isOpen={openImport}
                    onClose={handleCloseImport}
                    onSubmit={handleImportData}
                />
                {/* <ModalUploadFile
                    show={openUpload}
                    onHide={() => setOpenUpload(false)}
                /> */}

            </div>
        </div>
    )
}

export default TabParticipant;
