import { DELETE_ADD_RATER, DELETE_ADD_RATER_INTERNAL, DELETE_ALL_ADD_RATER, DELETE_ALL_ADD_RATER_INTERNAL, DELETE_DATA_SEE_RATERS, SET_ADD_RATER, SET_ADD_RATER_INTERNAL, SET_DATA_SEE_RATERS } from "./AddRaterType";

// const setAddRaterAction = (reqData) => ({
//     type: SET_ADD_RATER,
//     data: reqData
// })

// const deleteAddRaterAction = (reqData) => ({
//     type: DELETE_ADD_RATER,
//     data: reqData
// })

// const deleteAllAddRaters = () => ({
//     type: DELETE_ALL_ADD_RATER,
//  });

 const setDataSeeRaters = (reqData) => ({
    type: SET_DATA_SEE_RATERS,
    data: reqData
 });

 const deleteDataSeeRaters = () => ({
    type: DELETE_DATA_SEE_RATERS,
 });

export default {
    // setAddRaterAction,
    // deleteAddRaterAction,
    // deleteAllAddRaters,
    setDataSeeRaters,
    deleteDataSeeRaters
}