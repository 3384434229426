import './App.css';
import React from 'react';
import { AppRouter } from './route/AppRouter';
import { ToastContainer } from 'react-toastify';
import { injectDispatch } from './shared/axios/interceptors/AuthExpiredInterceptors';
import { useDispatch } from 'react-redux';

export default function App() {
  injectDispatch(useDispatch());

  return (
    <div className='App'>
      <ToastContainer limit={5} />
      <AppRouter />
    </div>
  )
}




