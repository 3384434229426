const ReportTemplateType = {
    SET_REPORT_TYPE: 'set_report_type',
    DISCARD_TEMPLATE: 'discard_template',
    UPDATE_TEMPLATE: 'update_template',
    CHANGE_CODE: 'change_code',
    CHANGE_NAME: 'change_name',
    CHANGE_LANGUAGE: 'change_language',
    UPDATE_SECTIONS: 'update_sections',
    CHANGE_THEME: 'change_theme',
    RESET_TEMPLATE: 'reset_template',
    SET_URL_PREV: 'set_url_prev',
    SET_URL_DATA: 'set_url_data',
    RESET_URL_DATA: 'reset_url_data',
    SET_URLPREVIEW_DATA: 'set_url_preview_data',
    RESET_URLPREVIEW_DATA: 'reset_url_preview_data'
};

export default ReportTemplateType;
