import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ServiceFactory } from './services/ServiceFactory'
import { DepsProvider } from './shared/context/DependencyContext'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '../src/custom_bootstrap.scss';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import 'react-toastify/dist/ReactToastify.css';
import { apiClientFactory } from './shared/axios/ApiClientFactory';
import { clientInstance } from './shared/axios/AxiosClient';
import { injectStore } from './shared/axios/interceptors/AuthHeaderInterceptors';
import { Store } from './redux/Store';
import { unstable_HistoryRouter as Router } from "react-router-dom";
import history from './history';


const apiClient = apiClientFactory(clientInstance);
const services = ServiceFactory(apiClient);
const { store, persistore } = Store();
injectStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router history={history}>
      <Provider store={store}>
        <PersistGate persistor={persistore}>
          <DepsProvider services={services}>
            <App />
          </DepsProvider>
        </PersistGate>
      </Provider>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
