import ReportTemplateType from './ReportTemplateType';
import { mapSectionToRedux } from '../../features/report_template/utils/useSection';

const {
    SET_REPORT_TYPE,
    DISCARD_TEMPLATE,
    RESET_TEMPLATE,
    UPDATE_TEMPLATE,
    CHANGE_CODE,
    CHANGE_NAME,
    CHANGE_LANGUAGE,
    UPDATE_SECTIONS,
    SET_URL_PREV,
    SET_URL_DATA,
    RESET_URL_DATA,
    SET_URLPREVIEW_DATA,
    RESET_URLPREVIEW_DATA
} = ReportTemplateType;

//
// Initial State
//
const initReduxState = {
    code: '',
    type: '',
    name: '',
    lang: '',
    sections: [],
    scales: [],
    comments: [],
    user_role: '',
    survey_code: '',
    created_by: '',
    updated_by: '',
    is_active: 0,
    is_deleted: 0
};

const initUrlState = {
    __prev: '',
    __state: [],
    survey_code: '',
    group_option: '', // 1=create, 2=choose
    group_code: '',
    individu_option: '', // 1=create, 2=choose
    individu_code: ''
};

const initUrlReportPreviewState = {
    __prev: '',
    report_type: '',
    tpl_code: '',
    survey_code: '',
    participant_id: 0,
    group_by: ''
};

//
// Actions
//

export function reportTemplateReducer(state = initReduxState, action) {
    switch (action.type) {
        case SET_REPORT_TYPE:
            return { ...state, type: action.data };
        case DISCARD_TEMPLATE:
        case RESET_TEMPLATE:
            return initReduxState;
        case CHANGE_CODE:
            return { ...state, code: action.data };
        case CHANGE_NAME:
            return { ...state, name: action.data };
        case CHANGE_LANGUAGE:
            return { ...state, lang: action.data };
        case UPDATE_SECTIONS:
            const sections = Array.isArray(action.data)
                ? action.data.map(mapSectionToRedux)
                : [];
            return { ...state, sections: sections };
        case UPDATE_TEMPLATE:
            return { ...state, ...action.data };
        default:
            return state;
    }
}

export function reportTemplateUrlReducer(state = initUrlState, action) {
    switch (action.type) {
        case SET_URL_PREV:
            return { ...state, __prev: action.data };
        case SET_URL_DATA:
            return { ...state, ...action.data };
        case RESET_URL_DATA:
            return initUrlState;
        default:
            return state;
    }
}

export function reportPreviewUrlReducer(state = initUrlReportPreviewState, action) {
    switch (action.type) {
        case SET_URLPREVIEW_DATA:
            return { ...state, ...action.data };
        case RESET_URLPREVIEW_DATA:
            return initUrlReportPreviewState;
        default:
            return state;
    }
}
