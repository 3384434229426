import { useState } from "react";
import { Form, Modal, Col, Row } from "react-bootstrap";
import { reportTypeOptions } from "../../constants";
import { ButtonOutlinePrimary } from "../../../../shared/component/button/Button";

const ModalAddReport = ({show, onHide, onSave}) => {
    const [reportType, setReportType] = useState(null);

    const handleOnHide = () => {
        setReportType(null)
        onHide()
    }

    const handleOnSave = () => {
        if (reportType) {
            onSave(reportType)
        }
    }

    return (
        <Modal show={show} size={'md'} centered onHide={handleOnHide}>
            <Modal.Body>
                <Row className="mb-3">
                    <label className="col-sm-3 col-form-label">Report Type</label>
                    <Col sm={9}>
                        {reportTypeOptions.map((opt, i) => (
                            <Form.Check
                                key={opt.eventKey}
                                type={'radio'}
                                id={`rpt-type-radio${i}`}
                                label={opt.item}
                                name={'rpt-type-radio'}
                                value={opt.val}
                                onFocus={(e) => { setReportType(e.target.value)}}
                            />
                        ))}
                    </Col>
                </Row>
                <Row>
                    <Col sm={{ span:9, offset:3 }}>
                        <ButtonOutlinePrimary label='Next' onClick={handleOnSave} />
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default ModalAddReport;
