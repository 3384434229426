export default function AuthService({ doPost }) {
   const doRequestOTP = async (userCred) => {
      try {
         return await doPost({
            url: '/auth/request_otp',
            data: userCred
         });
      } catch (err) {
         throw err;
      }
   }

   const doVerifyOTP = async (otpCred) => {
      try {
         return await doPost({
            url: '/auth/verify_otp',
            data: otpCred
         });
      } catch (err) {
         throw err;
      }
   }

   return { doRequestOTP, doVerifyOTP }
}