import { useState } from "react";
import FaqView from "./faq/FaqView";
import ContactUsView from "./contact_us/ContactUsView";
import IntroductionView from "./introduction/IntroductionView";

function SupportView() {
    const [menu, setMenu] = useState(1);

    const onChangeMenu = (ev) => {
        setMenu(ev.target.value);
    };





    return (
        <div className="container tw-h-max tw-max-w-[1400px]">

        <div className='m-4 mt-2 d-flex'>
            <h4> Configuration Support </h4>
        </div>

        <div className='card shadow rounded-3' style={{ borderLeft: 0, borderBottom: 0, borderRight: 0 }}>

            <div className="card-header d-flex p-1">
                <div
                    className="btn-group"
                    role="group"
                    aria-label="Basic radio toggle button group"
                >
                    <input
                        type="radio"
                        className="btn-check"
                        name="btn-radio"
                        id="btn-faq"
                        autoComplete="off"
                        value={1}
                        defaultChecked
                        onChange={onChangeMenu}
                    />
                    <label className="btn btn-outline-primary rounded-start-3" htmlFor="btn-faq" style={{ width: '12vw' }}>
                        FAQ
                    </label>
                    <input
                        type="radio"
                        className="btn-check"
                        name="btn-radio"
                        id="btn-contact"
                        autoComplete="off"
                        value={2}
                        onChange={onChangeMenu}
                    />
                    <label className="btn btn-outline-primary" htmlFor="btn-contact" style={{ width: '12vw', borderRadius: 0 }}>
                        Contact Us
                    </label>
                    <input
                        type="radio"
                        className="btn-check"
                        name="btn-radio"
                        id="btn-introduction"
                        autoComplete="off"
                        value={3}
                        onChange={onChangeMenu}
                    />
                    <label className="btn btn-outline-primary rounded-end-3" htmlFor="btn-introduction" style={{ width: '12vw' }}>
                        Introduction
                    </label>
                </div>
            </div>

            <div className="card-body p-4">
                {menu == 1 ?
                    (
                        <FaqView />
                    )
                    :
                    (
                        menu == 2 ?
                            (
                                <ContactUsView />
                            ) :
                            (
                                <IntroductionView />
                            )
                    )
                }
            </div>
        </div>

    </div>
     
    )


}

export default SupportView;