import { useEffect, useState } from "react";
import display1 from './../../../../../assets/images/scale_sample/display preview.png';
import display2 from './../../../../../assets/images/scale_sample/display preview (1).png';
import display3 from './../../../../../assets/images/scale_sample/display preview (2).png';
import display4 from './../../../../../assets/images/scale_sample/display preview (3).png';
import display5 from './../../../../../assets/images/scale_sample/display preview (4).png';
import { useDispatch, useSelector } from "react-redux";
import useRefs from "react-use-refs";
import screenToast from "../../../../../shared/hook/ScreenToast";
import { rootAction } from "../../../../../redux/RootAction";
import { contentLayoutChoices, scaleDisplayList, scaleLabelData } from "../../../../cms_multirater/tab/tab_content/contentData";
import { InputOnly, InputWithLabel, TextAreaWithLabel } from "../../../../../shared/component/input/Input";
import { IconButtonDelete, IconButtonDuplicate } from "../../../../../shared/component/button/IconButton";
import { DropdownWithLabel } from "../../../../../shared/component/dropdown/DropdownInput";
import { Dropdown } from "react-bootstrap";
import { ButtonContent, ButtonLink } from "../../../../../shared/component/button/Button";
import ModalAddContentFrom from "../../../../cms_multirater/tab/tab_content/tab_content_components/ModalAddContent";
import screenAlert from "../../../../../shared/hook/SweetAlert";


function SectionView({handleCopySection}) {
    const [addContentModal, setAddContentModal] = useState(false);
    const [hoverScale, setHoverScale] = useState('');
    const contentView = useSelector((state) => state.view_content_single_rater);
    const sectionReducer = useSelector((state) => state.section_single_rater.length > 0 && state.section_single_rater.find((el) => el.section_id == contentView.section_id));
    const dispatch = useDispatch();
    const [layoutRef, isUsingScaleRef] = useRefs();
    const scaleLabel = useSelector((state) => state.scale_choice_single_rater.length > 0 && state.scale_choice_single_rater.find((el) => el.section_id == contentView.section_id));
    const [counterScale, setCounterScale] = useState(4);
    const showToast = screenToast();
    const showAlert = screenAlert();

    useEffect(() => {
        if (scaleLabel && scaleLabel.content_choice.length > 0) {
            let counterSclaeCurrent = scaleLabel.content_choice[scaleLabel.content_choice.length - 1].scale_id;
            setCounterScale(counterSclaeCurrent + 1);
        }
    }, []);

    const handleDeleteSection = () => {
        dispatch(rootAction.singleRater.clearContentViewSRAction());
        dispatch(rootAction.singleRater.deleteSectionSRAction(sectionReducer.section_id));
    }

    const handleOnChangeText = (e, scale_id) => {
        if (e.target.id == 'choice_text') {
            dispatch(rootAction.singleRater.editScaleChoiceSRAction({
                section_key: contentView.section_id,
                scale_key: scale_id,
                field: e.target.id,
                payload: e.target.value
            }));
        } else {
            dispatch(rootAction.singleRater.editSectionSRAction({
                key: contentView.section_id,
                field: e.target.id,
                payload: e.target.value
            }));
        }
    }

    const handleOnSelect = (ref, val) => {
        if (ref.current.id == 'using_scale') {
            if (val == 1) {
                dispatch(rootAction.singleRater.editSectionSRAction({
                    key: contentView.section_id,
                    field: ref.current.id,
                    payload: val
                }));
            }
        }
        if (ref.current.id == 'using_scale') {
            if (val == 1) {
                dispatch(rootAction.singleRater.setScaleChoiceSRAction(scaleLabelData(contentView.section_id))) && dispatch(rootAction.singleRater.editSectionSRAction({
                    key: contentView.section_id,
                    field: 'scale_range',
                    payload: scaleLabelData().content_choice.length
                }))
            } else {
                let isUsingScale = false;
 
                for (let i = 0; i < sectionReducer.content.length; i++) {
                   if (sectionReducer.content[i].content_type === 'scale') {
                      isUsingScale = true;
                      break;
                   }
                }
    
                if (isUsingScale) {
                   showToast.error("Content with scale exists; please re-select content type of question in the section");
                }

                dispatch(rootAction.singleRater.clearScaleChoiceSRAction(contentView.section_id)) && dispatch(rootAction.singleRater.editSectionSRAction({
                    key: contentView.section_id,
                    field: 'scale_range',
                    payload: 0
                }))
            }
        }
    }

    const handleAddScaleChoie = () => {
        if (scaleLabel.content_choice.length >= 10) {
            showToast.error('Maximum scale choice reached');
        } else {
            dispatch(rootAction.singleRater.addScaleChoiceSRAction({
                key: contentView.section_id,
                payload: {
                    scale_id: counterScale,
                    choice_code: '',
                    choice_text: '',
                    choice_score: scaleLabel.content_choice.length + 1
                }
            }));
            dispatch(rootAction.singleRater.editSectionSRAction({
                key: contentView.section_id,
                field: 'scale_range',
                payload: scaleLabel.content_choice.length + 1
            }));
            setCounterScale(prevState => prevState + 1);
        }
    }

    const handleDeleteScaleChoice = (scale_id) => {
        dispatch(rootAction.singleRater.deleteScaleChoiceSRAction({
            section_key: contentView.section_id,
            scale_key: scale_id
        }));
        dispatch(rootAction.singleRater.editSectionSRAction({
            key: contentView.section_id,
            field: 'scale_range',
            payload: scaleLabel.content_choice.length - 1
        }));
    }

    const handleAddContent = () => {
        let content = {
            content_id: sectionReducer.content.length + 1,
            content_type: '',
            content_code: '',
            content_name: 'Question ' + (sectionReducer.content.length + 1),
            item: '',
            item_in_eng: '',
            help: '',
            score_same_as_scale: 1,
            score: 0,
            goal_id: 10,
            group_survey: '',
            dimension: '',
            aspect: '',
            is_mandatory: 0,
            has_box_answer: 0,
            action_for_strength: '',
            action_for_weakness: '',
            content_choice: []
        }

        dispatch(rootAction.singleRater.setContentSRAction({
            key: sectionReducer.section_id,
            payload: content
        }));
        dispatch(rootAction.singleRater.setContentViewSRAction({
            section_id: sectionReducer.section_id,
            content_id: content.content_id
        }));
    }

    const alertToDuplicate = () => {
        showAlert.info({
            text: `Are you sure to duplicate ${sectionReducer.section_name} ?`,
            buttons: {
                cancel: {
                    text: "Cancel",
                    className: "bg-white text-dark"
                },
                confirm: {
                    text: "Confirm",
                    value: true,
                    className: "bg-success"
                }
            }
        }).then(value => {
            if (value) {
                handleCopySection(sectionReducer,scaleLabel)
            }
        });
    }

    return (
        <div className="col-9" style={{ minHeight: '100vh' }}>

            <div className="col-12 border-bottom pb-1 d-flex justify-content-between align-items-center" style={{ boxShadow: 'inset 0 -0.1rem 0 0 rgb(0,0,0,0.2)', height: '6vh' }}>
                <div>

                </div>
                <h5>
                    Content Structure - Section
                </h5>
                <div>
                <IconButtonDuplicate onClick={alertToDuplicate}/>
                <IconButtonDelete onClick={handleDeleteSection} />
                </div>
            </div>


            <div className="col-12 row">
                <div className="col-8">
                    <div className="my-4" >
                        <InputWithLabel id={'section_name'} label={'Title'} type={'text'} value={sectionReducer.section_name} handleOnChange={handleOnChangeText} />
                    </div>
                    <div className="my-4">
                        <TextAreaWithLabel id={'description'} label={'Description'} type={'text'} input_height={'15vh'} value={sectionReducer.description} handleOnChange={handleOnChangeText} />
                    </div>
                    <div className="my-4">
                        <DropdownWithLabel
                            id={'content_layout'}
                            innerRef={layoutRef}
                            label={'Content Layout'}
                            disabled={true}
                            helpText={'This is default choice, more is coming soon'}
                            default_item={sectionReducer.content_layout ? contentLayoutChoices.find((el) => el.val === sectionReducer.content_layout).item : null}
                            dropdown_item={
                                contentLayoutChoices.map((cont, i) => {
                                    return (
                                        <Dropdown.Item key={i} onClick={() => handleOnSelect(layoutRef, cont.val)}>
                                            {cont.item}
                                        </Dropdown.Item>
                                    )
                                })
                            }
                        />
                    </div>
                    <div className="tw-flex tw-w-full tw-items-center my-4">
                        <div className={`tw-w-1/5 tw-font-medium tw-text-gray-800`}>Scale</div>

                        <div id={'using_scale'} ref={isUsingScaleRef} className="d-flex align-items-start" onChange={(e) => handleOnSelect(isUsingScaleRef, e.target.value)}>
                            <div className="form-check" style={{ cursor: 'pointer' }}>
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="isScale"
                                    id="flexRadioDefault1"
                                    checked={sectionReducer.using_scale == 1 ? true : false}
                                    value={1}
                                    style={{ cursor: 'pointer' }}
                                />
                                <label className="form-check-label" htmlFor="flexRadioDefault1" style={{ cursor: 'pointer' }}>
                                    Yes
                                </label>
                            </div>

                            <div className="form-check ms-4" style={{ cursor: 'pointer' }}>
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="isScale"
                                    id="flexRadioDefault2"
                                    checked={sectionReducer.using_scale == 0 ? true : false}
                                    value={0}
                                    style={{ cursor: 'pointer' }}
                                />
                                <label className="form-check-label" htmlFor="flexRadioDefault2" style={{ cursor: 'pointer' }}>
                                    No
                                </label>
                            </div>
                        </div>
                    </div>
                    {
                        sectionReducer.using_scale == 1 &&
                        (
                            <div>
                                <div className="">
                                    {scaleLabel.content_choice.map((el, i) => {
                                        return (

                                            <div className="border d-flex align-items-center px-4" style={{ width: "70%" }} key={i}>
                                                <div className="text-center">{i + 1}</div>
                                                <div className="my-2" style={{ width: '100%' }}><InputOnly id={'choice_text'} type={'text'} value={el.choice_text} handleOnChange={(e) => handleOnChangeText(e, el.scale_id)} /></div>
                                                <ButtonLink label="remove" onClick={() => handleDeleteScaleChoice(el.scale_id)} />
                                            </div>

                                        )
                                    })}
                                    <div>
                                        <ButtonLink label="Add option" onClick={handleAddScaleChoie} />
                                    </div>
                                </div>
                            </div>
                        )
                    }

                </div>

                <div className="col-4 my-4">
                    <div className="d-flex justify-content-end">
                        <ButtonContent label="+ Content" onClick={() => setAddContentModal(true)} />
                    </div>
                </div>


            </div>
            {
                sectionReducer.using_scale == 1 &&
                <div className="col-12 row">
                    <div className="col-8 my-4">
                        <DropdownWithLabel
                            label={'Scale Display'}
                            default_item={scaleDisplayList[0].text}
                            disabled={true}
                            helpText={'This is default choice, more is coming soon'}
                            dropdown_item={
                                scaleDisplayList.map((el) => {
                                    return (
                                        <Dropdown.Item key={el.id} onMouseOverCapture={() => setHoverScale(el.id)}>
                                            {el.text}
                                        </Dropdown.Item>
                                    )
                                })
                            }
                        />
                    </div>
                    <div className="col-4">
                        <div style={{ color: 'rgba(0, 0, 0, 0.5)' }}>Preview</div>
                        <div className="">
                            {hoverScale == '1' ? <img src={display1} alt="" /> :
                                hoverScale == '2' ? <img src={display2} alt="" /> :
                                    hoverScale == '3' ? <img src={display3} alt="" /> :
                                        hoverScale == '4' ? <img src={display4} alt="" /> :
                                            hoverScale == '5' ? <img src={display5} alt="" /> : <img src={display1} alt="" />}

                        </div>
                    </div>
                </div>
            }
            <ModalAddContentFrom
                show={addContentModal}
                onHide={() => setAddContentModal(false)}
                title={'Do you want to add content from ?'}
                handleOnClickNew={handleAddContent}
            />
        </div>
    )

}

export default SectionView