import React from 'react';

const SummaryCards = ({ dataMetrics }) => {
  const { total_active_single_rater, total_active_multi_rater, total_active_participants } = dataMetrics;
  return (
    <div className="summary-cards">
      <div className="card">Active Single Rater Survey: {total_active_single_rater}</div>
      <div className="card">Active Multi Rater Survey: {total_active_multi_rater}</div>
      <div className="card">Active Participants: {total_active_participants}</div>
    </div>
  );
};



export default SummaryCards;
