export const SET_VIEW = 'set_view_update';
export const RESET_VIEW_TAB = 'reset_view_tab_update';

export const LOAD_MULTI_RATER = 'load_multi_rater';
export const SET_MULTI_RATER = 'set_multi_rater_update';
export const RESET_MULTI_RATER = 'reset_multi_rater_update';

export const LOAD_SETTING = 'load_setting';
export const SET_SETTING = 'set_setting_update';
export const RESET_SETTING = 'reset_setting_update';

export const SET_VIEW_CONTENT_UPDATE = 'set_view_content_update';
export const CLEAR_VIEW_CONTENT_UPDATE = 'clear_view_content_update';
export const RESET_CONTENT_VIEW = 'reset_content_view_update';

export const LOAD_SECTION_CONTENT = 'load_section_content';
export const SET_SECTION = 'set_section_update';
export const EDIT_SECTION = 'edit_section_update';
export const DELETE_SECTION = 'delete_section_update';
export const RESET_SECTION = 'reset_section_update';

export const SET_CONTENT = 'set_content_update';
export const SET_CONTENT_BULK = 'set_content_bulk_update';
export const EDIT_CONTENT = 'edit_content_update';
export const DELETE_CONTENT = 'delete_content_update';

export const SET_CONTENT_CHOICE = 'set_content_choice_update';
export const EDIT_CONTENT_CHOICE = 'edit_content_choice_update';
export const DELETE_CONTENT_CHOICE = 'delete_content_choice_update';

export const LOAD_SCALE_CHOICE = 'load_scale_choice';
export const SET_SCALE_CHOICE = 'set_scale_choice_update';
export const ADD_SCALE_CHOICE = 'add_scale_choice_update';
export const EDIT_SCALE_CHOICE = 'edit_scale_choice_update';
export const DELETE_SCALE_CHOICE = 'delete_scale_choice_update';
export const CLEAR_SCALE_CHOICE = 'clear_scale_choice_update';
export const RESET_SCALE_CHOICE = 'reset_scale_choice_update';

export const LOAD_SCORE = 'load_score';
export const SET_SCORE = 'set_score_update';
export const RESET_SCORE = 'reset_score_update';

export const LOAD_PARTICIPANT = 'load_participant';
export const SET_PARTICIPANT = 'set_participant_update';
export const DELETE_PARTICIPANT = 'delete_participant_update';
export const RESET_PARTICIPANT = 'reset_participant_update';

export const SET_VIEW_EDIT_PARTICIPANT = 'set_view_edit_participant_update';
export const RESET_VIEW_EDIT_PARTICIPANT = 'reset_view_edit_participant_update';
export const EDIT_PARTICIPANT = 'edit_participant_update';

export const SET_IS_EDITING = 'set_is_editing_update';
export const RESET_IS_EDITING = 'reset_is_editing_update';

export const SET_CONTENT_CHOICE_SCALE = 'set_content_choice_scale_update';

export const SET_VALID_SETTING = 'set_valid_setting_update';
export const SET_VALID_CONTENT = 'set_valid_content_update';
export const SET_VALID_PARTICIPANT = 'set_valid_participant_update';
export const RESET_VALID_INPUT = 'reset_valid_input_update';

export const OWNERSHIP_LIST_MR = 'ownership_list_mr';
export const RESET_OWNERSHIP_MR = 'reset-ownership_mr';
export const DELETE_OWNERSHIP_MR = 'delete_ownership_mr';


export const SET_VIEW_EDIT_PARTICIPANT_KEY_EMAIL = 'set_view_edit_participant_key_email_update';
export const RESET_VIEW_EDIT_PARTICIPANT_KEY_EMAIL = 'reset_view_edit_participant_key_email_update';


export const SET_SETTING_LABEL_DATA_MR = 'update_set_setting_label_data_mr';
export const RESET_SETTING_LABEL_DATA_MR = 'update_reset_setting_label_data_mr';

