import { ADD_CONTENT_MANDATORY_SR, CLEAR_DATA_PARTICIPANT_RATER_SURVEY_FORM_SR, CLEAR_SURVEY_FIELD_SR, DELETE_ALL_MANDATORY_CONTENT_SR, DELETE_ALL_MANY_ANSWER_SR, DELETE_MANDATORY_CONTENT_SR, DELETE_MANY_ANSWER_SR, DELETE_SR_SURVEY_DETAIL_DATA, EDIT_MANY_ANSWER_SAME_CONTENT_SR, EDIT_MANY_ANSWER_SR, SET_DATA_PARTICIPANT_RATER_SURVEY_FORM_SR, SET_MANY_ANSWER_SR, SET_ONE_ANSWER_SR, SET_SR_SURVEY_DETAIL_DATA, SET_SURVEY_FIELD_SR } from "./SingleraterFormPartType";

const setOneAnswerSR = (answerData) => ({
    type: SET_ONE_ANSWER_SR,
    data: answerData
 });

 const setManyAnswerSR = (answerData) => ({
    type: SET_MANY_ANSWER_SR,
    data: answerData
 });

 const deleteManyAnswerSR = (answerData) => ({
    type: DELETE_MANY_ANSWER_SR,
    data: answerData
 });

 const deleteAllManyAnswerSR = () => ({
   type: DELETE_ALL_MANY_ANSWER_SR,
});

 const editManyAnswerSR = (answerData) => ({
    type: EDIT_MANY_ANSWER_SR,
    data: answerData
 });

 const setSurveyFieldSR = (reqData) => ({
    type: SET_SURVEY_FIELD_SR,
    data: reqData
 });

 const clearDataParticipantRaterSRForm = () => ({
   type: CLEAR_DATA_PARTICIPANT_RATER_SURVEY_FORM_SR,
});

 const setDataParticipantRaterSRForm = (dataReq) => ({
    type: SET_DATA_PARTICIPANT_RATER_SURVEY_FORM_SR,
    data: dataReq
 });

 const clearSurveyFieldSR = () => ({
   type: CLEAR_SURVEY_FIELD_SR,
});

const editManyAnswerSameContentSR = (answerData) => ({
   type: EDIT_MANY_ANSWER_SAME_CONTENT_SR,
   data: answerData
});

const addContentMandatorySR = (dataReq) => ({
   type: ADD_CONTENT_MANDATORY_SR,
   data: dataReq
});

const deleteMandatoryContentSR = (dataReq) => ({
   type: DELETE_MANDATORY_CONTENT_SR,
   data: dataReq
});

const deleteAllMandatoryContentSR = () => ({
   type: DELETE_ALL_MANDATORY_CONTENT_SR,
   
});


const setSRDetailData = (dataReq) => ({
   type: SET_SR_SURVEY_DETAIL_DATA,
   data: dataReq
});

const deleteSRDetailData = (dataReq) => ({
   type: DELETE_SR_SURVEY_DETAIL_DATA,
   
});


export default {
    setOneAnswerSR,
    setManyAnswerSR,
    deleteManyAnswerSR,
    editManyAnswerSR,
    setSurveyFieldSR,
    deleteAllManyAnswerSR,
    clearDataParticipantRaterSRForm,
    setDataParticipantRaterSRForm,
    clearSurveyFieldSR,
    editManyAnswerSameContentSR,
    addContentMandatorySR,
    deleteMandatoryContentSR,
    deleteAllMandatoryContentSR,
    setSRDetailData,
    deleteSRDetailData
}