export const ContactUsService = ({ doPost }) => {

    const postContactUs = async (msg, number, wa, email) => {
        try {
            return await doPost({
                url: '/admin/create_contact',
                data: {
                    msg: msg,
                    phone_number: number,
                    whatsAppNumber: wa,
                    email: email

                },
            });
        } catch (e) {
            throw e;
        }
    };

    const getContactUs = async () => {
        try {
            return await doPost({
                url: '/admin/get_contact',
                data: {
                    contact_id: "1",
                },
            });
        } catch (e) {
            throw e;
        }
    };

    const updateContactUs = async (msg, number, wa, email) => {
        try {
            return await doPost({
                url: '/admin/update_contact',
                data: {

                    contact_id: "1",
                    msg: msg,
                    phone_number: number,
                    whatsAppNumber: wa,
                    email: email

                },
            });
        } catch (e) {
            throw e;
        }
    };

    /* TULIS API GET CONTACTUS UTK PARTICIPANT DIBAWAH INI */


    return {
        postContactUs,
        getContactUs,
        updateContactUs

    };
};