import { Modal } from "react-bootstrap";
import { InputTextWithLabel, TextAreaWithLabel } from "../../../shared/component/input/Input";


function ViewQuestion({size,show,onHide,question = questionTemplate}) {
    const handleOnChange = () => {

    }
    return(
        <Modal
          size={size}
          show={show}
          onHide={onHide}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
             Detail Question
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
                <div className="mb-1">
                    <InputTextWithLabel label='Goal' id='goal' disabled={true} value={question.goal_name} handleOnChange={handleOnChange}/>
                </div>
                <div className="mb-1">
                    <InputTextWithLabel label='Group' id='group' disabled={true} value={question.group_survey} handleOnChange={handleOnChange}/>
                </div>
                <div className="mb-1">
                    <InputTextWithLabel label='Dimension' id='dimension' disabled={true} value={question.dimension} handleOnChange={handleOnChange}/>
                </div>
                <div className="mb-1">
                    <InputTextWithLabel label='Aspect' id='aspect' disabled={true} value={question.aspect} handleOnChange={handleOnChange}/>
                </div>
                <div className="mb-1">
                    <TextAreaWithLabel label='Action for Strength' id='action-strength' disabled={true} value={question.action_for_strength} handleOnChange={handleOnChange}/>
                </div>
                <div className="mb-1">
                    <TextAreaWithLabel label='Action for Weakness' id='action-weakness' disabled={true} value={question.action_for_weakness} handleOnChange={handleOnChange}/>
                </div>
                <div className="mb-1">
                    <TextAreaWithLabel label='Item (Main Language)' id='item-main' disabled={true} value={question.item} handleOnChange={handleOnChange}/>
                </div>
                <div className="mb-1">
                    <TextAreaWithLabel label='Item (English)' id='item-english' disabled={true} value={question.item_in_eng} handleOnChange={handleOnChange}/>
                   
                </div>
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </Modal>
        )
}

export default ViewQuestion;


const questionTemplate = {
    goal_name: "",
    group_survey: "",
    dimension: "",
    aspect: "",
    item: "",
    item_in_eng: "",
    action_for_strength: "",
    action_for_weakness: "",
    total_field: 0
  }