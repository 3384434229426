import { useEffect, useRef, useState } from "react";
import { ButtonOutlinePrimary, ButtonPrimary } from "../../../../../shared/component/button/Button";
import { IconButtonDelete, IconButtonEdit } from "../../../../../shared/component/button/IconButton";
import ModalAddEditRaters from "./ModalAddEditRaters";
import { InputWithLabel } from "../../../../../shared/component/input/Input";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useEditRatersParticipant from "./useEditRatersParticipant";
import { roleInSurvey } from "../participantData";
import screenAlert from "../../../../../shared/hook/SweetAlert";
import { rootAction } from "../../../../../redux/RootAction";
import screenToast from "../../../../../shared/hook/ScreenToast";
import { DropdownButtonOnly } from "../../../../../shared/component/dropdown/DropdownInput";
import { Dropdown } from "react-bootstrap";
import SelectRatersInternal from "./raters_internal/SelectRatersInternal";

function EditRatersForParticipant() {
    const navigate = useNavigate();
    const [addRaters, setAddRaters] = useState(false);
    const [editRaters, setEditRaters] = useState({ isShow: false, data: {} });
    const [participantRaters, setParticipantRaters] = useState({});
    const editParticipantView = useSelector((state) => state.view_edit_participant_multi_rater);
    const editParticipantViewByEmail = useSelector((state) => state.view_edit_participant_multi_rater_by_email);
    const participantReducer = useSelector((state) => state.participant_multi_rater.find((el) => el.nik == editParticipantView ));
    const dispatch = useDispatch();
    // const { participantData } = useEditRatersParticipant({ nik: editParticipantView, email:editParticipantViewByEmail});
    const showAlert = screenAlert();
    const showToast = screenToast();
    const  editRatersref= useRef(null);
    const employeeRef  = useRef(null);
   const participantReducerByEmail = useSelector((state) => state.participant_multi_rater.find((el) => el.email == editParticipantViewByEmail ));




    useEffect(() => {
        setParticipantRaters(participantReducer);
        if (editParticipantView == '') {
            setParticipantRaters(participantReducerByEmail)
        }
    }, []);

    const handleOnChangeText = (e) => {
        if (e.target.id == 'email_participant') {
            setParticipantRaters({
                ...participantRaters,
                email: e.target.value,
                raters: [...participantRaters.raters.map((rat) => {
                    if (rat.nik == editParticipantView) {
                        return {
                            ...rat,
                            email: e.target.value
                        }
                    } else {
                        return rat;
                    }
                })]
            });
        }
    }

    const handleOnChangeTextByEmail = (e) => {
        if (e.target.id == 'email_participant') {
            setParticipantRaters({
                ...participantRaters,
                email: e.target.value,
                raters: [...participantRaters.raters.map((rat) => {
                    if (rat.email == editParticipantViewByEmail) {
                        return {
                            ...rat,
                            email: e.target.value
                        }
                    } else {
                        return rat;
                    }
                })]
            });
        }
        if (e.target.id == 'name') {
            setParticipantRaters({
                ...participantRaters,
                full_name: e.target.value,
                raters: [...participantRaters.raters.map((rat) => {
                    if (rat.email == editParticipantViewByEmail) {
                        return {
                            ...rat,
                            full_name: e.target.value
                        }
                    } else {
                        return rat;
                    }
                })]
            });
        }

        if (e.target.id == 'phone_number') {
            setParticipantRaters({
                ...participantRaters,
                phone_number: e.target.value,
                raters: [...participantRaters.raters.map((rat) => {
                    if (rat.email == editParticipantViewByEmail) {
                        return {
                            ...rat,
                            phone_number: e.target.value
                        }
                    } else {
                        return rat;
                    }
                })]
            });
        }

        if (e.target.id == 'position') {
            setParticipantRaters({
                ...participantRaters,
                position: e.target.value,
                raters: [...participantRaters.raters.map((rat) => {
                    if (rat.email == editParticipantViewByEmail) {
                        return {
                            ...rat,
                            position: e.target.value
                        }
                    } else {
                        return rat;
                    }
                })]
            });
        }

        if (e.target.id == 'business_unit') {
            setParticipantRaters({
                ...participantRaters,
                business_unit_name: e.target.value,
                raters: [...participantRaters.raters.map((rat) => {
                    if (rat.email == editParticipantViewByEmail) {
                        return {
                            ...rat,
                            business_unit_name: e.target.value
                        }
                    } else {
                        return rat;
                    }
                })]
            });
        }


    }

    const handleOnCreateRaters = (val) => {
        if (!participantRaters.raters.find((rat) => rat.nik == val.nik)) {
            setParticipantRaters({
                ...participantRaters,
                raters: [...participantRaters.raters, val]
            });
        } else {
            showToast.error("Already add raters")
        }
    }

    const handleOnEditRaters = (e, val, email) => {
        setEditRaters({
            ...editRaters,
            isShow: !editRaters.isShow,
            data: {
                ...val,
                email: email ? email : val?.email
            }
        });
    }

    const handleOnSaveEditRaters = (val) => {
        setParticipantRaters({
            ...participantRaters,
            raters: [...participantRaters.raters].map((el) => {
                if (el.nik == val.nik) {
                    return val;
                } else {
                    return el;
                }
            })
        });
    }

    const handleOnDeleteRaters = (e, val) => {
        // console.log('handleOnDeleteRaters',val);
        setParticipantRaters({
            ...participantRaters,
            raters: participantRaters.raters.filter((el) => el.nik != val)
        });
    }

    const handleOnSubmitRaters = () => {
        showAlert.info({
            text: "Are you sure to save raters for \"" + participantRaters.full_name + "\"?",
            buttons: {
                cancel: {
                    text: "Cancel",
                    className: "bg-white text-dark"
                },
                confirm: {
                    text: "Confirm",
                    value: true,
                    className: "bg-success"
                }
            }
        }).then(value => {
            if (value) {
                dispatch(rootAction.multiRater.editParticipantMRAction(participantRaters));
                dispatch(rootAction.multiRater.resetViewEditParticipant());
                dispatch(rootAction.multiRater.resetViewEditParticipantByEmail());  
                navigate(-1);
            }
        });
    }

    const handleRefEmployeeListCard = () => {
        employeeRef.current.scrollIntoView({ behavior: 'smooth' });
      };

      const handleRefEditRatersCard = () => {
        editRatersref.current.scrollIntoView({ behavior: 'smooth' });
      };

    const addRatersButton = [
        {
            label: 'Internal',
            action: () => handleRefEmployeeListCard(),
            isDisabled: false
        },
        {
            label: 'External',
            action: () => setAddRaters(true),
            isDisabled: false
        },
    ]

    const handleBack = () => {
        dispatch(rootAction.multiRater.resetViewEditParticipant());
        dispatch(rootAction.multiRater.resetViewEditParticipantByEmail());

        navigate(-1);

    }

 

    if (editParticipantView == '' || participantRaters.is_internal == 0) {
       
        return (
            <div className="container tw-h-max">
                <div className='my-4 d-flex justify-content-between' ref={editRatersref}>
                    <h4>Edit Raters</h4>
                </div>
                <div className='my-4 d-flex justify-content-between align-items-center'>
                    <span className="fst-italic">Change survey participant information and raters information.</span>
                    <div className="d-flex justify-content-end">
                        <div className=" me-2">
                            <ButtonOutlinePrimary label="Back" onClick={() => navigate(-1)} />
                        </div>
                        <div className="">
                            <ButtonPrimary label="Save" onClick={handleOnSubmitRaters} />
                        </div>
                    </div>
                </div>
    
                <div className='card shadow p-4 border-0' >
                    <div className="col-12 d-flex flex-column" style={{ borderBottom: '0.1rem solid black' }} >
                        <div className="w-full">
                            <h5 className="m-0">Participant Information</h5>
                        </div>
                        <div className="w-full d-flex">
                            <div className="col-6 pe-4">
                                <div className="my-4">
                                    <InputWithLabel id={'name'} label={'Name'} value={participantRaters.full_name} handleOnChange={handleOnChangeTextByEmail} />
                                </div>
                                <div className="my-4">
                                    <InputWithLabel id={'nik'} label={'NIK'} disabled={true} value={participantRaters.nik} handleOnChange={handleOnChangeTextByEmail} bg_input={'rgba(171, 181, 190, 0.20)'} />
                                </div>
                                <div className="my-4">
                                    <InputWithLabel id={'phone_number'} label={'Number'} value={participantRaters.phone_number} handleOnChange={handleOnChangeTextByEmail} />
                                </div>
                                 <div className="my-4">
                                                    <InputWithLabel id={'email_participant'} label={'Email'} value={participantRaters.email?.toLowerCase()} handleOnChange={handleOnChangeTextByEmail} disabled={false}  />
                                                        {!participantRaters?.email &&
                                                            <div className="d-flex flex-row mt-2">
                                                                <span className="tw-w-1/5"></span>
                                                                <span className="tw-w-4/5" style={{ fontSize: '0.8rem', color: 'red' }}>
                                                                    Email cannot be empty. Please edit with a valid email.
                                                                </span>
                                                            </div>
                                                        }                                                   
                                                   
                                    
                                </div>
    
                            </div>
                            <div className="col-6 ps-4">
                                <div className="my-4">
                                    <InputWithLabel id={'position'} label={'Position'}  value={participantRaters.position} handleOnChange={handleOnChangeTextByEmail} />
                                </div>
                                <div className="my-4">
                                    <InputWithLabel id={'business_unit'} label={'Business Unit / Company'} value={participantRaters.business_unit_name} handleOnChange={handleOnChangeTextByEmail} />
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div className="w-full d-flex justify-content-between my-4">
                        <h5 className="m-0 tw-self-center">Raters Information</h5>
                        <DropdownButtonOnly
                            dropdown_item={
                                addRatersButton.map((addR, i) => {
                                    return (
                                        <Dropdown.Item
                                            key={i} style={{ border: '1px solid white' }}
                                            onClick={addR.action}
                                            disabled={addR.isDisabled}
                                        >
                                            {addR.label}</Dropdown.Item>
                                    )
                                })
                            }
                            label={"ADD"}
                        />
                    </div>
                    <div className='table-responsive'>
                        <table className="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th style={{ verticalAlign: 'middle', textAlign: 'center' }} >Type</th>
                                    <th style={{ verticalAlign: 'middle', textAlign: 'center' }} >Relationship</th>
                                    <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>NIK</th>
                                    <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>Name</th>
                                    <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>Email</th>
                                    <th colSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(participantRaters.raters) && participantRaters.raters.map((rat, i) => {
                                    return (
                                        <tr key={i}>
                                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{rat.is_internal == 1 ? 'Internal' : 'External'}</td>
                                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{roleInSurvey.find((el) => el.id == rat.role_in_survey_id).role_name}</td>
                                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{rat.nik}</td>
                                            <td style={{ verticalAlign: 'middle', }}>{rat.full_name}</td>
                                            <td style={{ verticalAlign: 'middle', }}>{rat.role_in_survey_id == 1 ? participantRaters.email : rat.email?.toLowerCase()}</td>
                                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}><IconButtonEdit onClick={(e) => handleOnEditRaters(e, rat, rat.role_in_survey_id == 1 ? participantRaters.email : '')} /></td>
                                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}><IconButtonDelete onClick={(e) => handleOnDeleteRaters(e, rat.nik)} disabled={i == 0 ? true : false} /></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
    
                {/* Employee Table for Select Internal Raters */}
                <div id="target" ref={employeeRef} >
                    <SelectRatersInternal 
                        scrollToTop={handleRefEditRatersCard} 
                        targetParticipantData={participantRaters}
                        raters={participantRaters.raters}
                        handleOnAddRaters={handleOnCreateRaters}
                        handleOnDeleteRaters={handleOnDeleteRaters}
                        />
                </div>
    
                <ModalAddEditRaters
                    type={'ADD'}
                    show={addRaters}
                    size={'lg'}
                    onHide={() => setAddRaters(false)}
                    handleOnSave={handleOnCreateRaters}
                    ratersType={0}
                />
    
                <ModalAddEditRaters
                    type={'EDIT'}
                    show={editRaters.isShow}
                    size={'lg'}
                    onHide={() => handleOnEditRaters()}
                    data={editRaters.data}
                    handleOnSave={handleOnSaveEditRaters}
                    ratersType={editRaters.data.is_internal}
                />
            </div>
        )
        
    } else {
        return (
            <div className="container tw-h-max">
                <div className='my-4 d-flex justify-content-between' ref={editRatersref}>
                    <h4>Edit Raters</h4>
                </div>
                <div className='my-4 d-flex justify-content-between align-items-center'>
                    <span className="fst-italic">Change survey participant information and raters information.</span>
                    <div className="d-flex justify-content-end">
                        <div className=" me-2">
                            <ButtonOutlinePrimary label="Back" onClick={() => handleBack()} />
                        </div>
                        <div className="">
                            <ButtonPrimary label="Save" onClick={handleOnSubmitRaters} />
                        </div>
                    </div>
                </div>
    
                <div className='card shadow p-4 border-0' >
                    <div className="col-12 d-flex flex-column" style={{ borderBottom: '0.1rem solid black' }} >
                        <div className="w-full">
                            <h5 className="m-0">Participant Information</h5>
                        </div>
                        <div className="w-full d-flex">
                            <div className="col-6 pe-4">
                                <div className="my-4">
                                    <InputWithLabel label={'Name'} disabled={true} value={participantRaters.full_name} bg_input={'rgba(171, 181, 190, 0.20)'} />
                                </div>
                                <div className="my-4">
                                    <InputWithLabel label={'NIK'} disabled={true} value={participantRaters.nik} bg_input={'rgba(171, 181, 190, 0.20)'} />
                                </div>
                                <div className="my-4">
                                    <InputWithLabel label={'Number'} value={participantRaters.phone_number} disabled={true} bg_input={'rgba(171, 181, 190, 0.20)'} />
                                </div>
                                 <div className="my-4">
                                                    <InputWithLabel id={'email_participant'} label={'Email'} value={participantRaters.email?.toLowerCase()} handleOnChange={handleOnChangeText} disabled={false}  />
                                                        {!participantRaters?.email &&
                                                            <div className="d-flex flex-row mt-2">
                                                                <span className="tw-w-1/5"></span>
                                                                <span className="tw-w-4/5" style={{ fontSize: '0.8rem', color: 'red' }}>
                                                                    Email cannot be empty. Please edit with a valid email.
                                                                </span>
                                                            </div>
                                                        }                                                   
                                                   
                                    
                                </div>
    
                            </div>
                            <div className="col-6 ps-4">
                                <div className="my-4">
                                    <InputWithLabel label={'Position'} disabled={true} value={participantRaters.position} bg_input={'rgba(171, 181, 190, 0.20)'} />
                                </div>
                                <div className="my-4">
                                    <InputWithLabel label={'Level'} disabled={true} value={participantRaters.level} bg_input={'rgba(171, 181, 190, 0.20)'} />
                                </div>
                                <div className="my-4">
                                    <InputWithLabel label={'Department'} disabled={true} value={participantRaters.department_name} bg_input={'rgba(171, 181, 190, 0.20)'} />
                                </div>
                                <div className="my-4">
                                    <InputWithLabel label={'Business Unit'} disabled={true} value={participantRaters.business_unit_name} bg_input={'rgba(171, 181, 190, 0.20)'} />
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div className="w-full d-flex justify-content-between my-4">
                        <h5 className="m-0 tw-self-center">Raters Information</h5>
                        <DropdownButtonOnly
                            dropdown_item={
                                addRatersButton.map((addR, i) => {
                                    return (
                                        <Dropdown.Item
                                            key={i} style={{ border: '1px solid white' }}
                                            onClick={addR.action}
                                            disabled={addR.isDisabled}
                                        >
                                            {addR.label}</Dropdown.Item>
                                    )
                                })
                            }
                            label={"ADD"}
                        />
                    </div>
                    <div className='table-responsive'>
                        <table className="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th style={{ verticalAlign: 'middle', textAlign: 'center' }} >Type</th>
                                    <th style={{ verticalAlign: 'middle', textAlign: 'center' }} >Relationship</th>
                                    <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>NIK</th>
                                    <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>Name</th>
                                    <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>Email</th>
                                    <th colSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(participantRaters.raters) && participantRaters.raters.map((rat, i) => {
                                    return (
                                        <tr key={i}>
                                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{rat.is_internal == 1 ? 'Internal' : 'External'}</td>
                                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{roleInSurvey.find((el) => el.id == rat.role_in_survey_id).role_name}</td>
                                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{rat.nik}</td>
                                            <td style={{ verticalAlign: 'middle', }}>{rat.full_name}</td>
                                            <td style={{ verticalAlign: 'middle', }}>{rat.role_in_survey_id == 1 ? participantRaters.email : rat.email?.toLowerCase()}</td>
                                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}><IconButtonEdit onClick={(e) => handleOnEditRaters(e, rat, rat.role_in_survey_id == 1 ? participantRaters.email : '')} /></td>
                                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}><IconButtonDelete onClick={(e) => handleOnDeleteRaters(e, rat.nik)} disabled={i == 0 ? true : false} /></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
    
                {/* Employee Table for Select Internal Raters */}
                <div id="target" ref={employeeRef} >
                    <SelectRatersInternal 
                        scrollToTop={handleRefEditRatersCard} 
                        targetParticipantData={participantRaters}
                        raters={participantRaters.raters}
                        handleOnAddRaters={handleOnCreateRaters}
                        handleOnDeleteRaters={handleOnDeleteRaters}
                        />
                </div>
    
                <ModalAddEditRaters
                    type={'ADD'}
                    show={addRaters}
                    size={'lg'}
                    onHide={() => setAddRaters(false)}
                    handleOnSave={handleOnCreateRaters}
                    ratersType={0}
                />
    
                <ModalAddEditRaters
                    type={'EDIT'}
                    show={editRaters.isShow}
                    size={'lg'}
                    onHide={() => handleOnEditRaters()}
                    data={editRaters.data}
                    handleOnSave={handleOnSaveEditRaters}
                    ratersType={editRaters.data.is_internal}
                />
            </div>
        )
    }
   
}
export default EditRatersForParticipant;