import { useDispatch, useSelector } from "react-redux";
import screenToast from "../../../shared/hook/ScreenToast";
import useDuplicateMultirater from "./useDuplicateMultirater";
import { rootAction } from "../../../redux/RootAction";
import { CONSTANTS } from "../../../common/constants";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

function DuplicateMultirater({ surveyCode }) {
   const { multiRater, setting, sectionContent, scaleChoice, scoreWeight, participant, error ,response} = useDuplicateMultirater({ survey_code: surveyCode });
   const showToast = screenToast();
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const user = useSelector((state) => state.user);

   useEffect(() => {
      if (error) {
         showToast.error("An error occurred. Please try again.")
      } else {
            if (response != undefined) {
               dispatch(rootAction.multiRater.duplicateMRAction(multiRater));
               dispatch(rootAction.multiRater.duplicateSettingMRAction(setting));
               dispatch(rootAction.multiRater.duplicateSectionContentMRAction(sectionContent));
               dispatch(rootAction.multiRater.duplicateScaleChoiceMRAction(scaleChoice));
               dispatch(rootAction.multiRater.duplicateScoreMRAction(scoreWeight));
               dispatch(rootAction.multiRater.duplicateParticipantMRAction(participant));

               if (user.role == CONSTANTS.ROLE.ADMIN){
               navigate(`${CONSTANTS.ROUTER.ADMIN_CMS}/${CONSTANTS.ROUTER.ADD_MULTI_RATER_SURVEY}`);
               } else if (user.role == CONSTANTS.ROLE.PIC) {
               navigate(`${CONSTANTS.ROUTER.PIC_CMS}/${CONSTANTS.ROUTER.ADD_MULTI_RATER_SURVEY}`);
               }
               
            }
      }
      
   }, [response])

   
   

   return <></>
}

export default DuplicateMultirater;