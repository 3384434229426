import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import screenToast from '../hook/ScreenToast';
import { useEffect } from 'react';

function ParticipantIsLogged() {
   const participant = useSelector((state) => state.user);
   const showToast = screenToast();
   let url;

   if (!participant || participant.role <= 0) {
      showToast.error("Please Login");
      url = '/login'
   } else if (participant.role != 3) {
      showToast.error("Please Login as Participant");
      url = participant.role == 1 ? '/admin/error' : '/pic/error';
   }
   
   return (
      participant && participant.role == 3 ? <Outlet /> : <Navigate to={url} />
   );
}

export { ParticipantIsLogged };
