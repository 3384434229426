import { PcCase } from 'lucide-react';
import './Card.css'

function SectionCard({titleCard,descCard}) {
    return(
        <div className='custom-card'>
            <div className='title-text'>
                {titleCard}
            </div>
           
            <pre className='description-text'>{descCard}</pre>
            
        </div>
    )
}

export default SectionCard;

export const PicCardSurvey = ({pic}) => {
    // if (pic.length != 0) {
    //     return(
    //         <div className='custom-card'>
    //             <div className='title-text' style={{textDecoration:'underline'}}>
    //                 Contact person for this survey :
    //             </div>
    //             <div className='description-text'>
    //                 {pic.map((pc,i)=>{
    //                     return(
    //                        <ul key={i}>
    //                             {/* {pc.email != null ? 
    //                                 pc.number != null ? 
    //                                     ` ${pc.email} / ` : 
    //                                     ` ${pc.email} ` 
    //                                 : 
    //                             ''}
    //                             {pc.number != null ? ` ${pc.number} ` : ''}
    //                             ( {pc.name != null ? `${pc.name}` : ''} ) */}
    //                             <li>
    //                                 {pc}
    //                             </li>
    //                        </ul>
    //                     )
    //                 })}
    //             </div>
    //         </div>
    //       )
    // }
    if (pic != null || pic != '' || pic != undefined) {
        return(
            <div className='custom-card'>
            <div className='title-text'>
                Contact person for this survey :
            </div>
            <div className='description-text'>
                {pic}
            </div>
        </div>
        )
    }
    else{
        return (
            <div className='description-text' style={{fontStyle:'italic',marginTop:'1%'}}>Please open the contact us page if you experience problems</div>
        )
    }
  
}
