import { useEffect, useState } from "react";
import { useDeps } from "../../../../../shared/context/DependencyContext";
import { useSelector } from "react-redux";
import screenToast from "../../../../../shared/hook/ScreenToast";
import { CONSTANTS } from "../../../../../common/constants";

function useEditParticipant({ nik,email}) {
    const [participantData, setParticpantData] = useState({});
    const { adminService, picService } = useDeps();
    const user = useSelector((state) => state.user);
    const showToast = screenToast();
    const [emailLoginAsParticipant, setEmailLoginAsParticipant] = useState('')
    const editParticipantViewByEmail = useSelector((state) => state.view_edit_participant_single_rater_by_email);
    const participantReducerByEmail = useSelector((state) => state.participant_single_rater.find((el) => el.email == editParticipantViewByEmail ));
 
    useEffect(() => {
       nik != '' && onGetUserByNik(nik);
       nik != '' && onCheckEmailAsParticipantByNIK(nik)
    }, [nik]);

    useEffect(() => {
      email != '' && email != null && setParticpantData(participantReducerByEmail);
 
    //   console.log('participant reducer edit EMAIL==>', participantReducerByEmail);
      
      
    }, [email]);
 
 
    const onGetUserByNik = async (nik) => {
       try {
          if (user.role == CONSTANTS.ROLE.ADMIN) {
             const response = await adminService.userMasterService.checkNIK(nik);
             if (response.status == 'SUCCESS' && response.data != 'NO DATA') {
                setParticpantData(response.data);
             } else {
                setParticpantData({});
                showToast.warning("No data found");
             }
          } else {
             const response = await picService.userMasterService.checkNIKPICUrl(nik);
             if (response.status == 'SUCCESS' && response.data != 'NO DATA') {
                setParticpantData(response.data);
             } else {
                setParticpantData({});
                showToast.warning("No data found");
             }
          }
       } catch (error) {
          console.log(error);
          showToast.warning("An error occurred. Please try again");
       }
    }
 
    const onCheckEmailAsParticipantByNIK = async (nik) => {
       try {
          nik = [nik];
 
          if (user.role == CONSTANTS.ROLE.ADMIN) {
             const response = await adminService.userMasterService.getEmailParticipantbyNIK(nik);
             if (response.status == 'SUCCESS' && response.data != 'NO DATA' && response.data.length > 0) {
                setEmailLoginAsParticipant(response.data[0].email);
                // console.log('email as participant => ',response);
             } else {
                setEmailLoginAsParticipant('');
             }
          } else {
             const response = await picService.userMasterService.getEmailParticipantbyNIKPICUrl(nik);
             if (response.status == 'SUCCESS' && response.data != 'NO DATA' && response.data.length > 0) {
                setEmailLoginAsParticipant(response.data[0].email);
                // console.log('email as participant => ',response);
 
             } else {
                setEmailLoginAsParticipant('');
             }
          }
       } catch (error) {
          console.log(error);
          // showToast.warning("An error occurred. Please try again");
       }
    }
 
    return {
       participantData,
       emailLoginAsParticipant,
       setParticpantData
 
    }
 }

export default useEditParticipant