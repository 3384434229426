import { forwardRef } from 'react';
import { Form } from 'react-bootstrap';

const FormSelect = forwardRef(
    ({ id, size = 'md', items = [], value, onChange, placeholder = 'Select' }, ref) => {
        return (
            <Form.Select id={id} size={size} onChange={onChange} value={value} ref={ref}>
                <option value="">{placeholder}</option>
                {Array.isArray(items) &&
                    items.map((i) => (
                        <option key={i.value} value={i.value}>
                            {i.label}
                        </option>
                    ))}
            </Form.Select>
        );
    }
);

export default FormSelect;
