// components/LoadingSpinner.js
import React from 'react';
import './LoadingSpinner.css'; // Include CSS for the spinner

const LoadingSpinner = () => {
  return (
    <div className="loading-spinner">
      <div className="spinner"></div>
      <p>Generating PDF...</p>
    </div>
  );
};

export default LoadingSpinner;
