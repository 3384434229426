import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate, faCheck } from "@fortawesome/free-solid-svg-icons";
import { useNavigate} from "react-router-dom";
import { CONSTANTS } from "../../../common/constants";
import { Footer } from "../../../features/layout/navbar/Footer";
import {   useDispatch, useSelector } from "react-redux";
import { SearchColumn } from "../../../shared/component/table_property/SearchColumn";
import { ButtonLink } from "../../../shared/component/button/Button";
import { ShowEntries } from "../../../shared/component/table_property/ShowEntries";
import Pagination from "../../../shared/component/table_property/Paginatination";
import { thTableSingleRaterSurvey } from "../../question_bank/component/singleratersurvey_constants";
import useSingleraterSurveyList from "./useSingleRaterSurveyList";
import { rootAction } from "../../../redux/RootAction";
import { useEffect } from "react";


export const TableSingleRater = () => {
  const navigate = useNavigate();
  const rater = useSelector((state)=>state.user);
  const {
    handleInputSearchChange,
    handleSearchData,
    onGetAllSingleRatersList,
    setSingleRatersList,
    handleshowItem,
    limit,
    singleRaterList,
    totalItem,
    setPageNumber,
    pageNumber,
    searchText  
  } = useSingleraterSurveyList();
  const dispatch = useDispatch();

  const paginate = (pageNumber) => {
    setPageNumber(pageNumber);
 };

    useEffect(()=>{
      dispatch(rootAction.singleSurveyForm.deleteAllMandatoryContentSR())
    },[])




 const previousPage =  () => {
      if (pageNumber != 1) {
        setPageNumber(pageNumber - 1);
        
      }
  };

  const nextPage = () => {
      if (pageNumber != Math.ceil(totalItem/ limit)) {
        setPageNumber(pageNumber + 1);
      }
  };

  const handleParticipantRaterMRFormData = (field,payload) => {
    dispatch(rootAction.singleSurveyForm.setDataParticipantRaterSRForm({
       field: field,
       payload: payload
   }));
  }

  const navigateToStartSurvey =  ( 
    s_raters_id,
    s_participant_id,
    survey_code,
    target_nik,
    target_full_name,
    target_email,
    survey_name,
    rater_role,
    rater_nik,
    rater_email,
    rater_bu,
    rater_dept) => {
      dispatch(rootAction.singleSurveyForm.deleteAllManyAnswerSR());
      handleParticipantRaterMRFormData('s_raters_id',s_raters_id);
      handleParticipantRaterMRFormData('s_participant_id',s_participant_id);
      handleParticipantRaterMRFormData('survey_code',survey_code);
      handleParticipantRaterMRFormData('target_nik',target_nik);
      handleParticipantRaterMRFormData('target_full_name',target_full_name);
      handleParticipantRaterMRFormData('target_email',target_email);
      handleParticipantRaterMRFormData('survey_name',survey_name);
      handleParticipantRaterMRFormData('rater_role',rater_role);
      handleParticipantRaterMRFormData('rater_nik',rater_nik);
      handleParticipantRaterMRFormData('rater_email',rater_email);
      handleParticipantRaterMRFormData('rater_bu',rater_bu);
      handleParticipantRaterMRFormData('rater_dept',rater_dept);

      navigate(
        `${CONSTANTS.ROUTER.PARTICIPANT_DASHBOARD}/${CONSTANTS.ROUTER.PARTICIPANT_SINGLERATER_SURVEY_FORM}`)

  }


  return (
    <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
      <div className="card" style={{ padding: "3%" }}>      
        <div className="pb-4 justify-content-between d-flex flex-row">
          <div className="col-4 d-flex flex-row align-items-center">
            <div>Show</div>

            <ShowEntries onClick_item={handleshowItem} default_item={limit} />
            <div className="">entries</div>
          </div>

          <div className="col-3 d-flex align-items-center">
          <div className="d-inline float-end">
            <div className="tooltips">
               <button
                className="me-2 btn btn-light "
                onClick={()=>onGetAllSingleRatersList(limit,pageNumber,searchText)}
              >
                <FontAwesomeIcon icon={faArrowsRotate} />
              </button>
              <span className="tooltiptext">Reload</span>
            </div>
          </div>
           
          <SearchColumn onChange_input={handleInputSearchChange}  onClick_btn={handleSearchData}/>
          
          </div>
        </div>

        <div className="table-responsive table-bordered mb-2">
          <table className="table table-striped table-bordered">
            <thead className="tw-h-16">
              <tr>
                {thTableSingleRaterSurvey.map((th, i) => {
                  return <HeadTable key={i} label={th} />;
                })}
                <th
                  style={{ textAlign: "center", verticalAlign: "middle" }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(singleRaterList) && singleRaterList.length != 0 ? (
                singleRaterList.map((dt, i) => {
                return (
                  <tr
                    key={i}
                    style={{ textAlign: "center", verticalAlign: "middle" }}
                  >
                    <td>{dt.survey_name}
                    </td>
                    <td>{ dt.status == "Done" || dt.status == "done"? "Done" : "Not Started" }</td>
                    <td style={{ textAlign: "center" }}>
                      { dt.status == "Done" || dt.status == "done"? (
                        <FontAwesomeIcon icon={faCheck} />
                      ) : (
                        <ButtonLink
                          label={"Start"}
                          onClick={()=>navigateToStartSurvey(
                            dt.s_raters_id,
                            dt.s_participant_id,
                            dt.survey_code,
                            dt.participant_nik,
                            dt.participant_full_name,
                            dt.participant_email,
                            dt.survey_name,
                            dt.rater_role,
                            rater.nik,
                            rater.email,
                            rater.business_unit_name,
                            rater.department_name
                          )}
                        />
                      )}
                    </td>
                  </tr>
                );
              })
              )
              :
              (
                <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  <td colSpan={7}>No data</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination showing={Array.isArray(singleRaterList) ? singleRaterList.length : 0} totalItem={totalItem} showItem={limit} previousPage={previousPage} nextPage={nextPage} paginate={paginate} limit={pageNumber}/>
      <Footer
        disableNext={true}
        onClickPrev={() =>
          navigate(
            `${CONSTANTS.ROUTER.PARTICIPANT_DASHBOARD}/${CONSTANTS.ROUTER.PARTICIPANT_INTRODUCTION}`
          )
        }
      />
    </div>
  );
};

export function HeadTable({ label }) {
  return (
    <th style={{ textAlign: "center", verticalAlign: "middle" }}>{label}</th>
  );
}
