import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { rootAction } from "../../../../../redux/RootAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderClosed, faFolderOpen } from "@fortawesome/free-solid-svg-icons";

const TreeNode = ({ node }) => {
    const [isExpandedTree, setIsExpandedTree] = useState(true);
    const contentView = useSelector((state) => state.update_view_content_single_rater);
    const dispatch = useDispatch();
 
    const handleOnExpand = () => {
       setIsExpandedTree(prevState => !prevState);
    }
 
    const handleOnClickSection = () => {
       dispatch(rootAction.updateSingleRater.setContentViewSRAction({
          section_id: node.section_id,
          content_id: 0
       }));
    }
 
    const handleOnClickContent = (e) => {
       dispatch(rootAction.updateSingleRater.setContentViewSRAction({
          section_id: node.section_id,
          content_id: e.target.value
       }));
    }
 
    return (
       <div className="tree-node">
          <div className={`node-toggle ${isExpandedTree ? 'expanded' : ''} tw-cursor-pointer`}>
             {
                isExpandedTree ?
                   <FontAwesomeIcon icon={faFolderOpen} style={{ color: '#FD7E14' }} onClick={handleOnExpand} /> : <FontAwesomeIcon icon={faFolderClosed} style={{ color: '#FD7E14' }} onClick={handleOnExpand} />
             }
          </div>
          <div className='d-flex w-100 flex-column'>
             <div className={`ms-2 tw-cursor-pointer tw-h-min ${contentView.section_id == node.section_id && contentView.content_id == 0 && "tw-bg-gradient-to-tr tw-from-indigo-200 tw-to-indigo-100 tw-text-indigo-800"}`} onClick={handleOnClickSection}>
                {node.section_name}
             </div>
             {
                isExpandedTree && (
                   <ul className="child-nodes">
                      {node.content.length > 0 && node.content.map((el, i) =>
                         <li key={i} value={el.content_id} className={`ms-3 tw-cursor-pointer ${contentView.section_id == node.section_id && contentView.content_id == el.content_id && "tw-bg-gradient-to-tr tw-from-indigo-200 tw-to-indigo-100 tw-text-indigo-800"}`} onClick={handleOnClickContent}>
                            {el.content_name}
                         </li>
                      )}
                   </ul>
                )
             }
          </div>
       </div>
    );
 };
 
 
 const TreeViewUpdate = () => {
    const sectionReducer = useSelector((state) => state.update_section_single_rater);
 
    return (
       <div>
          {sectionReducer.length > 0 && sectionReducer.map((rootNode, i) => (
             <TreeNode key={i} node={rootNode} />
          ))}
       </div>
    );
 };
 
 export default TreeViewUpdate;