import { useState } from "react";
import { useDeps } from "../../../shared/context/DependencyContext";
import { useSelector } from "react-redux";

function useOnlineForm(params) {
    const { participantService } = useDeps();
    const [countMR, setCountMR] = useState(0);
    const [countSR, setCountSR] = useState(0);
    const user = useSelector((state)=>state.user);

    const onGetCountActiveSurvey = async (
  ) => {
    try {
      const responseMR =
        await participantService.multiRaterListService.getCountActiveMRSurvey(
          user.nik
        );

        if (responseMR.status == "SUCCESS") {
          setCountMR(responseMR.data.active_survey)
          // console.log('response MR', responseMR);
        }

      const responseSR =
        await participantService.singleRaterListService.getCountActiveSRSurvey(
          user.nik
        );

     

      if (responseSR.status == "SUCCESS") {
        setCountSR(responseSR.data.active_survey)
        // console.log('response SR', responseSR);
      }
    } catch (error) {
      console.log(error);
    } finally {
      // console.log("finally");
    }
  };

  return {
    countSR,
    countMR,
    onGetCountActiveSurvey
  }

}



export default useOnlineForm