import { SET_SIDEBAR } from "./PreferencesType";

// define action constant
const setSidebarAction = (reqData) => ({
   type: SET_SIDEBAR,
   data: reqData
});

export default {
   setSidebarAction
}