import { useDispatch, useSelector } from "react-redux";
import screenToast from "../../../shared/hook/ScreenToast";
import useDuplicateSinglerater from "./useDUplicateSingleRater";
import { useNavigate } from "react-router-dom";
import { rootAction } from "../../../redux/RootAction";
import { CONSTANTS } from "../../../common/constants";
import { useEffect } from "react";

function DuplicateSinglerater({ surveyCode }) {
    const { singleRater, setting, sectionContent, scaleChoice, scoreWeight, participant, error ,response} = useDuplicateSinglerater({ survey_code: surveyCode });
    const showToast = screenToast();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.user);
 
    useEffect(() => {
      if (error) {
         showToast.error("An error occurred. Please try again.")
      } else {
         if (response != undefined) {
            dispatch(rootAction.singleRater.duplicateSRAction(singleRater));
            dispatch(rootAction.singleRater.duplicateSettingSRAction(setting));
            dispatch(rootAction.singleRater.duplicateSectionContentSRAction(sectionContent));
            dispatch(rootAction.singleRater.duplicateScaleChoiceSRAction(scaleChoice));
            dispatch(rootAction.singleRater.duplicateScoreSRAction(scoreWeight));
            dispatch(rootAction.singleRater.duplicateParticipantSRAction(participant));
      
            if (user.role == CONSTANTS.ROLE.ADMIN) {
               navigate(`${CONSTANTS.ROUTER.ADMIN_CMS}/${CONSTANTS.ROUTER.ADD_SINGLE_RATER_SURVEY}`);
            } else if (user.role == CONSTANTS.ROLE.PIC) {
               navigate(`${CONSTANTS.ROUTER.PIC_CMS}/${CONSTANTS.ROUTER.ADD_SINGLE_RATER_SURVEY}`);
            }
         }
         
      }
    }, [response])
    
 
    return <></>
 }

 export default DuplicateSinglerater;