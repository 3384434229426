import { UserPlus, Users, FolderPlus, LayoutTemplate, Folder, UserCheck, BookMarked, Headphones, Settings, UserCog, UserCog2, PowerOff, FilePlus, Files ,BookIcon, LayoutDashboard} from 'lucide-react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Sidebar, SidebarItem } from '../../../shared/component/sidebar/Sidebar';
import { CONSTANTS } from '../../../common/constants';
import { rootAction } from '../../../redux/RootAction';
import { clearAddMultiraterRedux, clearUpdateMultiraterRedux } from '../../../common/utils';

/**
 * This part of code is made using Tailwind.css
 */

export default function AdminNav(props) {
   const adminSideBar = [
      { icon: <LayoutDashboard size={20} />, text: "Dashboard", path: CONSTANTS.ROUTER.DASHBOARD },
      { icon: <FilePlus size={20} />, text: "Add Single-Rater Survey", path: CONSTANTS.ROUTER.ADD_SINGLE_RATER_SURVEY },
      { icon: <Files size={20} />, text: "Single-Rater Survey List", path: CONSTANTS.ROUTER.SINGLE_RATER_SURVEY_LIST },
      { icon: <FolderPlus size={20} />, text: "Add Multi-Rater Survey", path: CONSTANTS.ROUTER.ADD_MULTI_RATER_SURVEY },
      { icon: <Folder size={20} />, text: "Multi-Rater Survey List", path: CONSTANTS.ROUTER.MULTI_RATER_SURVEY_LIST },
      { icon: <LayoutTemplate size={20} />, text: "Report Template", path: CONSTANTS.ROUTER.REPORT_TEMPLATE },
      { icon: <Users size={20} />, text: "Target Participant History", path: CONSTANTS.ROUTER.TARGET_PARTICIPANT_DATA },
      { icon: <BookIcon size={20} />, text: "Question History", path: CONSTANTS.ROUTER.QUESTION_BANK },
      { icon: <UserCheck size={20} />, text: "Raters History", path: CONSTANTS.ROUTER.RATERS_HISTORY },
      { icon: <Headphones size={20} />, text: "Support & Introduction", path: CONSTANTS.ROUTER.ADMIN_SUPPORT },
      { icon: <Settings size={20} />, text: "Data Config", path: CONSTANTS.ROUTER.CONFIG_DATA },
      { icon: <UserCog size={20} />, text: "Admin List", path: CONSTANTS.ROUTER.ADMIN_LIST },
      { icon: <UserCog2 size={20} />, text: "PIC List", path: CONSTANTS.ROUTER.PIC_LIST },
      // { icon: <ActivitySquare size={20} />, text: "Survey History", path: CONSTANTS.ROUTER.SURVEY_HISTORY },
      { icon: <PowerOff size={20} />, text: "Sign Out", path: CONSTANTS.ROUTER.LOGIN, on_click: (() => handleLogout()) }
   ];

   const picSideBar = [
      { icon: <BookMarked size={20} />, text: "My Projects", path: CONSTANTS.ROUTER.DASHBOARD },
      { icon: <FilePlus size={20} />, text: "Add Single-Rater Survey", path: CONSTANTS.ROUTER.ADD_SINGLE_RATER_SURVEY },
      { icon: <Files size={20} />, text: "Single-Rater Survey List", path: CONSTANTS.ROUTER.SINGLE_RATER_SURVEY_LIST },
      { icon: <FolderPlus size={20} />, text: "Add Multi-Rater Survey", path: CONSTANTS.ROUTER.ADD_MULTI_RATER_SURVEY },
      { icon: <Folder size={20} />, text: "Multi-Rater Survey List", path: CONSTANTS.ROUTER.MULTI_RATER_SURVEY_LIST },
      { icon: <Users size={20} />, text: "Target Participant History", path: CONSTANTS.ROUTER.TARGET_PARTICIPANT_DATA },
      { icon: <BookIcon size={20} />, text: "Question History", path: CONSTANTS.ROUTER.QUESTION_BANK },
      { icon: <UserCheck size={20} />, text: "Raters History", path: CONSTANTS.ROUTER.RATERS_HISTORY },
      { icon: <PowerOff size={20} />, text: "Sign Out", path: CONSTANTS.ROUTER.LOGIN, on_click: (() => handleLogout()) }
   ];

   const [sidebar, setSidebar] = useState([]);

   const userReducer = useSelector((state) => state.user);

   // render first time only
   useEffect(() => {
      if (userReducer.role == 1) {
         setSidebar(adminSideBar);
      } else if (userReducer.role == 2) {
         setSidebar(picSideBar);
      }
   }, []);

   const dispatch = useDispatch();
   const navigate = useNavigate();

   const handleLogout = async () => {
      clearAddMultiraterRedux(dispatch);
      clearUpdateMultiraterRedux(dispatch);
      dispatch(rootAction.user.userLogoutAction());
      navigate(CONSTANTS.ROUTER.LOGIN, { replace: true });
   }

   return (
      <>
         <Sidebar>
            {sidebar.map((value, key) => (
               <SidebarItem key={key} icon={value.icon} text={value.text} path={value.path} last={key + 1 === sidebar.length ? true : false} on_click={value?.on_click} />
            ))}
         </Sidebar >
         <div className={`tw-h-fit tw-bg-white tw-border-r tw-flex 2xl:tw-hidden`}>
            <div className="tw-p-4">
               <div className="tw-p-2">
                  <UserPlus size={20} className="tw-text-transparent" />
               </div>
            </div>
         </div>
         <div className='tw-flex tw-w-full tw-flex-col'>
            <div className={`tw-px-4 tw-pt-[5rem] tw-flex tw-w-full 
                           tw-justify-center tw-h-full tw-overflow-auto tw-bg-slate-50`}>
               <Outlet />
            </div>
         </div>
      </>
   )
}
