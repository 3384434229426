import { Table } from 'react-bootstrap';
import { CONTENTS } from '../../constants';

const {
    SR_DATA_LIST,
    SR_DATA_LIST_GD,
    SR_DATA_LIST_GDA,
    SR_DATA_LIST_GDAI,
    SR_DATA_SCALE,
    SR_DATA_PARTCP,
    MRG_DATA_LIST,
    MRG_DATA_SCALE,
    MRG_DATA_PARTCP_ALL,
    MRG_DATA_PARTCP_NUM,
    MRG_DATA_LIST_GD,
    MRG_DATA_LIST_GDA,
    MRG_DATA_LIST_GDAI,
    MRI_DATA_LIST,
    MRI_DATA_SCALE,
    MRI_DATA_PARTCP_ALL,
    MRI_DATA_PARTCP_NUM,
    MRI_DATA_LIST_GD,
    MRI_DATA_LIST_GDA,
    MRI_DATA_LIST_GDAI
} = CONTENTS;

const DisplayData = ({ contentId, result, ordered = false }) => (
    <>
        {contentId.startsWith(MRI_DATA_LIST) ||
        contentId.startsWith(SR_DATA_LIST) ||
        contentId.startsWith(MRG_DATA_LIST) ? (
            <DisplayList contentId={contentId} values={result.values} />
        ) : [MRI_DATA_SCALE, SR_DATA_SCALE, MRG_DATA_SCALE].includes(contentId) ? (
            <DisplayScale scales={result.values} />
        ) : [MRI_DATA_PARTCP_ALL, SR_DATA_PARTCP, MRG_DATA_PARTCP_ALL].includes(
              contentId
          ) ? (
            <DisplayParticipant headers={result.headers} values={result.values} />
        ) : [MRI_DATA_PARTCP_NUM, MRG_DATA_PARTCP_NUM].includes(contentId) ? (
            <DisplayNumParticipant headers={result.headers} values={result.values} />
        ) : null}
    </>
);

const DisplayScale = ({ scales }) => {
    return (
        <div className="fw-light text-center col-md-5 mx-auto">
            <Table striped bordered hover size="sm" className="fw-light">
                <tbody>
                    {scales.map((sc) => (
                        <tr>
                            <td>{sc[0]}</td>
                            <td>{sc[1]}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

const DisplayParticipant = ({ headers, values }) => (
    <div className="fw-light text-center col-md-5 mx-auto">
        <Table striped bordered hover size="sm" className="fw-light">
            <thead>
                <tr>
                    <th>{headers[0]}</th>
                    <th>{headers[1]}</th>
                </tr>
            </thead>
            <tbody>
                {values.map((pt) => (
                    <tr>
                        <td className="text-start">{pt[0]}</td>
                        <td>{pt[1]}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    </div>
);

const DisplayNumParticipant = ({ headers, values }) => {
    const list = [
        ['Self', 1],
        ['Subordinate', 2],
        ['Peer', 5],
        ['Supervisor', 1],
        ['Total', 9]
    ];

    return (
        <div className="fw-light text-center col-md-6 mx-auto">
            <Table striped bordered hover size="sm" className="fw-light">
                <thead>
                    <tr>
                        <th>{headers[0]}</th>
                        <th>{headers[1]}</th>
                        <th>{headers[2]}</th>
                        <th>{headers[3]}</th>
                    </tr>
                </thead>
                <tbody>
                    {values.map((rt, i) => (
                        <tr className={i == values.length - 1 ? 'fw-bold' : ''}>
                            <td className="text-start">{rt[0]}</td>
                            <td>{rt[1]}</td>
                            <td>{rt[2]}</td>
                            <td>{rt[3]}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

const DisplayList = ({ contentId, values }) => {
    const isMultiLevel = [
        MRI_DATA_LIST_GD,
        MRI_DATA_LIST_GDA,
        MRI_DATA_LIST_GDAI,
        MRG_DATA_LIST_GD,
        MRG_DATA_LIST_GDA,
        MRG_DATA_LIST_GDAI,
        SR_DATA_LIST_GD,
        SR_DATA_LIST_GDA,
        SR_DATA_LIST_GDAI
    ].includes(contentId);

    const isLevel6 = [
        MRI_DATA_LIST_GDA,
        MRI_DATA_LIST_GDAI,
        MRG_DATA_LIST_GDA,
        MRG_DATA_LIST_GDAI,
        SR_DATA_LIST_GDA,
        SR_DATA_LIST_GDAI
    ].includes(contentId);

    const isLevel7 = [MRI_DATA_LIST_GDAI, MRG_DATA_LIST_GDAI, SR_DATA_LIST_GDAI].includes(
        contentId
    );

    const style = isMultiLevel
        ? { fontSize: '13px' }
        : {
              fontSize: '13px',
              listStyle: 'decimal inside'
          };

    return (
        <div className="px-3 py-1">
            <ul className="list-unstyled mb-0" style={style}>
                {isMultiLevel
                    ? values.map((gd) => (
                          <li>
                              <div className="text-bg-info p-2">
                                  <b>G: {gd['group']}</b>
                              </div>
                              <List>
                                  {gd['dimensions'].map((gda) => (
                                      <li>
                                          <div className="text-bg-light p-2">
                                              <b>D: {gda['dim']}</b>
                                          </div>
                                          {isLevel6
                                              ? gda['aspects'].map((gdai) => (
                                                    <List>
                                                        <li>
                                                            <b>{gdai['aspect']}</b>
                                                            {isLevel7 ? (
                                                                <List>
                                                                    {gdai['items'].map(
                                                                        (item) => (
                                                                            <li>
                                                                                {item}
                                                                            </li>
                                                                        )
                                                                    )}
                                                                </List>
                                                            ) : null}
                                                        </li>
                                                    </List>
                                                ))
                                              : null}
                                      </li>
                                  ))}
                              </List>
                          </li>
                      ))
                    : values.map((item) => <li>{item}</li>)}
            </ul>
        </div>
    );
};

const List = ({ children }) => <ul className="list-unstyled ms-4">{children}</ul>;

export default DisplayData;
