import { useEffect, useState } from "react";
import { ButtonPrimary } from "../../../shared/component/button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faEnvelope, faShareNodes } from "@fortawesome/free-solid-svg-icons";
import { ShowEntries } from "../../../shared/component/table_property/ShowEntries";
import { SearchColumn } from "../../../shared/component/table_property/SearchColumn";
import { IconButtonDelete, IconButtonDuplicate, IconButtonEdit, IconButtonPrimary } from "../../../shared/component/button/IconButton";
import { DropdownIcon } from "../../../shared/component/dropdown/DropdownInput";
import { Dropdown } from "react-bootstrap";
import ModalSharingSurvey from "../sharing_survey/ModalSharingSurvey";
import { useNavigate } from "react-router-dom";
import { CONSTANTS } from "../../../common/constants";
import useMultiraterList from "./useMultiraterList";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../../shared/component/table_property/Paginatination";
import LoadingBar from "react-top-loading-bar";
import { clearAddMultiraterRedux, clearReportUrlRedux, clearUpdateMultiraterRedux } from "../../../common/utils";
import screenAlert from "../../../shared/hook/SweetAlert";
import DuplicateMultirater from "../duplicate_multirater/DuplicateMultiraterView";
import { Loading } from "../../../shared/component/loading/Loading";

function MultiraterListView() {
   const {
      allSurvey,
      pageNumber,
      setPageNumber,
      searchText,
      setSearchText,
      showItem,
      setShowItem,
      totalItem,
      handleChangeSearchText,
      handleDeleteSurvey,
      handleSearchBar,
      handleShowItem,
      onSendInvitationtoAllRaters,
      progress,
      setProgress,
      loading

   } = useMultiraterList();
   const [openFilter, setOpenFilter] = useState(false);
   const [openShare, setOpenShare] = useState(false);
   const navigate = useNavigate();
   const user = useSelector((state) => state.user);
   const dispatch = useDispatch();
   const showAlert = screenAlert();
   const [isDuplicate, setIsDuplicate] = useState(false);
   const [surveyCode, setSurveyCode] = useState('');

   const [shareSurvey, setShareSurvey] = useState({
      survey_code: '',
      survey_name:'',
      owner:'',
      shared_by:user.email
   })

   //handler share survey
   const handleShareSurvey = (survey_code,survey_name,created_by) => {
      setShareSurvey({
         survey_code:survey_code,
         survey_name: survey_name,
         owner:created_by,
         shared_by:user.email
      })
      setOpenShare(true)
   }

   // handler
   const paginate = (pageNumber) => {
      setPageNumber(pageNumber);
   };

   const previousPage = () => {
      if (pageNumber != 1) {
         setPageNumber(pageNumber - 1);

      }
   };

   const nextPage = () => {
      if (pageNumber != Math.ceil(totalItem / showItem)) {
         setPageNumber(pageNumber + 1);
      }
   };

   const handleNavigateAddMultiraterPage = () => {
      if (user.role == CONSTANTS.ROLE.ADMIN) {
         navigate(`${CONSTANTS.ROUTER.ADMIN_CMS}/${CONSTANTS.ROUTER.ADD_MULTI_RATER_SURVEY}`)
      } else {
         navigate(`${CONSTANTS.ROUTER.PIC_CMS}/${CONSTANTS.ROUTER.ADD_MULTI_RATER_SURVEY}`)
      }
   }

   const onDuplicateMultiRater = async (e, surveyCode) => {
      e.preventDefault();

      showAlert.info({
         text: "Are you sure to duplicate?\nThis will replace your progress on adding a multirater survey (if any).",
         buttons: {
            cancel: {
               text: "Cancel",
               className: "bg-white text-dark"
            },
            confirm: {
               text: "Confirm",
               value: true,
               className: "bg-success"
            }
         }
      }).then(value => {
         if (value) {
            clearAddMultiraterRedux(dispatch);
            setIsDuplicate(true);
            setSurveyCode(surveyCode);
         }
      });
   }

   //effect
   useEffect(() => {
      clearUpdateMultiraterRedux(dispatch);
      clearReportUrlRedux(dispatch);
   }, []);

   return (
      <>
         <LoadingBar color="#7749F8" progress={progress} onLoaderFinished={() => setProgress(0)} />
        
         <div className="container tw-h-max">
            <div className='my-4 d-flex justify-content-between'>
               <h4>Multi-Rater Project List</h4>
            </div>
            <div className='my-4 d-flex justify-content-between align-items-center'>
               <span className="fst-italic">List all the projects you have created or shared with me.</span>
               <div className="d-flex justify-content-end">
                  <ButtonPrimary label={'Add'} onClick={handleNavigateAddMultiraterPage} />
               </div>
            </div>

            <div className='card shadow p-4 border-0' >
               <div className='justify-content-between mb-4 d-flex flex-row'>
                  <div className='col-4 d-flex flex-row align-items-center'>
                     <div>
                        Show
                     </div>

                     <ShowEntries onClick_item={handleShowItem} default_item={showItem} />
                     <div className=''>
                        entries
                     </div>
                  </div>

                  <div className='col-3 d-flex align-items-center'>
                    
                     <SearchColumn onChange_input={handleChangeSearchText} onClick_btn={handleSearchBar} />

                    
                  </div>

               </div>

               <div className='mt-1 table-responsive'>
                  <table className="table table-striped table-bordered">
                     <thead>
                        <tr>
                           <th rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }} >No</th>
                           <th rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>Project Name</th>
                           <th rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>Goal</th>
                           <th rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>Owner</th>
                           <th rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>Status</th>
                           <th rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>Your Role</th>
                           <th colSpan={3} style={{ verticalAlign: 'middle', textAlign: 'center' }}>Actions</th>
                           <th rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }} >Invitation</th>
                           <th rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }} >Sharing <br></br> Survey</th>

                        </tr>

                        <tr>
                           <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>Duplicate</th>
                           <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>Update</th>
                           <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>Delete</th>
                        </tr>

                     </thead>
                     <tbody>
                        {Array.isArray(allSurvey) && allSurvey.length != 0 ?
                           allSurvey.map((dt, i) => {
                              return (<tr key={i} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                 {
                                    pageNumber == 1 ? <td>{i + 1}</td> : <td>{(i + 1) + (showItem * (pageNumber - 1))}</td>
                                 }
                                 <td style={{ textAlign: 'start' }}>{dt.survey_name}</td>
                                 <td style={{ textAlign: 'start' }}>{dt.goal_name}</td>
                                 <td style={{ textAlign: 'start' }}>{dt.created_by}</td>
                                 <td>{dt.is_active == 0 ? 'not active' : 'active'}</td>
                                 <td> 
                                    {user.role == 1 ? 'admin' : dt.ownership_role != 'NULL'   ? dt.ownership_role : dt.created_by == user.email  ? 'owner' : 'viewer'}
                                    
                                    </td>
                                 <td>
                                    <IconButtonDuplicate onClick={(e) => onDuplicateMultiRater(e, dt.survey_code)} />
                                 </td>
                                 <td>
                                    <IconButtonEdit onClick={() => navigate(`${CONSTANTS.ROUTER.UPDATE_MULTIRATER}`, { state: { survey_code: dt.survey_code , ownership: dt.ownership_role} })} 
                                    disabled={dt.ownership_role == 'explorer' || dt.ownership_role == 'editor' || dt.created_by == user.email || user.role == 1 ? false : true}
                                   
                                    />
                                 </td>
                                 <td>
                                    <IconButtonDelete disabled={dt.created_by == user.email || user.role == 1 ? false : true} onClick={() => handleDeleteSurvey(dt.survey_code)} />
                                 </td>
                                 <td>
                                    <DropdownIcon
                                       disabled={dt.created_by == user.email || user.role == 1 || dt.ownership_role == 'editor' ? false : true}
                                       icon_default={faEnvelope}
                                       size_icon={'xl'}

                                       dropdown_item={
                                          invite.map((inv, i) => {
                                             return (
                                                <Dropdown.Item key={i}>
                                                   <div className="d-flex" onClick={() => onSendInvitationtoAllRaters(dt.survey_code)}>
                                                      <div className="me-2">
                                                         {inv.icon}
                                                      </div>
                                                      <div>
                                                         {inv.label}
                                                      </div>
                                                   </div>
                                                </Dropdown.Item>
                                             )
                                          })
                                       }

                                    />
                                 </td>
                                 <td>
                        <IconButtonPrimary icon={faShareNodes} disabled={dt.created_by == user.email || user.role == 1 ? false : true}
                          onClick={() => handleShareSurvey(dt.survey_code,dt.survey_name,dt.created_by)}
                        />
                      </td>


                              </tr>)
                           }) : <tr>
                              <td colSpan={11} style={{ textAlign: 'center', verticalAlign: 'middle' }}>No data</td>
                           </tr>
                        }
                     </tbody>

                  </table>
               </div>
               <Pagination showing={Array.isArray(allSurvey) ? allSurvey.length : 0} totalItem={totalItem} showItem={showItem} previousPage={previousPage} nextPage={nextPage} paginate={paginate} limit={pageNumber} />

            </div>
            <ModalSharingSurvey
               show={openShare}
               onHide={() => setOpenShare(false)}
               title={`Sharing Survey`}
               shared_by={shareSurvey.shared_by}
               owner={shareSurvey.owner}
               survey_code={shareSurvey.survey_code}
               survey_name={shareSurvey.survey_name}
            />

            {isDuplicate && <DuplicateMultirater surveyCode={surveyCode} />}
         </div>
      </>
   )
}

export default MultiraterListView;


const dataSurvey = [
   {
      project_name: 'Project Survey 1',
      goal: 'engagement',
      owner: 'emailowner1@mail.com',
      status: 'active',
      your_role: 'viewer',
   },
   {
      project_name: 'Project Survey 2',
      goal: 'Leadership Development',
      owner: 'emailowner2@mail.com',
      status: 'non active',
      your_role: 'explorer',
   },
   {
      project_name: 'Project Survey 3',
      goal: 'engagement',
      owner: 'emailowner3@mail.com',
      status: 'active',
      your_role: 'editor',
   },
   {
      project_name: 'Project Survey 4',
      goal: 'engagement',
      owner: 'emailowner4@mail.com',
      status: 'active',
      your_role: 'owner',
   },
   {
      project_name: 'Project Survey 5',
      goal: 'engagement',
      owner: 'emailowner5@mail.com',
      status: 'active',
      your_role: 'viewer',
   },
   {
      project_name: 'Project Survey 6',
      goal: 'Leadership Development',
      owner: 'emailowner6@mail.com',
      status: 'non active',
      your_role: 'explorer',
   },
   {
      project_name: 'Project Survey 7',
      goal: 'engagement',
      owner: 'emailowner7@mail.com',
      status: 'active',
      your_role: 'editor',
   },
   {
      project_name: 'Project Survey 8',
      goal: 'engagement',
      owner: 'emailowner8@mail.com',
      status: 'active',
      your_role: 'owner',
   }
]

export const invite = [
   {
      icon: <FontAwesomeIcon icon={faPaperPlane} size={'lg'} style={{ color: '54595E' }} />,
      label: 'Send Invitation',

   },
   // {
   //   icon: <FontAwesomeIcon icon={faShareFromSquare} size={'lg'} style={{ color: '54595E' }} />,
   //   label: 'Send Reminder'
   // },
   // {
   //   icon: <FontAwesomeIcon icon={faClockRotateLeft} size={'lg'} style={{ color: '54595E' }} />,
   //   label: 'History Invitation'
   // },

]

