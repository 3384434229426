export const UserMasterService = ({ doPost }) => {
   const checkNIK = async (nik) => {
      try {
         return await doPost({
            url: `/admin/check_user_master_admin`,
            data: {
               user_nik: nik,
            },
         });
      } catch (e) {
         throw e;
      }
   }

   const checkNIKPICUrl = async (nik) => {
      try {
         return await doPost({
            url: `/pic/check_user_master_admin`,
            data: {
               user_nik: nik,
            },
         });
      } catch (e) {
         throw e;
      }
   }

   const getEmailParticipantbyNIK = async (nik) => {
      try {
         return await doPost({
            url: `/admin/get_email_by_nik`,
            data: {
               nik: nik,
            },
         });
      } catch (e) {
         throw e;
      }
   }

   const getEmailParticipantbyNIKPICUrl = async (nik) => {
      try {
         return await doPost({
            url: `/pic/get_email_by_nik`,
            data: {
               nik: nik,
            },
         });
      } catch (e) {
         throw e;
      }
   }

   

   return {
      checkNIK,
      checkNIKPICUrl,
      getEmailParticipantbyNIK,
      getEmailParticipantbyNIKPICUrl,
   };
};