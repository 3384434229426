import {
  faWhatsapp,
  faWhatsappSquare,
} from "@fortawesome/free-brands-svg-icons";
import {
  faCopy,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import walogo from "../../../assets/svg/walogo.svg";
import telephonelogo from "../../../assets/svg/telephonelogo.svg";
import emaillogo from "../../../assets/svg/emaillogo.svg";
import faqlogo from "../../../assets/svg/faqlogo.svg";

import { IconButtonGeneral } from "../../../shared/component/button/IconButton";
import { ButtonLink } from "../../../shared/component/button/Button";
import { useNavigate } from "react-router-dom";
import { CONSTANTS } from "../../../common/constants";
import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { UseContactUs } from "./useContactUs";

function ParticipantContactUsView() {
  const navigate = useNavigate();
  const [copiedItem, setCopiedItem] = useState(null);
  const { contactUsWhatsapp, contactUsNumber, contactUsEmail, contactMessage } =
    UseContactUs();

  const handleCopy = (itemName) => {
    setCopiedItem(itemName);
    setTimeout(() => setCopiedItem(null), 2000);
  };

  return (
    <div className="container tw-h-max tw-max-w-[1400px]">
      <div className="mb-4 col-12">
        <h4>Contact Us</h4>
      </div>
      <div
        className="p-4 mb-4 tw-rounded-lg"
        style={{ boxShadow: "0px 0.1rem 0.1rem 0px rgba(0, 0, 0, 0.25)" }}
      >
        <div className="d-flex">
          <FontAwesomeIcon icon={faTriangleExclamation} size="xl" />
          <div className="ms-2">
            <h5>Important</h5>
          </div>
        </div>
        <div>{contactMessage}</div>
      </div>

      <div className="col-12 d-flex justify-content-between">
        <div
          className={`col-5 my-4 p-4 tw-rounded-lg d-flex align-items-center ${
            copiedItem === "whatsapp" || "email" || "number" ? "copied" : ""
          }`}
          style={{ boxShadow: "0px 0.1rem 0.1rem 0px rgba(0, 0, 0, 0.25)" }}
        >
          <div className="col-1 me-4 mb-2">
            <img src={walogo} alt="" />
          </div>

          <div className="col-11">
            <div className="col-12">
              <h5>WhatsApp</h5>
            </div>
            <div className="col-12">
              <CopyToClipboard
                text={contactUsWhatsapp}
                onCopy={() => handleCopy("whatsapp")}
              >
                <span>
                  {contactUsWhatsapp}
                  <span style={{ marginLeft: "1rem" }}>
                    <IconButtonGeneral
                      icon={faCopy}
                      color_enabled={"#7749F8"}
                    />
                  </span>
                </span>
              </CopyToClipboard>
              {copiedItem === "whatsapp" && (
                <span style={{ color: "green" }}>Copied!</span>
              )}
            </div>
          </div>
        </div>

        <div
          className={`col-5 my-4 p-4 tw-rounded-lg d-flex align-items-center ${
            copiedItem === "email" ? "copied" : ""
          }`}
          style={{ boxShadow: "0px 0.1rem 0.1rem 0px rgba(0, 0, 0, 0.25)" }}
        >
          <div className="col-1 me-4 mb-2">
            <img src={emaillogo} alt="" />
          </div>

          <div className="col-11">
            <div className="col-12">
              <h5>EMAIL</h5>
            </div>
            <div className="col-12">
              <CopyToClipboard
                text={contactUsEmail}
                onCopy={() => handleCopy("email")}
              >
                <span>
                  {contactUsEmail}
                  <span style={{ marginLeft: "1rem" }}>
                    <IconButtonGeneral
                      icon={faCopy}
                      color_enabled={"#7749F8"}
                    />
                  </span>
                </span>
              </CopyToClipboard>
              {copiedItem === "email" && (
                <span style={{ color: "green" }}>Copied!</span>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 d-flex justify-content-between">
        <div
          className={`col-5 my-4 p-4 tw-rounded-lg d-flex align-items-center ${
            copiedItem === "number" ? "copied" : ""
          }`}
          style={{ boxShadow: "0px 0.1rem 0.1rem 0px rgba(0, 0, 0, 0.25)" }}
        >
          <div className="col-1 me-4 mb-2">
            <img src={telephonelogo} alt="" />
          </div>

          <div className="col-11">
            <div className="col-12">
              <h5>Number</h5>
            </div>
            <div className="col-12">
              <CopyToClipboard
                text={contactUsNumber}
                onCopy={() => handleCopy("number")}
              >
                <span>
                  {contactUsNumber}
                  <span style={{ marginLeft: "1rem" }}>
                    <IconButtonGeneral
                      icon={faCopy}
                      color_enabled={"#7749F8"}
                    />
                  </span>
                </span>
              </CopyToClipboard>
              {copiedItem === "number" && (
                <span style={{ color: "green" }}>Copied!</span>
              )}
            </div>
          </div>
        </div>

        <div
          className="col-5 my-4 p-4 tw-rounded-lg d-flex align-items-center"
          style={{ boxShadow: "0px 0.1rem 0.1rem 0px rgba(0, 0, 0, 0.25)" }}
        >
          <div className="col-1 me-4 mb-2">
            <img src={faqlogo} alt="" />
          </div>

          <div className="col-11">
            <div className="col-12">
              <h5>FAQ</h5>
            </div>
            <div className="col-12">
              <ButtonLink
                onClick={() =>
                  navigate(
                    `${CONSTANTS.ROUTER.PARTICIPANT_DASHBOARD}/${CONSTANTS.ROUTER.PARTICIPANT_FAQ}`
                  )
                }
                label=" Click here to see a list of FAQ"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ParticipantContactUsView;
