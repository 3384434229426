import { useState, useEffect } from "react";
import { useDeps } from "../../../shared/context/DependencyContext";
import swal from "sweetalert";
import screenToast from "../../../shared/hook/ScreenToast";
import useViewState from "../../../shared/hook/UseViewState";
import screenAlert from "../../../shared/hook/SweetAlert";

function useFaq() {
    const {adminService} = useDeps();
    const [allFaq, setAllFaq] = useState([]);
    const [showItem, setShowItem] = useState(10);
    const [changeData, setChangeData] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [totalItem, setTotalItem] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [answerSelect, setAnswerSelect] = useState('');
    const [questionSelect, setQuestionSelect] = useState('');
    const [idSelect, setIdSelect] = useState(0);
    const [modalAdd, setModalAdd] = useState(false);
    const [modalEdit,setModalEdit] = useState(false);
    const showToast = screenToast();
    const { viewState, setError, setSuccess } = useViewState();
    const [progress, setProgress] = useState(0)
    const showAlert = screenAlert();

    useEffect(() => {
        if (pageNumber == 1) {
            onGetSearchFaq(searchText,showItem, pageNumber);
        }
        setPageNumber(1);

    }, [showItem, changeData])

    useEffect(() => {
       onGetSearchFaq(searchText,showItem, pageNumber);
    }, [pageNumber])

    useEffect(() => {
        if (viewState.isError == true) {
            showToast.error(viewState.errorMsg);
            setError(false, '');
        }

        if (viewState.isSuccess == true) {
            showToast.success(viewState.successMsg);
            setSuccess(false, '');
        }
    }, [viewState]);
    

    const onPostFaq = async (question,answer) => {
        try {
            const response = await adminService.faqService.postFaq(question,answer);

            if (response.status == 'SUCCESS') {
              setChangeData(!changeData);
              setSuccess(true,'Saved Successfully')
              
            }
        } catch (error) {
            console.log(error)
           
        } finally {
            // console.log('finally handlePostFaq')
        }
    }

    const onGetSearchFaq = async (search_text,limit,page_number) => {
        setProgress(10);
        try {
            setProgress(30);
            const response = await adminService.faqService.getFaqSearch(search_text,limit,page_number);
            setProgress(60);
            if (response.status == 'SUCCESS') {
                setAllFaq(response.data.data);
                setTotalItem(response.data.totalItem)
            }
        } catch (error) {
            console.log(error)
        } finally {
            // console.log('finally onGetSearchFaq')
            setProgress(100);
        }
    }

    const onUpdateFaq = async (id,question,answer) => {
        // console.log(`id ${idSelect}`)
        // console.log(`questionSelect ${questionSelect}`)
        // console.log(`answerSelect ${answerSelect}`)
        
        try {
            const response = await adminService.faqService.updateFaq(id,question,answer);

            if (response.status == 'SUCCESS') {
                setChangeData(!changeData);
                setQuestionSelect('');
                setAnswerSelect('');
                setIdSelect(0);
                setModalEdit(false);
                setSuccess(true,'Saved Successfully')

            }
            // console.log("===========RESPONSE UPDATE============");
            // console.log(response);
            // else {
            //     setError(true,`${response.status } to update`);
            // }
        } catch (error) {
            console.log(error)

        } finally {
            // console.log('finally onUpdateFaq')
        }
    }

    const onDeleteFaq = async (id) => {
        try {
            const response = await adminService.faqService.deleteFaq(id);

            if (response.status == 'SUCCESS') {
                setChangeData(!changeData);
              setSuccess(true,'Deleted Successfully')

            }else {
              }
        } catch (error) {
            console.log(error)
        } finally {
            // console.log('finally onDeleteFaq')
        }
    }

    const handleDelete = (id) => {
        showAlert.warning({
            text: "Are you sure to delete this?",
            buttons: {
                cancel: {
                    text: "Cancel",
                    className: ""
                },
                confirm: {
                    text: "Yes",
                    value: true,
                    className: "bg-success"
                }
            }
        }).then(value => {
            if (value) {
                onDeleteFaq(id);
            }
        });
    }

    const handleChangeAnswer = (e) => {
        setAnswerSelect(e.target.value)
    }

    const handleChangeQuestion = (e) => {
        setQuestionSelect(e.target.value)
    }
    
    const handleAddSave = async () => {
        if (questionSelect == '' || answerSelect == '') {
        //   console.log('incompleted data');
        }else {
            await onPostFaq(questionSelect,answerSelect);
            setQuestionSelect('');
            setAnswerSelect('');
            setIdSelect(0);
            setModalAdd(false);
        }
    }

    const handleOpenModalEdit = (id,qst,ans) => {
        // console.log("handleOpenModalEdit");
        // console.log(id);
        // console.log(qst);
        // console.log(ans);


        setQuestionSelect(qst);
        setAnswerSelect(ans);
        setIdSelect(id);
        setModalEdit(true);
    }

    const handleSaveUpdate = async () => {
       await onUpdateFaq(idSelect,questionSelect,answerSelect);
    }
    

   

    const handleHideModalEdit =  () => {
        setQuestionSelect('');
        setAnswerSelect('');
        setIdSelect(0);
        setModalEdit(false);

    }

    const handleSearchData = () => {
        if (pageNumber == 1) {
            onGetSearchFaq(searchText,showItem, pageNumber)
        } else {
            setPageNumber(1);
        }
    }

    const handleInputSearchChange = (e) => {
        setSearchText(e.target.value);
        
    }

    const handleshowItem = (item) => {
        setShowItem(item);
    }

    return{
        allFaq,
        onPostFaq,
        searchText,
        setSearchText,
        answerSelect,
        setAnswerSelect,
        pageNumber,
        showItem,
        totalItem,
        setPageNumber,
        onUpdateFaq,
        onDeleteFaq,
        questionSelect,
        setQuestionSelect,
        idSelect,
        setIdSelect,
        setShowItem,
        handleChangeAnswer,
        handleChangeQuestion,
        modalAdd,
        setModalAdd,
        modalEdit,
        setModalEdit,
        handleAddSave,
        handleHideModalEdit,
        handleOpenModalEdit,
        handleSaveUpdate,
        onGetSearchFaq,
        handleDelete,
        handleSearchData,
        handleInputSearchChange,
        handleshowItem,
        progress,
        setProgress

    }
}
export default useFaq;