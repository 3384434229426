import { useState } from "react";
import { useDeps } from "../../shared/context/DependencyContext";
import { useEffect } from "react";
import swal from "sweetalert";
import screenToast from "../../shared/hook/ScreenToast";
import useViewState from "../../shared/hook/UseViewState";
import { useSelector } from "react-redux";
import { CONSTANTS } from "../../common/constants";
import screenAlert from "../../shared/hook/SweetAlert";

function useConfigData(params) {
    const [goal, setGoal] = useState('');
    const [errorGoal, setErrorGoal] = useState('');
    const [isloading, setIsloading] = useState(false)
    const [allGoal, setAllGoal] = useState([]);
    const { adminService, picService } = useDeps();
    const [showModal, setShowModal] = useState(false);
    const [changeData, setChangeData] = useState(false);
    const showToast = screenToast();
    const { viewState, setError, setSuccess } = useViewState();
    const [progress, setProgress] = useState(0);
    const user = useSelector((state) => state.user);
    const showAlert = screenAlert();


    useEffect(() => {
        if (viewState.isError == true) {
            showToast.error(viewState.errorMsg);
            setError(false, '');
        }

        if (viewState.isSuccess == true) {
            showToast.success(viewState.successMsg);
            setSuccess(false, '');
        }
    }, [viewState]);

    useEffect(() => {
        onGetAllGoal();
    }, [changeData]);

    const onPostGoalData = async () => {
        setIsloading(true);
        try {
            const response = await adminService.configDataService.postGoal(goal);
            if (response.status == 'SUCCESS') {
                setShowModal(false);
                setChangeData(!changeData);
                setGoal('');
                setSuccess(true,'Saved Successfully')
            }else {
                setError(true,response.status)
            }
        } catch (error) {
            console.log(error)
            setError(true,'Error System')
        } finally {
            setIsloading(false);
        }
    }

    const onGetAllGoal = async () => {
        setProgress(10);
        setIsloading(true);
        try {
            setProgress(30);
            if (user.role == CONSTANTS.ROLE.ADMIN) {
                const response = await adminService.configDataService.getAllGoals();
                setProgress(60);
                if (response.status == 'SUCCESS') {
                    if (response.data != 'NO DATA') {
                        setAllGoal(response.data);
                    }
                }
            } else {
                const response = await picService.configDataService.getAllGoalsPICUrl();
                setProgress(60);
                if (response.status == 'SUCCESS') {
                    if (response.data != 'NO DATA') {
                        setAllGoal(response.data);
                    }
                }
                
            }

        } catch (error) {
            console.log(error);

        } finally {
            setIsloading(false);
            setProgress(100);
        }

    }

    const onDeleteGoal = async (id) => {
        setIsloading(true);
        try {
            const response = await adminService.configDataService.deleteGoal(id);
            if (response.status == 'SUCCESS') {
                setChangeData(!changeData);
            setSuccess(true,'Deleted Successfully')
            }else {
                setError(true,response.status)
            }
        } catch (error) {
            console.log(error)
            setError(true,'Error System')
        } finally {
            setIsloading(false);
        }
    }

    const handleChangeGoal = (e) => {
        setGoal(e.target.value);
    };

    const handleErrorGoal = (value) => {
        setErrorGoal(value);
    };

    const handleDeleteGoal = (id) => {
        showAlert.warning({
            text: "Are you sure to delete this?",
            buttons: {
                cancel: {
                    text: "Cancel",
                    className: ""
                },
                confirm: {
                    text: "Yes",
                    value: true,
                    className: "bg-success"
                }
            }
        }).then(value => {
            if (value) {
                onDeleteGoal(id)
            }
        });
       
    }




    return {
        goal,
        handleChangeGoal,
        errorGoal,
        handleErrorGoal,
        allGoal,
        onGetAllGoal,
        onPostGoalData,
        showModal,
        setShowModal,
        handleDeleteGoal,
        isloading,
        progress,
        setProgress
    }


}

export default useConfigData;