import { SET_SIDEBAR } from "./PreferencesType";

// initial state
const initPreferenceState = {
   expanded_sidebar: true
}

// define reducer action
export function preferenceReducer(state = initPreferenceState, action) {
   switch (action.type) {
      case SET_SIDEBAR:
         return {
            ...state,
            [action.data.field]: action.data.payload
         }
      default:
         return state;
   }
}