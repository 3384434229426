import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { faFile, faFileExcel, faFileWord } from '@fortawesome/free-solid-svg-icons';
import useTabReport from './useTabReport';
import usePagination from '../../../report_template/utils/usePagination';
import useRouting from '../../../report_template/utils/useRouting';
import { rootAction } from '../../../../redux/RootAction';
import { REPORT_TYPE } from '../../../report_template/constants';
import { ButtonPrimary } from '../../../../shared/component/button/Button';
import { IconButtonGeneral } from '../../../../shared/component/button/IconButton';
import { SearchColumn } from '../../../../shared/component/table_property/SearchColumn';
import { ShowEntries } from '../../../../shared/component/table_property/ShowEntries';
import Pagination from '../../../../shared/component/table_property/Paginatination';
import FormSelect from '../../../report_template/components/dropdown/FormSelect';
import { DivRow, BtnMini, DownloadSpinner } from './Components';

function TabReportView({ survey_code }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [surveyCode, setSurveyCode] = useState(location.state);
    const participantsReducer = useSelector(
        (state) => state.update_participant_multi_rater
    );
    const reportUrl = useSelector((state) => state.reportTemplateUrl);
    const groupRef = useRef(null);
    const [groupOpt, setGroupOpt] = useState('');
    const [individuOpt, setInidividuOpt] = useState('');
    const [groupBy, setGroupBy] = useState('');
    const [isGroupDownload, setIsGroupDownload] = useState(false);
    const [selectedPid, setSelectedPid] = useState(null);
    const {
        showItem,
        pageNumber,
        setPageNumber,
        handleShowItem,
        getIndexNo,
        paginationProps
    } = usePagination();
    const {
        showToast,
        user,
        colors,
        itemSelect,
        groupTemplates,
        setGroupTemplates,
        individualTemplates,
        setIndividualTemplates,
        participants,
        setParticipants,
        searchText,
        isDownloading,
        downloadPercentage,
        handleChangeSearchText,
        handleLoadSurveyReport,
        handleLoadTemplatesByType,
        handleDownloadReport,
        handleDownloadDocument
    } = useTabReport();
    const {
        pathname,
        urlAddTemplateMRG,
        urlEditTemplateMRG,
        urlAddTemplateMRI,
        urlEditTemplateMRI,
        urlReportPreviewTemplate
    } = useRouting(user.role); //CONSTANTS.ROLE.PIC
    const urlProps = {
        __prev: pathname,
        __state: {
            tab_report: 5,
            state: { survey_code: surveyCode.survey_code }
        }
    };

    // Handlers

    const handleNavigateTemplateGroup = (e) => {
        dispatch(
            rootAction.reportTemplate.updateTemplate({
                type: REPORT_TYPE.MULTI_GROUP,
                survey_code: surveyCode.survey_code,
                code: '',
                name: '',
                lang: ''
            })
        );
        dispatch(
            rootAction.reportTemplate.setUrlData({
                ...urlProps,
                survey_code: surveyCode.survey_code,
                group_option: 1,
                individu_option: ''
            })
        );
        if (reportUrl.group_option == 1) {
            navigate(urlEditTemplateMRG(), { state: { code: reportUrl.group_code } });
        } else {
            navigate(urlAddTemplateMRG());
        }
    };
    const handleChangeTemplateGroup = (e) => {
        dispatch(
            rootAction.reportTemplate.setUrlData({
                survey_code: surveyCode.survey_code,
                group_code: e.target.value,
                group_option: 2
            })
        );
    };
    const handleNavigateTemplateIndividu = (e) => {
        dispatch(
            rootAction.reportTemplate.updateTemplate({
                type: REPORT_TYPE.MULTI_INDIVIDU,
                survey_code: surveyCode.survey_code,
                code: '',
                name: '',
                lang: ''
            })
        );
        dispatch(
            rootAction.reportTemplate.setUrlData({
                ...urlProps,
                survey_code: surveyCode.survey_code,
                individu_option: 1,
                group_option: ''
            })
        );
        if (reportUrl.individu_option == 1) {
            navigate(urlEditTemplateMRI(), { state: { code: reportUrl.individu_code } });
        } else {
            navigate(urlAddTemplateMRI());
        }
    };
    const handleChangeTemplateIndividu = (e) => {
        dispatch(
            rootAction.reportTemplate.setUrlData({
                survey_code: surveyCode.survey_code,
                individu_code: e.target.value,
                individu_option: 2
            })
        );
    };
    const handleNextPage = () => {
        if (pageNumber !== Math.ceil(participants.length / showItem)) {
            setPageNumber(pageNumber + 1);
        }
    };
    const handleSearchBar = () => {
        setParticipants(
            participantsReducer.filter((item) =>
                item?.full_name.toLowerCase().includes(searchText.toLowerCase())
            )
        );
    };
    const alertDownloadPreviewGroup = () => {
        if (!reportUrl.group_code) {
            showToast.error('Grup Report Template belum dipilih!');
        }
        if (!groupBy) {
            showToast.error('Kategori Grup Report belum dipilih!');
            if (groupRef.current) {
                groupRef.current.focus();
            }
        }
    };
    const handleDownloadDocIndividu = (pid) => {
        if (reportUrl.individu_code) {
            setIsGroupDownload(false);
            setSelectedPid(pid);
            handleDownloadDocument(
                REPORT_TYPE.MULTI_INDIVIDU,
                reportUrl.individu_code,
                survey_code,
                pid
            );
        } else {
            showToast.error('Report Template Individu belum dipilih!');
        }
    };
    const handleDownloadDocGroup = () => {
        alertDownloadPreviewGroup();

        if (reportUrl.group_code && groupBy) {
            setIsGroupDownload(true);
            handleDownloadDocument(
                REPORT_TYPE.MULTI_GROUP,
                reportUrl.group_code,
                survey_code,
                null,
                groupBy
            );
        }
    };
    const handleReportPreview = (pid) => {
        if (reportUrl.individu_code) {
            dispatch(
                rootAction.reportTemplate.setUrlPreviewData({
                    __prev: pathname,
                    report_type: REPORT_TYPE.MULTI_INDIVIDU,
                    tpl_code: reportUrl.individu_code,
                    survey_code: survey_code,
                    participant_id: pid,
                    group_by: null
                })
            );
            navigate(urlReportPreviewTemplate());
        } else {
            showToast.error('Report Template Individu belum dipilih!');
        }
    };
    const handleReportPreviewGroup = () => {
        alertDownloadPreviewGroup();

        if (reportUrl.group_code && groupBy) {
            dispatch(
                rootAction.reportTemplate.setUrlPreviewData({
                    __prev: pathname,
                    report_type: REPORT_TYPE.MULTI_GROUP,
                    tpl_code: reportUrl.group_code,
                    survey_code: survey_code,
                    participant_id: null,
                    group_by: groupBy
                })
            );
            navigate(urlReportPreviewTemplate());
        }
    };

    // Effects

    useEffect(() => {
        const mapper = (tpl) => {
            return { label: tpl.name, value: tpl.tpl_code };
        };
        handleLoadTemplatesByType(REPORT_TYPE.MULTI_GROUP).then((res) => {
            if (res !== undefined) setGroupTemplates(res.map(mapper));
        });
        handleLoadTemplatesByType(REPORT_TYPE.MULTI_INDIVIDU).then((res) => {
            if (res !== undefined) setIndividualTemplates(res.map(mapper));
        });
        handleLoadSurveyReport(surveyCode.survey_code).then((res) => {
            if (res === null || res === undefined) {
                return;
            }
            dispatch(
                rootAction.reportTemplate.setUrlData({
                    group_option: parseInt(res.group_option),
                    group_code: res.group_tpl_code,
                    individu_option: parseInt(res.individu_option),
                    individu_code: res.individu_tpl_code
                })
            );
            setGroupOpt(parseInt(res.group_option));
            setInidividuOpt(parseInt(res.individu_option));
        });
    }, []);

    useEffect(() => {
        setParticipants(participantsReducer);
    }, [participantsReducer]);

    return (
        <div>
            <DivRow>
                <div className="me-4 col-md-2 fw-bold"> Group Report Template</div>
                <div className="">
                    <Form.Check
                        inline
                        label={`${
                            reportUrl.group_option == 1 ? 'Update' : 'Create'
                        } Template`}
                        name={'tpl_group'}
                        type="radio"
                        id={`inline-tpl_group-1`}
                        value={1}
                        onChange={() => setGroupOpt(1)}
                        checked={groupOpt == 1}
                    />
                    <Form.Check
                        inline
                        label="Choose Template"
                        name={'tpl_group'}
                        type="radio"
                        id={`inline-tpl_group-2`}
                        value={2}
                        onChange={() => setGroupOpt(2)}
                        checked={groupOpt == 2}
                    />
                </div>
                <div>
                    {groupOpt == 1 ? (
                        <BtnMini
                            label={`${reportUrl.group_option == 1 ? 'Update' : 'Create'}`}
                            onClick={handleNavigateTemplateGroup}
                        />
                    ) : null}
                    {groupOpt == 2 ? (
                        <FormSelect
                            id={`id-tpl_group`}
                            items={groupTemplates}
                            value={reportUrl.group_code}
                            onChange={handleChangeTemplateGroup}
                        />
                    ) : null}
                </div>
            </DivRow>

            <DivRow>
                <div className="me-4 col-md-2 fw-bold">Generate Group Report</div>
                <div className=" me-2">
                    <FormSelect
                        id={'report_select'}
                        ref={groupRef}
                        items={itemSelect}
                        onChange={(e) => {
                            setGroupBy(e.target.value);
                        }}
                    />
                </div>
                <div className=" me-2">
                    <ButtonPrimary
                        label="View"
                        style={{
                            backgroundColor: colors.view,
                            color: 'white',
                            borderColor: colors.view
                        }}
                        onClick={handleReportPreviewGroup}
                    />
                </div>
                <div className=" me-2">
                    <ButtonPrimary
                        label="Download Excel"
                        style={{
                            backgroundColor: colors.excel,
                            color: 'white',
                            borderColor: colors.excel
                        }}
                        onClick={() => handleDownloadReport(survey_code)}
                    />
                </div>
                <div className=" me-2">
                    <ButtonPrimary
                        label="Download Doc"
                        style={{
                            backgroundColor: colors.pdf,
                            color: 'white',
                            borderColor: colors.pdf
                        }}
                        onClick={handleDownloadDocGroup}
                    />
                    {isDownloading && isGroupDownload && (
                        <DownloadSpinner percentage={downloadPercentage} />
                    )}
                </div>
                {/* <div className=" me-2">
                    <ButtonPrimary label="Finalize All" disabled={true} />
                </div> */}
            </DivRow>

            <hr className="my-4" />

            <DivRow className="mt-4">
                <div className="me-4 col-md-2 fw-bold mb-2">
                    Individual Report Template
                </div>
                <div className="">
                    <Form.Check
                        inline
                        label={`${
                            reportUrl.individu_option == 1 ? 'Update' : 'Create'
                        } Template`}
                        name={'tpl_individu'}
                        type="radio"
                        id={`inline-tpl_individu-1`}
                        value={1}
                        onChange={() => setInidividuOpt(1)}
                        checked={individuOpt == 1}
                    />
                    <Form.Check
                        inline
                        label="Choose Template"
                        name={'tpl_individu'}
                        type="radio"
                        id={`inline-tpl_individu-2`}
                        value={2}
                        onChange={() => setInidividuOpt(2)}
                        checked={individuOpt == 2}
                    />
                </div>
                <div>
                    {individuOpt == 1 ? (
                        <BtnMini
                            label={`${
                                reportUrl.individu_option == 1 ? 'Update' : 'Create'
                            }`}
                            onClick={handleNavigateTemplateIndividu}
                        />
                    ) : null}
                    {individuOpt == 2 ? (
                        <FormSelect
                            id={`id-tpl_individu`}
                            items={individualTemplates}
                            value={reportUrl.individu_code}
                            onChange={handleChangeTemplateIndividu}
                        />
                    ) : null}
                </div>
            </DivRow>

            {/* <hr /> */}

            <div className="mt-2 mb-4">
                <div className="row justify-content-between mb-2">
                    <div className="col-4 d-flex flex-row align-items-center">
                        <div>Show </div>
                        <ShowEntries
                            onClick_item={handleShowItem}
                            default_item={showItem}
                        />
                        <div className=""> entries</div>
                    </div>

                    <div className="col-3 d-flex align-items-center">
                        <SearchColumn
                            onChange_input={handleChangeSearchText}
                            onClick_btn={handleSearchBar}
                        />
                    </div>
                </div>

                <div className="mt-1 table-responsive">
                    <p className="fw-bold">Target Participants</p>
                    <table className="table table-striped table-bordered table-sm">
                        <thead>
                            <tr className="text-center align-middle">
                                <th rowSpan={2}>No</th>
                                <th rowSpan={2}>Name</th>
                                <th rowSpan={2}>NIK</th>
                                <th rowSpan={2}>Email</th>
                                <th rowSpan={2}>Level</th>
                                <th rowSpan={2}>Department</th>
                                <th rowSpan={2}>Business Unit</th>
                                <th colSpan={3}>Individual Report</th>
                            </tr>
                            <tr className="text-center align-middle">
                                <th>Preview</th>
                                <th colSpan={2}>Download</th>
                            </tr>
                        </thead>
                        <tbody>
                            {participants.map((dt, i) => (
                                <tr key={i} className="text-center align-middle">
                                    <td>{getIndexNo(i, pageNumber, showItem)}</td>
                                    <td>{dt.full_name}</td>
                                    <td>{dt.nik}</td>
                                    <td>{dt.email}</td>
                                    <td>{dt.level}</td>
                                    <td>{dt.department_name}</td>
                                    <td>{dt.business_unit_name}</td>
                                    <td>
                                        <IconButtonGeneral
                                            color_enabled={colors.view}
                                            icon={faFile}
                                            size={'xl'}
                                            onClick={() => handleReportPreview(dt.id)}
                                        />
                                    </td>
                                    <td>
                                        <IconButtonGeneral
                                            color_enabled={colors.excel}
                                            icon={faFileExcel}
                                            size={'xl'}
                                        />
                                    </td>
                                    <td>
                                        <IconButtonGeneral
                                            color_enabled={colors.pdf}
                                            icon={faFileWord}
                                            size={'xl'}
                                            onClick={() =>
                                                handleDownloadDocIndividu(dt.id)
                                            }
                                        />{' '}
                                        {isDownloading &&
                                            !isGroupDownload &&
                                            selectedPid == dt.id && (
                                                <DownloadSpinner
                                                    percentage={downloadPercentage}
                                                />
                                            )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <Pagination
                    {...paginationProps}
                    totalItem={participants.length}
                    nextPage={handleNextPage}
                    showing={Array.isArray(participants) ? participants.length : 0}
                />
            </div>
        </div>
    );
}

export default TabReportView;
