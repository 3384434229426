import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilter } from "@fortawesome/free-solid-svg-icons"
import { ShowEntries } from "../../../shared/component/table_property/ShowEntries"
import { SearchColumn } from "../../../shared/component/table_property/SearchColumn"
import FilterModal from "../../../shared/component/modal/FilterModal"
import { useState } from "react"
import {  IconButtonView } from "../../../shared/component/button/IconButton"
import ReadDetailTargetParticipantView from "./detail_target_participant/ReadDetailTPView"
import Pagination from "../../../shared/component/table_property/Paginatination"
import useTargetParticipantHistory from "./useTargetParticipantHistory"
import LoadingBar from "react-top-loading-bar"

function TargetParticipantHistoryView() {
  const [openFilter, setOpenFilter] = useState(false);
  const [openView, setOpenView] = useState(false)
  const [participantdetail, setParticipantDetail] = useState();
  const [surveyDetail, setSurveyDetail] = useState([]);
  const { onGetAllTPHistoryData,
    allTPHistory,
    pageNumber,
    setSearchText,
    searchText,
    setPageNumber,
    filterBu,
    filterDep,
    filterLevel,
    setLimit,
    setFilterBu,
    setFilterDep,
    setFilterLevel,
    limit,
    handleInputSearchChange,
    handleSearchData,
    progress,
      setProgress,
    totalItem} = useTargetParticipantHistory();

  const handleReadDetailData = (index) => {
    setParticipantDetail(allTPHistory[index]);
    if (allTPHistory[index].survey_detail != null && Array.isArray(allTPHistory[index].survey_detail) && allTPHistory[index].survey_detail.length != 0) {
      setSurveyDetail(allTPHistory[index].survey_detail)
    }
    setOpenView(true);
   
  }

  const filterField = [
    {
      field_name: 'Level',
      field_type: 'text',
      field_category: []
    },
    {
      field_name: 'Business Unit',
      field_type: 'text',
      field_category: []
    },
    {
      field_name: 'Department',
      field_type: 'text',
      field_category: []
    },
  ];

  const paginate = (pageNumber) => {
    setPageNumber(pageNumber);
 };

 const previousPage =  () => {
      if (pageNumber != 1) {
        setPageNumber(pageNumber - 1);
        
      }
  };

  const nextPage = () => {
      if (pageNumber != Math.ceil(totalItem/ limit)) {
        setPageNumber(pageNumber + 1);
      }
  };

 
  return (
    <>
       <LoadingBar color="#7749F8" progress={progress} onLoaderFinished={() => setProgress(0)} />

      <div className="container tw-h-max tw-max-w-[1400px]">
      <div className='m-4 d-flex'><h4>Target Participant History</h4></div>

      <div className='shadow p-4'>

        <div className='row justify-content-between'>
          <div className='col-4 d-flex flex-row align-items-center'>
            <div>
              Show
            </div>

            <ShowEntries onClick_item={setLimit} default_item={limit}  />

            <div className=''>
              entries
            </div>
          </div>

          <div className='col-3 d-flex align-items-center'>
            <button className="me-2 btn btn-light" onClick={() => setOpenFilter(true)}>
              <FontAwesomeIcon icon={faFilter} />
            </button>
            <SearchColumn onChange_input={handleInputSearchChange} onClick_btn={handleSearchData}
  />
            <FilterModal
              size={'sm'}
              show={openFilter}
              onHideModal={() => setOpenFilter(false)}
              filterField={filterField}
              title={'Filter By'}
            />
          </div>

        </div>

        <div className='mt-1 table-responsive'>
          <table className="table table-striped table-bordered table-sm">
            <thead>
              <tr style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                <th rowSpan={2}  >No</th>
                <th rowSpan={2} >Name</th>
                <th rowSpan={2} >NIK</th>
                <th rowSpan={2} >Email</th>
                <th rowSpan={2} >Business Unit</th>
                <th colSpan={2} >Number of Surveys</th>
                <th rowSpan={2}> View Detail</th>
              </tr>
              <tr style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                <th>Invited</th>
                <th>Completed</th>

              </tr>

            </thead>
            <tbody>
              { allTPHistory.length != 0 ?
                allTPHistory.map((dt, i) => {
                  return (<tr key={i} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                    {
                      pageNumber == 1 ? <td>{i+1}</td> : <td>{(i+1) + (limit*(pageNumber-1))}</td>
                    }
                    <td>{dt.full_name}</td>
                    <td>{dt.nik}</td>
                    <td>{dt.email != undefined && typeof dt.email == 'string' ? dt.email.toLowerCase() : dt.email}</td>
                    <td>{dt.business_unit_name}</td>
                    <td>{dt.invited}</td>
                    <td>{dt.completed}</td>
                    <td><IconButtonView onClick={() => handleReadDetailData(i)} /></td>
                  </tr>)
                })
                :
                <tr>
                  <td style={{textAlign:'center'}} colSpan={8}>No data</td>
                </tr>
              }
            </tbody>
          </table>
        </div>
        <Pagination showing={allTPHistory.length} totalItem={totalItem} showItem={limit} previousPage={previousPage} nextPage={nextPage} paginate={paginate} limit={pageNumber}/>

        
      </div>
      <ReadDetailTargetParticipantView
        show={openView}
        onHide={() => setOpenView(false)}
        size={'lg'}
        participant={participantdetail}
        survey_detail={surveyDetail}
      />


      </div>
    </>
   
  )
}

export default TargetParticipantHistoryView;

const survey_detail= [
  {
    survey_code: "",
    survey_name: "",
    status: "",
    start_date: "",
    end_date: ""
  }
]

