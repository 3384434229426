import { ButtonPrimary } from "../../shared/component/button/Button"
import loginLogo from "../../assets/images/logo_horizontal_tc.png";
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import loading from '../../assets/json/loading2.json'
import Lottie from "lottie-react";
import './LoadingLogin.css'
import useAuthomaticLoginByLink from "./useAutomaticLoginByLink";
import { useDispatch } from "react-redux";
import { rootAction } from "../../redux/RootAction";

// SurveyLogin.js

 // Untuk menangani parameter URL dan navigasi

const LoginSurveyByLink = () => {
  const { survey_code, raters_id, participant_id, token } = useParams(); // Mengekstrak parameter dari URL
//   const history = useHistory();
  const {surveyDetailData,onGetDetailSurveyData} = useAuthomaticLoginByLink();
  const dispatch = useDispatch()

  useEffect(() => {
    // taruh get API disini
  //   dispatch(rootAction.user.userLoginAction({
  //     token: token,
  // }));
    onGetDetailSurveyData(survey_code,raters_id,participant_id,token)
    //taruh ke redux user
    
    //route ke 

  }, [survey_code, raters_id, participant_id, token]);

  return (
    <div className='backdrop-container'>
            <div className='backdrop-content'>
                <Lottie animationData={loading} loop={true} style={{width:'100px',height:'100px'}}/>
                Please wait!
            </div>
        </div>
  );

};

export default LoginSurveyByLink;


