export const SingleRaterListService = ({ doPost }) => {
    const getAllSingleRatersList = async (
      limit,
      page_number,
      search_bar,
      rater_nik
    ) => {
      try {
        return await doPost({
          url: "/participant/survey_assign_raters",
          data: {
            limit: limit,
            page_number: page_number,
            survey_type:'single',
            search_bar: search_bar,
            rater_nik : rater_nik
          },
        });
      } catch (e) {
        throw e;
      }
    };

    const getCountActiveSRSurvey = async (
      nik
    ) => {
      try {
        return await doPost({
          url: "/participant/count_active_survey_assign_raters",
          data: {
            nik:nik,
            survey_type:'single',
          },
        });
      } catch (e) {
        throw e;
      }
    };
  
    return {
        getAllSingleRatersList,
        getCountActiveSRSurvey
    };
  };