import { useEffect, useState } from "react";
import { useDeps } from "../../../shared/context/DependencyContext";
import screenToast from "../../../shared/hook/ScreenToast";
import useViewState from "../../../shared/hook/UseViewState";

function useIntroduction() {
    const {adminService} = useDeps();
    const [introductionText, setIntroductionText] = useState('');
    // const [imagePath, setImagePath] = useState();
    // const [image,setImage] = useState();
    const [defaultData, setDefaultData] = useState(false)
    const [changeData, setChangeData] = useState(false)
    const showToast = screenToast();



    useEffect(() => {
        onGetIntroduction();
    },[changeData])

    
    const onGetIntroduction = async () => {
        try {
            const response = await adminService.instructionService.getInstructionText();

            if (response.status == 'SUCCESS') {
                setDefaultData(true)
                setIntroductionText(response.data.intro_text)
                // setImagePath(response.data.intro_image_link)

            }
        } catch (error) {
            console.log(error)
        } finally {
            // console.log('finally onGetIntroduction')
        }
    }

    const onPostIntroduction = async (teks) => {
        
        try {
            const response = await adminService.instructionService.postInstruction(teks);
            if (response.status == 'SUCCESS'){
                
                // console.log(response)
            }
            
        } catch (error) {
            console.log(error)
        } finally {
          
        }
    }

    const onUpdateIntroduction = async (teks) => {
        
        try {
            const response = await adminService.instructionService.updateInstruction(teks);
            if (response.status == 'SUCCESS'){
                showToast.success('Saved successfully')
            }else {
                showToast.error(response.status)
            }
            
        } catch (error) {
            console.log(error)
            showToast.error('Error System')
        } finally {
          
        }
    }

    

    // const onGetImage = async (image_path) => {
    //     try {
    //         const response = await adminService.instructionService.getInstructionImage(image_path);

            
    //             setImage(response)
    //             console.log(typeof response);
    //             console.log(response);
            
    //     } catch (error) {
    //         console.log(error)
    //     } finally {
          
    //     }
    // }

   

    // const handleChangeImage = (e) => {
    //     const file = e.target.files[0];
    //     if (file) {
    //         const reader = new FileReader();
      
    //         reader.onload = (e) => {
    //           // Mendapatkan URL data sebagai binary string
    //           const dataUrl = e.target.result;
    //           setImagePath(dataUrl);
    //         };
      
    //         // Membaca file sebagai URL data
    //         reader.readAsArrayBuffer(file);
    //       }

        
        // console.log("Type of file ", typeof file);
        // console.log('file => ', file);
        // const url = URL.createObjectURL(file);
        // console.log("File name : ", file.name);
        // var result = ''
        // let reader = new FileReader();
        // console.log(`url ${url}`);
        // // setImagePath(url);

        // if (imagePath) {
            
        //     reader.readAsDataURL(file);
        //     reader.onload = function() {
        //         console.log("Data read : ", reader.result);
        //         // result = reader.result
        //         result = reader.result;
        //         console.log("READER RESULT : ", reader.readAsBinaryString);
        //         setImagePath(result)
        //     };
        //     reader.onerror = function() {
        //         console.log(reader.error);
        //     };
        // }else {
        //     console.log(`masuk ke else`);
        // }
        // ===========
        // let reader = new FileReader();
        // let file = e.target.files[0];
        // console.log('I can see the file s info'); //I can see the file's info
        // console.log(file); //I can see the file's info
        // reader.onload= () => {
        //     var array = new Uint32Array(file);
        //     console.log("_+_array:",array); // the array is empty!
        //     var binaryString = String.fromCharCode.apply(null,array) ;
        //     console.log("__binaryString:",binaryString);
        //   setImagePath(
        //     binaryString);
        // }
        // reader.readAsBinaryString(file)
    // }

    const handleChangeText = (e) => {
        setIntroductionText(e.target.value);
        console.log(e.target.value)
    }

    const handleSubmit = () => {
         onUpdateIntroduction(introductionText);
    }

    
    return{
        handleChangeText,
        handleSubmit,
        introductionText
    }
}

export default useIntroduction;