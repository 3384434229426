import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import screenToast from '../hook/ScreenToast';

function PicIsLogged() {
   const pic = useSelector((state) => state.user);
   const showToast = screenToast();
   let url;

   if (!pic || pic.role <= 0) {
      showToast.error("Please Login");
      url = '/login'
   } else if (pic.role != 2) {
      showToast.error("Please Login as PIC");
      url = pic.role == 1 ? '/admin/error' : '/participant/error';
   }

   return (
      pic && pic.role == 2 ? <Outlet /> : <Navigate to={url} />
   );
}

export { PicIsLogged };