export const dataQuestion = [
    {
      goal_name: "",
      group_survey: "",
      dimension: "",
      aspect: "",
      item: "",
      item_in_eng: "",
      action_for_strength: "",
      action_for_weakness: "",
      total_field: 0
    },

]

export const thTableQuestion = [
    'ID','Goal','Group','Dimension', 'Aspect','Item (Main Language)'
];

export const filterFieldQuestion = [
  {
    field_name:'ID',
    field_type:'text',
    field_category:[]
  },
  {
    field_name:'Goal',
    field_type:'category',
    field_category:['config goal 1','config goal 2','config goal 3','config goal 4','config goal 5','config goal 6','config goal 7','others']
  },{
    field_name:'Group',
    field_type:'text',
    field_category:[]
  },{
    field_name:'Dimension',
    field_type:'text',
    field_category:[]
  },{
    field_name:'Aspect',
    field_type:'text',
    field_category:[]
  },
  {
    field_name:'Item (Main Language)',
    field_type:'text',
    field_category:[]
  },

  
];

