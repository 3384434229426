import Modal from 'react-bootstrap/Modal';
import { ButtonPrimary } from '../../../shared/component/button/Button';
import { InputOnly } from '../../../shared/component/input/Input';

function AddGoal({onChangeGoal,onSave,show,onHide,goal}) {
    return(
    <Modal
      show={show}
      
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
      
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Goal
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='d-flex' >
        
        <InputOnly type={'text'} handleOnChange={onChangeGoal} input_width={'100%'} style={{marginRight:'0.5rem'}} />
       
        <ButtonPrimary onClick={onSave} label='Save' disabled={goal == '' ? true : false}></ButtonPrimary>
      </Modal.Body>
    </Modal>
    )
}

export default AddGoal;