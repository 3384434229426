import { Table } from 'react-bootstrap';
import {
    CONTENTS,
    groupOptions,
    dimensionOptions,
    aspectOptions,
    itemOptions,
    yearOfServiceOptions,
    businessUnitOptions,
    companyOptions,
    departmentOptions,
    genderOptions,
    levelOptions
} from '../../constants';
import { createRandom, randomTypes } from '../../utils/createRandom';

const {
    SR_SUMMARY_GROUP,
    SR_SUMMARY_DIMENSION,
    SR_SUMMARY_ASPECT,
    SR_SUMMARY_ITEM
} = CONTENTS;
const { BASE10, BASE100 } = randomTypes;

const findMedian = (arr) => {
    const mid = Math.floor(arr.length / 2),
        nums = arr.sort((a, b) => a - b);
    return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
};

const createData = (contentId) => {
    let options = [];

    if (contentId.startsWith(SR_SUMMARY_GROUP)) {
        options = groupOptions;
    } else if (contentId.startsWith(SR_SUMMARY_DIMENSION)) {
        options = dimensionOptions;
    } else if (contentId.startsWith(SR_SUMMARY_ASPECT)) {
        options = aspectOptions;
    } else if (contentId.startsWith(SR_SUMMARY_ITEM)) {
        options = itemOptions;
    }

    return options.map((opt) => {
        const cols = getColumnOptions(contentId.substring(7)).map((_) =>
            createRandom(BASE10, 1, 10)
        );
        const total = createTotal(contentId.substring(8), cols);
        return [opt, total, ...cols];
    });
};

const createTotal = (suffix, data = []) => {
    return suffix === '.1'
        ? Math.min(...data)
        : suffix === '.2'
        ? Math.max(...data)
        : suffix === '.3'
        ? findMedian(data)
        : suffix === '.4'
        ? (data.reduce((a, b) => a + b, 0) / data.length).toFixed(2)
        : null;
};

const getColumnName = (suffix) => getColumns(suffix, false);

const getColumnOptions = (suffix) => getColumns(suffix, true);

const getColumns = (suffix, isOptions) => {
    if (['1.1', '1.2', '1.3', '1.4'].includes(suffix)) {
        //YOS
        return isOptions ? yearOfServiceOptions : 'Year of Services';
    } else if (['2.1', '2.2', '2.3', '2.4'].includes(suffix)) {
        //Dept
        return isOptions ? departmentOptions : 'Department';
    } else if (['3.1', '3.2', '3.3', '3.4'].includes(suffix)) {
        //BU
        return isOptions ? businessUnitOptions : 'Business Unit';
    } else if (['4.1', '4.2', '4.3', '4.4'].includes(suffix)) {
        //Company
        return isOptions ? companyOptions : 'Company';
    } else if (['5.1', '5.2', '5.3', '5.4'].includes(suffix)) {
        //Gender
        return isOptions ? genderOptions : 'Gender';
    } else if (['6.1', '6.2', '6.3', '6.4'].includes(suffix)) {
        //Level
        return isOptions ? levelOptions : 'Level';
    }
    return isOptions ? [] : '';
};

const createColumns = (contentId) => {
    const firstCol = contentId.startsWith(SR_SUMMARY_GROUP)
        ? 'Group'
        : contentId.startsWith(SR_SUMMARY_DIMENSION)
        ? 'Dimension'
        : contentId.startsWith(SR_SUMMARY_ASPECT)
        ? 'Aspect'
        : contentId.startsWith(SR_SUMMARY_ITEM)
        ? 'Item'
        : null;
    const secCol = 'Techconnect ' + new Date().getFullYear();
    const otherCols = getColumnOptions(contentId.substring(7));
    return [firstCol, secCol, ...otherCols];
};

const SummaryTable = ({ contentId, title }) => {
    const data = createData(contentId);
    const columns = createColumns(contentId);
    const colTitle = getColumnName(contentId.substring(7));

    return (
        <>
            <h6 className="fw-light">{title}</h6>
            <CreateTable columns={columns} data={data} title={colTitle} />
        </>
    );
};

const CreateTable = ({ columns, data, title }) => {
    return (
        <Table striped bordered hover size="sm" className="fw-light">
            <thead className="text-center">
                <tr>
                    <th rowSpan={3} style={{ verticalAlign: 'middle' }}>
                        {columns[0]}
                    </th>
                    <th
                        rowSpan={3}
                        style={{ verticalAlign: 'middle', width: '10%' }}
                    >
                        {columns[1]}
                    </th>
                    <th
                        colSpan={columns.length - 2}
                        style={{ verticalAlign: 'top' }}
                    >
                        {title}
                    </th>
                </tr>
                <tr>
                    {Array.isArray(columns) &&
                        columns.map((c, i) => (
                            <>
                                {i > 1 && (
                                    <th>{createRandom(BASE100, 10, 50)}</th>
                                )}
                            </>
                        ))}
                </tr>
                <tr>
                    {Array.isArray(columns) &&
                        columns.map((c, i) => <>{i > 1 && <th>{c}</th>}</>)}
                </tr>
            </thead>
            <tbody>
                {Array.isArray(data) &&
                    data.map((row, rowId) => (
                        <tr>
                            {Array.isArray(row) &&
                                row.map((col, colId) => (
                                    <td
                                        className={`${
                                            colId > 0 ? 'text-end' : ''
                                        }`}
                                    >
                                        {data[rowId][colId]}
                                    </td>
                                ))}
                        </tr>
                    ))}
            </tbody>
        </Table>
    );
};

export default SummaryTable;
