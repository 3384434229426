import { Dropdown, Modal } from "react-bootstrap";
import { InformationWithLabel, InputWithButton } from "../../../shared/component/input/Input";
import { DropdownWithLabel } from "../../../shared/component/dropdown/DropdownInput";
import { ButtonPrimary } from "../../../shared/component/button/Button";
import useSharingSurvey from "./useSharingSurvey";
import { useState } from "react";

function ModalSharingSurvey({size,show,onHide,title, survey_code, owner,shared_by,survey_name}) {
    const {selectedPIC,handleGetPICbyNIK, message, handleSharingSurvey, setMessage, setSelectedPIC} = useSharingSurvey(onHide);
    const [nikPIC, setNikPIC] = useState('');
    const [roleOwnership, setRoleOwnership] = useState('viewer')

    const handleOnChangeNIK = (e) => {
        setNikPIC(e.target.value)
    }

    const handleOnHide = () => {
        setMessage(false);
        setRoleOwnership('viewer');
        setNikPIC('');
        setSelectedPIC()
        onHide()
    }


    return(
        <Modal
        size={size}
        show={show}
        onHide={handleOnHide}
        aria-labelledby="contained-modal-title-vcenter"

        >
        <Modal.Header closeButton >
            <div>
                <div>
                    <Modal.Title id="contained-modal-title-vcenter">
                    {title}
                    </Modal.Title>
                </div>
            <div className="mt-2 fw-semibold">
                {survey_name}
            </div>
            </div>
        </Modal.Header>

        <Modal.Body>
            <div className="fst-italic my-2">Please enter the NIK of the PIC you want to share this survey with</div>
            <InputWithButton class_btn={'btn btn-success'} label_button={'Check NIK'} style_btn={{borderRadius:0,height:'100%',color:'white'}}
             onChange_input={handleOnChangeNIK}
             onClick_btn={()=>handleGetPICbyNIK(nikPIC)}
             />
        
        {
            message ?
            <div className="my-4">
                <div className="fw-bold d-flex" >
                    <div style={{color:'#17A2B8'}}>
                        Data Found ,
                    </div>
                    <div className="ms-2 fst-italic">
                    Please select a role !
                    </div>
                </div>
                <div className="my-2">
                    <InformationWithLabel label={'Name'} value={selectedPIC.name}/>
                </div>
                <div className="my-2">
                    <InformationWithLabel label={'Email'} value={selectedPIC.email}/>
                </div>
                <div className="my-2">
                    <DropdownWithLabel 
                    label={'Role'}
                    default_item={roleOwnership != 'viewer' ? roleOwnership : 'Select'}
                    dropdown_item={<div>
                        <Dropdown.Item onClick={()=>setRoleOwnership('explorer')}>explorer</Dropdown.Item>
                        <Dropdown.Item onClick={()=>setRoleOwnership('editor')}>editor</Dropdown.Item>
                    </div>}
                    />
                </div>
            </div>
            :
            <div className="my-4 fw-bold" style={{color:"#DC3545"}}>
            Data not found !
            </div>
        }
           

            
        </Modal.Body>
        {
            message ? 
            <Modal.Footer>
                <ButtonPrimary label="Share" onClick={()=>handleSharingSurvey(survey_code,owner,selectedPIC.name,selectedPIC.no_hp,selectedPIC.nik,selectedPIC.email,roleOwnership,shared_by)}
                disabled={roleOwnership == 'viewer' ? true : false}    
                    />
            </Modal.Footer>
            :
            <Modal.Footer>
                <ButtonPrimary label="Close" onClick={handleOnHide}/>
            </Modal.Footer>
        }
       

        </Modal>
    )
}

export default ModalSharingSurvey;