import screenToast from "../../hook/ScreenToast";
import { rootAction } from "../../../redux/RootAction";
import axios from "axios";

let dispatch;
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
   failedQueue.forEach(prom => {
      if (error) {
         prom.reject(error);
      } else {
         prom.resolve(token);
      }
   });

   failedQueue = [];
};

export const injectDispatch = (_dispatch) => {
   dispatch = _dispatch;
}

export const responseHandler = (response) => response;

export const authExpiredInterceptor = async (error) => {
   const showToast = screenToast();
   const originalRequest = error.config;

   if (error.response && error.response.status === 401 && error.response.data.detail === 'TOKEN EXPIRED' && !originalRequest._retry) {
      if (isRefreshing) {
         try {
            const token = await new Promise(function (resolve, reject) {
               failedQueue.push({ resolve, reject });
            });
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            return await axios(originalRequest);
         } catch (err) {
            return await Promise.reject(err);
         }
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise(function (resolve, reject) {
         // TODO: Change with api refresh token
         axios.post('http://localhost:8000/refresh-token')
            .then((response) => {
               // TODO: Store new token to storage
               // response.data.status === 'SUCCESS'
               // dispatch(rootAction.user.userRefreshTokenAction(response.data.token));
               // originalRequest.headers['Authorization'] = 'Bearer ' + response.data.token;
               // processQueue(null, response.data.token);
               resolve(axios(originalRequest));
            })
            .catch((err) => {
               processQueue(err, null);
               reject(err);

               showToast.error("Session expired.");
               dispatch(rootAction.user.userLogoutAction());
            })
            .finally(() => { isRefreshing = false })
      })
   }

   return Promise.reject(error);
}