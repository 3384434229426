import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import screenToast from '../hook/ScreenToast';

function AdminIsLogged() {
   const admin = useSelector((state) => state.user);
   const showToast = screenToast();
   let url;

   if (!admin || admin.role <= 0) {
      showToast.error("Please Login");
      url = '/login'
   } else if (admin.role != 1) {
      showToast.error("Please Login as Admin");
      url = admin.role == 2 ? '/pic/error' : '/participant/error';
   }

   return (
      admin && admin.role == 1 ? <Outlet /> : <Navigate to={url} />
   );
}

export { AdminIsLogged };
