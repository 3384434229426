import { Stack, Table } from "react-bootstrap";
import { CONTENTS } from "../../constants";

const DevelopmentForm = ({ contentId, title }) => {
    return (
        <>
            {contentId === CONTENTS.DEV_FORM_CHALLENGE
                ? <Challenge title={title} />
                : (contentId === CONTENTS.DEV_FORM_KEEPSTARTSTOP
                    ? <KeepStartStop />
                    : null)}
        </>
    )
}

const Challenge = ({ title }) => (
    <div className="fw-light px-4">
        <div className="fw-bold">{title}</div>
        {[1,2,3,4,5,6].map(num => (
            <div className="border-bottom">{num}.</div>
        ))}
    </div>
)

const KeepStartStop = () => (
    <Stack direction="horizontal" gap={3}>
        <KSSTable title={'KEEP'} />
        <KSSTable title={'START'} />
        <KSSTable title={'STOP'} />
    </Stack>
)

const KSSTable = ({ title }) => (
    <Stack className="fw-light border">
        <div className="text-center">{title}</div>
        <div>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <td>No.</td>
                        <td>Aspect</td>
                        <td>Action</td>
                        <td>Date</td>
                    </tr>
                </thead>
                <tbody>
                    {[0,1].map(_ => (<tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                    </tr>))}
                </tbody>
            </Table>
        </div>
    </Stack>
)

export default DevelopmentForm;
