import { CONSTANTS } from '../common/constants';

export const ReportTemplateService = ({ doPost, role }) => {
    const baseURL = role === CONSTANTS.ROLE.ADMIN ? 'admin' : 'pic';

    const getSurveyReport = async (surveyCode) => {
        try {
            return await doPost({
                url: `/${baseURL}/get_survey_report`,
                data: {
                    survey_code: surveyCode
                }
            })
        } catch (err) {
            throw err;
        }
    }

    const updateSurveyReport = async (reportData) => {
        try {
            return await doPost({
                url: `/${baseURL}/update_survey_report`,
                data: reportData
            })
        } catch (err) {
            throw err;
        }
    }

    const getListData = async (search, limit, page_number, type = '') => {
        try {
            return await doPost({
                url: `/${baseURL}/get_list_templates_by_user`,
                data: {
                    search: search,
                    type: type,
                    created_by: '',
                    limit: limit,
                    page_number: page_number
                }
            });
        } catch (err) {
            throw err;
        }
    };

    const getSingleData = async (templateData) => {
        try {
            return await doPost({
                url: '/admin/get_single_template',
                data: templateData
            });
        } catch (err) {
            throw err;
        }
    };

    const saveData = async (templateData) => {
        try {
            return await doPost({
                url: `/${baseURL}/add_template`,
                data: templateData
            });
        } catch (err) {
            throw err;
        }
    };

    const updateData = async (templateData) => {
        try {
            return await doPost({
                url: '/admin/update_template',
                data: templateData
            });
        } catch (err) {
            throw err;
        }
    };

    const deleteData = async (templateData) => {
        try {
            return await doPost({
                url: '/admin/delete_template',
                data: templateData
            });
        } catch (err) {
            throw err;
        }
    };

    const getPreviewReportData = async (templateData) => {
        try {
            return await doPost({
                url: '/admin/preview_docx_report',
                data: templateData
            });
        } catch (err) {
            throw err;
        }
    };

    return {
        getSurveyReport,
        updateSurveyReport,
        getListData,
        getSingleData,
        saveData,
        updateData,
        deleteData,
        getPreviewReportData
    };
};
